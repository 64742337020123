import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'stretch',
    padding: `${theme.spacing(0.7)}px ${theme.spacing(1.2)}px`,
    width: '100%',
    backgroundColor: ({ active }) =>
      active ? theme.colors.divider : 'transparent',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.colors.divider,
    },

    // [theme.breakpoints.down('sm')]: {
    //   '&:last-child': {
    //     // marginBottom: '172px',
    //   },
    // },

    // [theme.breakpoints.up('sm')]: {
    //   '&:last-child': {
    //     marginBottom: '79px',
    //   },
    // },
    // [theme.breakpoints.up('md')]: {
    //   '&:last-child': {
    //     marginBottom: '26px',
    //   },
    // },
  },
  title: {
    fontSize: theme.spacing(1.75),
  },
  about: {
    fontSize: theme.spacing(1.5),
    color: transparentize(0.42, theme.colors.white),
  },
  icon: {
    padding: '0 8px 0 0',
  },
  descriptionWrap: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    overflow: 'hidden',

    '& > .MuiBox-root': {
      height: theme.spacing(7),
    },
  },
  imageWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1.2),
    flex: `0 0 ${theme.spacing(7)}px`,
    width: theme.spacing(7),
    height: theme.spacing(9.5),
    borderRadius: theme.spacing(0.25),
    backgroundColor: theme.colors.storyListItemBg,
    overflow: 'hidden',
  },
}));

import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Image = ({ src, alt, ...props }) => {
  const { root } = useStyles();

  return <img src={src} alt={alt} className={root} {...props} />;
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Image;

import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default makeStyles((theme) => ({
  loader: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: transparentize(0.6, theme.colors.black),
    zIndex: 10,
  },
  innerWrap: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },

  gridLines: {
    height: '100%',
    pointerEvents: 'none',
    width: '100%',
    margin: 0,
    backgroundImage:
      'repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%), repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%)',
    top: 0,
    position: 'absolute',
    zIndex: '1000',
    '&.main': {
      backgroundSize: '50px 50px',
      opacity: 0.2,
    },
    '&.secondary': {
      backgroundSize: '10px 10px',
      opacity: 0.15,
    },
  },

  wrap: {
    height: '100%',
    position: 'relative',
    width: ({ wrapWidth }) => wrapWidth,
    maxHeight: ({ wrapHeight }) => wrapHeight,
    overflowY: 'auto',
    backgroundColor: transparentize(0.88, theme.colors.white),
    '-ms-overflow-style': 'none' /* IE and Edge */,
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },

    [theme.breakpoints.down('lg')]: {
      width: '100% !important',
    },
  },
  grid: {
    width: '100%',
    height: '100%',
  },
  zIndexButton: {
    background: 'rgba(0, 0, 0, 0.55)',
    padding: 1.5,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.6)',
    },
  },

  dropPlaceholder: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },

  dropWrap: { height: '100%', width: '100%' },
  dropTarget: { height: '100%' },

  dropOverTarget: {
    backgroundColor: transparentize(0.6, theme.colors.black),
  },
  dropLoader: { position: 'absolute' },
}));

import React, { forwardRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Input, InputLabel } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

const StyledInput = forwardRef(
  (
    {
      label,
      error,
      placeholder,
      onChangeValue,
      inputValue,
      fullWidth,
      multiline = false,
      width,
      ...props
    },
    ref
  ) => {
    const handleOnChange = useCallback(
      (e) => {
        if (onChangeValue) {
          return onChangeValue(props.name, e.currentTarget.value);
        }
        return props.onChange(e.currentTarget.value);
      },
      [props, onChangeValue]
    );

    const value = useMemo(() => {
      if (inputValue) {
        return inputValue;
      }

      return props.value || '';
    }, [inputValue, props.value]);

    return (
      <FormControl
        fullWidth={fullWidth}
        style={{ width }}
        focused
        error={!!error}
      >
        <InputLabel>{label}</InputLabel>
        <Input
          {...props}
          fullWidth={fullWidth}
          disableUnderline
          placeholder={placeholder}
          innerRef={ref}
          multiline={multiline}
          value={value}
          onChange={handleOnChange}
          onKeyDown={(e) => e.stopPropagation()}
        />
        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
);

StyledInput.displayName = 'StyledInput';

StyledInput.defaultProps = {
  placeholder: '',
  inputValue: '',
  onChangeValue: null,
  fullWidth: true,
};

StyledInput.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  placeholder: PropTypes.string,
  onChangeValue: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export default StyledInput;

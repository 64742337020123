import { PAGE_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import { pagesListSelector } from 'common/selectors/pages';
import {
  CREATE_CHECKPOINT,
  DELETE_CHECKPOINT,
  END_LOADING_CHECKPOINT,
  FETCHED_CHECKPOINT,
  START_LOADING_CHECKPOINT,
  UPDATE_CHECKPOINT,
} from 'common/types/checkpointList';
import {
  createCheckpointService,
  deleteCheckpointService,
  getCheckpointListService,
  updateCheckpointService,
} from 'services/checkpoint';
import { errorToast, successToast } from 'services/toast';
import {
  storyListEditItemSelector,
  storyListSelectedIdsSelector,
} from '../selectors/editStory';
import { storyItemsSelector } from '../selectors/story';
import {
  clearEditItemAction,
  editItemAction,
  fetchHistoryListAction,
  setEditAction,
  setSelectedAction,
} from './editStoryActions';

export const fetchedCheckpointListAction = (payload) => ({
  type: FETCHED_CHECKPOINT,
  payload,
});

export const createCheckpointItemSuccessAction = (payload) => ({
  type: CREATE_CHECKPOINT,
  payload,
});

export const deleteCheckpointItemSuccessAction = (payload) => ({
  type: DELETE_CHECKPOINT,
  payload,
});

export const updateCheckpointItemSuccessAction = (payload) => ({
  type: UPDATE_CHECKPOINT,
  payload,
});

export const startLoadingCheckpointAction = () => ({
  type: START_LOADING_CHECKPOINT,
});

export const endLoadingCheckpointAction = () => ({
  type: END_LOADING_CHECKPOINT,
});

export const fetchCheckpointListAction = (storyId) => {
  return (dispatch) => {
    dispatch(startLoadingCheckpointAction());

    getCheckpointListService({ story: storyId })
      .then((res) => {
        dispatch(endLoadingCheckpointAction());
        dispatch(fetchedCheckpointListAction(res.rows));
      })
      .catch((error) => {
        dispatch(endLoadingCheckpointAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const createCheckpointItemAction = (
  body,
  type = UPDATE_TYPE.STANDART
) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = storyListSelectedIdsSelector(state);
    const storyId = storyItemsSelector(state).id;

    dispatch(startLoadingCheckpointAction());

    createCheckpointService(
      {
        ...body,
        story: storyId,
        originPage:
          type !== UPDATE_TYPE.STANDART ? body.originPage : selectedIds.page,
        originFrame:
          type !== UPDATE_TYPE.STANDART ? body.originFrame : selectedIds.frame,
      },
      type
    )
      .then((res) => {
        dispatch(fetchHistoryListAction(storyId));
        dispatch(endLoadingCheckpointAction());
        dispatch(setSelectedAction({ ...selectedIds, page: '', frame: '' }));
        dispatch(createCheckpointItemSuccessAction(res));
        dispatch(setEditAction(false));
        dispatch(clearEditItemAction());
        successToast('Checkpoint created success');
      })
      .catch((error) => {
        dispatch(endLoadingCheckpointAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const updateCheckpointItemAction = (body, updateType) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = storyListSelectedIdsSelector(state);
    const pagesList = pagesListSelector(state);
    const story = storyItemsSelector(state);
    dispatch(startLoadingCheckpointAction());

    updateCheckpointService(body, updateType)
      .then((res) => {
        dispatch(fetchHistoryListAction(story.id));
        dispatch(fetchCheckpointListAction(story.id));
        dispatch(updateCheckpointItemSuccessAction(res));
        dispatch(endLoadingCheckpointAction());
        dispatch(
          setSelectedAction({
            ...selectedIds,
            page: res.originPage,
            frame: '',
            frameItem: '',
          })
        );
        dispatch(
          editItemAction({
            item: pagesList.find((page) => page.id === res.originPage),
            type: PAGE_TYPE,
          })
        );
        dispatch(setEditAction(false));
        successToast('Checkpoint item update success');
      })
      .catch((error) => {
        dispatch(endLoadingCheckpointAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const deleteCheckpointAction = (
  checkpoint,
  type = UPDATE_TYPE.STANDART
) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = storyListSelectedIdsSelector(state);
    const editCheckpointItem = storyListEditItemSelector(state);
    const { id } = storyItemsSelector(state);
    dispatch(startLoadingCheckpointAction());

    deleteCheckpointService(checkpoint?.id || editCheckpointItem.id, type)
      .then(() => {
        dispatch(fetchHistoryListAction(id));
        dispatch(
          deleteCheckpointItemSuccessAction(checkpoint || editCheckpointItem)
        );
        dispatch(clearEditItemAction());
        dispatch(setEditAction(false));
        dispatch(setSelectedAction({ ...selectedIds, page: '', frame: '' }));
        dispatch(endLoadingCheckpointAction());
        successToast('Checkpoint item deleted success');
      })
      .catch((error) => {
        dispatch(endLoadingCheckpointAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

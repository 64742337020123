import React from 'react';
import { Box } from '@material-ui/core';

export const CircleIcon = ({ isActive }) => {
  return (
    <Box
      style={{
        width: '12px',
        height: '12px',
        border: '2px solid white',
        borderRadius: '50%',
        opacity: isActive ? 1 : 0.5,
      }}
    />
  );
};

import { PAGE_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import { treeCompare } from 'utils/treeCompare';
import {
  CLEAR_EDIT_ITEM,
  CONFIRM_OPEN,
  EDIT_ITEM,
  END_LOADING,
  FETCH_LIST_ERROR,
  FETCH_LIST_SUCCESS,
  FETCHED_HISTORY,
  NEXT_EDIT_ITEM,
  SET_EDIT,
  SET_FRAME_ANIMATIOIN,
  SET_LOCK_FRAMES_FETCH,
  SET_SELECTED,
  SET_SELECTED_TAB,
  SET_SETTINGS,
  SET_SHOW_GRID_LINES,
  SET_SNAP_TO_FRAMES,
  SET_WARNING,
  SET_WARNING_CHECKPOINTS,
  SET_WARNING_DECISIONS,
  SET_WARNING_HOTSPOTS,
  START_LOADING,
  UPDATE_LIST_POSITION_ERROR,
  UPDATE_LIST_POSITION_SUCCESS,
  NEXT_SET_SELECTED,
} from 'common/types/editStory';
import {
  // storyListAllChaptersSelector,
  storyListEditItemSelector,
  storyListEditStatusSelector,
  storyListItemsSelector,
  storyListSelectedIdsSelector,
} from 'common/selectors/editStory';
import { storyItemsSelector } from 'common/selectors/story';
import { CREATE_BACKPACK } from 'common/types/backpackList';
import {
  createPage as createPageService,
  deletePage as deletePageService,
  updatePage as updatePageService,
} from 'services/pages';
import {
  getStoryTree as getStoryTreeService,
  updateStoryTreePosition as updateStoryTreePositionService,
} from 'services/stories';
import { errorToast, successToast } from 'services/toast';
import {
  createChapterService,
  deleteChapterService,
  ungroupChapterService,
  updateChapterListService,
  updateChapterService,
} from 'services/chapters';
import getHistoryList from 'services/history';
import { createBackpackItemService } from 'services/backpack';
import { fetchPagesAction } from './pagesListActions';

export const startLoadingAction = () => ({
  type: START_LOADING,
});

export const endLoadingAction = () => ({
  type: END_LOADING,
});

export const fetchListSuccessAction = (payload) => ({
  type: FETCH_LIST_SUCCESS,
  payload,
});

export const fetchListErrorAction = (payload) => ({
  type: FETCH_LIST_ERROR,
  payload,
});

export const fetchedHistoryListAction = (payload) => ({
  type: FETCHED_HISTORY,
  payload,
});

export const updateListPositionSuccessAction = (payload) => ({
  type: UPDATE_LIST_POSITION_SUCCESS,
  payload,
});

export const updateListPositionErrorAction = (payload) => ({
  type: UPDATE_LIST_POSITION_ERROR,
  payload,
});

export const nextSelectedAction = (payload) => ({
  type: NEXT_SET_SELECTED,
  payload,
});

export const setSelectedActionSuccess = (payload) => ({
  type: SET_SELECTED,
  payload,
});

export const editItemActionSuccess = (payload) => ({
  type: EDIT_ITEM,
  payload,
});

export const nextEditItemAction = (payload) => ({
  type: NEXT_EDIT_ITEM,
  payload,
});

export const confirmOpenAction = (payload) => ({
  type: CONFIRM_OPEN,
  payload,
});

export const clearEditItemAction = () => ({
  type: CLEAR_EDIT_ITEM,
});

export const setEditAction = (payload) => ({
  type: SET_EDIT,
  payload,
});

export const setWarningAction = (payload) => ({
  type: SET_WARNING,
  payload,
});

export const setWarningHotspotsAction = (payload) => ({
  type: SET_WARNING_HOTSPOTS,
  payload,
});

export const setWarningDecisionsAction = (payload) => ({
  type: SET_WARNING_DECISIONS,
  payload,
});

export const setWarningCheckpointsAction = (payload) => ({
  type: SET_WARNING_CHECKPOINTS,
  payload,
});

export const setSettingsAction = (payload) => ({
  type: SET_SETTINGS,
  payload,
});

export const setSelectTabAction = (payload) => ({
  type: SET_SELECTED_TAB,
  payload,
});

export const createGameItemSuccessAction = (payload) => ({
  type: CREATE_BACKPACK,
  payload,
});

export const setAnimation = (payload) => ({
  type: SET_FRAME_ANIMATIOIN,
  payload,
});

export const setLockFramesFetch = (payload) => ({
  type: SET_LOCK_FRAMES_FETCH,
  payload,
});

export const setShowGridLinesAction = (payload) => ({
  type: SET_SHOW_GRID_LINES,
  payload,
});

export const setSnapToFramesAction = (payload) => ({
  type: SET_SNAP_TO_FRAMES,
  payload,
});

export const editItemAction = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    const isEdited = storyListEditStatusSelector(state);
    const editItem = storyListEditItemSelector(state);
    const oldId =
      editItem?.id || editItem?.fid || editItem?._id || editItem?.page;
    const newId =
      data.item?.id || data.item?.fid || data.item?._id || data.item?.page;

    if (isEdited && oldId !== newId) {
      dispatch(nextEditItemAction(data));
      return;
    }
    dispatch(editItemActionSuccess(data));
  };
};

export const setSelectedAction = (newSelected, skipThree = false) => {
  return (dispatch, getState) => {
    const state = getState();
    const isEdited = storyListEditStatusSelector(state);
    const oldSelected = storyListSelectedIdsSelector(state);
    const isEqual = treeCompare(newSelected, oldSelected);
    if (isEdited && !isEqual && !skipThree) {
      dispatch(confirmOpenAction(true));
      dispatch(nextSelectedAction(newSelected));
      return;
    }
    if (!skipThree) dispatch(setSelectedActionSuccess(newSelected));
  };
};

export const fetchListAction = (storyId) => {
  return (dispatch) => {
    dispatch(startLoadingAction());

    getStoryTreeService(storyId)
      .then((res) => {
        dispatch(endLoadingAction());
        dispatch(fetchListSuccessAction(res));
      })
      .catch((error) => {
        dispatch(fetchListErrorAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const fetchHistoryListAction = (storyId) => {
  return (dispatch) => {
    getHistoryList(storyId)
      .then((res) => {
        dispatch(fetchedHistoryListAction(res));
      })
      .catch(() => {
        dispatch(fetchedHistoryListAction({}));
      });
  };
};

export const updateListPositionAction = (list) => {
  return (dispatch) => {
    dispatch(updateListPositionSuccessAction(list));
  };
};

export const updateServerPositionAction = (data) => {
  return () => {
    updateStoryTreePositionService(data).catch((error) => {
      errorToast(error?.message || 'Could not update tree position');
    });
  };
};

export const updateListPageAction = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    const list = storyListItemsSelector(state);
    const selected = storyListSelectedIdsSelector(state);
    const newList = [...list];

    if (selected.page) {
      if (!selected.chapter) {
        const index = list.findIndex((item) => item.id === data.id);
        newList[index] = data;
      } else {
        const chapterIndex = list.findIndex(
          (item) => item.id === selected.chapter
        );
        const pageIndex = list[chapterIndex].children.findIndex(
          (item) => item.id === data.id
        );
        newList[chapterIndex].children[pageIndex] = data;
      }

      dispatch(updateListPositionAction(newList));
    }
  };
};

export const addGameItemAction = (body, type = UPDATE_TYPE.STANDART) => {
  return (dispatch) => {
    createBackpackItemService(body, type)
      .then((res) => {
        dispatch(createGameItemSuccessAction(res));
      })
      .catch((error) => {
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const updatePageAction = (data, updateType = UPDATE_TYPE.STANDART) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = storyListSelectedIdsSelector(state);
    const story = storyItemsSelector(state);
    const { id } = storyItemsSelector(state);
    dispatch(startLoadingAction());
    updatePageService(data, updateType)
      .then((page) => {
        dispatch(fetchListAction(id));
        dispatch(fetchHistoryListAction(id));
        dispatch(
          setSelectedAction({
            ...selectedIds,
            page: page.id,
            chapter: page.originChapter || '',
            frame: '',
            frameItem: '',
          })
        );
        dispatch(fetchPagesAction(story.id));
        dispatch(
          editItemAction({
            item: page,
            type: PAGE_TYPE,
          })
        );
        dispatch(setEditAction(false));
      })
      .catch((error) => {
        dispatch(endLoadingAction());
        errorToast(error?.message || 'Update page failed');
      });
  };
};

export const deletePageAction = (pages, type = UPDATE_TYPE.STANDART) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = storyListSelectedIdsSelector(state);
    const { id } = storyItemsSelector(state);
    let pagesForDeleting = [];

    dispatch(startLoadingAction());

    if (Array.isArray(pages)) {
      pagesForDeleting = [...pages];
    } else {
      pagesForDeleting = [pages];
    }

    Promise.all(
      pagesForDeleting.map((pageId) => deletePageService(pageId, type))
    )
      .then(() => {
        dispatch(clearEditItemAction());
        dispatch(
          setSelectedAction({
            ...selectedIds,
            page: '',
            frame: '',
            frameItem: '',
          })
        );

        dispatch(fetchHistoryListAction(id));
        dispatch(fetchListAction(id));
        dispatch(fetchPagesAction(id));
        dispatch(setEditAction(false));
        dispatch(endLoadingAction());
        successToast('Page deleted successfully');
      })
      .catch((error) => {
        dispatch(endLoadingAction());
        errorToast(error?.message || 'Page couldn`t delete. Try again');
      });
  };
};

export const addNewPageAction = (
  data,
  type = UPDATE_TYPE.STANDART,
  id = null
) => {
  return (dispatch, getState) => {
    const state = getState();
    const list = storyListItemsSelector(state);

    dispatch(startLoadingAction());

    createPageService(type, {
      title: data.title,
      story: data.story,
      soundInfo: {},
      ...(id && { id }),
    })
      .then((res) => {
        dispatch(endLoadingAction());
        dispatch(
          setSelectedAction({
            chapter: '',
            page: res.id,
            frame: '',
            frameItem: '',
          })
        );
        dispatch(
          editItemAction({
            item: res,
            type: PAGE_TYPE,
          })
        );
        dispatch(fetchHistoryListAction(data.story));
        dispatch(setEditAction(false));
        dispatch(updateListPositionSuccessAction([...list, res]));
        dispatch(fetchPagesAction(data.story));
        successToast('Page creation successful');
        dispatch(endLoadingAction());
      })
      .catch((error) => {
        dispatch(endLoadingAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const addNewChapterAction = (data, type = UPDATE_TYPE.STANDART) => {
  return (dispatch) => {
    dispatch(startLoadingAction());

    createChapterService(data, type)
      .then((res) => {
        dispatch(
          setSelectedAction({
            chapter: '',
            page: '',
            frame: '',
            frameItem: '',
          })
        );
        dispatch(fetchHistoryListAction(data.story));
        dispatch(clearEditItemAction());
        dispatch(setEditAction(false));
        dispatch(fetchListAction(res.story));
        successToast('Chapter create success');
      })
      .catch((error) => {
        dispatch(endLoadingAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const updateChapterAction = (data, updateType) => {
  return (dispatch) => {
    dispatch(startLoadingAction());

    updateChapterService(data, updateType)
      .then((res) => {
        dispatch(fetchHistoryListAction(res.chapter.story));
        dispatch(fetchListAction(res.chapter.story));
        dispatch(setEditAction(false));
        successToast('Chapter updated success');
      })
      .catch((error) => {
        dispatch(endLoadingAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const updateChapterListAction = (pages) => {
  return (dispatch, getState) => {
    const state = getState();
    const { id } = storyItemsSelector(state);

    updateChapterListService({ story: id, ids: pages })
      .then(() => {
        dispatch(fetchListAction(id));
      })
      .catch((error) => {
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const createNewPageInChapterAction = (
  { title, story },
  chapterId,
  type = UPDATE_TYPE.STANDART
) => {
  return (dispatch, getState) => {
    const state = getState();
    const currentChapter = storyListEditItemSelector(state);
    dispatch(startLoadingAction());

    createPageService(type, {
      title,
      story,
      isInChapter: true,
      originChapter: chapterId || currentChapter.id,
    })
      .then((res) => {
        dispatch(
          setSelectedAction({
            chapter: chapterId || currentChapter.id,
            page: res.id,
            frame: '',
            frameItem: '',
          })
        );
        dispatch(
          editItemAction({
            item: res,
            type: PAGE_TYPE,
          })
        );

        dispatch(fetchHistoryListAction(res.story));
        dispatch(fetchListAction(res.story));
        dispatch(setEditAction(false));
        successToast('Chapter updated success');
        dispatch(endLoadingAction());
      })
      .catch((error) => {
        dispatch(endLoadingAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const deleteChapterAction = (data, type = UPDATE_TYPE.STANDART) => {
  return (dispatch) => {
    dispatch(startLoadingAction());

    deleteChapterService(data.id, type)
      .then(() => {
        dispatch(fetchListAction(data.story));
        dispatch(fetchHistoryListAction(data.story));
        dispatch(setEditAction(false));
        dispatch(
          setSelectedAction({
            chapter: '',
            page: '',
            frame: '',
            frameItem: '',
          })
        );
        dispatch(clearEditItemAction());
        dispatch(fetchPagesAction(data.story));
        dispatch(endLoadingAction());
        successToast('Chapter deleted successfully');
      })
      .catch((error) => {
        dispatch(endLoadingAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const ungroupChapterAction = (data, type = UPDATE_TYPE.STANDART) => {
  return (dispatch) => {
    // const state = getState();
    // const chapters = storyListAllChaptersSelector(state);
    // const currentChapter = chapters.filter((item) => item.id === data.id)[0];

    dispatch(startLoadingAction());
    ungroupChapterService(data.id, type)
      .then(() => {
        dispatch(fetchListAction(data.story));
        dispatch(setEditAction(false));
        dispatch(
          setSelectedAction({
            chapter: '',
            page: '',
            frame: '',
            frameItem: '',
          })
        );
        dispatch(fetchHistoryListAction(data.story));
        dispatch(clearEditItemAction());
        dispatch(fetchPagesAction(data.story));
        dispatch(endLoadingAction());
        successToast('The Chapter was ungrouped successfully');
      })
      .catch((error) => {
        dispatch(endLoadingAction());
        errorToast(error?.message || 'Something wrong. Please try again');
      });
  };
};

import {
  CREATE_FRAME,
  CREATE_FRAME_MULTIPLE,
  DELETE_FRAME,
  FETCHED_FRAMES,
  FETCHED_TARGET_FRAMES,
  LOADING_FRAMES,
  UPDATE_CREATED_FRAMES,
  UPDATE_CREATED_MULTIPLE_FRAMES,
  UPDATE_FRAME,
  UPDATE_FRAME_LIST,
} from 'common/types/framesList';

const initialState = {
  frames: [],
  loading: false,
  targetFrames: [],
};

const framesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_FRAMES:
      return {
        ...state,
        frames: action.payload,
        loading: false,
      };

    case FETCHED_TARGET_FRAMES: {
      const frames = action.payload.map((frame) => {
        return {
          id: frame.id,
          title: frame.title || 'Frame',
          originPage: frame.originPage,
        };
      });
      return {
        ...state,
        targetFrames: frames,
      };
    }
    case CREATE_FRAME:
      return {
        ...state,
        frames: [...state.frames, action.payload],
        loading: false,
      };

    case UPDATE_CREATED_FRAMES:
      const { id, originPage } = action.payload;
      return {
        ...state,
        targetFrames: [
          ...state.targetFrames,
          {
            id,
            title: action.payload.title || 'Frame',
            originPage,
          },
        ],
      };

    case UPDATE_CREATED_MULTIPLE_FRAMES:
      const multipleFrames = action.payload.map((frame) => {
        return {
          id: frame.id,
          title: frame.title || 'Frame',
          originPage: frame.originPage,
        };
      });
      return {
        ...state,
        targetFrames: [...state.targetFrames, ...multipleFrames],
      };

    case CREATE_FRAME_MULTIPLE:
      return {
        ...state,
        frames: [...state.frames, ...action.payload],
        loading: false,
      };

    case UPDATE_FRAME: {
      const framesList = [...state.frames];
      const updateIndex = framesList.findIndex(
        (frame) =>
          (frame.id || frame.fid) === (action.payload.id || action.payload.fid)
      );

      if (updateIndex >= 0) {
        framesList.splice(updateIndex, 1, action.payload);
      }

      return { ...state, frames: framesList, loading: false };
    }
    case UPDATE_FRAME_LIST: {
      const framesList = [...state.frames];
      const newFrames = [...action.payload];
      newFrames.forEach((newFrame) => {
        framesList.forEach((frame, i) => {
          if (frame.id === newFrame.id) framesList.splice(i, 1);
        });
      });

      return {
        ...state,
        loading: false,
        frames: [...framesList, ...newFrames],
      };
    }
    case DELETE_FRAME: {
      const frames = [...state.frames];
      const index = frames.findIndex(
        (frame) =>
          (frame.id || frame.fid) === (action.payload.id || action.payload.fid)
      );
      if (index >= 0) {
        frames.splice(index, 1);
      }
      return { ...state, frames, loading: false };
    }
    case LOADING_FRAMES:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default framesReducer;

import { createSelector } from 'reselect';
import { mappedArrayToPageObject } from 'utils/mappedArrayToPageObject';

export const hotSpotsListSelector = (state) => state.hotSpotsList.hotSpots;
export const hotSpotsListLoadingSelector = (state) =>
  state.hotSpotsList.loading;

export const mappedHotSpotsObject = createSelector(
  hotSpotsListSelector,
  (list) => mappedArrayToPageObject(list)
);

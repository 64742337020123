import React from 'react';
import { Box } from '@material-ui/core';

const BlockTitle = ({ title, icon }) => {
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between'>
      {title}
      {icon}
    </Box>
  );
};

export default BlockTitle;

// export const defaultOption = { value: '', id: '' };

export const defaultValue = {
  title: '',
  attachmentInfo: {},
  videoImage: {},
  additionalAttachmentInfo: {},
  loopAudio: false,
  fullScreen: false,
  windowHeight: '',
  windowWidth: '',
  storyEnd: false,
  newWindow: false,
  canReturn: false,
  zIndex: 1,
  vvtLanguages: [],
  animation: {
    appear: 'manual',
  },
};

export const defaultSubtitle = { label: '', file: '', tag: '' };

export const GAMES = [
  {
    politicalCards: [
      'FREMTIDSFRØ - GAME 3: POLITICAL CARD GAME',
      'https://webgl-unity-i2a.s3.eu-north-1.amazonaws.com/DU-PoliticalCards-release/index.html', // 'https://unity-web-gl.s3.us-east-2.amazonaws.com/PoliticalCardsFinal/index.html',
    ],
  },
  {
    geneticEngineering: [
      'FREMTIDSFRØ - GAME 2: GENETIC ENGINEERING',
      'https://webgl-unity-i2a.s3.eu-north-1.amazonaws.com/DU-GeneticEngineering-release/index.html', // 'https://unity-web-gl.s3.us-east-2.amazonaws.com/GeneticEngineeringFinal/index.html',
    ],
  },
  {
    ecosystems: [
      'FREMTIDSFRØ - GAME 1: ECOSYSTEMS',
      'https://webgl-unity-i2a.s3.eu-north-1.amazonaws.com/fremtidsfro-ecosystems-v1.0.0-alpha/index.html', // 'https://unity-web-gl.s3.us-east-2.amazonaws.com/EcosystemsFinal/index.html',
    ],
  },
  {
    crimeScene: [
      'THE MYSTERY OF THE GENESIS CLINIC - GAME 1: CRIME SCENE',
      'https://webgl-unity-i2a.s3.eu-north-1.amazonaws.com/DU-GenesisClinic-Game1-release/index.html',
    ],
  },
  {
    dnaProfilign: [
      'THE MYSTERY OF THE GENESIS CLINIC - GAME 2: DNA PROFILING',
      'https://webgl-unity-i2a.s3.eu-north-1.amazonaws.com/DU-GenesisClinic-Game2-release/index.html',
    ],
  },
  // {
  //  dnaProfilign: [
  //    'THE MYSTERY OF THE GENESIS CLINIC - GAME 2: DNA PROFILING',
  //    'https://webgl-unity-i2a.s3.eu-north-1.amazonaws.com/DNA-Game-NoIntro/index.html',
  //  ],
  // },
  {
    switchPuzzle: [
      'THE MYSTERY OF THE GENESIS CLINIC - GAME 3: SWITCH PUZZLE',
      'https://webgl-unity-i2a.s3.eu-north-1.amazonaws.com/DU-GenesisClinic-Game3-release/index.html',
    ],
  },
];

export const animationTypes = {
  backIn: ['backInDown', 'backInLeft', 'backInRight', 'backInUp'],
  bouncing: [
    'bounceIn',
    'bounceInDown',
    'bounceInLeft',
    'bounceInRight',
    'bounceInUp',
  ],
  fadeIn: ['fadeIn', 'fadeInDown', 'fadeInLeft', 'fadeInRight', 'fadeInUp'],
  flip: ['flip', 'flipInX', 'flipInY', 'flipOutX', 'flipOutY'],
  zoom: ['zoomIn'],
  slideIn: ['slideInDown', 'slideInLeft', 'slideInRight', 'slideInUp'],
};

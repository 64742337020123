import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  listWrap: {
    overflowY: 'auto',
    height: `calc(100% - ${theme.spacing(7.5)}px)`,

    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 240px)',
    },

    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 148px)',
    },
    [theme.breakpoints.up('md')]: {
      height: `calc(100% - ${theme.spacing(7.5)}px)`,
    },
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: theme.spacing(4),
  },
  list: {
    flex: '1 1 auto',
  },
  message: {
    marginTop: '20px',
  },
  listTitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    margin: '12px 10px 10px',
  },
}));

import React, { useCallback, useState } from 'react';
import { compile } from 'path-to-regexp';
import { Box, Drawer, AppBar, Button } from '@material-ui/core';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'constants/index';
import { getPreviewLink } from 'utils/helpers/helpers';
import { storyItemsSelector } from 'common/selectors/story';
import { storyListCurrentStorySelector } from 'common/selectors/storyList';
import { storyListSelectedPageSelector } from 'common/selectors/editStory';
import {
  setCurrentLanguageAction,
  setCurrentStoryAction,
  setCurrentStoryIsEditAction,
} from 'common/actions/storyListActions';
import { ArrowLeft } from 'components/Icons/ArrowLeft';
import { PreviewIcon } from 'components/Icons/PreviewIcon';
// eslint-disable-next-line import/no-cycle
import StoryForm from '../StoryForm/StoryForm';
import ConfirmDialog from '../ConfirmDialog/index';
import useStyles from './styles';

const selector = createSelector(
  storyItemsSelector,
  storyListSelectedPageSelector,
  storyListCurrentStorySelector,
  (story, currentPage, currentStory) => ({
    story,
    currentPage,
    currentStory,
  })
);

export const StoryFormDrawer = ({ open, setOpen }) => {
  const { story, currentPage, currentStory } = useSelector(selector);

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [isBackArrow, setIsBackArrow] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const previewStory = () => {
    if (currentStory?.id || story?.id) {
      window.open(
        getPreviewLink(currentStory?.id || story?.id, false, currentPage?.id)
      );
    }
  };

  const handleOpenConfirmDialog = useCallback(
    () => setOpenConfirmDialog(true),
    []
  );

  const handleCloseConfirmDialog = useCallback(
    () => setOpenConfirmDialog(false),
    []
  );

  const handleRedirect = useCallback(() => {
    dispatch(setCurrentStoryIsEditAction(false));
    dispatch(setCurrentStoryAction(null));
    dispatch(setCurrentLanguageAction('nb'));
    history.push(compile(ROUTES.STORIES_EDIT)({ id: currentStory?.id }));
    if (isBackArrow) {
      setOpen(false);
      setIsBackArrow(false);
    }
  }, [isBackArrow, setOpen, dispatch, history, currentStory?.id]);

  const handleEditStory = useCallback(
    (back) => {
      if (!isEdit) {
        if (back) {
          setOpen(false);
        } else {
          handleRedirect();
          setIsBackArrow(false);
        }
      } else {
        handleOpenConfirmDialog();
      }
    },
    [isEdit, setOpen, handleRedirect, handleOpenConfirmDialog]
  );

  return (
    <>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        anchor='bottom'
        className={classes.drawer}
      >
        <>
          <AppBar position='relative' className={classes.drawerHeader}>
            <Box
              display='flex'
              alignItems='center'
              onClick={() => {
                setIsBackArrow(true);
                handleEditStory(true);
              }}
            >
              <ArrowLeft />
              <p style={{ marginLeft: 6 }}> Story</p>
            </Box>
            <Box className={classes.actionButtons}>
              <PreviewIcon
                style={{ fontSize: '26px', marginTop: '1px' }}
                onClick={previewStory}
              />
              <Button
                className={classes.editButton}
                disableElevation
                disableFocusRipple
                disableRipple
                onClick={() => {
                  setIsBackArrow(false);
                  handleEditStory(false);
                }}
              >
                Edit
              </Button>
            </Box>
          </AppBar>
          <Box className={classes.formWrapper}>
            <StoryForm isDrawer setIsEdit={setIsEdit} />
          </Box>
        </>
      </Drawer>
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onSubmit={handleRedirect}
      />
    </>
  );
};

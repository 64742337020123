import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  BACKPACK_TYPE,
  CHAPTER_TYPE,
  CHECKPOINT_TYPE,
  DECISION_TYPE,
  FRAME_TYPE,
  HOT_SPOT_TYPE,
  IMAGE_LAYER_TYPE,
  IMAGE_VIDEO_TYPE,
  PAGE_TYPE,
  SETTINGS_TYPE,
  SPEECHBUBBLE_LAYER_TYPE,
  TEXT_LAYER_TYPE,
} from 'constants/editTypes';
import useMobile from 'utils/hooks/useMobile';
import { ShowMenu } from 'components/Icons/ShowMenu';
import ControlBarTitle from '../ControlBarTitle';
import useStyles from './styles';

const ControlBarWrap = ({ children, type }) => {
  const { isSmallDesktop } = useMobile();

  const [showLeftMenu, setShowLeftMenu] = useState(!isSmallDesktop);

  const classes = useStyles({ showLeftMenu });

  return (
    <Box className={showLeftMenu ? classes.wrap : classes.closedWrap}>
      <Box className={classes.contentWrapper}>
        {isSmallDesktop && (
          <Box className={classes.showMenu}>
            <ShowMenu onClick={() => setShowLeftMenu(!showLeftMenu)} />
            <Typography>Properties</Typography>
          </Box>
        )}
        <Box className={classes.content}>
          <ControlBarTitle type={type} />
          <Box>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};
ControlBarWrap.propTypes = {
  type: PropTypes.oneOf([
    HOT_SPOT_TYPE,
    FRAME_TYPE,
    DECISION_TYPE,
    PAGE_TYPE,
    CHAPTER_TYPE,
    IMAGE_LAYER_TYPE,
    TEXT_LAYER_TYPE,
    IMAGE_VIDEO_TYPE,
    BACKPACK_TYPE,
    CHECKPOINT_TYPE,
    SETTINGS_TYPE,
    SPEECHBUBBLE_LAYER_TYPE,
  ]).isRequired,
};
export default ControlBarWrap;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import useStyles from './styles';

const ControlButton = ({ text, onClick, noMargin, isActive, ...props }) => {
  const classes = useStyles({ noMargin, isActive });

  return (
    <Button className={classes.deleteBtn} onClick={onClick} {...props}>
      {text}
    </Button>
  );
};

ControlButton.defaultProps = {
  noMargin: false,
  isActive: false,
};

ControlButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
  isActive: PropTypes.bool,
};

export default ControlButton;

import { useEffect, useRef } from 'react';

export const useDebounceFunction = (func, delay, cleanUp = false) => {
  const timeoutRef = useRef(undefined);

  const clearTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  };

  // Clear timer when unmount component if cleanUp set true
  // canceling last function call
  useEffect(() => (cleanUp ? clearTimer : undefined), [cleanUp]);

  return (...args) => {
    clearTimer();
    timeoutRef.current = setTimeout(() => func(...args), delay);
  };
};

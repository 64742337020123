import { SET_USER } from 'common/types/auth';
import { RESET_STORE } from 'common/types/common';

const initialState = {
  user: null,
  loading: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;

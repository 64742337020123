import React from 'react';
import classNames from 'classnames';
import Settings from '@material-ui/icons/Settings';

export const SettingsIcon = ({ fill = '#fff', className, ...props }) => {
  return (
    <Settings
      style={{ fill, width: 13, height: 13 }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};

// <svg
//       width='10'
//       height='10'
//       viewBox='0 0 10 10'
//       fill='none'
//       xmlns='http://www.w3.org/2000/svg'
//     >
//       <path
//         d='M8.80627 5.70766L9.66546 6.20361C9.76358 6.26024 9.80913 6.37736 9.77552 6.48556C9.55233 7.20415 9.17129 7.85334 8.67292 8.39253C8.63562 8.43291 8.58571 8.4594 8.53137 8.46766C8.47703 8.47591 8.4215 8.46545 8.37389 8.43797L7.51534 7.94211C7.15412 8.25162 6.73882 8.49173 6.29037 8.65037V9.64194C6.29037 9.69694 6.27165 9.7503 6.23729 9.79323C6.20293 9.83617 6.15497 9.86614 6.10131 9.87819C5.39656 10.0365 4.63964 10.0446 3.89932 9.87833C3.78864 9.85348 3.70975 9.75543 3.70975 9.64198V8.65037C3.26129 8.49174 2.84599 8.25163 2.48477 7.94211L1.62622 8.43797C1.57862 8.46545 1.52309 8.47591 1.46875 8.46766C1.41441 8.4594 1.36449 8.43291 1.32719 8.39253C0.828847 7.85334 0.44778 7.20415 0.224595 6.48556C0.190986 6.37738 0.23653 6.26026 0.334655 6.20361L1.19387 5.70766C1.10757 5.23984 1.10757 4.76018 1.19387 4.29236L0.334675 3.79641C0.23655 3.73978 0.191006 3.62266 0.224615 3.51446C0.4478 2.79587 0.828847 2.14668 1.32721 1.60749C1.36451 1.56711 1.41443 1.54062 1.46877 1.53237C1.52311 1.52411 1.57864 1.53457 1.62624 1.56204L2.48479 2.05791C2.84602 1.7484 3.26131 1.50829 3.70977 1.34965V0.358056C3.70977 0.303061 3.72849 0.249704 3.76285 0.206765C3.79721 0.163826 3.84516 0.133863 3.89882 0.121807C4.60358 -0.0364592 5.36049 -0.0446447 6.10081 0.121665C6.2115 0.146524 6.29039 0.244568 6.29039 0.358016V1.34963C6.73885 1.50826 7.15414 1.74838 7.51536 2.05789L8.37391 1.56202C8.42152 1.53455 8.47705 1.52409 8.53139 1.53235C8.58573 1.5406 8.63564 1.56709 8.67294 1.60747C9.17129 2.14666 9.55235 2.79585 9.77554 3.51444C9.80915 3.62262 9.7636 3.73974 9.66548 3.79639L8.80627 4.29234C8.89257 4.76017 8.89257 5.23983 8.80627 5.70766ZM6.61297 5C6.61297 4.11065 5.88942 3.3871 5.00007 3.3871C4.11071 3.3871 3.38717 4.11065 3.38717 5C3.38717 5.88935 4.11071 6.6129 5.00007 6.6129C5.88942 6.6129 6.61297 5.88935 6.61297 5Z'
//         fill='white'
//       />
//     </svg>

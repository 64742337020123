import { END_LOADING, FETCH_STORY, START_LOADING } from 'common/types/story';

const initialState = {
  story: null,
  loading: false,
};

const storyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STORY:
      return {
        ...state,
        story: action.story,
      };
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case END_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default storyReducer;

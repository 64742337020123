import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  picker: {
    position: 'absolute',
    right: theme.spacing(27),
    zIndex: 1,
  },
  restartIcon: {
    padding: '0px',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

import React from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogWrapper from 'components/DialogWrapper';
import useStyles from './styles';

const ChangeOrientationContainer = ({ isOpen, handleClose, handleSubmit }) => {
  const classes = useStyles();

  return (
    <DialogWrapper open={isOpen} onClose={handleClose} disableBackdropClick>
      <DialogTitle disableTypography>
        <Box component='h3' fontSize='1rem' fontWeight='fontWeightMedium' m={0}>
          Warning
        </Box>
      </DialogTitle>
      <DialogContent>
        Are you sure you want to change orientation? This might destroy an
        already existing layout of frames.
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button disableElevation onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleSubmit}
        >
          OK
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};

export default ChangeOrientationContainer;

import React, { forwardRef } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from '@material-ui/core';
import useStyles from './styles';

const SelectField = forwardRef(
  ({ children, label, error, helperText, ...props }, ref) => {
    const classes = useStyles();

    return (
      <FormControl fullWidth focused error={!!error}>
        <InputLabel>{label}</InputLabel>
        <Select
          className={classes.select}
          fullWidth
          disableUnderline
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
          innerRef={ref}
          {...props}
        >
          {children}
        </Select>
        {(!!error || !!helperText) && (
          <FormHelperText innerRef={ref}>{error || helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

SelectField.displayName = 'SelectField';

export default SelectField;

import React from 'react';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import classNames from 'classnames';

export const PreviewIcon = ({ className, ...props }) => {
  return (
    <PlayCircleFilledWhiteIcon
      style={{ fontSize: '20px' }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};

/* <svg
        width='14'
        height='14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7 0a7 7 0 100 14A7 7 0 007 0zm3.27 7.68L5.3 10.53a.68.68 0 01-1.01-.6V4.07c0-.51.56-.84 1-.59l4.98 3.02c.46.26.46.93 0 1.19z'
          fill='#fff'
        />
      </svg> */

import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    width: '100vw',
    height: '100%',
  },
  contentWrap: {
    width: '100%',
    height: '100%',
  },
  content: {
    height: 'calc(100% - 48px)',

    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 20px)',
    },
  },
  loader: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: transparentize(0.4, theme.colors.black),
    zIndex: 10,
  },
}));

import React from 'react';
import classNames from 'classnames';
import DeleteIcon from '@material-ui/icons/Delete';

export const TrashIcon = ({ fill = 'white', className, ...props }) => {
  return (
    <>
      <DeleteIcon
        style={{ fill, width: 11, height: 11 }}
        className={classNames({
          [className]: !!className,
        })}
        {...props}
      />
    </>
  );
};

/* <svg width='8' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <path
    d='M.5 1.31V.87C.5.68.67.5.88.5h1.75l.14-.3c.06-.12.2-.2.34-.2h1.78c.14 0 .27.08.34.2l.14.3h1.75c.21 0 .38.17.38.38v.43c0 .1-.08.19-.19.19H.7a.19.19 0 01-.19-.19zM7 2.2v5.06c0 .41-.34.75-.75.75h-4.5A.75.75 0 011 7.25V2.19c0-.1.08-.19.19-.19H6.8c.1 0 .19.08.19.19zM2.75 3.25A.25.25 0 002.5 3a.25.25 0 00-.25.25v3.5c0 .14.11.25.25.25s.25-.11.25-.25v-3.5zm1.5 0A.25.25 0 004 3a.25.25 0 00-.25.25v3.5c0 .14.11.25.25.25s.25-.11.25-.25v-3.5zm1.5 0A.25.25 0 005.5 3a.25.25 0 00-.25.25v3.5c0 .14.11.25.25.25s.25-.11.25-.25v-3.5z'
    fill='#fff'
  />
</svg> */

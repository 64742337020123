import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  sidebarWrap: {
    display: 'flex',
    flexDirection: 'column',
    flex: `0 0 ${theme.spacing(24.875)}px`,
    maxWidth: theme.spacing(24.875),
    padding: theme.spacing(1.5),
    backgroundColor: theme.colors.sidebarBackground,
    transform: 'translateX(0)',
    transition: 'all .5s ease-in-out',

    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  closedSidebarWrap: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 41px',
    maxWidth: '41px',
    width: '41px',
    backgroundColor: theme.colors.sidebarBackground,
    transform: 'translateX(calc(41px - 100%))',
    transition: 'all .5s ease-in-out',
    padding: '16px 13px',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: theme.spacing(4.5),

    '& .MuiTab-wrapper': {
      textTransform: 'capitalize',
    },
  },

  tabWrap: {
    overflowY: 'auto',
    marginLeft: -theme.spacing(0.75),
    height: '100%',
    paddingRight: ({ isSmallDesktop }) => (isSmallDesktop ? '20px' : '0px'),
    width: ({ isSmallDesktop }) =>
      isSmallDesktop ? 'calc(100% + 20px)' : '100%',
    paddingBottom: ({ isSmallDesktop }) => (isSmallDesktop ? '182px' : '80px'),
  },

  headerIcon: {
    padding: '5px',
  },

  addPage: {
    padding: '5px 0px 5px 5px',
  },

  storyTitle: {
    maxWidth: theme.spacing(14.375),
    fontSize: theme.spacing(1.75),
  },

  showMenu: {
    display: 'flex',
    justifyContent: ({ showLeftMenu }) =>
      showLeftMenu ? 'space-between' : 'space-between',
    alignItems: 'center',
    padding: '53px 0px 12px 0px',
    transition: 'all .5s ease-in-out',

    '& p': {
      marginLeft: ({ showLeftMenu }) => (showLeftMenu ? 0 : '-48px'),
      color: 'rgba(255, 255, 255, 0.5)',
      opacity: ({ showLeftMenu }) => (showLeftMenu ? 1 : 0),
      fontSize: theme.spacing(1.25),
      transition: 'all .5s ease-in-out',
    },
  },

  content: {
    height: '100%',
    display: ({ showLeftMenu }) => (showLeftMenu ? 'block' : 'none'),
    transition: 'all .5s ease-in-out',
  },
}));

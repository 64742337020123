import React from 'react';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const CollapseArrowIcon = ({ fill = 'white', className, ...props }) => {
  return (
    <ExpandMoreIcon
      style={{ fill, width: 12, height: 12 }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};

/* <svg width='6' height='4' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M2.77 3.72L.1 1.05A.33.33 0 01.1.6L.4.28a.33.33 0 01.47 0L3 2.39 5.13.28a.33.33 0 01.46 0l.31.3c.13.14.13.34 0 .47L3.23 3.72a.33.33 0 01-.46 0z'
          fill='#fff'
        />
      </svg> */

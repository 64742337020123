import React from 'react';
import { Dialog } from '@material-ui/core';
import useStyles from './styles';

const DialogWrapper = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: classes.paper }} {...props}>
      {children}
    </Dialog>
  );
};

export default DialogWrapper;

import axios from 'axios';
import { errorToast, successToast } from 'services/toast';

const getPagesList = (params = {}) => {
  return axios.get('/pages', {
    params: { limit: 100, sort: 'order', ...params },
  });
};

const getPage = (id) => {
  return axios.get(`/pages/${id}`).then((res) => res.data);
};

const createPage = (type, body) =>
  axios.post(`/pages?type=${type}`, body).then((res) => res.data);

const duplicatePage = (pageId, type) => {
  return axios
    .put(`/pages/${pageId}/duplicate?type=${type}`)
    .then((res) => res.data);
};

const updatePagesOrder = (pageIds) => {
  return axios
    .post('pages/updateList', pageIds)
    .then(() => successToast('Pages order is updated'))
    .catch(() => errorToast('Pages order not updated'));
};

const updatePage = (body, updateType) => {
  const frames = body.frames?.map((frame) =>
    !frame.title ? { ...frame, title: 'Frame' } : frame
  );
  return axios
    .put(
      `/pages/${body.id}`,
      { ...body, frames },
      { params: { type: updateType } }
    )
    .then((res) => res.data && res.data.updatedPage);
};

const deletePage = (id, type) => axios.delete(`/pages/${id}?type=${type}`);

export {
  getPagesList,
  getPage,
  createPage,
  updatePage,
  deletePage,
  updatePagesOrder,
  duplicatePage,
};

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { currentUserSelector } from 'common/selectors/auth';
import { viewSocket } from 'services/analytics';

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const useSocket = (storyId) => {
  const { currentUser } = useSelector(selector);

  const [usersOnStoryCount, setUsersOnStoryCount] = useState(0);
  const [userIdsOnStory, setUserIdsOnStory] = useState([]);
  const [storyViews, setStoryViews] = useState(0);

  useEffect(() => {
    const socket = viewSocket();
    socket.emit('leave', storyId);
    socket.on('connect', () => {
      socket.emit('join', storyId);
      socket.on('join', (viewInfo) => {
        const currentUsers = viewInfo.users?.filter(
          (user) => user !== currentUser.id
        );
        setUsersOnStoryCount(currentUsers.length);
        setUserIdsOnStory(currentUsers);
        setStoryViews(viewInfo.views);
      });

      socket.on('leave', (ids) => {
        const currentUsers = ids.filter((user) => user !== currentUser.id);
        setUsersOnStoryCount(currentUsers.length);
        setUserIdsOnStory(currentUsers);
      });
    });
    return () => socket.emit('leave', storyId);
  }, [storyId, currentUser.id]);

  return { userIdsOnStory, usersOnStoryCount, storyViews };
};

export const typeMenu = [
  {
    click: 'On click',
  },
];

export const actionMenu = [
  {
    navigate: 'Navigate to',
  },
];

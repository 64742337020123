import {
  RESET_SHEECHBUBBLE,
  RESET_TO_DEFAULT,
  SET_CLEANED,
  SET_DROWINGMODE,
  SET_FILE_CHANGE,
  SET_IMPORT_STATUS,
  SET_MODE_CHANGE,
  SET_SHEECHBUBBLE,
} from 'common/types/speechbubble';

const initialState = {
  isDrowingMode: false,
  cleaned: false,
  canvas: null,
  exportedJSON: null,
  importedJSON: null,
  mode: 'draw',
  imported: false,
  tools: {
    color: '#000000',
    width: 2,
    textColor: '#000000',
    textWidth: 400,
    textSize: 10,
    text: '',
    fillOpacity: 1,
    fillColor: '#ffffff',
  },
};

const speechbubbleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHEECHBUBBLE:
      return {
        ...state,
        tools: action.payload,
      };
    case SET_DROWINGMODE:
      return {
        ...state,
        isDrowingMode: action.payload,
      };
    case SET_MODE_CHANGE:
      return {
        ...state,
        mode: action.payload,
      };
    case SET_FILE_CHANGE:
      return {
        ...state,
        canvas: action.payload.canvas,
        exportedJSON: action.payload.exportedJSON,
      };
    case SET_CLEANED:
      return {
        ...initialState,
        isDrowingMode: true,
        cleaned: action.payload,
      };
    case SET_IMPORT_STATUS:
      return {
        ...initialState,
        imported: action.payload.imported,
        importedJSON: action.payload.importedJSON,
      };
    case RESET_SHEECHBUBBLE:
      return {
        ...state,
        isDrowingMode: action.payload,
        cleaned: true,
      };
    case RESET_TO_DEFAULT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default speechbubbleReducer;

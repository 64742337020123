import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  ClickAwayListener,
  Divider,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { createSelector } from 'reselect';
import PropTypes from 'prop-types';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
  BACKPACK_TYPE,
  CHAPTER_TYPE,
  CHECKPOINT_TYPE,
  DECISION_TYPE,
  FRAME_TYPE,
  HOT_SPOT_TYPE,
  IMAGE_LAYER_TYPE,
  PAGE_TYPE,
  SPEECHBUBBLE_LAYER_TYPE,
  TEXT_LAYER_TYPE,
} from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import { newStoryListAfterRemove } from 'utils/helpers/helpers';
import { formatListForService } from 'utils/formatTreeForService';
import { useKeyboardEvents } from 'utils/hooks/useKeyboardEvents';
import useMobile from 'utils/hooks/useMobile';
import {
  clearEditItemAction,
  ungroupChapterAction,
  updateChapterAction,
  updateListPositionAction,
  updatePageAction,
  updateServerPositionAction,
} from 'common/actions/editStoryActions';
import {
  storyListAllChaptersSelector,
  storyListItemsSelector,
} from 'common/selectors/editStory';
import { storyItemsSelector } from 'common/selectors/story';
import {
  duplicatePageAction,
  updateFrameAction,
} from 'common/actions/framesListActions';
import { updateHotSpot } from 'common/actions/hotSpotsListActions';
import { updateLayerAction } from 'common/actions/layersListAction';
import { updateDecisionAction } from 'common/actions/decisionsListActions';
import { updateCheckpointItemAction } from 'common/actions/checkpointListAction';
import { updateBackpackItemAction } from 'common/actions/backpackListAction';
import {
  AddDecisionIcon,
  AddHotspotIcon,
  BackpackIcon,
  CheckpointControlIcon,
  CircleIcon,
  ClosedFolderIcon,
  CollapseArrowIcon,
  FrameIcon,
  ImageIcon,
  LayerIcon,
  OpenFolderIcon,
  PageIcon,
  SpeechbubbleIcon,
  VideoIcon,
  VolumeIcon,
} from 'components/Icons';
import StyledInput from 'components/forms/StyledInput';
import { GamepadIcon } from 'components/Icons/GamepadIcon';
import { errorToast } from 'services/toast';
import MovePageMenu from '../MovePageMenu';
import useStyles from './styles';

const selector = createSelector(
  storyListAllChaptersSelector,
  storyListItemsSelector,
  storyItemsSelector,
  (chaptersList, storyList, story) => ({
    chaptersList,
    storyList,
    story,
  })
);

const ListItemTitle = ({
  isActive,
  isCircleFrame,
  isOpen,
  title,
  type,
  listItem,
  onClick,
  onExpand,
  isGroupSelection,
  onGroupSelectionClick,
  onDelete,
  onDuplicate,
  checkedItems,
  setCheckedItems,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [itemRenaming, setItemRenaming] = useState(false);
  const [listTitle, setListTitle] = useState(title);
  const dispatch = useDispatch();
  const classes = useStyles({ isOpen, isActive });

  const { chaptersList, storyList, story } = useSelector(selector);
  const { isPressedEnter } = useKeyboardEvents();
  const { isSmallDesktop } = useMobile();
  const wasRenamed = useMemo(() => title !== listTitle, [title, listTitle]);

  const icon = useMemo(() => {
    if (isCircleFrame) return <CircleIcon isActive={isActive} />;
    switch (type) {
      case PAGE_TYPE:
        return <PageIcon />;
      case FRAME_TYPE:
        return <FrameIcon />;
      case HOT_SPOT_TYPE:
        return <AddHotspotIcon />;
      case DECISION_TYPE:
        return <AddDecisionIcon />;
      case IMAGE_LAYER_TYPE:
        return <LayerIcon />;
      case SPEECHBUBBLE_LAYER_TYPE:
      case 'speechbubble':
        return <SpeechbubbleIcon />;
      case BACKPACK_TYPE:
        return <BackpackIcon />;
      case CHECKPOINT_TYPE:
        return <CheckpointControlIcon />;
      case 'video':
        return <VideoIcon />;
      case 'volume':
        return <VolumeIcon />;
      case 'image':
        return <ImageIcon />;
      case 'sound':
      case 'audio':
        return <VolumeIcon />;
      case 'game':
        return <GamepadIcon fill='white' size='15' />;
      case CHAPTER_TYPE:
        if (isOpen) {
          return <OpenFolderIcon />;
        }
        return <ClosedFolderIcon />;
      default:
        return null;
    }
  }, [type, isOpen, isCircleFrame, isActive]);

  useEffect(() => {
    setListTitle(title);
  }, [title]);

  useEffect(() => {
    const input = document.querySelector('#renameInput');
    if (input) input.focus();
  }, [itemRenaming]);

  const showCollapseIcon = useMemo(() => {
    switch (type) {
      case 'page':
      case 'frame':
      case 'chapter':
      case 'speechbubble':
        return true;
      default:
        return false;
    }
  }, [type]);

  const showMenu = useMemo(() => {
    switch (type) {
      case 'page':
      case 'frame':
      case 'chapter':
      case 'hotspot':
      case 'layer_text':
      case 'layer_image':
      case 'layer_speechbubble':
      case 'checkpoint':
      case 'decision':
      case 'item':
        return true;
      default:
        return false;
    }
  }, [type]);

  const handleOpenMenu = useCallback(
    (e) => {
      e.preventDefault();
      if (listItem && setCheckedItems) setCheckedItems([listItem.id]);
      setAnchorEl(e.currentTarget);
    },
    [listItem, setCheckedItems]
  );

  const handleOnCloseMenu = useCallback(() => setAnchorEl(null), []);

  const handleClickAway = useCallback(() => {
    handleOnCloseMenu();
  }, [handleOnCloseMenu]);

  const handleOnDelete = useCallback(() => {
    onDelete();
    handleOnCloseMenu();
  }, [onDelete, handleOnCloseMenu]);

  const handleOnGroupSelectedClick = useCallback(() => {
    onGroupSelectionClick();
    handleOnCloseMenu();
  }, [handleOnCloseMenu, onGroupSelectionClick]);

  const handleRename = useCallback(() => {
    if (listItem || props.item) setItemRenaming(true);
    setAnchorEl(null);
  }, [listItem, props.item]);

  const handleUpdateTitle = useCallback(
    (item) => {
      const upd = UPDATE_TYPE.UPDATE;
      const updatingItem = listItem || item;
      const renamedItem = {
        ...updatingItem,
        title: listTitle,
        name: listTitle,
        decisionName: listTitle,
      };
      switch (type) {
        case PAGE_TYPE: {
          dispatch(clearEditItemAction());
          dispatch(updatePageAction(renamedItem, upd));
          break;
        }
        case FRAME_TYPE: {
          dispatch(updateFrameAction(renamedItem, upd));
          break;
        }
        case HOT_SPOT_TYPE: {
          dispatch(updateHotSpot(renamedItem, upd));
          break;
        }
        case IMAGE_LAYER_TYPE:
        case SPEECHBUBBLE_LAYER_TYPE:
        case TEXT_LAYER_TYPE: {
          dispatch(updateLayerAction(renamedItem, upd));
          break;
        }
        case DECISION_TYPE: {
          dispatch(updateDecisionAction(renamedItem, upd));
          break;
        }
        case CHECKPOINT_TYPE: {
          dispatch(updateCheckpointItemAction(renamedItem, upd));
          break;
        }
        case BACKPACK_TYPE: {
          dispatch(updateBackpackItemAction(renamedItem, upd));
          break;
        }
        case CHAPTER_TYPE: {
          dispatch(updateChapterAction(renamedItem, upd));
          break;
        }
        default: {
          errorToast('Rename error! Most likely unknown object type was met.');
        }
      }
      setItemRenaming(false);
    },
    [dispatch, listTitle, listItem, type]
  );

  const handleRenameClickAway = useCallback(() => {
    if (wasRenamed) handleUpdateTitle(props.item);
    else setItemRenaming(false);
  }, [wasRenamed, handleUpdateTitle, props.item]);

  useEffect(() => {
    if (isPressedEnter) {
      setItemRenaming(false);
      if (wasRenamed) handleUpdateTitle(props.item);
    }
  }, [isPressedEnter, handleUpdateTitle, props.item, wasRenamed]);

  const handleAddDuplicate = useCallback(
    (item) => {
      dispatch(duplicatePageAction(item.id, onDuplicate, UPDATE_TYPE.STANDART));
      setAnchorEl(null);
    },
    [dispatch, onDuplicate]
  );

  const handleUngroupChapter = useCallback(
    (item) => {
      dispatch(ungroupChapterAction(item));
      setAnchorEl(null);
    },
    [dispatch]
  );

  const handleUngroupPage = useCallback(
    (item) => {
      const newStoryList = newStoryListAfterRemove(
        storyList,
        chaptersList,
        item
      );
      if (newStoryList.type !== CHAPTER_TYPE) {
        // if chapter in newStoryList remains empty, newStoryListAfterRemove returns CHAPTER_TYPE
        dispatch(
          updateServerPositionAction({
            story: story?.id,
            ids: formatListForService(newStoryList),
          })
        );
        dispatch(updateListPositionAction(newStoryList));
        dispatch(
          updatePageAction({ ...item, isInChapter: false }, UPDATE_TYPE.UPDATE)
        );
      } else {
        dispatch(ungroupChapterAction(newStoryList));
      }
      setAnchorEl(null);
    },
    [storyList, chaptersList, dispatch, story]
  );

  const handleExpand = useCallback(
    (e) => {
      e.stopPropagation();
      onExpand();
    },
    [onExpand]
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        className={classes.root}
        onContextMenu={handleOpenMenu}
        onDoubleClick={handleRename}
      >
        {showCollapseIcon && (
          <Box className={classes.arrow} onClick={handleExpand}>
            <CollapseArrowIcon />
          </Box>
        )}
        <Box className={classes.icon}>{icon}</Box>
        {itemRenaming ? (
          <ClickAwayListener onClickAway={handleRenameClickAway}>
            <StyledInput
              style={{
                marginTop: 0,
                backgroundColor: isActive && '#282E42',
              }}
              label=''
              error=''
              id='renameInput'
              className={classes.renameInput}
              value={listTitle}
              onChange={(value) => {
                setListTitle(value);
              }}
            />
          </ClickAwayListener>
        ) : (
          <Box className={classes.title} onClick={onClick}>
            {listTitle}
          </Box>
        )}
        {isSmallDesktop && isActive && (
          <MoreHorizIcon className={classes.points} onClick={handleOpenMenu} />
        )}
        {showMenu && (
          <Menu
            keepMounted
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleOnCloseMenu}
            getContentAnchorEl={null}
            PaperProps={{
              className: classes.menu,
            }}
          >
            {isGroupSelection && (
              <MenuItem onClick={handleOnGroupSelectedClick}>
                Group selection
              </MenuItem>
            )}
            {(props?.item?.type === PAGE_TYPE ||
              listItem?.type === FRAME_TYPE) &&
              !props?.item?.isInChapter && (
                <MovePageMenu
                  item={props.item || listItem}
                  checkedItems={checkedItems}
                  onClose={handleOnCloseMenu}
                />
              )}
            <MenuItem onClick={() => handleRename(props, type)}>
              Rename
            </MenuItem>
            {props?.item?.type === PAGE_TYPE && (
              <MenuItem onClick={() => handleAddDuplicate(props.item)}>
                Duplicate page
              </MenuItem>
            )}
            {props?.item?.type === PAGE_TYPE && props?.item?.isInChapter && (
              <MenuItem
                onClick={() => handleUngroupPage(props.item)}
                className={classes.deleteList}
              >
                Ungroup
              </MenuItem>
            )}
            {isGroupSelection && <Divider />}
            {onDelete && (
              <MenuItem onClick={handleOnDelete} className={classes.deleteList}>
                Delete
              </MenuItem>
            )}
            {props?.item?.type === CHAPTER_TYPE && (
              <MenuItem
                onClick={() => handleUngroupChapter(props.item)}
                className={classes.deleteList}
              >
                Ungroup
              </MenuItem>
            )}
          </Menu>
        )}
      </Box>
    </ClickAwayListener>
  );
};

ListItemTitle.propspropTypes = {
  isGroupSelection: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'chapter',
    'page',
    'frame',
    'decision',
    'hotspot',
    'item',
    'checkpoint',
    'image',
    'sound',
    'audio',
  ]).isRequired,
  onClick: PropTypes.func,
  onExpand: PropTypes.func,
  onDelete: PropTypes.func,
  onGroupSelectionClick: PropTypes.func,
  onDuplicate: PropTypes.func,
};

export default ListItemTitle;

import React, { useCallback, useState } from 'react';
import {
  Divider,
  IconButton,
  ListItemIcon,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { CIRCULAR_FRAME, SIMPLE_FRAME } from 'constants/frameTypes';
import { detectOperatingSystem } from 'utils/detectOperatingSystem';
import MenuWrap from 'components/MenuWrap';
import { AddFrameIcon } from 'components/Icons';
import { FrameIcon } from 'components/Icons/FrameIcon';
import { CircleIcon } from 'components/Icons/CircleIcon';

const FrameMenu = ({ onAddFrame }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  // simple frame = rectangular frame
  const handleAddSimpleFrame = useCallback(() => {
    onAddFrame(SIMPLE_FRAME);
    setAnchorEl(null);
  }, [onAddFrame]);

  const handleAddCircularFrame = useCallback(() => {
    onAddFrame(CIRCULAR_FRAME);
    setAnchorEl(null);
  }, [onAddFrame]);

  return (
    <MenuWrap
      anchorEl={anchorEl}
      onToggle={() => {}}
      onRightClick={setAnchorEl}
      toggler={
        <Tooltip
          title={`Add Frame (${detectOperatingSystem()} + F)`}
          placement='bottom'
        >
          <IconButton onClick={handleAddSimpleFrame}>
            <AddFrameIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <MenuItem onClick={handleAddSimpleFrame}>
        <ListItemIcon>
          <FrameIcon />
        </ListItemIcon>
        Add simple frame
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleAddCircularFrame}>
        <ListItemIcon>
          <CircleIcon />
        </ListItemIcon>
        Add circular frame
      </MenuItem>
    </MenuWrap>
  );
};

FrameMenu.propTypes = {
  onAddFrame: PropTypes.func.isRequired,
};

export default FrameMenu;

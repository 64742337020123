import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Box, MenuItem } from '@material-ui/core';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getDefaultCheckpointItem,
  getDefaultCheckpointWay,
} from 'constants/defaultItems';
import { CHECKPOINT_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import { useDebounce } from 'utils/hooks/useDebounce';
import { treeCompare } from 'utils/treeCompare';
import { pageListOrderSelector } from 'common/selectors/pages';
import {
  backpackListSelector,
  mappedBackpackObjectById,
} from 'common/selectors/backpack';
import { storyListEditItemSelector } from 'common/selectors/editStory';
import { editItemAction, setEditAction } from 'common/actions/editStoryActions';
import {
  createCheckpointItemAction,
  deleteCheckpointAction,
  updateCheckpointItemAction,
} from 'common/actions/checkpointListAction';
import {
  framesListSelector,
  targetFramesListSelector,
} from 'common/selectors/frames';
import FormWrap from 'components/forms/FormWrap';
import StyledInput from 'components/forms/StyledInput';
import SelectField from 'components/forms/SelectField';
import MultiselectItem from 'components/forms/MultiselectMenuItem';
import { TrashIcon } from 'components/Icons';
import ControlBarBlock from '../ControlBarBlock';
import BlockTitle from '../DecisionControl/BlockTitle';
import ControlButton from '../ControlButton';
import { actionMenu, typeMenu } from './CheckpointControl.constants';
import { validationSchema } from './CheckpointControl.validation';
import useStyles from './styles';

const selector = createSelector(
  pageListOrderSelector,
  backpackListSelector,
  mappedBackpackObjectById,
  storyListEditItemSelector,
  framesListSelector,
  targetFramesListSelector,
  (
    pagesList,
    backpackList,
    backpackObjectById,
    editCheckpoint,
    framesList,
    targetFrames
  ) => ({
    pagesList,
    backpackList,
    backpackObjectById,
    editCheckpoint,
    framesList,
    targetFrames,
  })
);

const CheckpointControl = () => {
  const dispatch = useDispatch();
  const {
    pagesList,
    backpackList,
    backpackObjectById,
    editCheckpoint,
    targetFrames,
  } = useSelector(selector);
  const classes = useStyles();
  const defaultValues = useCallback(() => getDefaultCheckpointItem(), []);
  const defaultWay = useMemo(() => getDefaultCheckpointWay(), []);

  const { control, reset, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues: { ...defaultValues, ...editCheckpoint },
    resolver: yupResolver(validationSchema()),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ways',
    keyName: 'waysKey',
  });

  const formField = useWatch({
    control,
  });

  const formFieldDebounced = useDebounce(formField, 300);

  const currentCheckpointId = useMemo(() => formField.id, [formField.id]);

  useEffect(() => {
    if (currentCheckpointId !== editCheckpoint.id) {
      reset({ ...defaultValues, ...editCheckpoint });
    }
  }, [currentCheckpointId, defaultWay, editCheckpoint, reset, defaultValues]);

  const handleUpdateChapter = useCallback(() => {
    const { id, ...fieldsData } = formFieldDebounced;
    // the order of the elements must be the same like in formField
    const fieldsForCompare = { ...defaultValues, ...editCheckpoint };
    if (!treeCompare(formFieldDebounced, fieldsForCompare)) {
      dispatch(setEditAction(true));
      dispatch(
        editItemAction({
          item: { ...editCheckpoint, ...fieldsData },
          type: CHECKPOINT_TYPE,
        })
      );
    }
  }, [formFieldDebounced, editCheckpoint, dispatch, defaultValues]);

  useEffect(() => {
    handleUpdateChapter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced]);

  const ways = useMemo(() => formField.ways, [formField.ways]);

  // const currentPage = useCallback(
  //   (index) => {
  //     if (ways.length - 1 >= index) {
  //       return pagesList.find((item) => item.id === ways[index].targetPage);
  //     }
  //     return null;
  //   },
  //   [ways, pagesList]
  // );

  // const currentDefaultPage = useMemo(
  //   () =>
  //     pagesList.find((item) => item.id === formField.defaultWay?.targetPage),
  //   [formField, pagesList]
  // );

  const handleRemove = useCallback(() => {
    dispatch(deleteCheckpointAction());
  }, [dispatch]);

  const onSubmit = useCallback(
    (data) => {
      const { id, ...otherData } = data;

      if (editCheckpoint.id) {
        dispatch(
          updateCheckpointItemAction(
            {
              ...editCheckpoint,
              otherData,
            },
            UPDATE_TYPE.UPDATE
          )
        );
      } else {
        dispatch(
          createCheckpointItemAction({
            ...otherData,
          })
        );
      }
    },
    [editCheckpoint, dispatch]
  );

  return (
    <FormWrap id='checkpoint-form' onSubmit={handleSubmit(onSubmit)}>
      <ControlBarBlock blockTitle='Details'>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              disableUnderline
              label='Name'
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='title'
          control={control}
        />
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Default way'>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <SelectField
              displayEmpty
              defaultValue=''
              label='Target page'
              error={error ? error.message : null}
              {...field}
              control={control}
              onChange={(e) => {
                setValue('defaultWay.targetFrame', '');
                setValue('defaultWay.targetPage', e.target.value);
              }}
            >
              <MenuItem value='' disabled>
                Select page...
              </MenuItem>
              {pagesList.map((page) => {
                return (
                  <MenuItem key={page.id} value={page.id}>
                    {page.title}
                  </MenuItem>
                );
              })}
            </SelectField>
          )}
          name='defaultWay.targetPage'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <SelectField
              displayEmpty
              label='Target frame'
              error={error ? error.message : null}
              {...field}
              control={control}
            >
              <MenuItem value='' disabled>
                Select frame...
              </MenuItem>
              {targetFrames
                .filter(
                  (frame) =>
                    frame.originPage === formField.defaultWay.targetPage
                )
                .map((frame) => {
                  return (
                    <MenuItem
                      key={frame.id || frame._id}
                      value={frame.id || frame._id}
                    >
                      {frame.title || frame._id}
                    </MenuItem>
                  );
                })}
            </SelectField>
          )}
          name='defaultWay.targetFrame'
          control={control}
        />
      </ControlBarBlock>
      {fields.map((item, index) => (
        <ControlBarBlock
          key={item.waysKey}
          blockTitle={
            <BlockTitle
              title={`Check ${index > 0 ? index : ''}`}
              icon={
                fields.length > 1 && (
                  <Box
                    className={classes.optionDelete}
                    onClick={() => remove(index)}
                  >
                    <TrashIcon />
                  </Box>
                )
              }
            />
          }
        >
          <Controller
            render={({ field, fieldState: { error } }) => (
              <SelectField
                displayEmpty
                defaultValue=''
                label='Type'
                error={error ? error.message : null}
                {...field}
                control={control}
              >
                <MenuItem value='' disabled>
                  Select type...
                </MenuItem>
                {typeMenu.map((option) => {
                  const keys = Object.keys(option);
                  return (
                    <MenuItem key={keys[0]} value={keys[0]}>
                      {option[keys[0]]}
                    </MenuItem>
                  );
                })}
              </SelectField>
            )}
            name={`ways.${index}.type`}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <SelectField
                multiple
                displayEmpty
                defaultValue={[]}
                label='Item'
                renderValue={(selected) => {
                  if (!selected || selected?.length === 0) {
                    return 'Select item...';
                  }
                  return selected
                    .map(
                      (selectItem) =>
                        backpackObjectById[selectItem]?.title || ''
                    )
                    .join(', ');
                }}
                error={error ? error.message : null}
                {...field}
                control={control}
              >
                {backpackList
                  .filter((backpack) => {
                    return (
                      pagesList.find(
                        (page) => page.id === backpack.originPage
                      ) &&
                      targetFrames.find(
                        (frame) => frame.id === backpack.originFrame
                      )
                    );
                  })
                  .map((backpack, listIndex) => (
                    <MultiselectItem
                      key={backpack.id}
                      hasDivider={!!listIndex}
                      value={backpack.id}
                      title={backpack.title}
                      checked={field?.value?.indexOf(backpack.id) > -1}
                    />
                  ))}
              </SelectField>
            )}
            name={`ways.${index}.requiredItem`}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <SelectField
                displayEmpty
                defaultValue=''
                label='Action'
                error={error ? error.message : null}
                {...field}
                control={control}
              >
                <MenuItem value='' disabled>
                  Select action...
                </MenuItem>
                {actionMenu.map((option) => {
                  const keys = Object.keys(option);
                  return (
                    <MenuItem key={keys[0]} value={keys[0]}>
                      {option[keys[0]]}
                    </MenuItem>
                  );
                })}
              </SelectField>
            )}
            name={`ways.${index}.action`}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <SelectField
                displayEmpty
                defaultValue=''
                label='Target page'
                error={error ? error.message : null}
                {...field}
                control={control}
                onChange={(e) => {
                  setValue(`ways.${index}.targetFrame`, '');
                  setValue(`ways.${index}.targetPage`, e.target.value);
                }}
              >
                <MenuItem value='' disabled>
                  Select page...
                </MenuItem>
                {pagesList.map((page) => {
                  return (
                    <MenuItem key={page.id} value={page.id}>
                      {page.title}
                    </MenuItem>
                  );
                })}
              </SelectField>
            )}
            name={`ways.${index}.targetPage`}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <SelectField
                displayEmpty
                label='Target frame'
                error={error ? error.message : null}
                {...field}
                control={control}
              >
                <MenuItem value='' disabled>
                  Select frame...
                </MenuItem>
                {targetFrames
                  .filter(
                    (frame) => frame.originPage === ways[index]?.targetPage
                  )
                  .map((frame) => {
                    return (
                      <MenuItem
                        key={frame.id || frame._id}
                        value={frame.id || frame._id}
                      >
                        {frame.title || frame._id}
                      </MenuItem>
                    );
                  })}
              </SelectField>
            )}
            name={`ways.${index}.targetFrame`}
            control={control}
          />
        </ControlBarBlock>
      ))}
      <ControlBarBlock noPaddingTitle>
        <ControlButton
          noMargin
          onClick={() => append(defaultWay)}
          text='+ Add Check'
        />
      </ControlBarBlock>
      <ControlBarBlock noPaddingTitle>
        <ControlButton
          noMargin
          onClick={handleRemove}
          text='Delete checkpoint'
        />
      </ControlBarBlock>
    </FormWrap>
  );
};

export default CheckpointControl;

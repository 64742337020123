import axios from 'axios';

export const createChapterService = (body, type) =>
  axios.post(`/chapters?type=${type}`, body).then((res) => res.data);

export const updateChapterService = (data, updateType) =>
  axios
    .put(`/chapters/${data.id}`, data, { params: { type: updateType } })
    .then((res) => res.data);

export const updateChapterListService = (body) =>
  axios.post('/chapters/list', body);

export const deleteChapterService = (id, type) =>
  axios.delete(`/chapters/${id}`, { params: { type } }).then((res) => res.data);

export const ungroupChapterService = (id, type) =>
  axios
    .delete(`/chapters/ungroup/${id}`, { params: { type } })
    .then((res) => res.data);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createSelector } from 'reselect';
import { Box, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PagesList from 'pages/admin/StoryEdit/components/PagesList';
import StoryEdit from 'pages/admin/StoryEdit';
import ControlBar from 'pages/admin/StoryEdit/components/ControlBar';
import { useSocket } from 'utils/hooks/useSocket';
import {
  lockFramesFetchSelector,
  storyListLoadingSelector,
  storyListSelectedPageSelector,
} from 'common/selectors/editStory';
import {
  fetchCurrentStoryAction,
  resetStoryAction,
} from 'common/actions/storyActions';
import { fetchDecisionsAction } from 'common/actions/decisionsListActions';
import { fetchPagesAction } from 'common/actions/pagesListActions';
import { fetchHotSpotsAction } from 'common/actions/hotSpotsListActions';
import { pagesListLoadingSelector } from 'common/selectors/pages';
import { hotSpotsListLoadingSelector } from 'common/selectors/hotSpots';
import { decisionListLoadingSelector } from 'common/selectors/decisions';
import { fetchLayersListAction } from 'common/actions/layersListAction';
import { layersListLoadingSelector } from 'common/selectors/layers';
import { fetchBackpackListAction } from 'common/actions/backpackListAction';
import { checkpointLoadingSelector } from 'common/selectors/checkpoint';
import { fetchCheckpointListAction } from 'common/actions/checkpointListAction';
import { fetchHistoryListAction } from 'common/actions/editStoryActions';
import {
  fetchFramesAction,
  fetchTargetFramesAction,
} from 'common/actions/framesListActions';
// import { framesListLoadingSelector } from 'common/selectors/frames';
import { storyListLanguageSelector } from 'common/selectors/storyList';
import { framesListLoadingSelector } from 'common/selectors/frames';
import SaveDialog from 'components/SaveDialog';
import Header from 'components/Header';
import useStyles from './styles';

const selector = createSelector(
  storyListLoadingSelector,
  pagesListLoadingSelector,
  framesListLoadingSelector,
  hotSpotsListLoadingSelector,
  decisionListLoadingSelector,
  layersListLoadingSelector,
  checkpointLoadingSelector,
  storyListSelectedPageSelector,
  storyListLanguageSelector,
  lockFramesFetchSelector,
  (
    storyListLoading,
    pagesListLoading,
    framesListLoading,
    hotSpotLoading,
    decisionListLoading,
    layersLoading,
    checkpointLoading,
    currentPage,
    currentLang,
    lockFramesFetch
  ) => ({
    storyListLoading,
    pagesListLoading,
    framesListLoading,
    hotSpotLoading,
    decisionListLoading,
    layersLoading,
    checkpointLoading,
    currentPage,
    currentLang,
    lockFramesFetch,
  })
);

const EditStoryLayout = () => {
  const classes = useStyles();
  const {
    storyListLoading,
    pagesListLoading,
    framesListLoading,
    hotSpotLoading,
    decisionListLoading,
    layersLoading,
    checkpointLoading,
    currentPage,
    currentLang,
    lockFramesFetch,
  } = useSelector(selector);
  const { id: storyId } = useParams();
  const dispatch = useDispatch();

  const { usersOnStoryCount } = useSocket(storyId);
  const [isConfirmDialogOpen, setOpenConformDialog] = useState(true);

  const toggleConfirmDialog = () =>
    setOpenConformDialog((prevState) => !prevState);

  const fetchStory = useCallback(() => {
    dispatch(fetchCurrentStoryAction(storyId));
  }, [storyId, dispatch]);

  const fetchHotSpots = useCallback(() => {
    dispatch(fetchHotSpotsAction(storyId, currentLang));
  }, [storyId, dispatch, currentLang]);

  const fetchDecision = useCallback(() => {
    dispatch(fetchDecisionsAction(storyId));
  }, [storyId, dispatch]);

  const fetchPages = useCallback(() => {
    dispatch(fetchPagesAction(storyId));
  }, [storyId, dispatch]);

  const fetchFrames = useCallback(() => {
    if (currentPage && !lockFramesFetch) {
      dispatch(fetchFramesAction(currentPage.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);

  const fetchTargetFrames = useCallback(() => {
    dispatch(fetchTargetFramesAction(storyId));
  }, [storyId, dispatch]);

  const fetchLayers = useCallback(() => {
    dispatch(fetchLayersListAction(storyId, currentLang));
  }, [storyId, dispatch, currentLang]);

  const fetchBackpack = useCallback(() => {
    dispatch(fetchBackpackListAction(storyId));
  }, [dispatch, storyId]);

  const fetchCheckpoint = useCallback(() => {
    dispatch(fetchCheckpointListAction(storyId));
  }, [dispatch, storyId]);

  const fetchHistory = useCallback(() => {
    dispatch(fetchHistoryListAction(storyId));
  }, [dispatch, storyId]);

  const resetStory = useCallback(() => {
    dispatch(resetStoryAction());
  }, [dispatch]);

  useEffect(() => {
    fetchStory();
    fetchHotSpots();
    fetchDecision();
    fetchPages();
    fetchTargetFrames();
    fetchBackpack();
    fetchCheckpoint();
    fetchHistory();

    return () => {
      resetStory();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchHotSpots();
  }, [fetchHotSpots]);

  useEffect(() => {
    fetchLayers();
  }, [fetchLayers]);

  useEffect(() => {
    fetchFrames();
  }, [fetchFrames]);

  const loading = useMemo(
    () =>
      storyListLoading ||
      pagesListLoading ||
      // framesListLoading ||
      hotSpotLoading ||
      layersLoading ||
      checkpointLoading ||
      decisionListLoading,
    [
      storyListLoading,
      pagesListLoading,
      // framesListLoading,
      hotSpotLoading,
      decisionListLoading,
      checkpointLoading,
      layersLoading,
    ]
  );

  return (
    <Box className={classes.wrap}>
      {loading && (
        <Box className={classes.loader}>
          <CircularProgress className='loading' />
        </Box>
      )}
      <SaveDialog
        isOpen={!!(isConfirmDialogOpen && usersOnStoryCount && storyId)}
        onClose={toggleConfirmDialog}
        warningTitle='Are you sure you want to make changes?'
        warningText={`${usersOnStoryCount} ${
          usersOnStoryCount > 1 ? 'users are ' : 'user is'
        } playing this story at the moment.`}
      />
      <PagesList />
      <Box className={classes.contentWrap}>
        <Header loading={loading || framesListLoading} />
        <Box className={classes.content}>
          <StoryEdit />
          <ControlBar />
        </Box>
      </Box>
    </Box>
  );
};

export default EditStoryLayout;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { FormControl, InputLabel } from '@material-ui/core';
import { handleSpeechbubbleDataAction } from 'common/actions/speechbubble';
import { DrawSlider } from './styles';

export const WidthSlider = ({ state, label, min, max, name, step }) => {
  const dispatch = useDispatch();

  const onChange = useCallback(
    (_, newValue) => {
      dispatch(handleSpeechbubbleDataAction({ ...state, [name]: newValue }));
    },
    [dispatch, name, state]
  );

  return (
    <FormControl fullWidth focused style={{ paddingBottom: 0 }}>
      <InputLabel>{label}</InputLabel>
      <DrawSlider
        onChange={onChange}
        valueLabelDisplay='auto'
        aria-label='pretto slider'
        defaultValue={state[name]}
        value={state[name]}
        min={min}
        max={max}
        name={name}
        step={step}
      />
    </FormControl>
  );
};

export const getDefaultFrame = (pageId, storyId, isCircle, title) => ({
  type: 'frame',
  x: 40,
  y: 50,
  w: 50,
  h: 50,
  fid: `${new Date().getTime()}`,
  attachmentInfo: {},
  mobileAttachmentInfo: {},
  additionalAttachmentInfo: {},
  mobileAdditionalAttachmentInfo: {},
  formCoords: {
    nw: {
      x: 0,
      y: 0,
    },
    ne: {
      x: 100,
      y: 0,
    },
    se: {
      x: 100,
      y: 100,
    },
    sw: {
      x: 0,
      y: 100,
    },
  },
  originPage: pageId,
  story: storyId,
  videoImage: {},
  title,
  loopAudio: false,
  storyEnd: false,
  fullScreen: false,
  isOverlay: false,
  isCircle,
  zIndex: 1,
  animation: {
    appear: 'manual',
    delay: 2,
    animation: '',
    duration: 3,
    focus: '',
    focusSpeed: 10,
    zoomIn: 15,
  },
  isHideNavigation: false,
  fadeIn: 0.5,
  fadeOut: 0.5,
  vvtLanguages: [],
});

export const getDefaultStory = () => ({
  title: { nb: '' },
  subtext: { nb: '' },
  imageInfo: null,
  mobileImageInfo: null,
  about: { nb: '' },
  ageGroup: 16,
  difficulty: 'medium',
  duration: '',
  draft: false,
  isHiddenIndicator: false,
  languages: ['nb'],
  isLandscape: true,
});

export const getDefaultHotSpot = (selectedFrame, selectedPage) => ({
  id: '',
  name: '',
  type: 'hotspot',
  triggerType: 'click',
  actionType: 'navigate',
  visible: true,
  originPage: selectedPage.id,
  originFrame: selectedFrame.id,
  target: {
    page: selectedPage.id,
    frame: '',
  },
  coordinates: {
    x: 0,
    y: 0,
    w: 80,
    h: 80,
  },
});

export const getDefaultDecision = (
  storyId,
  selectedFrame,
  selectedPage,
  currentLng
) => ({
  story: storyId,
  options: [{ text: { [currentLng]: '', index: 0 }, frame: '', page: '' }],
  decisionName: 'Decision',
  decisionCase: { [currentLng]: '' },
  originPage: selectedPage.id,
  originFrame: selectedFrame.id,
});

export const getDefaultChapter = (storyId, pages) => ({
  title: '',
  pages,
  story: storyId,
  soundInfo: {},
});

export const getDefaultPage = (storyId, title = 'New Page') => ({
  title,
  story: storyId,
  soundInfo: {},
});

export const getDefaultLayerImage = () => ({
  title: '',
  imageInfo: {},
  mobileImageInfo: {},
  coordinates: {
    x: 0,
    y: 0,
    w: 80,
    h: 80,
  },
  isOverlay: false,
  zIndex: 2,
});

export const getDefaultSpeechbubble = () => ({
  coordinates: {
    x: 0,
    y: 0,
    w: 80,
    h: 80,
  },
  imageInfo: {},
  soundInfo: {},
  additionalAttachmentInfo: {},
  title: 'Speech bubble',
  loopAudio: false,
  zIndex: 2,
  animation: {
    appear: 'manual',
    delay: 2,
    animation: '',
    duration: 3,
    focus: '',
    focusSpeed: 10,
  },
  fadeIn: 0.5,
  fadeOut: 0.5,
  isDrawn: false,
});

export const layerFont = {
  fontSize: 10,
  fontStyle: 'Comic Neue',
  fontColor: 'black',
};

export const getDefaultLayerText = () => ({
  title: '',
  text: '',
  coordinates: {
    x: 0,
    y: 0,
    w: 80,
    h: 80,
  },
  font: layerFont,
  isOverlay: false,
  zIndex: 2,
});

export const getDefaultBackpackItem = () => ({
  id: '',
  title: '',
  description: '',
  type: '',
  originPage: '',
  originFrame: '',
  backpackAttachmentInfo: {},
  mobileBackpackAttachmentInfo: {},
  frameAttachmentInfo: {},
  gltfAttachmentInfo: {},
  binAttachmentInfo: {},
  texturesAttachmentInfo: [],
  audioAttachmentInfo: {},
  videoAttachmentInfo: {},
  link: '',
  notes: [''],
  coordinates: {
    x: 0,
    y: 0,
    w: 80,
    h: 80,
  },
});

export const getDefaultCheckpointWay = () => ({
  type: '',
  requiredItem: [],
  action: '',
  targetPage: '',
  targetFrame: '',
});

export const getDefaultCheckpointItem = () => ({
  id: '',
  title: '',
  story: '',
  originPage: '',
  originFrame: '',
  defaultWay: {
    targetPage: '',
    targetFrame: '',
  },
  ways: [getDefaultCheckpointWay()],
});

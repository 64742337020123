import * as Yup from 'yup';
import { URL_REGEX } from 'constants/regex';

export const validationSchema = () =>
  Yup.lazy((value) => {
    if (value.actionType === 'navigate') {
      return Yup.object().shape({
        name: Yup.string().trim().required('This field is required'),
        triggerType: Yup.string().trim().required('This field is required'),
        actionType: Yup.string().trim().required('This field is required'),
        target: Yup.object().shape({
          page: Yup.string().trim().required('This field is required'),
          frame: Yup.string().trim().required('This field is required'),
        }),
        visible: Yup.boolean(),
        mandatory: Yup.boolean(),
        mandatoryType: Yup.string().when('mandatory', {
          is: true,
          then: Yup.string().trim().required('This field is required'),
        }),
      });
    }

    return Yup.object().shape({
      name: Yup.string().trim().required('This field is required'),
      triggerType: Yup.string().trim().required('This field is required'),
      actionType: Yup.string().trim().required('This field is required'),
      visible: Yup.boolean(),
      mandatory: Yup.boolean(),
      mandatoryType: Yup.string().when('mandatory', {
        is: true,
        then: Yup.string().trim().required('This field is required'),
      }),
      ...(value.actionType === 'pop-up' && {
        url: Yup.string()
          .trim()
          .required('This field is required')
          .matches(URL_REGEX, 'Please, enter valid url'),
      }),
      ...(value.triggerType === 'hover' && {
        text: Yup.string().trim().required('This field is required'),
      }),
    });
  });

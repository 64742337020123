import * as Yup from 'yup';
import { URL_REGEX, VIMEO_REGEX } from 'constants/regex';
import { ATTACHMENTS_TYPE } from 'constants/attachmentsType';

export const validationSchema = () =>
  Yup.lazy((value) => {
    let validationObject = {
      title: Yup.string().trim().required('This field is required'),
      storyEnd: Yup.boolean(),
      loopAudio: Yup.boolean(),
      fullScreen: Yup.boolean(),
      newWindow: Yup.boolean(),
      isOverlay: Yup.boolean(),
      zIndex: Yup.number()
        .typeError('Must be a number')
        .positive('Index must positive'),
      attachmentInfo: Yup.object()
        .shape({
          type: Yup.string().trim(),
          name: Yup.string().trim(),
          url: Yup.string().trim(),
          size: Yup.number(),
        })
        .nullable(true)
        .test(
          'attachmentInfo',
          'This field is required',
          (fieldData) =>
            !!(fieldData?.type || fieldData?.url || fieldData?.name)
        )
        .test(
          'attachmentInfo',
          'The field has to have correct url value',
          (fieldData) =>
            fieldData?.type === ATTACHMENTS_TYPE.VIDEO && fieldData?.url
              ? VIMEO_REGEX.test(fieldData?.url)
              : true
        )
        .test(
          'attachmentInfo',
          'The field has to have correct url value',
          (fieldData) =>
            fieldData?.type === ATTACHMENTS_TYPE.URL && fieldData?.url
              ? URL_REGEX.test(fieldData?.url)
              : true
        ),
      attachmentVideoInfo: Yup.object()
        .shape({
          type: Yup.string().trim(),
          name: Yup.string().trim(),
          url: Yup.string().trim(),
          size: Yup.number(),
        })
        .nullable(true)
        .test(
          'attachmentVideoInfo',
          'The field has to have correct url value',
          (fieldData) =>
            fieldData?.type === ATTACHMENTS_TYPE.VIDEO && fieldData?.url
              ? VIMEO_REGEX.test(fieldData?.url)
              : true
        ),
      additionalAttachmentInfo: Yup.object()
        .shape({
          type: Yup.string().trim(),
          name: Yup.string().trim(),
          url: Yup.string().trim(),
          size: Yup.number(),
        })
        .nullable(true),
      videoImage: Yup.object()
        .shape({
          type: Yup.string().trim(),
          name: Yup.string().trim(),
          url: Yup.string().trim(),
          size: Yup.number(),
        })
        .nullable(true),
      animation: Yup.object()
        .shape({
          appear: Yup.string().trim(),
          delay: Yup.number().when('appear', {
            is: () => value.animation.appear === 'automated',
            then: Yup.number()
              .typeError('You must specify a number')
              .required('Please, enter delay before the next frame.'),
          }),
          animation: Yup.string().trim(),
          duration: Yup.number().when('animation', {
            is: () => !!value.animation.animation,
            then: Yup.number()
              .typeError('You must specify a number')
              .required('Please, enter animation duration.'),
          }),
          focus: Yup.string().trim(),
          focusSpeed: Yup.number().when('focus', {
            is: () => !!value.animation.focus,
            then: Yup.number()
              .typeError('You must specify a number')
              .required('Please, enter effect speed.'),
          }),
          zoomIn: Yup.number().when('focus', {
            is: () => !!value.animation.focus,
            then: Yup.number()
              .typeError('You must specify a number')
              .required('Please, enter zoom in.'),
          }),
        })
        .nullable(true)
        .test(
          'attachmentVideoInfo',
          'The field has to have correct url value',
          (fieldData) =>
            fieldData?.type === ATTACHMENTS_TYPE.VIDEO && fieldData?.url
              ? VIMEO_REGEX.test(fieldData?.url)
              : true
        ),
      fadeIn: Yup.number()
        .typeError('You must specify a number')
        .positive('Must be positive'),
      fadeOut: Yup.number()
        .typeError('You must specify a number')
        .positive('Must be positive'),
      crossFade: Yup.number()
        .typeError('You must specify a number')
        .positive('Must be positive'),
    };

    if (value.newWindow) {
      validationObject = {
        ...validationObject,
        windowHeight: Yup.number().required('This field is required'),
        windowWidth: Yup.number().required('This field is required'),
      };
    }

    return Yup.object().shape({ ...validationObject });
  });

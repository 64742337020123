import { PAGE_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import {
  CREATE_BACKPACK,
  DELETE_BACKPACK,
  END_LOADING_BACKPACK,
  FETCHED_BACKPACK,
  START_LOADING_BACKPACK,
  UPDATE_BACKPACK,
} from 'common/types/backpackList';
import { pagesListSelector } from 'common/selectors/pages';
import {
  createBackpackItemService,
  deleteBackpackItemService,
  deleteGameItemsService,
  getBackpackListService,
  updateBackpackItemService,
} from 'services/backpack';
import { errorToast, successToast } from 'services/toast';
import {
  storyListEditItemSelector,
  storyListSelectedIdsSelector,
  storyListSelectedPageSelector,
} from '../selectors/editStory';
import { storyItemsSelector } from '../selectors/story';
import {
  clearEditItemAction,
  editItemAction,
  fetchHistoryListAction,
  setEditAction,
  setSelectedAction,
} from './editStoryActions';

export const fetchedBackpackListAction = (payload) => ({
  type: FETCHED_BACKPACK,
  payload,
});

export const updateBackpackSuccessAction = (payload) => ({
  type: UPDATE_BACKPACK,
  payload,
});

export const startLoadingBackpackAction = () => ({
  type: START_LOADING_BACKPACK,
});

export const endLoadingBackpackAction = () => ({
  type: END_LOADING_BACKPACK,
});

export const removeBackpackItemSuccessAction = (payload) => ({
  type: DELETE_BACKPACK,
  payload,
});

export const createBackpackItemSuccessAction = (payload) => ({
  type: CREATE_BACKPACK,
  payload,
});

export const fetchBackpackListAction = (storyId) => {
  return (dispatch) => {
    dispatch(startLoadingBackpackAction());

    getBackpackListService({ story: storyId })
      .then((res) => {
        dispatch(endLoadingBackpackAction());
        dispatch(fetchedBackpackListAction(res.rows));
      })
      .catch((error) => {
        dispatch(endLoadingBackpackAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const addBackpackItemAction = (body, type = UPDATE_TYPE.STANDART) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = storyListSelectedIdsSelector(state);
    const storyId = storyItemsSelector(state).id;
    dispatch(startLoadingBackpackAction());

    createBackpackItemService({ ...body, story: storyId }, type)
      .then((res) => {
        dispatch(setEditAction(false));
        dispatch(fetchHistoryListAction(storyId));
        dispatch(endLoadingBackpackAction());
        dispatch(setSelectedAction({ ...selectedIds, page: '', frame: '' }));
        dispatch(createBackpackItemSuccessAction(res));
        dispatch(clearEditItemAction());
        successToast('Backpack item created success');
      })
      .catch((error) => {
        dispatch(endLoadingBackpackAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const removeBackpackItemAction = (item, type = UPDATE_TYPE.STANDART) => {
  return (dispatch, getState) => {
    dispatch(startLoadingBackpackAction());
    const state = getState();
    const editBackpackItem = storyListEditItemSelector(state);
    const selectedPage = storyListSelectedPageSelector(state);
    const { id } = storyItemsSelector(state);
    dispatch(startLoadingBackpackAction());

    deleteBackpackItemService(item?.id || editBackpackItem.id, type)
      .then(() => {
        dispatch(fetchHistoryListAction(id));
        dispatch(removeBackpackItemSuccessAction(item || editBackpackItem));
        dispatch(clearEditItemAction());
        dispatch(endLoadingBackpackAction());
        dispatch(
          editItemAction({
            item: selectedPage,
            type: PAGE_TYPE,
          })
        );
        successToast('Backpack item deleted success');
      })
      .catch((error) => {
        dispatch(endLoadingBackpackAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const removeGameItemsAction = (frameId, type = UPDATE_TYPE.STANDART) => {
  return (dispatch, getState) => {
    dispatch(startLoadingBackpackAction());
    const state = getState();
    const story = storyItemsSelector(state);
    dispatch(startLoadingBackpackAction());
    deleteGameItemsService(frameId, type)
      .then(() => {
        dispatch(fetchBackpackListAction(story.id));
        dispatch(endLoadingBackpackAction());
      })
      .catch(() => {
        dispatch(endLoadingBackpackAction());
      });
  };
};

export const updateBackpackItemAction = (body, updateType) => {
  return (dispatch, getState) => {
    const state = getState();
    const pagesList = pagesListSelector(state);
    const selectedIds = storyListSelectedIdsSelector(state);
    const story = storyItemsSelector(state);

    dispatch(startLoadingBackpackAction());

    updateBackpackItemService(body, updateType)
      .then((res) => {
        dispatch(setEditAction(false));
        dispatch(fetchBackpackListAction(story.id));
        dispatch(endLoadingBackpackAction());
        dispatch(fetchHistoryListAction(story.id));
        dispatch(updateBackpackSuccessAction(res));
        dispatch(
          setSelectedAction({
            ...selectedIds,
            page: res.item.originPage,
            frame: '',
            frameItem: '',
          })
        );
        if (updateType === UPDATE_TYPE.UPDATE) {
          dispatch(
            editItemAction({
              item: pagesList.find((page) => page.id === res.item.originPage),
              type: PAGE_TYPE,
            })
          );
          successToast('Backpack item update success');
        }
      })
      .catch((error) => {
        dispatch(endLoadingBackpackAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

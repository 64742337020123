import {
  CREATE_LAYER,
  DELETE_LAYER,
  END_LOADING_LAYERS,
  FETCHED_LAYERS,
  START_LOADING_LAYERS,
  UPDATE_LAYER,
} from '../types/layersList';

const initialState = {
  layersList: [],
  loading: false,
};

const layersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_LAYERS:
      return {
        ...state,
        layersList: action.payload,
      };
    case CREATE_LAYER:
      return {
        ...state,
        layersList: [...state.layersList, action.payload],
      };
    case START_LOADING_LAYERS:
      return {
        ...state,
        loading: true,
      };
    case END_LOADING_LAYERS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_LAYER: {
      const layersList = [...state.layersList];

      const index = layersList.findIndex(
        (hotSpot) => hotSpot.id === action.payload.id
      );

      if (index >= 0) {
        layersList.splice(index, 1);
      }

      return { ...state, layersList, loading: false };
    }
    case UPDATE_LAYER: {
      const layersList = [...state.layersList];

      const updateIndex = layersList.findIndex(
        (hotSpot) => hotSpot.id === action.payload.id
      );

      if (updateIndex >= 0) {
        layersList.splice(updateIndex, 1, action.hotSpot);
      }

      return { ...state, hotSpot: { ...layersList }, loading: false };
    }
    default:
      return state;
  }
};

export default layersReducer;

import {
  CREATE_STORY_SUCCESS,
  DELETE_STORY_SUCCESS,
  EDIT_STATUS,
  END_LOADING,
  FETCH_STORIES,
  FETCH_USERS,
  SET_CURRENT_LANGUAGE,
  SET_CURRENT_STORY,
  START_LOADING,
} from 'common/types/storyList';
import { getUsers } from 'services/auth';
import {
  createStory as createStoryService,
  deleteStory as deleteStoryService,
  duplicateStory as duplicateStoryService,
  getStoriesList as getStoriesListService,
  updateStory as updateStoryService,
} from 'services/stories';
import { errorToast, successToast } from 'services/toast';

export const startLoadingStoryAction = () => ({
  type: START_LOADING,
});

export const endLoadingStoryAction = () => ({
  type: END_LOADING,
});

export const fetchStoryListSuccessAction = (payload) => ({
  type: FETCH_STORIES,
  payload,
});

export const fetchUsersSuccessAction = (payload) => ({
  type: FETCH_USERS,
  payload,
});

export const createStorySuccessAction = (payload) => ({
  type: CREATE_STORY_SUCCESS,
  payload,
});

export const deleteStorySuccessAction = (payload) => ({
  type: DELETE_STORY_SUCCESS,
  payload,
});

export const setCurrentStoryAction = (payload) => ({
  type: SET_CURRENT_STORY,
  payload,
});

export const setCurrentLanguageAction = (payload) => ({
  type: SET_CURRENT_LANGUAGE,
  payload,
});

export const setCurrentStoryIsEditAction = (payload) => ({
  type: EDIT_STATUS,
  payload,
});

export const fetchStoryListAction = (debouncedSearchTerm) => {
  return (dispatch) => {
    dispatch(startLoadingStoryAction());
    getStoriesListService(debouncedSearchTerm)
      .then((res) => {
        dispatch(endLoadingStoryAction());
        dispatch(fetchStoryListSuccessAction(res.rows));
      })
      .catch((error) => {
        dispatch(endLoadingStoryAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const fetchUsersAction = () => {
  return (dispatch) => {
    dispatch(startLoadingStoryAction());

    getUsers()
      .then((res) => {
        dispatch(endLoadingStoryAction());
        dispatch(fetchUsersSuccessAction(res));
      })
      .catch((error) => {
        dispatch(endLoadingStoryAction());
        if (
          error.response &&
          error.response.data &&
          error.response.data.err.message
        ) {
          errorToast(
            error.response.data.err.message || 'Something wrong. Try again'
          );
        }
      });
  };
};

export const createStoryAction = (body) => {
  return (dispatch) => {
    dispatch(startLoadingStoryAction());
    createStoryService(body)
      .then((res) => {
        dispatch(endLoadingStoryAction());
        dispatch(setCurrentStoryIsEditAction(false));
        dispatch(createStorySuccessAction(res));
        dispatch(fetchStoryListAction());
        successToast('Story created success');
      })
      .catch((error) => {
        dispatch(endLoadingStoryAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const updateStoryAction = (body) => {
  return (dispatch) => {
    dispatch(startLoadingStoryAction());
    updateStoryService(body)
      .then((res) => {
        dispatch(endLoadingStoryAction());
        dispatch(setCurrentStoryIsEditAction(false));
        dispatch(fetchStoryListAction());
        dispatch(setCurrentStoryAction(res.updatedStory));
        successToast('Story updated success');
      })
      .catch((error) => {
        dispatch(endLoadingStoryAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const deleteStoryAction = (id) => {
  return (dispatch) => {
    dispatch(startLoadingStoryAction());
    deleteStoryService(id)
      .then(() => {
        dispatch(endLoadingStoryAction());
        dispatch(setCurrentStoryIsEditAction(false));
        dispatch(setCurrentStoryAction(null));
        dispatch(deleteStorySuccessAction(id));
        successToast('Story delete success');
      })
      .catch((error) => {
        dispatch(endLoadingStoryAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const duplicateStoryAction = (id) => {
  return (dispatch) => {
    dispatch(startLoadingStoryAction());

    duplicateStoryService(id)
      .then((res) => {
        dispatch(endLoadingStoryAction());
        dispatch(createStorySuccessAction(res.duplicate));
        successToast('Story duplicate success');
      })
      .catch((error) => {
        dispatch(endLoadingStoryAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

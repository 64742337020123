import { createSelector } from 'reselect';

export const storyListFramesSelector = (state) => state.frames.frames;
export const framesListLoadingSelector = (state) => state.frames.loading;
export const targetFramesListSelector = (state) => state.frames.targetFrames;

// export const groupedTargetFramesSelector = createSelector(
//   storyListFramesSelector,
//   storyListLayoutSelector,
//   (storyFrames, storyLayout) => {
//     const groupedFramesList = [];
//     storyFrames?.forEach((frame) => {
//       const filteredFrame = {
//         id: frame.id,
//         title: frame.title,
//       };
//       const layout = frame[storyLayout] ? storyLayout : 'desktop';
//       if (frame[layout]?.originPage)
//         if (frame[layout].originPage in groupedFramesList)
//           groupedFramesList[frame[layout].originPage].push(filteredFrame);
//         else groupedFramesList[frame[layout].originPage] = [filteredFrame];
//     });
//     return groupedFramesList;
//   }
// );

export const framesListSelector = createSelector(
  storyListFramesSelector,
  (storyFrames) => {
    const framesList = [];
    storyFrames?.forEach((frame) => {
      if (frame.originPage)
        if (frame.originPage in framesList)
          framesList[frame.originPage].push(frame);
        else framesList[frame.originPage] = [frame];
    });
    return framesList;
  }
);

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import soundIcon from 'assets/icons/sound.svg';
import {
  BACKPACK_TYPE,
  IMAGE_LAYER_TYPE,
  SPEECHBUBBLE_LAYER_TYPE,
  TEXT_LAYER_TYPE,
} from 'constants/editTypes';
import { layerFont } from 'constants/defaultItems';
import { ATTACHMENTS_TYPE } from 'constants/attachmentsType';
import Image from 'components/Image';
import GridHotspot from '../GridHotspot';
import GridLayer from '../GridLayer/GridLayer';
import GridBackpack from '../GridBackpack';
import { getLanguageField } from '../../../ControlBar/LayerSpeechbubbleControl/LayerSpeechbubble.helpers';
import useStyles from './styles';

const GridImage = ({
  attachmentInfo,
  additionalAttachmentInfo,
  hotSpot = [],
  layer = [],
  backPackItem = [],
  clipPath,
  isCircle,
  frameId,
  currentLang,
}) => {
  const classes = useStyles({ clipPath, isCircle });

  const hotSpotElements = useMemo(() => {
    return hotSpot.map(({ coordinates, id }) => {
      const config = {
        left: `${coordinates.x}%`,
        top: `${coordinates.y}%`,
        width: `${coordinates.w}px`,
        height: `${coordinates.h}px`,
      };

      return <GridHotspot key={id} {...config} />;
    });
  }, [hotSpot]);

  const layerElements = useMemo(() => {
    return layer.map(
      ({
        coordinates,
        id,
        imageInfo,
        type,
        content,
        font,
        isSpeechbubble,
        speechbubble,
        soundInfo,
      }) => {
        const config = {
          left: `${getLanguageField(coordinates, currentLang).x}%`,
          top: `${getLanguageField(coordinates, currentLang).y}%`,
          width: `${getLanguageField(coordinates, currentLang).w}px`,
          height: `${getLanguageField(coordinates, currentLang).h}px`,
          imageUrl: getLanguageField(imageInfo, currentLang)?.url || '',
          imageName: getLanguageField(imageInfo, currentLang)?.name || '',
          type,
          textSize: font?.fontSize || layerFont.fontSize,
          textStyle: font?.fontStyle || layerFont.fontStyle,
          content,
          isSpeechbubble,
          speechbubble,
        };

        return (
          <GridLayer
            key={id}
            hasSound={!!getLanguageField(soundInfo)?.url}
            {...config}
          />
        );
      }
    );
  }, [layer, currentLang]);

  const backpackElements = useMemo(() => {
    return backPackItem.map(
      ({
        coordinates,
        id,
        frameAttachmentInfo,
        audioAttachmentInfo,
        videoAttachmentInfo,
      }) => {
        const config = {
          left: `${coordinates.x}%`,
          top: `${coordinates.y}%`,
          width: `${coordinates.w}px`,
          height: `${coordinates.h}px`,
          imageUrl: frameAttachmentInfo?.url || '',
          imageName: frameAttachmentInfo?.name || '',
          audioName: audioAttachmentInfo?.name || '',
          videoName: videoAttachmentInfo?.name || '',
        };
        return <GridBackpack key={id} {...config} />;
      }
    );
  }, [backPackItem]);

  return (
    <Box className={classes.wrap}>
      {hotSpotElements}
      {layerElements}
      {backpackElements}
      <div className={classes.imageWrap}>
        <Image
          id={`frameImage-${frameId}`}
          src={attachmentInfo.url}
          alt={attachmentInfo.name}
          style={{ clipPath }}
        />
      </div>
      {additionalAttachmentInfo?.type === ATTACHMENTS_TYPE.AUDIO && (
        <Box className={classes.sound}>
          <Image src={soundIcon} alt={attachmentInfo.name} />
        </Box>
      )}
    </Box>
  );
};

GridImage.defaultProps = {
  additionalAttachmentInfo: null,
  backPackItem: [],
  layer: [],
  hotSpot: [],
};

GridImage.propTypes = {
  attachmentInfo: PropTypes.shape({
    type: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  additionalAttachmentInfo: PropTypes.shape({
    type: PropTypes.string,
    size: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
  }),
  hotSpot: PropTypes.arrayOf(
    PropTypes.shape({
      coordinates: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        w: PropTypes.number.isRequired,
        h: PropTypes.number.isRequired,
      }).isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  layer: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      imageInfo: PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
      }),
      content: PropTypes.string,
      type: PropTypes.oneOf([
        IMAGE_LAYER_TYPE,
        TEXT_LAYER_TYPE,
        BACKPACK_TYPE,
        SPEECHBUBBLE_LAYER_TYPE,
      ]),
      font: PropTypes.shape({
        fontSize: PropTypes.number,
        fontStyle: PropTypes.string,
      }),
    })
  ),
  backPackItem: PropTypes.arrayOf(
    PropTypes.shape({
      coordinates: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        w: PropTypes.number.isRequired,
        h: PropTypes.number.isRequired,
      }).isRequired,
      id: PropTypes.string.isRequired,
      frameAttachmentInfo: PropTypes.shape({
        url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
};

export default GridImage;

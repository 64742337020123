import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object().shape({
    title: Yup.string().trim().required('This field is required'),
    defaultWay: Yup.object().shape({
      targetPage: Yup.string().trim().required('This field is required'),
      targetFrame: Yup.string().trim().required('This field is required'),
    }),
    ways: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().trim().required('This field is required'),
        action: Yup.string().trim().required('This field is required'),
        targetPage: Yup.string().trim().required('This field is required'),
        targetFrame: Yup.string().trim().required('This field is required'),
        requiredItem: Yup.array().test(
          'requiredItem',
          'This field is required',
          (arr) => arr?.length >= 1
        ),
      })
    ),
  });

import { PAGE_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import {
  CREATE_HOT_SPOT,
  DELETE_HOT_SPOT,
  END_LOADING_HOT_SPOT,
  FETCHED_HOT_SPOT,
  START_LOADING_HOT_SPOT,
  UPDATE_HOT_SPOT,
} from 'common/types/hotSpotsList';
import {
  storyListEditItemSelector,
  storyListSelectedIdsSelector,
  storyListSelectedPageSelector,
} from 'common/selectors/editStory';
import { storyItemsSelector } from 'common/selectors/story';
import { pagesListSelector } from 'common/selectors/pages';
import { storyListLanguageSelector } from 'common/selectors/storyList';
import {
  createHotSpot as createHotSpotService,
  deleteHotSpot as deleteHotSpotService,
  getHotSpotsList as getHotSpotsListService,
  updateHotSpot as updateHotSpotService,
} from 'services/hotSpots';
import { errorToast, successToast } from 'services/toast';
import {
  clearEditItemAction,
  editItemAction,
  fetchHistoryListAction,
  setEditAction,
  setSelectedAction,
} from './editStoryActions';

export const fetchedHotSpotsAction = (hotSpots) => ({
  type: FETCHED_HOT_SPOT,
  hotSpots,
});

export const updateHotSpotSuccesAction = (hotSpot) => ({
  type: UPDATE_HOT_SPOT,
  hotSpot,
});

export const startLoadingHotSpotsAction = () => ({
  type: START_LOADING_HOT_SPOT,
});

export const endLoadingHotSpotsAction = () => ({ type: END_LOADING_HOT_SPOT });

export const removedHotSpotAction = (hotSpot) => ({
  type: DELETE_HOT_SPOT,
  hotSpot,
});

export const fetchHotSpotsAction = (storyId) => {
  return (dispatch, getState) => {
    dispatch(startLoadingHotSpotsAction());
    const state = getState();
    const language = storyListLanguageSelector(state);

    getHotSpotsListService({ story: storyId, language })
      .then((res) => {
        dispatch(fetchedHotSpotsAction(res.rows || []));
        dispatch(endLoadingHotSpotsAction());
      })
      .catch((error) => {
        dispatch(endLoadingHotSpotsAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const removeHotSpotAction = (hotspot, type = UPDATE_TYPE.STANDART) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = storyListSelectedIdsSelector(state);
    const selectedPage = storyListSelectedPageSelector(state);
    const editHotSpot = storyListEditItemSelector(state);
    const { id } = storyItemsSelector(state);
    dispatch(startLoadingHotSpotsAction());

    deleteHotSpotService(hotspot?.id || editHotSpot.id, type)
      .then(() => {
        dispatch(fetchHistoryListAction(id));
        dispatch(removedHotSpotAction(hotspot || editHotSpot));
        dispatch(clearEditItemAction());
        dispatch(setSelectedAction({ ...selectedIds, frameItem: '' }));
        dispatch(
          editItemAction({
            item: selectedPage,
            type: PAGE_TYPE,
          })
        );
        successToast('Hotspot deleted success');
      })
      .catch((error) => {
        dispatch(endLoadingHotSpotsAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const addHotSpot = (body, type = UPDATE_TYPE.STANDART) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = storyListSelectedIdsSelector(state);
    const selectedPage = storyListSelectedPageSelector(state);
    const { id } = storyItemsSelector(state);
    dispatch(startLoadingHotSpotsAction());

    createHotSpotService(
      {
        ...body,
        story: id,
        originPage:
          type !== UPDATE_TYPE.STANDART ? body.originPage : selectedIds.page,
        originFrame:
          type !== UPDATE_TYPE.STANDART ? body.originFrame : selectedIds.frame,
      },
      type
    )
      .then((hotSpot) => {
        dispatch(fetchHistoryListAction(id));
        dispatch(endLoadingHotSpotsAction());
        dispatch(setEditAction(false));
        dispatch({ type: CREATE_HOT_SPOT, hotSpot });
        dispatch(setSelectedAction({ ...selectedIds, frameItem: '' }));
        dispatch(
          editItemAction({
            item: selectedPage,
            type: PAGE_TYPE,
          })
        );
        dispatch(clearEditItemAction());
        successToast('Hotspot create success');
      })
      .catch((error) => {
        dispatch(endLoadingHotSpotsAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const updateHotSpot = (body, updateType) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = storyListSelectedIdsSelector(state);
    const pagesList = pagesListSelector(state);
    const { id } = storyItemsSelector(state);

    dispatch(startLoadingHotSpotsAction());

    updateHotSpotService(body, updateType)
      .then((hotSpot) => {
        dispatch(setEditAction(false));
        dispatch(endLoadingHotSpotsAction());
        dispatch(updateHotSpotSuccesAction(hotSpot));
        // todo refactor if update hotspot mappedArrayToPageObject selector didint recalculate
        dispatch(fetchHistoryListAction(id));
        dispatch(fetchHotSpotsAction(id));
        dispatch(
          setSelectedAction({
            ...selectedIds,
            page: hotSpot.originPage,
            frame: '',
            frameItem: '',
          })
        );
        dispatch(
          editItemAction({
            item: pagesList.find((page) => page.id === hotSpot.originPage),
            type: PAGE_TYPE,
          })
        );
        successToast('Hotspot updated successfully');
      })
      .catch((error) => {
        dispatch(endLoadingHotSpotsAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

import React from 'react';
import classNames from 'classnames';
import ReplayIcon from '@material-ui/icons/Replay';

export const RestartIcon = ({ fill = '#fff', className, ...props }) => (
  <ReplayIcon
    viewBox='0 0 24 24'
    style={{ fill, width: 15, height: 15, transform: 'scaleX(-1)' }}
    className={classNames({
      [className]: !!className,
    })}
    {...props}
  />
);

// <svg
// fill='#fff'
// xmlns='http://www.w3.org/2000/svg'
// viewBox='0 0 24 24'
// width='13px'
// height='13px'
// {...props}
// >
// <path d='M 2 2 L 4.9394531 4.9394531 C 3.1262684 6.7482143 2 9.2427079 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 6.486 17.514 2 12 2 L 12 4 C 16.411 4 20 7.589 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 9.7940092 4.9004767 7.7972757 6.3496094 6.3496094 L 9 9 L 9 2 L 2 2 z' />
// </svg>

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Typography,
  Tab,
  InputLabel,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { compile } from 'path-to-regexp';
import { yupResolver } from '@hookform/resolvers/yup';
import { getEnglishName } from 'all-iso-language-codes';
import ISO6391 from 'iso-639-1';
import Menu from 'pages/admin/StoryEdit/components/PagesList/Menu/Menu';
import { renderLanguageList } from 'pages/admin/StoryEdit/components/PagesList/PagesList';
import { ROUTES } from 'constants/index';
import { getDefaultStory } from 'constants/defaultItems';
import { monthNames } from 'constants/month';
import colors from 'constants/colors';
import useMobile from 'utils/hooks/useMobile';
import { treeCompare } from 'utils/treeCompare';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useSocket } from 'utils/hooks/useSocket';
import { getPreviewLink } from 'utils/helpers/helpers';
import {
  storyListCurrentStorySelector,
  storyListEditStatusSelector,
  storyListUsersSelector,
} from 'common/selectors/storyList';
import {
  createStoryAction,
  deleteStoryAction,
  duplicateStoryAction,
  setCurrentLanguageAction,
  setCurrentStoryAction,
  setCurrentStoryIsEditAction,
  updateStoryAction,
} from 'common/actions/storyListActions';
import StyledInput from 'components/forms/StyledInput';
import SelectField from 'components/forms/SelectField';
import StyledCheckbox from 'components/forms/StyledCheckbox';
import FileInput from 'components/forms/FileInput';
import { CopyIcon } from 'components/Icons/CopyIcon';
import { EyeIcon } from 'components/Icons/EyeIcon';
import TabsWrapper from 'components/TabsWrapper/TabsWrapper';
import { errorToast, successToast } from 'services/toast';
import { copyStoryToEnvService } from 'services/stories';
import ConfirmDialog from '../ConfirmDialog';
import {
  AVAILABLE_ENVS,
  difficulty,
  fieldsForValidation,
} from './StoryForm.constants';
import DeleteConfirmDialog from './Dialogs/DeleteDialog';
import { customSchema, validationSchema } from './StoryForm.validation';
import useStyles from './styles';

const selector = createSelector(
  storyListCurrentStorySelector,
  storyListEditStatusSelector,
  storyListUsersSelector,
  (currentStory, wasEdit, allUsers) => ({
    currentStory,
    wasEdit,
    allUsers,
  })
);

const adornmentCorrection = {
  start: 7, // start value (i npx)
  correction: 5.64, // to how many px move adornment (in px)
};

const defaultSelectedLng = {
  index: 0,
  lng: 'nb',
};

const StoryForm = ({ isDrawer = false, setIsEdit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useMobile();
  const { currentStory, wasEdit, allUsers } = useSelector(selector);

  const isDefaultStory = useMemo(() => !currentStory?.id, [currentStory]);
  const emptyStory = useMemo(() => getDefaultStory(), []);
  const classes = useStyles({ isMobile, isDefaultStory });

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [usersOnStory, setUsersOnStory] = useState([]);
  const [unauthorizedUsers, setUnauthorizedUsers] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageInput, setLanguageInput] = useState('');
  const [storyData, setStoryData] = useState(currentStory || emptyStory);
  const [selectedLanguage, setSelectedLanguage] = useState(defaultSelectedLng);

  const countryList = useMemo(() => renderLanguageList(), []);

  const { storyViews, userIdsOnStory } = useSocket(currentStory.id);
  const { control, handleSubmit, reset, setError, setValue } = useForm({
    defaultValues: {
      id: currentStory.id || '',
      title: currentStory.title || emptyStory.title,
      subtext: currentStory.subtext || emptyStory.subtext,
      imageInfo: currentStory.imageInfo || emptyStory.imageInfo,
      about: currentStory.about || emptyStory.about,
      ageGroup: `${currentStory.ageGroup}` || emptyStory.ageGroup,
      difficulty: currentStory.difficulty || emptyStory.difficulty,
      duration: currentStory.duration || emptyStory.duration,
      draft: currentStory.draft || emptyStory.draft,
      isHiddenIndicator:
        currentStory.isHiddenIndicator || emptyStory.isHiddenIndicator,
      languages: currentStory.languages || emptyStory.languages,
      isLandscape: currentStory.isLandscape ?? emptyStory.isLandscape,
    },
    mode: 'onChange',
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({
    control,
  });

  const formFieldDebounced = useDebounce(formField, 300);

  useEffect(() => {
    if (currentStory?.id !== formField.id) {
      setSelectedLanguage(defaultSelectedLng);
      setStoryData(currentStory || emptyStory);
      reset({
        id: currentStory.id || '',
        title: currentStory.title || emptyStory.title,
        subtext: currentStory.subtext || emptyStory.subtext,
        imageInfo: currentStory.imageInfo || emptyStory.imageInfo,
        about: currentStory.about || emptyStory.about,
        ageGroup: `${currentStory.ageGroup}` || emptyStory.ageGroup,
        difficulty: currentStory.difficulty || emptyStory.difficulty,
        duration: currentStory.duration || emptyStory.duration,
        draft: currentStory.draft || emptyStory.draft,
        isHiddenIndicator:
          currentStory.isHiddenIndicator || emptyStory.isHiddenIndicator,
        languages: currentStory.languages || emptyStory.languages,
        isLandscape: currentStory.isLandscape ?? emptyStory.isLandscape,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, currentStory, formField.id]);

  useEffect(() => {
    reset({
      id: currentStory.id || '',
      title: storyData.title,
      subtext: storyData.subtext,
      imageInfo: storyData.imageInfo || emptyStory.imageInfo,
      about: storyData.about,
      ageGroup: `${storyData.ageGroup}`,
      difficulty: storyData.difficulty,
      duration: storyData.duration,
      draft: storyData.draft,
      isHiddenIndicator: storyData.isHiddenIndicator,
      languages: storyData.languages,
      isLandscape: storyData.isLandscape,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const handleUpdateStory = useCallback(() => {
    // the order of the elements must be the same like in formField
    const fieldsForCompare = {
      id: currentStory.id,
      title: currentStory.title,
      subtext: currentStory.subtext || '',
      imageInfo: currentStory.imageInfo || emptyStory.imageInfo,
      about: currentStory.about,
      ageGroup: `${currentStory.ageGroup}`,
      difficulty: currentStory.difficulty,
      duration: currentStory.duration,
      draft: currentStory.draft,
      isHiddenIndicator: currentStory.isHiddenIndicator,
      languages: currentStory.languages,
      isLandscape: currentStory.isLandscape,
    };

    if (!treeCompare(fieldsForCompare, formFieldDebounced)) {
      return dispatch(setCurrentStoryIsEditAction(true));
    }
    return dispatch(setCurrentStoryIsEditAction(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStory, dispatch, formFieldDebounced]);

  const handleOpenDeleteDialog = useCallback(
    () => setOpenDeleteDialog(true),
    []
  );

  const handleCloseDeleteDialog = useCallback(
    () => setOpenDeleteDialog(false),
    []
  );

  const handleOpenConfirmDialog = useCallback(
    () => setOpenConfirmDialog(true),
    []
  );

  const handleCloseConfirmDialog = useCallback(
    () => setOpenConfirmDialog(false),
    []
  );

  const handleDeleteStory = useCallback(() => {
    dispatch(deleteStoryAction(formField.id));
  }, [dispatch, formField.id]);

  const handleRedirect = useCallback(() => {
    dispatch(setCurrentStoryIsEditAction(false));
    dispatch(setCurrentStoryAction(null));
    dispatch(setCurrentLanguageAction('nb'));
    history.push(compile(ROUTES.STORIES_EDIT)({ id: currentStory?.id }));
  }, [history, currentStory, dispatch]);

  useEffect(() => {
    if (isDrawer) {
      setIsEdit(wasEdit);
    }
  }, [isDrawer, setIsEdit, wasEdit]);

  const handleEditStory = useCallback(() => {
    if (!wasEdit) {
      handleRedirect();
    } else {
      handleOpenConfirmDialog();
    }
  }, [handleOpenConfirmDialog, handleRedirect, wasEdit]);

  const trimValue = useCallback((value) => {
    if (typeof value === 'string') {
      return value.trim();
    }
    if (typeof value === 'object' && value !== null) {
      const trimmed = {};
      // eslint-disable-next-line no-restricted-syntax, prefer-const, no-plusplus, guard-for-in
      for (const [lang, text] of Object.entries(value)) {
        trimmed[lang] = trimValue(text);
      }
      return trimmed;
    }
    return value;
  }, []);

  const extractTextFields = useCallback(
    (obj, onlyTrim) => {
      const extracted = {};
      // eslint-disable-next-line no-restricted-syntax, prefer-const, no-plusplus, guard-for-in
      for (const [key, value] of Object.entries(obj)) {
        if (fieldsForValidation.includes(key)) {
          if (onlyTrim) {
            // eslint-disable-next-line no-param-reassign
            obj[key] = trimValue(value);
          } else {
            extracted[key] = value[selectedLanguage.lng];
          }
        }
      }
      return onlyTrim ? obj : extracted;
    },
    [selectedLanguage.lng, trimValue]
  );

  const stateValidation = useCallback(
    async (obj) => {
      // eslint-disable-next-line no-restricted-syntax, prefer-const, no-plusplus, guard-for-in
      for (const field of fieldsForValidation) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(field) && typeof obj[field] === 'object') {
          // eslint-disable-next-line no-restricted-syntax, prefer-const, no-plusplus, guard-for-in
          for (const lang of obj.languages) {
            if (
              // eslint-disable-next-line no-prototype-builtins
              obj[field].hasOwnProperty(lang) &&
              obj[field][lang] === '' &&
              lang !== selectedLanguage.lng
            ) {
              errorToast(
                `field ${field} with ${getEnglishName(lang)} language is empty`
              );
              return true;
            }
          }
        }
      }
      return false;
    },
    [selectedLanguage.lng]
  );

  const handleValidation = useCallback(async () => {
    try {
      await customSchema().validate(extractTextFields(storyData, false), {
        abortEarly: false,
      });
      return false;
    } catch ({ ...error }) {
      // eslint-disable-next-line array-callback-return
      error.inner.map(({ ...item }) => {
        setError(`${item.path}.${selectedLanguage.lng}`, {
          type: 'custom',
          message: item.message,
        });
      });
      return true;
    }
  }, [extractTextFields, selectedLanguage.lng, setError, storyData]);

  const onSubmit = useCallback(
    (data) => {
      handleValidation().then((hasErrors) => {
        if (!hasErrors) {
          stateValidation(storyData).then((err) => {
            if (!err) {
              const trimmedData = extractTextFields(storyData, true);
              if (data.id) {
                dispatch(updateStoryAction(trimmedData));
              } else {
                dispatch(createStoryAction(trimmedData));
                dispatch(setCurrentStoryAction(null));
              }
            }
          });
        }
      });
    },
    [dispatch, extractTextFields, handleValidation, stateValidation, storyData]
  );

  const getDate = useCallback((dateStr) => {
    const date = new Date(dateStr);
    return `${date.getDate()} ${
      monthNames[date.getMonth()]
    } ${date.getFullYear()} at ${date.getHours()}:${
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`
    } `;
  }, []);

  const handleDuplicateStory = useCallback(() => {
    dispatch(duplicateStoryAction(currentStory.id));
  }, [currentStory.id, dispatch]);

  useEffect(() => {
    handleUpdateStory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced]);

  const handleCopy = useCallback(
    ({ isStudent }) => {
      navigator.clipboard.writeText(getPreviewLink(currentStory.id, isStudent));
      successToast('Copied to clipboard');
    },
    [currentStory.id]
  );

  const handleShowUsers = useCallback(
    (e) => {
      if (usersOnStory.length + unauthorizedUsers > 0) setAnchorEl(e.target);
    },
    [usersOnStory.length, unauthorizedUsers]
  );

  useEffect(() => {
    if (userIdsOnStory) {
      setUnauthorizedUsers(
        userIdsOnStory.filter((storyUser) => storyUser === 'unsigned').length
      );
      userIdsOnStory.filter((userId) => !!userId);
      const storyUsers = userIdsOnStory
        .filter((storyUser) => storyUser !== 'unsigned')
        .map((storyUserId) => {
          return allUsers.find((user) => {
            return storyUserId === user.id;
          });
        });
      setUsersOnStory(storyUsers.filter((user) => user));
    }
  }, [allUsers, userIdsOnStory]);

  const handleCopyToEnv = (e) => {
    const { value: to } = e.target;

    copyStoryToEnvService({
      from: process.env.REACT_APP_ENV,
      to,
      storyId: currentStory.id,
    })
      .then(() => {
        successToast('Story copied successfully!');
      })
      .catch((err) => errorToast(err.message));
  };

  const availableEnvs = useMemo(
    () => AVAILABLE_ENVS[process.env.REACT_APP_ENV],
    []
  );

  const handleUpdateStoryState = (name, value, language) => {
    setStoryData((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], [language]: value },
    }));
  };

  const handleUpdateState = (name, value) => {
    setStoryData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdateStoryLanguages = (languageCode) => {
    if (!storyData.languages.includes(languageCode)) {
      setStoryData((prevState) => ({
        ...prevState,
        languages: [...prevState.languages, languageCode],
      }));
    }
    setLanguageInput('');
  };

  const handleChange = useCallback((event, newValue) => {
    const { textContent } = event.target;
    setSelectedLanguage({
      index: newValue,
      lng: ISO6391.getCode(textContent),
    });
  }, []);

  return (
    <Box className={classes.wrap}>
      <form id='story-form' onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.titleHeader}>
          <Box className={classes.titleContainer}>
            <Typography className={classes.title} noWrap>
              {currentStory.title[selectedLanguage.lng] || ''}
            </Typography>
            {currentStory.id && (
              <>
                <Box component='span' className={classes.viewCount}>
                  {storyViews}
                </Box>
                <IconButton className={classes.previewButtons} disabled>
                  <EyeIcon fill={colors.grey} />
                </IconButton>
              </>
            )}
          </Box>

          <Box className={classes.preview}>
            <Box component='span'>
              Preview link:
              <IconButton
                className={classes.previewButtons}
                onClick={() => handleCopy({ isStudent: false })}
              >
                <CopyIcon />
              </IconButton>
            </Box>
            <Box component='span'>
              Preview link for students:
              <IconButton
                className={classes.previewButtons}
                onClick={() => handleCopy({ isStudent: true })}
              >
                <CopyIcon />
              </IconButton>
              <Box>
                Currently playing: {usersOnStory.length + unauthorizedUsers}
                <IconButton
                  className={classes.previewButtons}
                  onMouseEnter={handleShowUsers}
                >
                  <EyeIcon />
                </IconButton>
                <Menu
                  keepMounted
                  anchorEl={anchorEl}
                  open={!!anchorEl}
                  getContentAnchorEl={null}
                  PaperProps={{
                    className: classes.menu,
                    onMouseLeave: () => setAnchorEl(null),
                  }}
                >
                  {usersOnStory.map((user, i) => (
                    <MenuItem
                      style={{ backgroundColor: 'transparent' }}
                      key={user?.id || `user-${i}`}
                    >
                      {user?.name}
                    </MenuItem>
                  ))}
                  <Divider />
                  {!!unauthorizedUsers && (
                    <MenuItem style={{ backgroundColor: 'transparent' }}>
                      Unauthorized: {unauthorizedUsers}
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            </Box>
          </Box>
        </Box>
        {currentStory.id && (
          <Box className={classes.info}>
            <Box component='span'>
              Created on {getDate(currentStory?.createdAt)}
              {currentStory.createdBy?.name && (
                <>by {currentStory.createdBy?.name}</>
              )}
            </Box>
            {!!currentStory?.lastUpdate && (
              <Box component='span'>
                Last update on {getDate(currentStory.updatedAt)} by{' '}
                {currentStory.lastUpdate?.name}
              </Box>
            )}
          </Box>
        )}
        <Divider className={classes.divider} />

        <Box className={classes.content}>
          <Box className={classes.languageTab}>
            <TabsWrapper
              value={selectedLanguage.index}
              onChange={handleChange}
              justifyContent='flex-start'
              indicatorColor='primary'
              textColor='primary'
              variant='scrollable'
              scrollButtons='auto'
              aria-label='scrollable auto tabs example'
            >
              {storyData.languages.map((item) => (
                <Tab
                  className={classes.tab}
                  label={getEnglishName(item)}
                  key={item}
                />
              ))}
            </TabsWrapper>
          </Box>
          <Box className={classes.selectLanguage}>
            <InputLabel className={classes.label}>
              Select language...
            </InputLabel>
            <SelectField
              label='Add language'
              value={languageInput}
              onChange={(e) => {
                handleUpdateStoryLanguages(e.target.value);
              }}
            >
              {countryList}
            </SelectField>
          </Box>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth
                disableUnderline
                label='Name'
                error={error ? error.message : ''}
                {...field}
                onChange={(e) => {
                  handleUpdateStoryState('title', e, selectedLanguage.lng);
                  setValue(`title.${selectedLanguage.lng}`, e);
                }}
              />
            )}
            name={`title.${selectedLanguage.lng}`}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth
                error={error ? error.message : ''}
                placeholder='(max. 32 characters)'
                disableUnderline
                label='Subtext'
                {...field}
                onChange={(e) => {
                  handleUpdateStoryState('subtext', e, selectedLanguage.lng);
                  setValue(`subtext.${selectedLanguage.lng}`, e);
                }}
              />
            )}
            name={`subtext.${selectedLanguage.lng}`}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth
                multiline
                rows={4}
                disableUnderline
                label='Description'
                error={error ? error.message : ''}
                {...field}
                onChange={(e) => {
                  handleUpdateStoryState('about', e, selectedLanguage.lng);
                  setValue(`about.${selectedLanguage.lng}`, e);
                }}
              />
            )}
            name={`about.${selectedLanguage.lng}`}
            control={control}
          />
        </Box>
        <Divider className={classes.divider} />

        <Box className={classes.content}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth
                disableUnderline
                label='Duration'
                error={error ? error.message : ''}
                {...field}
                onChange={(e) => {
                  handleUpdateState('duration', e);
                  setValue('duration', e);
                }}
              />
            )}
            name='duration'
            control={control}
          />
          <Controller
            render={({ field }) => (
              <SelectField
                label='Orientation'
                {...field}
                onChange={(e) => {
                  handleUpdateState('isLandscape', e.target.value);
                  setValue('isLandscape', e.target.value);
                }}
              >
                {/*  eslint-disable-next-line react/jsx-boolean-value */}
                <MenuItem value={true}>Landscape</MenuItem>
                <MenuItem value={false}>Portrait</MenuItem>
              </SelectField>
            )}
            name='isLandscape'
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth
                disableUnderline
                label='Age'
                error={error ? error.message : ''}
                {...field}
                onChange={(e) => {
                  handleUpdateState('ageGroup', e);
                  setValue('ageGroup', e);
                }}
                startAdornment={
                  field.value && (
                    <span
                      className={classes.prefixValue}
                      style={{
                        marginLeft: `${
                          adornmentCorrection.start +
                          field.value.length * adornmentCorrection.correction
                        }px`,
                      }}
                    >
                      +
                    </span>
                  )
                }
              />
            )}
            name='ageGroup'
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <SelectField
                label='Difficulty'
                error={error ? error.message : null}
                {...field}
                onChange={(e) => {
                  handleUpdateState('difficulty', e.target.value);
                  setValue('difficulty', e.target.value);
                }}
                control={control}
              >
                <MenuItem value='' disabled>
                  Select difficulty...
                </MenuItem>
                {difficulty.map((item) => {
                  const keys = Object.keys(item);
                  return (
                    <MenuItem key={keys[0]} value={keys[0]}>
                      {item[keys[0]]}
                    </MenuItem>
                  );
                })}
              </SelectField>
            )}
            name='difficulty'
            control={control}
          />
          <Controller
            render={(controlProps) => (
              <FileInput
                value={controlProps.field.value?.name || ''}
                label='Story Cover'
                placeholder='Select Cover Image'
                accept={['image/*']}
                onError={setError}
                isBig
                {...controlProps}
                onChange={(e) => {
                  handleUpdateState('imageInfo', e.original);
                  setValue('imageInfo', e.original);
                  handleUpdateState('mobileImageInfo', e.compressed);
                  setValue('mobileImageInfo', e.compressed);
                }}
              />
            )}
            name='imageInfo'
            control={control}
          />
          <Controller
            name='draft'
            control={control}
            render={({ field }) => (
              <StyledCheckbox
                label='Draft'
                onChange={(e) => {
                  handleUpdateState('draft', e.target.checked);
                  field.onChange(e.target.checked);
                }}
                checked={!!field.value}
              />
            )}
          />
        </Box>
      </form>
      <Box className={classes.btnContainer}>
        <Button
          form='story-form'
          type='submit'
          variant='contained'
          color='secondary'
          disableElevation
          fullWidth
        >
          Save changes
        </Button>
        <Box className={classes.submitWrap}>
          <Box className={classes.buttonHolder}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              disabled={!currentStory.id}
              onClick={handleOpenDeleteDialog}
            >
              Delete
            </Button>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              disabled={!currentStory.id}
              onClick={handleDuplicateStory}
            >
              Duplicate
            </Button>
          </Box>
          {availableEnvs && (
            <Box width='150px'>
              <SelectField
                fullWidth={false}
                disabled={!currentStory.id}
                label='Copy to'
                value=''
                displayEmpty
                onChange={handleCopyToEnv}
              >
                <MenuItem disabled value=''>
                  Select environment
                </MenuItem>
                {availableEnvs.map((env) => (
                  <MenuItem key={env.name} value={env.value}>
                    {env.name}
                  </MenuItem>
                ))}
              </SelectField>
            </Box>
          )}
          <Button
            disabled={!currentStory.id}
            variant='contained'
            color='primary'
            disableElevation
            onClick={handleEditStory}
          >
            Edit Story
          </Button>
        </Box>
        {currentStory?.id && (
          <>
            <DeleteConfirmDialog
              open={openDeleteDialog}
              onClose={handleCloseDeleteDialog}
              onSubmit={handleDeleteStory}
              storyTitle={currentStory.title[selectedLanguage.lng]}
            />
            <ConfirmDialog
              open={openConfirmDialog}
              onClose={handleCloseConfirmDialog}
              onSubmit={handleRedirect}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default StoryForm;

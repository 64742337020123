import React from 'react';
import { Box, Paper } from '@material-ui/core';
import useStyles from './styles';

const AuthWrap = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper}>{children}</Paper>
    </Box>
  );
};

export default AuthWrap;

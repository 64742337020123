import React from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import classNames from 'classnames';

export const FrameIcon = ({
  fillOpacity = 0.5,
  fill = 'white',
  className,
  ...props
}) => {
  return (
    <CheckBoxOutlineBlankIcon
      style={{ fill, fillOpacity, width: 16, height: 16 }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};

/* <svg
        width='12'
        height='12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.71 0H1.3C.58 0 0 .58 0 1.29v9.42C0 11.42.58 12 1.29 12h9.42c.71 0 1.29-.58 1.29-1.29V1.3C12 .58 11.42 0 10.71 0zm-.16 10.71h-9.1a.16.16 0 01-.16-.16v-9.1c0-.1.07-.16.16-.16h9.1c.1 0 .16.07.16.16v9.1c0 .1-.07.16-.16.16z'
          fill='#fff'
          fillOpacity={fillOpacity}
        />
      </svg> */

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  drawerHeader: {
    backgroundColor: theme.colors.menuBackground,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    padding: '25px 15px 13px',
    height: '62px',

    '& p': {
      fontSize: '16px',
      fontWeight: 500,
    },
  },

  actionButtons: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '12px',
    alignItems: 'center',
  },

  editButton: {
    backgroundColor: '#FC2472',
    padding: '6px 8px',
    minWidth: '50px',
    margin: 0,
    fontSize: '10px',
    fontWeight: 500,
  },
  formWrapper: {
    padding: '0px 2px 2px',
    backgroundColor: '#212538',
  },
}));

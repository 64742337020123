export const mappedArrayToPageObject = (list) =>
  (list || []).reduce((acc, item) => {
    if (acc[item.originFrame]) {
      acc[item.originFrame] = [...acc[item.originFrame], item];
    } else {
      acc[item.originFrame] = [item];
    }

    return acc;
  }, {});

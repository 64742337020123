import React, { forwardRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, Input, InputLabel } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import useStyles from './styles';

const StyledColorInput = forwardRef(
  ({ label, error, placeholder, onChangeValue, inputValue, ...props }, ref) => {
    const classes = useStyles({ color: props.value });

    const handleOnChange = useCallback(
      (e) => {
        if (onChangeValue) {
          return onChangeValue(props.name, e.currentTarget.value);
        }
        return props.onChange(e.currentTarget.value);
      },
      [props, onChangeValue]
    );

    const value = useMemo(() => {
      if (inputValue) {
        return inputValue;
      }

      return `      ${props.value.toUpperCase()}` || '';
    }, [inputValue, props.value]);

    return (
      <FormControl fullWidth focused error={!!error}>
        <Box className={classes.colorBox} />
        <InputLabel>{label}</InputLabel>
        <Input
          {...props}
          fullWidth
          disableUnderline
          placeholder={placeholder}
          innerRef={ref}
          value={value}
          onChange={handleOnChange}
          className={classes.inputWrap}
        />
        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
);

StyledColorInput.displayName = 'StyledColorInput';

StyledColorInput.defaultProps = {
  placeholder: '',
  inputValue: '',
  onChangeValue: null,
};

StyledColorInput.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  placeholder: PropTypes.string,
  onChangeValue: PropTypes.func,
};

export default StyledColorInput;

import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object().shape({
    ageGroup: Yup.number().typeError('Must be number!'),
    difficulty: Yup.string()
      .oneOf(['easy', 'medium', 'hard'])
      .required('This field is required'),
    draft: Yup.boolean(),
    imageInfo: Yup.object()
      .shape({
        type: Yup.string().trim(),
        name: Yup.string().trim(),
        url: Yup.string().trim(),
        size: Yup.number(),
      })
      .nullable(true),
  });

export const customSchema = () =>
  Yup.object().shape({
    title: Yup.string().trim().required('This field is required'),
    about: Yup.string().trim().required('This field is required'),
    subtext: Yup.string().trim().required('This field is required').max(32),
  });

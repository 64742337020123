import React from 'react';
import classnames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ShowMenu = ({ className, ...props }) => {
  return (
    <SvgIcon
      viewBox='0 0 11 14'
      style={{ width: 15, height: 15, fill: 'none', cursor: 'pointer' }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classnames({
        [className]: !!className,
      })}
      {...props}
    >
      <path
        d='M9.92039 12.3811H2.07959C1.002 12.3811 0.119995 11.4991 0.119995 10.4215V2.58069C0.119995 1.50309 1.002 0.621094 2.07959 0.621094H9.91919C10.998 0.621094 11.88 1.50309 11.88 2.58069V10.4203C11.88 11.4991 10.998 12.3811 9.92039 12.3811ZM2.07959 1.60149C1.5408 1.60149 1.0992 2.04309 1.0992 2.58189V10.4215C1.0992 10.9603 1.5396 11.4019 2.07959 11.4019H9.91919C10.458 11.4019 10.8996 10.9603 10.8996 10.4215V2.58069C10.8996 2.04189 10.458 1.60029 9.91919 1.60029H2.07959V1.60149Z'
        fill='#6E707B'
      />
      <path
        d='M4.04038 12.3811C3.74638 12.3811 3.55078 12.1855 3.55078 11.8915V1.11069C3.55078 0.816694 3.74638 0.621094 4.04038 0.621094C4.33438 0.621094 4.52998 0.816694 4.52998 1.11069V11.8903C4.52998 12.1855 4.33438 12.3811 4.04038 12.3811Z'
        fill='#6E707B'
      />
      <path
        d='M6.98038 6.99074C6.83398 6.99074 6.73558 6.94154 6.63718 6.84314C6.44158 6.64754 6.44158 6.35354 6.63718 6.15674L8.10718 4.68674C8.30278 4.49114 8.59678 4.49114 8.79358 4.68674C8.99038 4.88234 8.98918 5.17634 8.79358 5.37314L7.32358 6.84434C7.22518 6.94274 7.12678 6.99074 6.98038 6.99074Z'
        fill='#6E707B'
      />
      <path
        d='M8.45038 8.46024C8.30398 8.46024 8.20558 8.41104 8.10718 8.31264L6.63718 6.84384C6.44158 6.64824 6.44158 6.35424 6.63718 6.15744C6.83278 5.96184 7.12678 5.96184 7.32358 6.15744L8.79358 7.62744C8.98918 7.82304 8.98918 8.11704 8.79358 8.31384C8.69518 8.41224 8.59678 8.46024 8.45038 8.46024Z'
        fill='#6E707B'
      />
    </SvgIcon>
  );
};

import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { BackpackIcon } from 'components/Icons';
import useStyles from './styles';

const BackpackItem = ({ text, onAdd, onCheck, ...props }) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      dense
      onClick={onCheck}
      ContainerProps={{ className: classes.wrap }}
      {...props}
    >
      <ListItemIcon>
        <BackpackIcon />
      </ListItemIcon>
      <ListItemText primary={text} />
      <ListItemSecondaryAction>
        {/* <ControlButton onClick={onAdd} text='Add' /> */}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

BackpackItem.propTypes = {
  text: PropTypes.string.isRequired,
  onCheck: PropTypes.func.isRequired,
};

export default BackpackItem;

/* eslint-disable react/jsx-curly-brace-presence */
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {
  IMAGE_LAYER_TYPE,
  SPEECHBUBBLE_LAYER_TYPE,
  TEXT_LAYER_TYPE,
} from 'constants/editTypes';
import { usePrevious } from 'utils/hooks/usePrevious';
import {
  storyListConfirmOpenSelector,
  storyListEditTypeSelector,
  storyListNextItemSelector,
  storyListNextSelectedSelector,
} from 'common/selectors/editStory';
import {
  confirmOpenAction,
  editItemActionSuccess,
  setEditAction,
  setSelectedActionSuccess,
} from 'common/actions/editStoryActions';
import DialogWrapper from 'components/DialogWrapper';
import { capitalizeFirstLetter } from '../../../../../../utils/helpers/helpers';
import useStyles from './styles';

const selector = createSelector(
  storyListEditTypeSelector,
  storyListConfirmOpenSelector,
  storyListNextItemSelector,
  storyListNextSelectedSelector,
  (editType, confirmOpen, nextItem, nextSelected) => ({
    editType,
    confirmOpen,
    nextItem,
    nextSelected,
  })
);

const ConfirmDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { editType, confirmOpen, nextItem, nextSelected } = useSelector(
    selector
  );
  const { item: prevEditType } = usePrevious(editType);

  const handleClose = useCallback(() => {
    dispatch(setEditAction(false));
    dispatch(confirmOpenAction(false));
    dispatch(editItemActionSuccess(nextItem));
    dispatch(setSelectedActionSuccess(nextSelected));
  }, [dispatch, nextItem, nextSelected]);

  const handleSave = useCallback(() => {
    dispatch(confirmOpenAction(false));
  }, [dispatch]);

  const editTypeName = useMemo(() => {
    switch (prevEditType) {
      case SPEECHBUBBLE_LAYER_TYPE:
      case IMAGE_LAYER_TYPE:
      case TEXT_LAYER_TYPE:
        return 'Layer';
      default:
        return prevEditType;
    }
  }, [prevEditType]);

  return (
    <DialogWrapper
      open={!!confirmOpen}
      onClose={handleClose}
      disableBackdropClick
    >
      <DialogTitle disableTypography>
        <Box component='h3' fontSize='1rem' fontWeight='fontWeightMedium' m={0}>
          Warning
        </Box>
      </DialogTitle>
      <DialogContent>
        {`Do you want to save the changes you made to the ${capitalizeFirstLetter(
          editTypeName
        )}?`}
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={handleClose}>{"Don't Save"}</Button>
        <Button
          variant='contained'
          color='primary'
          onClick={handleSave}
          type='submit'
          form={`${editType}-form`}
        >
          Save
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};

export default ConfirmDialog;

import { END_LOADING, FETCH_STORY, START_LOADING } from 'common/types/story';
import {
  getStory as getStoryService,
  updateStory as updateStoryService,
} from 'services/stories';
import { errorToast } from 'services/toast';
import {
  fetchedTargetFramesAction,
  fetchFramesAction,
} from './framesListActions';
import { fetchLayersSuccessAction } from './layersListAction';
import { fetchedPages } from './pagesListActions';
import { fetchedHotSpotsAction } from './hotSpotsListActions';
import { fetchedDecisionsSuccessAction } from './decisionsListActions';
import { fetchedCheckpointListAction } from './checkpointListAction';
import { fetchedBackpackListAction } from './backpackListAction';
import { fetchListSuccessAction } from './editStoryActions';

export const startLoadingStoryAction = () => ({
  type: START_LOADING,
});

export const endLoadingStoryAction = () => ({
  type: END_LOADING,
});

export const fetchStorySuccessAction = (story) => ({
  type: FETCH_STORY,
  story,
});

export const fetchCurrentStoryAction = (id) => {
  return (dispatch) => {
    dispatch(startLoadingStoryAction());
    getStoryService(id)
      .then((res) => {
        dispatch(endLoadingStoryAction());
        dispatch(fetchStorySuccessAction(res));
      })
      .catch((error) => {
        dispatch(endLoadingStoryAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const updateCurrentStoryAction = (story, name, value) => {
  return (dispatch) => {
    dispatch(startLoadingStoryAction());
    updateStoryService({ ...story, [name]: value })
      .then((res) => {
        if (name === 'hideBorder') dispatch(fetchFramesAction(story.id));
        dispatch(fetchStorySuccessAction(res.data.updatedStory));
      })
      .catch((error) => {
        dispatch(endLoadingStoryAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const resetStoryAction = () => {
  return (dispatch) => {
    dispatch(fetchedPages([]));
    dispatch(fetchLayersSuccessAction([]));
    dispatch(fetchedTargetFramesAction([]));
    dispatch(fetchedHotSpotsAction([]));
    dispatch(fetchedDecisionsSuccessAction([]));
    dispatch(fetchedCheckpointListAction([]));
    dispatch(fetchedBackpackListAction([]));
    dispatch(fetchListSuccessAction([]));
  };
};

import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

export const DrawSlider = withStyles({
  root: {
    color: 'rgb(250, 36, 113)',
    height: 6,
    marginTop: '8px',
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -5,
    marginLeft: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  track: {
    height: 6,
    borderRadius: 4,
  },
  rail: {
    height: 6,
    borderRadius: 4,
  },
})(Slider);

export const UPDATE_TYPE = {
  UPDATE: 'standart',
  UNDO: 'undo',
  UNGROUP: 'ungroup',
  DELETE: 'delete',
  REDO: 'redo',
  STANDART: 'standart',
};

export const HISTORY_ACTIONS = {
  CREATE: 'created',
  UPDATE: 'updated',
  DETELE: 'deleted',
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { Box, ListSubheader, MenuItem, useTheme } from '@material-ui/core';
import { SPEECHBUBBLE_LAYER_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import { useDebounce } from 'utils/hooks/useDebounce';
import { treeCompare } from 'utils/treeCompare';
import { capitalizeFirstLetter, getImageSize } from 'utils/helpers/helpers';
import {
  storyListEditItemSelector,
  storyListEditTypeSelector,
} from 'common/selectors/editStory';
import { editItemAction, setEditAction } from 'common/actions/editStoryActions';
import {
  addLayerAction,
  removeLayerAction,
  updateLayerAction,
} from 'common/actions/layersListAction';
import { storyItemsSelector } from 'common/selectors/story';
import { pageListItemFrameSelector } from 'common/selectors/pages';
import { storyListLanguageSelector } from 'common/selectors/storyList';
import { speechbubbleSelector } from 'common/selectors/speechbubble';
import {
  handleDrowingModeAction,
  handleResetSpeechbubbleAction,
  handleSpeechbubbleCleanAction,
  handleSpeechbubbleImportedAction,
} from 'common/actions/speechbubble';
import StyledCheckbox from 'components/forms/StyledCheckbox/StyledCheckbox';
import FormWrap from 'components/forms/FormWrap';
import StyledInput from 'components/forms/StyledInput';
import FileInput from 'components/forms/FileInput';
import SelectField from 'components/forms/SelectField/SelectField';
import { errorToast } from 'services/toast';
import { uploadFile } from 'services/files';
import ControlBarBlock from '../ControlBarBlock';
import ControlButton from '../ControlButton';
import { animationTypes } from '../FrameControl/FrameControl.constants';
import {
  COLS,
  GRID_WIDTH_LANDSCAPE,
  GRID_WIDTH_PORTRAIT,
} from '../../EditGridPage/EditGridPage.constants';
import { defaultValues } from './LayerSpeechbubbleControl.constants';
import { validationSchema } from './LayerSpeechbubbleControl.validations';
import { DrawControl } from './components/DrawControl/DrawControl';
import { getFileFromSvg } from './LayerSpeechbubble.helpers';

const selector = createSelector(
  storyListEditItemSelector,
  storyListEditTypeSelector,
  storyItemsSelector,
  pageListItemFrameSelector,
  storyListLanguageSelector,
  speechbubbleSelector,
  (
    editItem,
    editType,
    currentStory,
    currentFrame,
    currentLang,
    speechBubbleState
  ) => ({
    editItem,
    editType,
    currentStory,
    currentFrame,
    currentLang,
    speechBubbleState,
  })
);
const LayerSpeechbubbleControl = () => {
  const {
    editItem,
    editType,
    currentStory,
    currentFrame,
    currentLang,
    speechBubbleState,
  } = useSelector(selector);
  const dispatch = useDispatch();

  const [multipleSB, setMultipleSB] = useState(
    editItem.language === 'multiple'
  );

  const { canvas, exportedJSON } = speechBubbleState;

  const {
    control,
    setError,
    reset,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      id: editItem?.id || '',
      title: editItem?.title || defaultValues.title,
      imageInfo: editItem?.imageInfo || defaultValues.imageInfo,
      soundInfo: editItem?.soundInfo || defaultValues.soundInfo,
      coordinates: editItem?.coordinates || defaultValues.coordinates,
      animation: {
        ...defaultValues.animation,
        ...editItem?.animation,
      },
      type: editType,
      fadeIn: editItem?.fadeIn || defaultValues.fadeIn,
      fadeOut: editItem?.fadeOut || defaultValues.fadeOut,
      loopAudio: editItem?.loopAudio || defaultValues.loopAudio,
      zIndex: editItem?.zIndex || defaultValues.zIndex,
      isDrawn: editItem?.isDrawn || defaultValues.isDrawn,
      svgJson: editItem?.svgJson || defaultValues.svgJson,
    },
    resolver: yupResolver(validationSchema(speechBubbleState.isDrowingMode)),
  });

  const formField = useWatch({
    control,
  });

  const formFieldDebounced = useDebounce(formField, 300);

  useEffect(() => {
    if (
      editItem &&
      (editItem?.id !== formField.id ||
        editItem?.coordinates !== formField.coordinates)
    ) {
      setMultipleSB(editItem.language === 'multiple');
      reset({
        id: editItem?.id || '',
        title: editItem?.title || defaultValues.title,
        imageInfo: editItem?.imageInfo || defaultValues.imageInfo,
        soundInfo: editItem?.soundInfo || defaultValues.soundInfo,
        coordinates: editItem?.coordinates || defaultValues.coordinates,
        animation: {
          ...defaultValues.animation,
          ...editItem?.animation,
        },
        type: editType,
        fadeIn: editItem?.fadeIn || defaultValues.fadeIn,
        fadeOut: editItem?.fadeOut || defaultValues.fadeOut,
        loopAudio: editItem?.loopAudio || defaultValues.loopAudio,
        zIndex: editItem?.zIndex || defaultValues.zIndex,
        isDrawn: editItem?.isDrawn || defaultValues.isDrawn,
        svgJson: editItem?.svgJson || defaultValues.svgJson,
      });
    }
    // eslint-disable-next-line
  }, [reset, editItem, formField.id]);

  // useEffect(() => {
  //   reset({
  //     ...editItem,
  //     coordinates: {
  //       ...editItem.coordinates,
  //       [currentLang]: getLanguageField(editItem.coordinates, currentLang),
  //     },
  //     soundInfo: {
  //       ...editItem.soundInfo,
  //       [currentLang]: getLanguageField(editItem.soundInfo, currentLang),
  //     },
  //     imageInfo: {
  //       ...editItem.imageInfo,
  //       [currentLang]: getLanguageField(editItem.imageInfo, currentLang),
  //     },
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentLang, reset]);

  const handleUpdateLayer = useCallback(() => {
    const { id, ...fieldsData } = formFieldDebounced;
    // the order of the elements must be the same like in formField
    const fieldsForCompare = {
      id: editItem?.id || '',
      title: editItem?.title || defaultValues.title,
      imageInfo: editItem?.imageInfo || defaultValues.imageInfo,
      soundInfo: editItem?.soundInfo || defaultValues.soundInfo,
      coordinates: editItem?.coordinates || defaultValues.coordinates,
      animation: {
        ...defaultValues.animation,
        ...editItem?.animation,
      },
      type: editType,
      fadeIn: editItem?.fadeIn || defaultValues.fadeIn,
      fadeOut: editItem?.fadeOut || defaultValues.fadeOut,
      loopAudio: editItem?.loopAudio || defaultValues.loopAudio,
      zIndex: editItem?.zIndex || defaultValues.zIndex,
      isDrawn: editItem?.isDrawn || defaultValues.isDrawn,
      svgJson: editItem?.svgJson || defaultValues.svgJson,
    };

    if (!treeCompare(formFieldDebounced, fieldsForCompare)) {
      dispatch(
        editItemAction({
          item: {
            ...editItem,
            ...fieldsData,
            coordinates: {
              ...editItem.coordinates,
              ...fieldsData.coordinates,
            },
            soundInfo: fieldsData.soundInfo,

            imageInfo: fieldsData.imageInfo,
          },
          type: SPEECHBUBBLE_LAYER_TYPE,
        })
      );
      dispatch(setEditAction(true));
    }
  }, [formFieldDebounced, editItem, editType, dispatch]);

  const onDelete = () => {
    setValue('coordinates', defaultValues.coordinates);
  };

  useEffect(() => {
    handleUpdateLayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced]);

  const onSubmit = useCallback(
    (data) => {
      const { id, ...fieldsData } = data;
      if (editItem.id) {
        dispatch(
          updateLayerAction(
            {
              ...editItem,
              ...fieldsData,
              coordinates: {
                ...editItem.coordinates,
                ...fieldsData.coordinates,
              },
              soundInfo: {
                ...editItem.soundInfo,
                ...fieldsData.soundInfo,
              },
              imageInfo: {
                ...editItem.imageInfo,
                ...fieldsData.imageInfo,
              },
              language: multipleSB ? 'multiple' : currentLang,
            },
            UPDATE_TYPE.UPDATE
          )
        );
      } else {
        dispatch(
          addLayerAction({
            ...editItem,
            ...fieldsData,
            language: multipleSB ? 'multiple' : currentLang,
          })
        );
      }
    },
    [editItem, dispatch, multipleSB, currentLang]
  );

  const handleUploadChange = useCallback(
    async (data) => {
      if (!editItem.id) {
        const img = new Image();
        img.src = data.original.url;
        img.onload = (e) => {
          const sizes = getImageSize(e.target.width, e.target.height);

          setValue('coordinates', {
            ...editItem.coordinates,
            w: sizes.imageWidth,
            h: sizes.imageHeight,
          });
          dispatch(handleResetSpeechbubbleAction(false));
        };
      }

      setValue('imageInfo', data.original, {
        shouldDirty: true,
        shouldValidate: true,
      });

      return data.original;
    },
    [editItem.id, editItem.coordinates, setValue, dispatch]
  );

  const onDrawingSubmit = useCallback(
    async (data) => {
      const textObjectError =
        !!exportedJSON.objects.length &&
        exportedJSON.objects[0].type === 'text' &&
        !exportedJSON.objects[0].text;

      if (exportedJSON.objects.length === 0 || textObjectError || !canvas) {
        errorToast('Drawing Speech Bubble is empty');
      } else {
        const file = getFileFromSvg(canvas);
        await uploadFile(file).then((res) =>
          handleUploadChange(res).then((imageData) => {
            const { id, ...fieldsData } = data;
            if (editItem.id) {
              dispatch(
                updateLayerAction(
                  {
                    ...editItem,
                    ...fieldsData,
                    coordinates: {
                      ...editItem.coordinates,
                      ...fieldsData.coordinates,
                    },
                    soundInfo: {
                      ...editItem.soundInfo,
                      ...fieldsData.soundInfo,
                    },
                    imageInfo: {
                      ...editItem.imageInfo,
                      ...imageData,
                    },
                    isDrawn: true,
                    svgJson: exportedJSON,
                    language: multipleSB ? 'multiple' : currentLang,
                  },
                  UPDATE_TYPE.UPDATE
                )
              );
            } else {
              dispatch(
                addLayerAction({
                  ...editItem,
                  ...fieldsData,
                  imageInfo: {
                    ...editItem.imageInfo,
                    ...imageData,
                  },
                  isDrawn: true,
                  svgJson: exportedJSON,
                  language: multipleSB ? 'multiple' : currentLang,
                })
              );
            }
            dispatch(handleSpeechbubbleCleanAction(false));
            dispatch(handleDrowingModeAction(false));
          })
        );
      }
    },
    [
      canvas,
      currentLang,
      dispatch,
      editItem,
      exportedJSON,
      handleUploadChange,
      multipleSB,
    ]
  );

  const handleRemove = useCallback(() => {
    dispatch(removeLayerAction());
  }, [dispatch]);

  //   const orientationCoef = useMemo(() => {
  //     return currentStory.isLandscape ? 2 : 1;
  //   }, [currentStory.isLandscape]);
  const { spacing } = useTheme();

  const frameWidth = useMemo(() => {
    return !currentStory?.isLandscape
      ? (spacing(GRID_WIDTH_LANDSCAPE) / COLS) * currentFrame?.w
      : (spacing(GRID_WIDTH_PORTRAIT) / COLS) * currentFrame?.w;
  }, [currentStory, spacing, currentFrame]);

  const frameHeight = useMemo(() => {
    return !currentStory?.isLandscape
      ? (spacing(GRID_WIDTH_LANDSCAPE) / COLS) * currentFrame?.h
      : (spacing(GRID_WIDTH_PORTRAIT) / COLS) * currentFrame?.h;
  }, [currentStory, spacing, currentFrame]);

  //   const gridHeight = useMemo(() => {
  //     return currentStory?.isLandscape
  //       ? spacing(GRID_WIDTH_LANDSCAPE) / COLS
  //       : spacing(GRID_WIDTH_PORTRAIT) / COLS;
  //   }, [currentStory, spacing]);

  const handleDimensionsChange = useCallback(
    (field, val) => {
      if (field.name === 'coordinates.x')
        return setValue(field.name, (val / frameWidth) * 100);
      if (field.name === 'coordinates.y')
        return setValue(field.name, (val / frameHeight) * 100);
      return setValue(field.name, parseInt(val, 10));
    },
    [setValue, frameHeight, frameWidth]
  );

  const handleAnimationChange = useCallback(
    async (option) => {
      //   dispatch(
      //     setAnimation({
      //       frameId: editItem.id,
      //       type,
      //     })
      //   );

      const animationToSave = {
        ...editItem.animation,
        // delay: autoDelay,
      };

      setValue(
        'animation.animation',
        { ...option, duration: animationToSave.duration || 3 },
        {
          shouldDirty: true,
          shouldValidate: true,
        }
      );
      animationToSave.animation = option;
      animationToSave.duration = animationToSave.duration || 3;
      // dispatch(
      //   editItemAction({
      //     item: {
      //       ...editItem,
      //       animation: animationToSave,
      //     },
      //     type: SPEECHBUBBLE_LAYER_TYPE,
      //   })
      // );
      //   const updatedFrame = { ...editFrame, animation: animationToSave };
      //   if (editItem.id)
      //     dispatch(updateFrameAction(updatedFrame, UPDATE_TYPE.UPDATE));
    },
    [setValue, editItem]
  );

  useEffect(() => {
    if (editItem.isDrawn && editItem.svgJson) {
      dispatch(
        handleSpeechbubbleImportedAction({
          importedJSON: editItem.svgJson,
          imported: true,
        })
      );
      dispatch(handleDrowingModeAction(true));
    }
  }, [dispatch, editItem.svgJson, editItem.isDrawn]);

  return (
    <FormWrap
      id='layer_speechbubble-form'
      onSubmit={handleSubmit(
        speechBubbleState.isDrowingMode ? onDrawingSubmit : onSubmit
      )}
    >
      <ControlBarBlock blockTitle='Details'>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              disableUnderline
              label='Name'
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='title'
          control={control}
        />
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Dimensions'>
        <Box display='flex' justifyContent='space-between'>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                disableUnderline
                label='X'
                fullWidth={false}
                error={error ? error.message : ''}
                {...field}
                width='45%'
                onChange={(e) => {
                  let val = parseInt(e, 10);
                  if (val + formField.coordinates.w > frameWidth)
                    val = frameWidth - formField.coordinates.w;
                  handleDimensionsChange(field, val);
                }}
                value={
                  field.value
                    ? ((field.value * frameWidth) / 100).toFixed(0) || '0'
                    : '0'
                }
              />
            )}
            name='coordinates.x'
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth={false}
                disableUnderline
                label='Y'
                error={error ? error.message : ''}
                {...field}
                width='45%'
                onChange={(e) => {
                  let val = parseInt(e, 10);
                  if (val + formField.coordinates.h > frameHeight)
                    val = frameHeight - formField.coordinates.h;
                  handleDimensionsChange(field, val);
                }}
                value={
                  field.value
                    ? ((field.value * frameHeight) / 100).toFixed(0) || '0'
                    : '0'
                }
              />
            )}
            name='coordinates.y'
            control={control}
          />
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth={false}
                disableUnderline
                label='W'
                error={error ? error.message : ''}
                {...field}
                width='45%'
                onChange={(e) => {
                  let val = parseInt(e, 10);
                  const x = (formField.coordinates.x * frameWidth) / 100;
                  if (val + x > frameWidth) val = frameWidth - x;
                  handleDimensionsChange(field, val);
                }}
                value={field.value ? field.value.toFixed(0) : '0'}
              />
            )}
            name='coordinates.w'
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth={false}
                disableUnderline
                label='H'
                error={error ? error.message : ''}
                {...field}
                width='45%'
                onChange={(e) => {
                  let val = parseInt(e, 10);
                  const y = (formField.coordinates.y * frameHeight) / 100;
                  if (val + y > frameWidth) val = frameHeight - y;
                  handleDimensionsChange(field, val);
                }}
                value={field.value ? field.value.toFixed(0) : '0'}
              />
            )}
            name='coordinates.h'
            control={control}
          />
        </Box>
      </ControlBarBlock>
      {speechBubbleState.isDrowingMode || editItem.isDrawn ? (
        <DrawControl handleUploadChange={handleUploadChange} />
      ) : (
        <ControlBarBlock blockTitle='Content'>
          <Controller
            render={(controlProps) => (
              <FileInput
                value={controlProps.field.value?.name || ''}
                label='Image (5mb max.)'
                placeholder='No file'
                clearErrors={clearErrors}
                errors={errors}
                validateImage
                accept={['image/*']}
                onChange={handleUploadChange}
                onDelete={onDelete}
                onError={setError}
                {...controlProps}
              />
            )}
            name='imageInfo'
            control={control}
          />
        </ControlBarBlock>
      )}
      <ControlBarBlock blockTitle='General Speech bubble settings'>
        <Controller
          render={() => (
            <StyledCheckbox
              label='Multiple language'
              onChange={(e) => {
                setMultipleSB(e.target.checked);
                dispatch(setEditAction(true));
              }}
              checked={multipleSB}
            />
          )}
          name='language'
          control={control}
        />
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Speech bubble sound'>
        <Controller
          render={(controlProps) => (
            <FileInput
              value={controlProps.field.value?.name || ''}
              label='Select file'
              onChange={(data) => {
                const media = new Audio(data.original.url);
                media.onloadedmetadata = () => {
                  const delay = Math.round(media.duration);
                  //   setAutoDelay(delay);
                  setValue('animation.delay', delay);
                };
                setValue(controlProps.field.name, data.original, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              placeholder='No file'
              accept={['audio/mp3']}
              onError={setError}
              {...controlProps}
            />
          )}
          name='soundInfo'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              error={error ? error.message : ''}
              disableUnderline
              label='Fade in duration'
              {...field}
              fullWidth
            />
          )}
          name='fadeIn'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              error={error ? error.message : ''}
              disableUnderline
              label='Fade out duration'
              {...field}
              fullWidth
            />
          )}
          name='fadeOut'
          control={control}
        />
        <Controller
          name='loopAudio'
          control={control}
          render={({ field }) => (
            <StyledCheckbox
              disabled={!formField.soundInfo}
              label='Loop audio'
              onChange={(e) => field.onChange(e.target.checked)}
              checked={!!field.value}
            />
          )}
        />
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Animation'>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <SelectField
              error={error ? error.message : null}
              defaultValue='manual'
              control={control}
              label='Next frame'
              displayEmpty
              {...field}
            >
              <MenuItem value='' disabled>
                Select action...
              </MenuItem>
              {['manual', 'automated'].map((option) => {
                return (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </SelectField>
          )}
          name='animation.appear'
          control={control}
        />
        {editItem?.animation?.appear === 'automated' && (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                error={error ? error.message : ''}
                disableUnderline
                label='Delay'
                {...field}
                fullWidth
              />
            )}
            name='animation.delay'
            control={control}
          />
        )}
        <Controller
          render={({ field, fieldState: { error } }) => (
            <SelectField
              error={error ? error.message : null}
              control={control}
              defaultValue=''
              label='Animation'
              displayEmpty
              {...field}
            >
              <MenuItem value=''>Without animation</MenuItem>
              <ListSubheader>Back In</ListSubheader>
              {animationTypes.backIn.map((option) => (
                <MenuItem
                  onClick={() => handleAnimationChange(option)}
                  value={option}
                  key={option}
                >
                  {capitalizeFirstLetter(option)}
                </MenuItem>
              ))}
              <ListSubheader>Bouncing</ListSubheader>
              {animationTypes.bouncing.map((option) => (
                <MenuItem
                  onClick={() => handleAnimationChange(option)}
                  value={option}
                  key={option}
                >
                  {capitalizeFirstLetter(option)}
                </MenuItem>
              ))}
              <ListSubheader>Fade In</ListSubheader>
              {animationTypes.fadeIn.map((option) => (
                <MenuItem
                  onClick={() => handleAnimationChange(option)}
                  value={option}
                  key={option}
                >
                  {capitalizeFirstLetter(option)}
                </MenuItem>
              ))}
              <ListSubheader>Flip</ListSubheader>
              {animationTypes.flip.map((option) => (
                <MenuItem
                  onClick={() => handleAnimationChange(option)}
                  value={option}
                  key={option}
                >
                  {capitalizeFirstLetter(option)}
                </MenuItem>
              ))}
              <ListSubheader>Zoom</ListSubheader>
              {animationTypes.zoom.map((option) => (
                <MenuItem
                  onClick={() => handleAnimationChange(option)}
                  value={option}
                  key={option}
                >
                  {capitalizeFirstLetter(option)}
                </MenuItem>
              ))}
              <ListSubheader>Slide In</ListSubheader>
              {animationTypes.slideIn.map((option) => (
                <MenuItem
                  onClick={() => handleAnimationChange(option)}
                  value={option}
                  key={option}
                >
                  {capitalizeFirstLetter(option)}
                </MenuItem>
              ))}
            </SelectField>
          )}
          name='animation.animation'
          control={control}
        />
        {editItem?.animation?.animation && (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                error={error ? error.message : ''}
                disableUnderline
                label='Duration'
                {...field}
                fullWidth
              />
            )}
            name='animation.duration'
            control={control}
          />
        )}
      </ControlBarBlock>
      <ControlBarBlock>
        <ControlButton
          onClick={handleRemove}
          text='Delete Layer'
          disabled={!editItem.id}
        />
      </ControlBarBlock>
    </FormWrap>
  );
};

export default LayerSpeechbubbleControl;

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  titleWrap: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.06)',
    margin: '-12px',
    marginBottom: 0,
    padding: '14px 12px 10px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      borderRight: '1px solid rgba(255, 255, 255, 0.06)',
      borderBottom: 'none',
      margin: 0,
      padding: 0,
      minWidth: '155px',
    },
  },

  languageOptionsWrap: {
    position: 'absolute',
    transform: 'translateY(100%)',
    backgroundColor: theme.colors.sidebarBackground,
    bottom: 0,
    left: 0,
    zIndex: 5,
    width: '100%',
    borderRadius: '2px',
    maxHeight: ({ languagesOpen }) => (languagesOpen ? '100vh' : '0px'),
    opacity: ({ languagesOpen }) => (languagesOpen ? '1' : '0'),
    pointerEvents: ({ languagesOpen }) => !languagesOpen && 'none',
    transition: 'all .5s ease-in-out',
  },

  languageOption: {
    padding: '13px 12px',
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: theme.spacing(1.25),
    display: 'flex',
    alignItems: 'center',
    gap: '3px',
    '&:last-of-type': {
      color: 'rgba(255, 255, 255, 0.58)',
    },
    borderBottom: '1px solid rgba(255, 255, 255, 0.06)',
    transition: 'all .3s ease',

    '&:hover': {
      backgroundColor: ({ languageAdding }) =>
        !languageAdding && theme.colors.ebonyClay,
    },
  },

  clearIconButton: { padding: '2px', marginLeft: 'auto' },

  storyTitle: {
    maxWidth: theme.spacing(14.375),
    fontSize: theme.spacing(1.75),

    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
      marginTop: '1px',
    },
  },

  currentLang: {
    fontSize: theme.spacing(1.25),
    color: 'rgba(255, 255, 255, 0.5)',
  },

  languageContainer: {
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    marginTop: '-2px',
    '& svg': {
      marginTop: '-2px',
    },
  },
  titleContent: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
}));

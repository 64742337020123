import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography } from '@material-ui/core';
import { getEnglishName } from 'all-iso-language-codes';
import Image from 'components/Image';
import { LanguageIcon, LogoIcon } from 'components/Icons';
import colors from '../../../../../../constants/colors';
import useStyles from './styles';

const StoryListItem = ({
  image,
  about,
  title,
  isActive,
  languages,
  onClick,
}) => {
  const classes = useStyles({ active: isActive });

  return (
    <Box className={classes.wrap} onClick={onClick}>
      <Box className={classes.imageWrap}>
        {image ? (
          <Image src={image} alt={title || 'story-image'} />
        ) : (
          <LogoIcon />
        )}
      </Box>
      <Box className={classes.descriptionWrap}>
        <Box>
          <Typography noWrap className={classes.title}>
            {title}
          </Typography>
          <Typography className={classes.about} noWrap>
            {about}
          </Typography>
          {languages.length > 1 && (
            <>
              <IconButton className={classes.icon} disabled>
                <LanguageIcon fill={colors.grey} />
              </IconButton>
              <Box component='span' className={classes.about}>
                {languages
                  .map((language) => getEnglishName(language))
                  .join(' | ')}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

StoryListItem.defaultProps = {
  image: '',
  about: '',
  isActive: false,
  languages: [],
};

StoryListItem.propTypes = {
  image: PropTypes.string,
  about: PropTypes.string,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  languages: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func.isRequired,
};

export default StoryListItem;

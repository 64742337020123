import { createSelector } from 'reselect';
import {
  storyListItemsSelector,
  storyListSelectedIdsSelector,
} from './editStory';
import { framesListSelector } from './frames';

export const pagesListSelector = (state) => state.pages.pages;
export const pagesListLoadingSelector = (state) => state.pages.loading;

export const pageListItemSelector = createSelector(
  storyListSelectedIdsSelector,
  pagesListSelector,
  (selectedIds, list) => {
    if (selectedIds.page) {
      return list.find((item) => item.id === selectedIds.page);
    }

    return null;
  }
);

export const pageListItemFrameSelector = createSelector(
  storyListSelectedIdsSelector,
  pageListItemSelector,
  framesListSelector,
  (selectedIds, selectedPage, framesList) => {
    if (selectedIds.frame && selectedPage) {
      return framesList[selectedPage.id]?.find(
        (item) => item.id === selectedIds.frame
      );
    }

    return null;
  }
);

// To have the same order as all pages (in addition with chapters)
export const pageListOrderSelector = createSelector(
  storyListItemsSelector,
  (list) => {
    const pages = [];

    const pushData = (arrOfPages) => {
      arrOfPages.forEach((page) => {
        if (page.children) {
          pushData(page.children);
        } else {
          pages.push(page);
        }
      });
    };

    pushData(list);

    return pages;
  }
);

import React from 'react';

export default function useMobile() {
  const [isMobile, toggleMobile] = React.useState(window.innerWidth <= 768);
  const [isSmallDesktop, toggleSmallDesktop] = React.useState(
    window.innerWidth <= 1120
  );
  const handleListAdaptation = () => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    toggleMobile(mobile || window.innerWidth <= 768);
    toggleSmallDesktop(mobile || window.innerWidth <= 1120);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleListAdaptation);
    // eslint-disable-next-line no-unused-vars
    return (_) => {
      window.removeEventListener('resize', handleListAdaptation);
    };
  });
  return { isMobile, isSmallDesktop };
}

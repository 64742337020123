import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  searchIcon: {
    minWidth: '180px',
    maxWidth: '230px',
    borderRadius: '5px',

    '& > input': {
      padding: '20px 20px 20px 0px',
      fontSize: '16px',

      '&::placeholder': {
        fontSize: '12px',
        lineHeight: ' 14px',
      },
    },

    '& .MuiIconButton-root': {
      padding: '14px 8px 12px 12px',
    },

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '230px',
    },
  },
  inputControll: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
    },
  },
}));

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SignIn from 'pages/auth/component/SignIn';
import SignUp from 'pages/auth/component/SignUp';
import { ROUTES } from 'constants/index';

const PublicRoutes = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.HOME} component={SignIn} />
      <Route exact path={ROUTES.SIGN_UP} component={SignUp} />
      <Redirect to={ROUTES.HOME} />
    </Switch>
  );
};

export default PublicRoutes;

import React, { useEffect } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import {
  BACKPACK_TYPE,
  HOT_SPOT_TYPE,
  IMAGE_LAYER_TYPE,
  SPEECHBUBBLE_LAYER_TYPE,
  TEXT_LAYER_TYPE,
} from 'constants/editTypes';
import {
  storyListEditTypeSelector,
  storyListSelectedPageSelector,
} from 'common/selectors/editStory';
import {
  clearEditItemAction,
  setSelectedAction,
} from 'common/actions/editStoryActions';
import { defaultSelected } from 'common/reducers/editStory';
import EditGridPage from './components/EditGridPage';
import SpotView from './components/SpotView';
import ConfirmDialog from './components/Dialogs/ConfirmDialog';
import useStyles from './styles';

const selector = createSelector(
  storyListSelectedPageSelector,
  storyListEditTypeSelector,
  (selectedPage, editedType) => ({
    selectedPage,
    editedType,
  })
);

const StoryEdit = () => {
  const { selectedPage, editedType } = useSelector(selector);
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearEditItemAction());
      dispatch(setSelectedAction(defaultSelected));
    };
  }, [dispatch]);

  const showSpotView =
    editedType === HOT_SPOT_TYPE ||
    editedType === IMAGE_LAYER_TYPE ||
    editedType === TEXT_LAYER_TYPE ||
    editedType === SPEECHBUBBLE_LAYER_TYPE ||
    editedType === BACKPACK_TYPE;

  return (
    <Box className={classes.root}>
      {selectedPage && !showSpotView && <EditGridPage />}
      {showSpotView && <SpotView />}
      <ConfirmDialog />
    </Box>
  );
};

export default StoryEdit;

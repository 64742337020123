import axios from 'axios';
import Cookies from 'js-cookie';

const getCurrentUser = () => {
  const token = Cookies.get('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  return axios.get('/admins/me').then((res) => res.data);
};

export const getUsers = () => {
  return axios.get('/users').then((res) => res.data.rows);
};

const signIn = (body) =>
  axios.post('/admins/login', body).then((res) => res.data);

const signUp = (body) => axios.post('/admins/', body).then((res) => res);

export { signIn, signUp, getCurrentUser };

export default {
  signIn,
  signUp,
  getCurrentUser,
};

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Menu, MenuItem } from '@material-ui/core';

const ContextMenu = forwardRef(({ anchorEl, onClose }, ref) => {
  return (
    <Box ref={ref}>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={!!anchorEl || false}
        onClose={onClose}
        getContentAnchorEl={null}
      >
        <MenuItem>Copy</MenuItem>
        <MenuItem>Delete</MenuItem>
      </Menu>
    </Box>
  );
});

ContextMenu.displayName = 'ContextMenu';

ContextMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOf([PropTypes.element, null]).isRequired,
};

export default Menu;

import {
  CREATE_CHECKPOINT,
  DELETE_CHECKPOINT,
  END_LOADING_CHECKPOINT,
  FETCHED_CHECKPOINT,
  START_LOADING_CHECKPOINT,
  UPDATE_CHECKPOINT,
} from 'common/types/checkpointList';

const initialState = {
  checkpoints: [],
  loading: false,
};

const checkpointReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_CHECKPOINT:
      return {
        ...state,
        checkpoints: [...action.payload],
      };
    case CREATE_CHECKPOINT:
      return {
        ...state,
        checkpoints: [...state.checkpoints, action.payload],
      };
    case START_LOADING_CHECKPOINT:
      return {
        ...state,
        loading: true,
      };
    case END_LOADING_CHECKPOINT:
      return {
        ...state,
        loading: false,
      };
    case DELETE_CHECKPOINT: {
      const checkpoints = [...state.checkpoints];

      const index = checkpoints.findIndex(
        (checkpoint) => checkpoint.id === action.payload.id
      );

      if (index >= 0) {
        checkpoints.splice(index, 1);
      }

      return { ...state, checkpoints, loading: false };
    }
    case UPDATE_CHECKPOINT: {
      const checkpoints = [...state.checkpoints];

      const index = checkpoints.findIndex(
        (checkpoint) => checkpoint.id === action.payload.id
      );

      if (index >= 0) {
        checkpoints.splice(index, 1);
      }

      return { ...state, checkpoints, loading: false };
    }
    default:
      return state;
  }
};

export default checkpointReducer;

import { useCallback, useEffect, useRef } from 'react';

export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  const clearHook = useCallback(() => {
    ref.current = null;
  }, []);
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return { item: ref.current, clearHook };
};

export const defaultValues = {
  coordinates: {
    x: 0,
    y: 0,
    w: 10,
    h: 10,
  },
  title: '',
  imageInfo: null,
};

import React, { useState } from 'react';
import { Divider, IconButton, MenuItem, Box, Tooltip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import GridOnIcon from '@material-ui/icons/GridOn';
import SnapIcon from '@mui/icons-material/AutoAwesomeMosaic';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';
import colors from 'constants/colors';
import { ROUTES } from 'constants/index';
import useMobile from 'utils/hooks/useMobile';
import {
  setShowGridLinesAction,
  setSnapToFramesAction,
} from 'common/actions/editStoryActions';
import MenuWrap from 'components/MenuWrap';
import { UndoArrow } from 'components/Icons/UndoArrow';
import { RedoArrow } from 'components/Icons/RedoArrow';
import { PreviewIcon } from 'components/Icons/PreviewIcon';
import { ArrowLeft } from 'components/Icons/ArrowLeft';
import useStyles from './styles';

export const ActionMenu = ({
  showGridLines,
  snapFrames,
  previewStory,
  onClose,
  handleUndo,
  undoHistory,
  handleRedo,
  redoHistory,
  onStoryExit,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isMobile } = useMobile();

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <MenuWrap
      className={classes.menuWraper}
      anchorEl={anchorEl}
      onToggle={setAnchorEl}
      hover={!isMobile}
      toggler={
        <Tooltip title='Options' placement='bottom'>
          <IconButton className={classes.menuMainIcon}>
            <MoreHorizIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <Box
        onMouseLeave={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem onClick={handleUndo}>
          <IconButton
            className={classes.undoIcon}
            disabled={!undoHistory?.length}
          >
            <UndoArrow />
          </IconButton>
          Undo
        </MenuItem>
        <Divider />

        <MenuItem onClick={handleRedo}>
          <IconButton
            className={classes.redoIcon}
            disabled={!redoHistory?.length}
          >
            <RedoArrow />
          </IconButton>
          Redo
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={() => dispatch(setShowGridLinesAction(!showGridLines))}
        >
          <IconButton className={classes.menuIcon}>
            <GridOnIcon
              style={{
                fill: showGridLines ? '#ffffff' : colors.grey,
              }}
            />
          </IconButton>
          Hide Grid
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => dispatch(setSnapToFramesAction(!snapFrames))}>
          <IconButton className={classes.menuIcon}>
            <SnapIcon
              style={{
                fill: snapFrames ? '#ffffff' : colors.grey,
              }}
            />
          </IconButton>
          Snap Frames
        </MenuItem>
        <Divider />

        <MenuItem onClick={previewStory}>
          <IconButton className={classes.menuIcon}>
            <PreviewIcon style={{ fill: '#ffffff' }} />
          </IconButton>
          Play Preview
        </MenuItem>
        <Divider />

        <MenuItem onClick={onClose}>
          <IconButton className={classes.menuIcon}>
            <ExitToAppIcon style={{ fill: '#ffffff' }} />
          </IconButton>
          Close Story Builder
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={() => {
            onClose();
            onStoryExit();
          }}
        >
          <Link to={ROUTES.HOME} style={{ textDecoration: 'none' }}>
            <IconButton className={classes.backBtn}>
              <ArrowLeft />
            </IconButton>
            Back to Stories
          </Link>
        </MenuItem>
        <Divider />
      </Box>
    </MenuWrap>
  );
};

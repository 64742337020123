import {
  CREATE_BACKPACK,
  DELETE_BACKPACK,
  END_LOADING_BACKPACK,
  FETCHED_BACKPACK,
  START_LOADING_BACKPACK,
  UPDATE_BACKPACK,
} from 'common/types/backpackList';

const initialState = {
  backpacks: [],
  loading: false,
};

const layersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_BACKPACK:
      return {
        ...state,
        backpacks: action.payload,
      };
    case CREATE_BACKPACK:
      return {
        ...state,
        backpacks: [...state.backpacks, action.payload],
      };
    case START_LOADING_BACKPACK:
      return {
        ...state,
        loading: true,
      };
    case END_LOADING_BACKPACK:
      return {
        ...state,
        loading: false,
      };
    case DELETE_BACKPACK: {
      const backpacks = [...state.backpacks];

      const index = backpacks.findIndex(
        (hotSpot) => hotSpot.id === action.payload.id
      );

      if (index >= 0) {
        backpacks.splice(index, 1);
      }

      return { ...state, backpacks, loading: false };
    }
    case UPDATE_BACKPACK: {
      const backpacks = [...state.backpacks];

      const updateIndex = backpacks.findIndex(
        (hotSpot) => hotSpot.id === action.payload.id
      );

      if (updateIndex >= 0) {
        backpacks.splice(updateIndex, 1, action.hotSpot);
      }

      return { ...state, hotSpot: { ...backpacks }, loading: false };
    }
    default:
      return state;
  }
};

export default layersReducer;

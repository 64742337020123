import React, { useCallback, useState } from 'react';
import {
  Divider,
  IconButton,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  IMAGE_LAYER_TYPE,
  SPEECHBUBBLE_LAYER_TYPE,
  TEXT_LAYER_TYPE,
} from 'constants/editTypes';
import {
  handleDrowingModeAction,
  handleSpeechbubbleImportedAction,
} from 'common/actions/speechbubble';
import MenuWrap from 'components/MenuWrap';
import { AddLayerIcon, ImageIcon, AddLayerTextIcon } from 'components/Icons';
import { SpeechbubbleIcon } from 'components/Icons/SpeechbubbleIcon';
import { SpeechbubbleDrowIcon } from 'components/Icons/SpeechbubbleDrowIcon';

const LayerMenu = ({ onAddLayer }) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleAddImageLayer = useCallback(() => {
    onAddLayer(IMAGE_LAYER_TYPE);
  }, [onAddLayer]);

  const handleAddTextLayer = useCallback(() => {
    onAddLayer(TEXT_LAYER_TYPE);
  }, [onAddLayer]);

  const handleAddSpeechbubbleLayer = () => {
    onAddLayer(SPEECHBUBBLE_LAYER_TYPE);
    dispatch(
      handleSpeechbubbleImportedAction({
        importedJSON: null,
        imported: false,
      })
    );
    dispatch(handleDrowingModeAction(false));
  };

  const handleAddDrawingSpeechbubbleLayer = () => {
    onAddLayer(SPEECHBUBBLE_LAYER_TYPE);
    dispatch(
      handleSpeechbubbleImportedAction({
        importedJSON: null,
        imported: false,
      })
    );
    dispatch(handleDrowingModeAction(true));
  };

  return (
    <MenuWrap
      anchorEl={anchorEl}
      hover
      onToggle={setAnchorEl}
      toggler={
        <Tooltip title='Add layer' placement='bottom'>
          <IconButton>
            <AddLayerIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <Box
        onMouseLeave={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem onClick={handleAddImageLayer}>
          <ListItemIcon>
            <ImageIcon fillOpacity={0.5} />
          </ListItemIcon>
          Add Image layer
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleAddTextLayer}>
          <ListItemIcon>
            <AddLayerTextIcon />
          </ListItemIcon>
          Add Text layer
        </MenuItem>
        <Divider />

        <MenuItem onClick={handleAddSpeechbubbleLayer}>
          <ListItemIcon>
            <SpeechbubbleIcon />
          </ListItemIcon>
          Add Speech bubble
        </MenuItem>
        <Divider />

        <MenuItem onClick={handleAddDrawingSpeechbubbleLayer}>
          <ListItemIcon>
            <SpeechbubbleDrowIcon opacity={0.5} />
          </ListItemIcon>
          Add draw speech bubble
        </MenuItem>
      </Box>
    </MenuWrap>
  );
};

LayerMenu.propTypes = {
  onAddLayer: PropTypes.func.isRequired,
};

export default LayerMenu;

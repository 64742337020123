import axios from 'axios';
import { encodeStoryTitle } from '../utils/titleFormat';

const uploadFile = (file, config = {}, isModel = false) => {
  if (!file) return new Promise(null);
  const formData = new FormData();
  formData.append('file', file, encodeStoryTitle(file.name));
  return axios
    .post(`upload-files${isModel ? '/texture/s3' : ''}`, formData, config)
    .then((res) => res.data);
};

const uploadMultipleFiles = (files, config = {}) => {
  if (!files || !files.length) return new Promise(null);
  const formData = new FormData();
  files.forEach((file) =>
    formData.append('file', file, encodeStoryTitle(file.name))
  );
  return axios
    .post('upload-files/multiple', formData, config)
    .then((res) => res.data);
};

export { uploadFile, uploadMultipleFiles };

export default {
  uploadFile,
  uploadMultipleFiles,
};

import { combineReducers } from 'redux';
import auth from './auth';
import story from './story';
import pages from './pages';
import decisionsList from './decisionsList';
import hotSpotsList from './hotSpotsList';
import editStory from './editStory';
import layersList from './layersList';
import backpackList from './backpackList';
import storyList from './storyList';
import checkpointList from './checkpointList';
import frames from './frames';
import speechbubble from './speechbubble';

export default combineReducers({
  auth,
  story,
  pages,
  decisionsList,
  hotSpotsList,
  editStory,
  layersList,
  backpackList,
  storyList,
  checkpointList,
  frames,
  speechbubble,
});

import { createMuiTheme } from '@material-ui/core/styles';
import { transparentize } from 'polished';
import colors from 'constants/colors';

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1140,
      lg: 1280,
      xl: 1920,
    },
  },
  colors,
  palette: {
    primary: { main: colors.crimson },
    secondary: { main: colors.secondary },
    background: {
      default: colors.background,
      primary: {
        lightest: colors.lightestGrey,
        light: colors.lightGrey,
        main: colors.grey,
        dark: colors.darkGrey,
      },
      secondary: {
        lightest: '#E0F7FA',
        light: '#4DD0E1',
        main: colors.crimson,
        dark: '#006064',
      },
    },
    divider: colors.divider,
    shape: {
      borderRadius: 2,
    },
    text: {
      primary: colors.white,
      error: colors.error,
    },
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ['"Roboto"', 'sans-serif'],
    layerFontFamily: ['"Comic Neue"', 'cursive'],
    h1: {
      fontWeight: 'bold',
      fontSize: 24,
      '@media (min-width: 600px)': {
        fontSize: 34,
      },
      '@media (min-width: 1280px)': {
        fontSize: 44,
      },
    },
    h2: {
      fontWeight: 'bold',
      fontSize: 18,
      '@media (min-width: 600px)': {
        fontSize: 24,
      },
      '@media (min-width: 1280px)': {
        fontSize: 34,
      },
    },
    h3: {
      fontWeight: 'bold',
      fontSize: 16,
      '@media (min-width: 600px)': {
        fontSize: 18,
      },
      '@media (min-width: 1280px)': {
        fontSize: 24,
      },
    },
    h4: {
      fontWeight: 'bold',
      fontSize: 14,
      '@media (min-width: 600px)': {
        fontSize: 16,
      },
      '@media (min-width: 1280px)': {
        fontSize: 16,
      },
    },
    subtitle1: {
      fontSize: 16,
      '@media (min-width: 1280px)': {
        fontSize: 18,
      },
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: '400',
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
  },

  overrides: {
    MuiButton: {
      root: {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: 16,
        borderRadius: 5,
        margin: '10px 0',
        '&:hover': {
          filter: 'brightness(1.15)',
          transform: 'scale(1.05)',
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
      },
      containedPrimary: {
        color: colors.white,
        backgroundColor: colors.crimson,
        '&$disabled': {
          color: colors.white,
          backgroundColor: colors.grey,
        },
      },
      containedSecondary: {
        color: colors.white,
        backgroundColor: colors.secondary,
        '&$disabled': {
          color: colors.white,
          backgroundColor: colors.grey,
        },
      },
    },
    MuiInputBase: {
      root: {
        'label + &': {
          marginTop: 20,
        },
        borderRadius: 2,
        background: 'rgba(255, 255, 255, 0.08)',
      },
      input: {
        boxSizing: 'border-box',
        fontFamily: 'Roboto',
        lineHeight: '18px',
        color: colors.white,
        fontSize: 10,
        height: 24,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 8,
        paddingRight: 8,
        '&:focus': {
          borderColor: colors.white,
        },
      },
      inputMultiline: {
        minHeight: '144px !important',
        boxSizing: 'border-box',
        fontFamily: 'Roboto',
        lineHeight: '18px',
        color: colors.white,
        borderRadius: 2,
        fontSize: 10,
        padding: 8,
        '&:focus': {
          borderColor: colors.grey,
        },
      },
      multiline: {
        boxSizing: 'border-box',
        fontFamily: 'Roboto',
        color: colors.white,
        fontSize: 10,
        padding: 0,
        '&:focus': {
          borderColor: colors.grey,
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 11,
        color: 'rgba(255, 255, 255, 0.5)',

        '&.Mui-focused': {
          paddingLeft: 0,
          color: 'rgba(255, 255, 255, 0.5)',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 11,
      },
    },
    MuiSelect: {
      root: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 8,
        paddingRight: 8,
        fontSize: 11,
        color: colors.white,
      },
      nativeInput: {
        height: 24,
      },
    },
    MuiTabs: {
      root: {
        minHeight: 'auto',
        '& .MuiTabScrollButton-root.Mui-disabled': {
          opacity: 0.2,
          width: '20px',
        },
      },
    },
    MuiTab: {
      root: {
        '@media (min-width: 600px)': { minWidth: '34px' },
      },
    },
    PrivateTabIndicator: {
      root: {
        minWidth: '34px',
      },
      colorSecondary: {
        backgroundColor: colors.crimson,
      },
    },
    MuiGrid: {
      item: {
        marginBottom: 10,
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: colors.background,
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: colors.menuBackground,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 10,

        '&:hover': {
          backgroundColor: transparentize(0.8, colors.white),
        },
      },
    },
    MuiListSubheader: {
      root: {
        fontSize: 12,
        lineHeight: 2,
        fontWeight: 600,
        color: colors.grey,
      },
    },
    MuiList: {
      root: {
        background: colors.menuBackground,
      },
    },
  },
});

import { ATTACHMENTS_TYPE } from './attachmentsType';

export const SELECT_TAB_TYPE = {
  0: ATTACHMENTS_TYPE.IMAGE,
  1: ATTACHMENTS_TYPE.VIDEO,
  2: ATTACHMENTS_TYPE.AUDIO,
  3: ATTACHMENTS_TYPE.URL,
  4: ATTACHMENTS_TYPE.GAME,
};

export const SELECT_TAB_TYPE_INDEX = {
  [ATTACHMENTS_TYPE.IMAGE]: 0,
  [ATTACHMENTS_TYPE.VIDEO]: 1,
  [ATTACHMENTS_TYPE.AUDIO]: 2,
  [ATTACHMENTS_TYPE.URL]: 3,
  [ATTACHMENTS_TYPE.GAME]: 4,
};

import React from 'react';
import classnames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';

export const CheckpointControlIcon = ({ className, ...props }) => {
  return (
    <SvgIcon
      viewBox='0 0 10 10'
      style={{ width: 10, height: 10, fill: 'none' }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classnames({
        [className]: !!className,
      })}
      {...props}
    >
      <path
        d='M6.75 1.93c-1.05 0-1.91-.68-3.23-.68-.48 0-.92.09-1.33.23A1.1 1.1 0 10.55 2v7.54c0 .26.2.47.47.47h.3c.27 0 .48-.21.48-.47V7.7a5.46 5.46 0 012.23-.43c1.05 0 1.91.67 3.23.67.94 0 1.7-.31 2.4-.8.16-.1.26-.3.26-.5V1.86a.62.62 0 00-.89-.56c-.67.3-1.49.62-2.28.62z'
        fill='#fff'
      />
    </SvgIcon>
  );
};

/* <svg
        width='10'
        height='10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.75 1.93c-1.05 0-1.91-.68-3.23-.68-.48 0-.92.09-1.33.23A1.1 1.1 0 10.55 2v7.54c0 .26.2.47.47.47h.3c.27 0 .48-.21.48-.47V7.7a5.46 5.46 0 012.23-.43c1.05 0 1.91.67 3.23.67.94 0 1.7-.31 2.4-.8.16-.1.26-.3.26-.5V1.86a.62.62 0 00-.89-.56c-.67.3-1.49.62-2.28.62z'
          fill='#fff'
        />
      </svg> */

export const defaultOption = {
  text: { nb: '', index: 0 },
  frame: '',
  page: '',
};

export const defaultValues = {
  id: '',
  decisionCase: { nb: '' },
  decisionName: '',
  options: [defaultOption],
  originFrame: '',
  originPage: '',
  story: '',
  storyEnd: false,
};

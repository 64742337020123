import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import Routes from 'routes';
import { theme } from 'styles/theme';
import store from './common/store';

const App = () => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          <Routes />
          <ToastContainer />
        </BrowserRouter>
      </MuiThemeProvider>
    </Provider>
  );
};

export default App;

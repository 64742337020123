import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

const ControlBarBlock = ({
  children,
  blockTitle,
  noPaddingTitle,
  margin,
  padding,
  onClick,
  position,
}) => {
  const classes = useStyles({ noPaddingTitle, margin, padding, position });

  return (
    <Box className={classes.root} onClick={onClick}>
      {blockTitle && <Box className={classes.title}>{blockTitle}</Box>}
      {children}
    </Box>
  );
};

ControlBarBlock.defaultProps = {
  blockTitle: '',
  margin: '',
  padding: '',
  noPaddingTitle: false,
  onClick: () => {},
};

ControlBarBlock.propTypes = {
  blockTitle: PropTypes.node,
  noPaddingTitle: PropTypes.bool,
  margin: PropTypes.string,
  padding: PropTypes.string,
  onClick: PropTypes.func,
};

export default ControlBarBlock;

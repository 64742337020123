import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import useMobile from 'utils/hooks/useMobile';
import StyledSearchInput from 'components/forms/StyledSearchInput/StyledSearchInput';
import useStyles from './styles';

export const SearchContainer = ({
  searchTerm,
  setSearchTerm,
  handleAddNewStory,
}) => {
  const classes = useStyles();
  const { isMobile } = useMobile();

  return (
    <Box className={classes.titleWrap}>
      {!isMobile && (
        <Typography variant='h2' noWrap className={classes.title}>
          Available stories
        </Typography>
      )}
      <Box className={classes.actionContainer}>
        <StyledSearchInput state={searchTerm} setSearchTerm={setSearchTerm} />
        <Button
          variant='contained'
          color='primary'
          onClick={handleAddNewStory}
          className={classes.mainAddBtn}
        >
          New Story
        </Button>
      </Box>
    </Box>
  );
};

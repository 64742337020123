import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { GamepadIcon } from 'components/Icons/GamepadIcon';
import useStyles from './styles';

const GridGame = ({ attachmentInfo, newWindow, onGoClick }) => {
  const classes = useStyles();
  return (
    <Box className={classes.wrap}>
      {newWindow ? (
        <>
          <Box className={classes.go} onClick={onGoClick}>
            Go
          </Box>
          <div className={classes.game}>
            <GamepadIcon />
          </div>
        </>
      ) : (
        <Box className={classes.game}>
          <GamepadIcon />
          {attachmentInfo.name}
        </Box>
      )}
    </Box>
  );
};

GridGame.propTypes = {
  attachmentInfo: PropTypes.shape({
    type: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  newWindow: PropTypes.bool,
  onGoClick: PropTypes.func.isRequired,
};

GridGame.defaultProps = {
  newWindow: false,
};

export default GridGame;

import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default makeStyles((theme) => ({
  wrap: {
    position: 'absolute',
    zIndex: 1,
    top: ({ top }) => top || 0,
    left: ({ left }) => left || 0,
    width: ({ width }) => width || theme.spacing(6.25),
    height: ({ height }) => height || theme.spacing(6.25),
    border: `${theme.spacing(0.125)}px solid ${theme.colors.dropZone}`,
    background: transparentize(0.67, theme.colors.dropZone),
  },
}));

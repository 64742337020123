import axios from 'axios';

export const getBackpackListService = (params = {}) =>
  axios
    .get('/items', { params: { limit: 100, sort: 'createdAt', ...params } })
    .then((res) => res.data);

export const createBackpackItemService = (body, type) =>
  axios.post(`/items?type=${type}`, body).then((res) => res.data);

export const updateBackpackItemService = (body, updateType) =>
  axios
    .put(`/items/${body.id || body._id}`, body, {
      params: { type: updateType },
    })
    .then((res) => res.data && res.data);

export const deleteBackpackItemService = (id, type) =>
  axios.delete(`/items/${id}?type=${type}`);

export const deleteGameItemsService = (frameId, type) =>
  axios.delete(`/items/batch/${frameId}?type=${type}`);

import React from 'react';
import classnames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';

export const CheckpointHeaderIcon = ({ className, ...props }) => {
  return (
    <SvgIcon
      viewBox='0 0 17 17'
      style={{ width: 17, height: 17, fill: 'none' }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classnames({
        [className]: !!className,
      })}
      {...props}
    >
      <path
        d='M10.5 2.5c-1.53 0-2.91-1-5.05-1-.98 0-1.83.2-2.53.47a1.5 1.5 0 10-2.17.83v12.7c0 .28.22.5.5.5h.5a.5.5 0 00.5-.5v-2.6a8.6 8.6 0 014-.9c1.53 0 2.91 1 5.05 1 1.83 0 3.2-.7 4.02-1.25.43-.28.68-.75.68-1.25V3a1.5 1.5 0 00-2.1-1.38c-1.11.48-2.29.88-3.4.88zm4 8c-.68.48-1.9 1-3.2 1-1.87 0-3.18-1-5.06-1-1.35 0-3 .3-3.99.75V4c.68-.48 1.9-1 3.2-1 1.87 0 3.18 1 5.06 1 1.35 0 3-.54 3.99-1v7.5z'
        fill='#fff'
      />
    </SvgIcon>
  );
};

// <svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
//       <path
//         d='M10.5 2.5c-1.53 0-2.91-1-5.05-1-.98 0-1.83.2-2.53.47a1.5 1.5 0 10-2.17.83v12.7c0 .28.22.5.5.5h.5a.5.5 0 00.5-.5v-2.6a8.6 8.6 0 014-.9c1.53 0 2.91 1 5.05 1 1.83 0 3.2-.7 4.02-1.25.43-.28.68-.75.68-1.25V3a1.5 1.5 0 00-2.1-1.38c-1.11.48-2.29.88-3.4.88zm4 8c-.68.48-1.9 1-3.2 1-1.87 0-3.18-1-5.06-1-1.35 0-3 .3-3.99.75V4c.68-.48 1.9-1 3.2-1 1.87 0 3.18 1 5.06 1 1.35 0 3-.54 3.99-1v7.5z'
//         fill='#fff'
//       />
//     </svg>

import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  Box,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ArrowDropDownRounded, Clear } from '@material-ui/icons';
// eslint-disable-next-line camelcase
import { getAll639_1, getEnglishName } from 'all-iso-language-codes';
import { theme } from 'styles/theme';
import useMobile from 'utils/hooks/useMobile';
import { storyItemsSelector } from 'common/selectors/story';
import { setCurrentLanguageAction } from 'common/actions/storyListActions';
import { updateCurrentStoryAction } from 'common/actions/storyActions';
import { storyListLanguageSelector } from 'common/selectors/storyList';
import { setSettingsAction } from 'common/actions/editStoryActions';
import SelectField from 'components/forms/SelectField/SelectField';
import { OptionsIcon } from 'components/Icons/OptionsIcon';
import useStyles from './styles';

const selector = createSelector(
  storyItemsSelector,
  storyListLanguageSelector,
  (story, currentLang) => ({
    story,
    currentLang,
  })
);

const commonLanguages = ['nb', 'nn', 'en', 'ru', 'uk'];

export const renderLanguageList = () => {
  const renderLanguageItem = (languageCode) => {
    const languageName = getEnglishName(languageCode);
    return (
      <MenuItem key={languageCode} value={languageCode}>
        {languageName}
      </MenuItem>
    );
  };

  return [
    <MenuItem value='' key='quick-select' disabled>
      Quick Select...
    </MenuItem>,
    ...commonLanguages.map((code) => renderLanguageItem(code)),
    <MenuItem value='' key='other' disabled>
      Other...
    </MenuItem>,
    ...getAll639_1()
      .filter((code) => !commonLanguages.includes(code))
      .map((code) => renderLanguageItem(code)),
  ];
};

export const StoryTitleBlock = () => {
  const dispatch = useDispatch();
  const { isSmallDesktop } = useMobile();

  const [languagesOpen, setLanguagesOpen] = useState(false);
  const [languageInput, setLanguageInput] = useState('');

  const { story, currentLang } = useSelector(selector);

  const classes = useStyles({
    languagesOpen,
    isSmallDesktop,
  });

  const title = React.useMemo(() => {
    return (
      <Tooltip title={story?.title[currentLang] || 'Digital Universe'}>
        <Typography variant='h6' noWrap className={classes.storyTitle}>
          {story?.title[currentLang]}
        </Typography>
      </Tooltip>
    );
  }, [classes.storyTitle, currentLang, story?.title]);

  const handleChooseLanguage = (lang) => {
    dispatch(setCurrentLanguageAction(lang));
    setLanguagesOpen(false);
  };

  const handleRemoveLanguage = (lang, e) => {
    e.stopPropagation();
    const languages = story.languages?.filter((l) => l !== lang) || ['nb'];
    dispatch(updateCurrentStoryAction(story, 'languages', languages));
    if (lang === currentLang) dispatch(setCurrentLanguageAction('nb'));
    setLanguagesOpen(false);
  };

  const handleUpdateStoryLanguages = (languageCode) => {
    dispatch(
      updateCurrentStoryAction(story, 'languages', [
        ...story.languages,
        languageCode,
      ])
    );
    setLanguageInput('');
  };

  const handleOnOpenSettings = useCallback(() => {
    dispatch(setSettingsAction(true));
  }, [dispatch]);

  return (
    <Box className={classes.titleWrap}>
      <Box className={classes.titleContent}>
        <Box>
          {title}
          <Box
            className={classes.languageContainer}
            onClick={() => setLanguagesOpen((prev) => !prev)}
          >
            <Typography className={classes.currentLang}>
              {getEnglishName(currentLang)}
            </Typography>
            {isSmallDesktop && (
              <IconButton style={{ padding: '1px' }}>
                <ArrowDropDownRounded
                  style={{
                    color: 'rgba(255, 255, 255, 0.5)',
                    fontSize: '20px',
                  }}
                />
              </IconButton>
            )}
          </Box>
        </Box>
        {isSmallDesktop && (
          <IconButton
            className={classes.headerIcon}
            onClick={handleOnOpenSettings}
          >
            <OptionsIcon />
          </IconButton>
        )}
      </Box>
      {!isSmallDesktop && (
        <IconButton
          style={{ padding: '1px' }}
          onClick={() => setLanguagesOpen((prev) => !prev)}
        >
          <ArrowDropDownRounded
            style={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: '34px' }}
          />
        </IconButton>
      )}
      <Box
        className={classes.languageOptionsWrap}
        onClick={(e) => e.stopPropagation()}
      >
        {story?.languages?.map((language, index) => (
          <Box
            key={language}
            className={classes.languageOption}
            style={{
              color: language === currentLang && theme.colors.layerDropZone,
            }}
            onClick={() => handleChooseLanguage(language)}
          >
            {getEnglishName(language)}
            {index > 0 && (
              <IconButton
                className={classes.clearIconButton}
                onClick={(e) => handleRemoveLanguage(language, e)}
              >
                <Clear
                  style={{
                    color: 'rgba(255, 255, 255, 0.5)',
                    fontSize: '12px',
                  }}
                />
              </IconButton>
            )}
          </Box>
        ))}

        <Box className={classes.languageOption}>
          <SelectField
            label='Add language'
            value={languageInput}
            onChange={(e) => {
              handleUpdateStoryLanguages(e.target.value);
            }}
          >
            {renderLanguageList()}
          </SelectField>
        </Box>
      </Box>
    </Box>
  );
};

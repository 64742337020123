import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  optionDelete: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: theme.spacing(0.25),
    backgroundColor: theme.colors.divider,
    cursor: 'pointer',
  },
  addOption: {
    cursor: 'pointer',
  },
}));

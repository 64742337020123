export const difficulty = [
  {
    easy: 'Easy',
  },
  {
    medium: 'Medium',
  },
  {
    hard: 'Hard',
  },
];

export const AVAILABLE_ENVS = {
  prod: [
    { value: 'stage', name: 'Staging' },
    { value: 'dev', name: 'Development' },
  ],
  stage: [{ value: 'dev', name: 'Development' }],
};

export const fieldsForValidation = ['title', 'about', 'subtext'];

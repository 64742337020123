import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import GridImage from '../GridImage/GridImage';
import useStyles from './styles';

const GridVideo = ({ attachmentInfo, videoImage, clipPath, isCircle }) => {
  const classes = useStyles({ clipPath, isCircle });

  if (videoImage?.url)
    return (
      <GridImage
        isCircle={isCircle}
        attachmentInfo={videoImage}
        clipPath={clipPath}
      />
    );

  return (
    <Box className={classes.wrap}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video src={attachmentInfo.url} autoPlay={false} />
    </Box>
  );
};

GridVideo.propTypes = {
  attachmentInfo: PropTypes.shape({
    type: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default GridVideo;

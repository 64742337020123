import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  menuIcon: { padding: '0px 10px 0px 0px' },
  menuWraper: {
    '& .MuiList-root': {
      minWidth: '141px',
    },

    '& li': {
      padding: '0px 8px',
      minHeight: '40px',

      '& p': {
        padding: '10px',
      },

      '& svg': {
        width: '20px',
      },
    },
  },
  redoIcon: {
    transform: 'rotate(25deg)',
    padding: '4px 8px 0px 0px',
  },
  undoIcon: {
    transform: 'rotate(-25deg)',
    padding: '0px 7px 3px 0px',
  },
  backBtn: {
    padding: '0px 8px 0px 0px',
  },
  menuMainIcon: {
    paddingRight: '0px',
    '& svg': {
      fill: '#ffffff',
    },
  },
}));

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    height: '100%',
  },
  sound: {
    position: 'absolute',
    top: theme.spacing(1.6),
    right: theme.spacing(1.6),
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  imageWrap: {
    borderRadius: ({ isCircle }) => isCircle && '50%',

    justifyContent: 'center',
    verticalAlign: 'middle',
    alignItems: 'center',
    overflow: 'hidden',
    display: 'flex',
    height: '100%',
  },
}));

import {
  SET_DROWINGMODE,
  SET_SHEECHBUBBLE,
  RESET_SHEECHBUBBLE,
  SET_MODE_CHANGE,
  SET_FILE_CHANGE,
  SET_CLEANED,
  SET_IMPORT_STATUS,
  RESET_TO_DEFAULT,
} from 'common/types/speechbubble';

export const setDrowingModeAction = (payload) => ({
  type: SET_DROWINGMODE,
  payload,
});

export const setSpeechbubbleDataAction = (payload) => ({
  type: SET_SHEECHBUBBLE,
  payload,
});

export const setResetSpeechbubbleAction = (payload) => ({
  type: RESET_SHEECHBUBBLE,
  payload,
});

export const setSpeechbubbleModeAction = (payload) => ({
  type: SET_MODE_CHANGE,
  payload,
});

export const setSpeechbubbleFileAction = (payload) => ({
  type: SET_FILE_CHANGE,
  payload,
});

export const setCleanAction = (payload) => ({
  type: SET_CLEANED,
  payload,
});

export const setImportAction = (payload) => ({
  type: SET_IMPORT_STATUS,
  payload,
});

export const setDefaultSpeechBubbleAction = (payload) => ({
  type: RESET_TO_DEFAULT,
  payload,
});

export const handleDrowingModeAction = (isDrowingMode) => {
  return (dispatch) => {
    dispatch(setDrowingModeAction(isDrowingMode));
  };
};

export const handleSpeechbubbleDataAction = (data) => {
  return (dispatch) => {
    dispatch(setSpeechbubbleDataAction(data));
  };
};

export const handleResetSpeechbubbleAction = (reset) => {
  return (dispatch) => {
    dispatch(setResetSpeechbubbleAction(reset));
  };
};

export const handleSpeechbubbleModeAction = (mode) => {
  return (dispatch) => {
    dispatch(setSpeechbubbleModeAction(mode));
  };
};

export const handleSpeechbubbleFileAction = (file) => {
  return (dispatch) => {
    dispatch(setSpeechbubbleFileAction(file));
  };
};

export const handleSpeechbubbleCleanAction = (cleaned) => {
  return (dispatch) => {
    dispatch(setCleanAction(cleaned));
  };
};

export const handleSpeechbubbleImportedAction = (imported) => {
  return (dispatch) => {
    dispatch(setImportAction(imported));
  };
};

export const handleResetSpeechbubbleToDefault = () => {
  return (dispatch) => {
    dispatch(setDefaultSpeechBubbleAction());
  };
};

import { createSelector } from 'reselect';
import { mappedArrayToPageObject } from 'utils/mappedArrayToPageObject';

export const backpackListSelector = (state) => state.backpackList.backpacks;
export const backpackLoadingSelector = (state) => state.backpackList.loading;

export const mappedBackpackObject = createSelector(
  backpackListSelector,
  (list) => mappedArrayToPageObject(list)
);

export const mappedBackpackObjectById = createSelector(
  backpackListSelector,
  (list) =>
    (list || []).reduce((acc, item) => {
      acc[item.id] = { ...item };

      return acc;
    }, {})
);

import React, { forwardRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Menu } from '@material-ui/core';
import useMobile from 'utils/hooks/useMobile';
import { ArrowDownIcon } from 'components/Icons';
import useStyles from './styles';

const MenuWrap = forwardRef(
  (
    {
      anchorEl = null,
      anchorOrigin,
      transformOrigin,
      onClose,
      onToggle,
      hover = false,
      onRightClick,
      toggler,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();
    const { isMobile } = useMobile();

    const handleMenuOpen = useCallback(
      (event) => {
        event.stopPropagation();
        onToggle(event.currentTarget);
      },
      [onToggle]
    );

    const handleMenuRightClickOpen = useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        onRightClick(event.currentTarget);
      },
      [onRightClick]
    );

    const handleMenuClose = useCallback(
      (event) => {
        event.stopPropagation();
        onToggle(null);
        onRightClick(null);
      },
      [onToggle, onRightClick]
    );

    const handleButton = useMemo(() => {
      if (toggler) {
        if (hover && !isMobile) {
          return (
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              onMouseEnter={handleMenuOpen}
            >
              {toggler}
            </Box>
          );
        }
        return (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            onClick={handleMenuOpen}
            onContextMenu={handleMenuRightClickOpen}
          >
            {toggler}
          </Box>
        );
      }

      return (
        <IconButton data-testid='menu-open-btn' onClick={handleMenuOpen}>
          <ArrowDownIcon />
        </IconButton>
      );
    }, [toggler, handleMenuOpen, hover, isMobile, handleMenuRightClickOpen]);

    return (
      <Box ref={ref}>
        {handleButton}
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          className={classes.menuWrapper}
          PaperProps={{
            className: classes.menu,
          }}
          {...props}
        />
      </Box>
    );
  }
);

MenuWrap.displayName = 'ContextMenu';

MenuWrap.defaultProps = {
  toggler: null,
  onRightClick: () => {},
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
};

MenuWrap.propTypes = {
  onToggle: PropTypes.func.isRequired,
  onRightClick: PropTypes.func,
  toggler: PropTypes.element,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string.isRequired,
    horizontal: PropTypes.string.isRequired,
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string.isRequired,
    horizontal: PropTypes.string.isRequired,
  }),
};

export default MenuWrap;

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrap: {
    '& >.MuiListItem-root': {
      padding: `${theme.spacing(0.375)}px ${theme.spacing(
        0.375
      )}px ${theme.spacing(0.375)}px ${theme.spacing(0.75)}px`,
    },

    '& >.Mui-selected': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.colors.crimson,

      '&:hover': {
        backgroundColor: theme.colors.crimson,
      },

      '& >.MuiListItemIcon-root': {
        '& path': {
          fillOpacity: 1,
        },
      },

      // '&+.MuiListItemSecondaryAction-root': {
      //   '& >.MuiButtonBase-root': {
      //     backgroundColor: theme.colors.backpackBtnActive,
      //   },
      // },
    },

    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(2.25),
    },

    '& .MuiTypography-displayBlock': {
      fontSize: theme.spacing(1.25),
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },

    '& .MuiListItemSecondaryAction-root': {
      right: theme.spacing(0.75),
    },
  },
}));

import React from 'react';
import classnames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';

export const VolumeIcon = ({ className, ...props }) => {
  return (
    <SvgIcon
      viewBox='0 0 12 12'
      style={{ width: 12, height: 12, fill: 'none' }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classnames({
        [className]: !!className,
      })}
      {...props}
    >
      <path
        d='M11.02.04L3.9 2.29a.75.75 0 00-.52.71v6.2c-.34-.12-.72-.2-1.13-.2C1.01 9 0 9.67 0 10.5S1 12 2.25 12c1.24 0 2.25-.67 2.25-1.5V5.17l6.37-2.02V7.7c-.33-.12-.71-.2-1.12-.2-1.24 0-2.25.67-2.25 1.5s1 1.5 2.25 1.5C10.99 10.5 12 9.83 12 9V.75c0-.5-.5-.87-.98-.71z'
        fill='#fff'
        fillOpacity='.5'
      />
    </SvgIcon>
  );
};

/* <svg width='12' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.02.04L3.9 2.29a.75.75 0 00-.52.71v6.2c-.34-.12-.72-.2-1.13-.2C1.01 9 0 9.67 0 10.5S1 12 2.25 12c1.24 0 2.25-.67 2.25-1.5V5.17l6.37-2.02V7.7c-.33-.12-.71-.2-1.12-.2-1.24 0-2.25.67-2.25 1.5s1 1.5 2.25 1.5C10.99 10.5 12 9.83 12 9V.75c0-.5-.5-.87-.98-.71z'
        fill='#fff'
        fillOpacity='.5'
      />
    </svg> */

import React, { useCallback, useState } from 'react';
import { Tooltip } from '@material-ui/core';

export const StyledTooltip = ({ children, placement, title }) => {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <Tooltip
      title={title}
      placement={placement}
      open={open}
      onMouseEnter={handleOpen}
      onMouseOut={handleClose}
    >
      {children}
    </Tooltip>
  );
};

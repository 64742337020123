import axios from 'axios';

export const getPageFramesService = (params = {}) => {
  return axios.get('/frames', {
    params: { sort: 'order', ...params },
  });
};

export const createFrameService = (body, type) =>
  axios.post(`/frames?type=${type}`, body).then((res) => res.data);

export const createMultipleFramesService = (body) =>
  axios.post('/frames/multiple', body).then((res) => res.data);

export const updateFrameService = (body, updateType) =>
  axios
    .put(`/frames/${body.id}`, body, { params: { type: updateType } })
    .then((res) => res.data.frame);

export const updateFramesListService = (body) =>
  axios.post('/frames/list', body).then((res) => res.data.frame);

export const deleteFrameService = (id, type) =>
  axios.delete(`/frames/${id}?type=${type}`);

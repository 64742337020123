import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default makeStyles((theme) => ({
  inputWrap: {
    justifyContent: 'space-between',
    height: ({ isBig }) => (isBig ? theme.spacing(4.75) : theme.spacing(3)),
    padding: `${theme.spacing(0.25)}px ${theme.spacing(
      0.375
    )}px ${theme.spacing(0.25)}px ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(0.25),

    '& > .MuiOutlinedInput-input': {
      display: 'none',
    },

    '&.Mui-focused': {
      borderWidth: 0,

      '& > .MuiOutlinedInput-notchedOutline': {
        height: theme.spacing(3),
        borderWidth: 0,
      },
    },
  },
  hiddenInput: {
    display: 'none',
  },
  uploadLabel: {
    display: 'flex',
    height: theme.spacing(3),
    flex: '0 0 49%',
    overflow: 'hidden',
  },
  uploadText: {
    color: ({ active }) =>
      active ? theme.colors.white : transparentize(0.5, theme.colors.white),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  uploadButton: {
    height: ({ isBig }) => (isBig ? theme.spacing(4.25) : theme.spacing(2.5)),
    padding: `${theme.spacing(0.375)}px ${theme.spacing(0.75)}px`,
    margin: 0,
    minWidth: theme.spacing(4.75),
    borderRadius: theme.spacing(0.25),
    backgroundColor: theme.colors.divider,
    fontSize: theme.spacing(1),
    fontWeight: 700,
  },
  deleteButton: {
    minWidth: 'auto',
    width: theme.spacing(2.5),
    height: ({ isBig }) => (isBig ? theme.spacing(4.25) : theme.spacing(2.5)),
    margin: 0,
    marginLeft: theme.spacing(0.25),
    padding: 0,
    backgroundColor: theme.colors.divider,

    '& > .MuiButton-label': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));

import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',

    '& > .MuiFormControl-root': {
      marginBottom: theme.spacing(2.5),
    },

    '& > .MuiButtonBase-root': {
      width: '100%',
    },

    '& .MuiInputBase-input': {
      height: theme.spacing(4.75),
    },

    '& .MuiFormLabel-root': {
      fontSize: theme.spacing(2),
    },

    '& .MuiSvgIcon-root': {
      color: transparentize(0.5, theme.colors.white),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
}));

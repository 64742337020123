import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
  },
  contentWrap: {
    flex: '1 1 auto',
    height: '100%',
    width: `calc(100% - ${theme.spacing(24.875)}px)`,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  content: {
    display: 'flex',
    height: 'calc(100% - 48px)',

    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  loader: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: transparentize(0.4, theme.colors.black),
    zIndex: 10,
  },
}));

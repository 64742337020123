import * as Yup from 'yup';

export const validationSchema = (isDrowingMode) =>
  Yup.object().shape({
    title: Yup.string().trim().required('This field is required'),
    imageInfo: Yup.object()
      .shape({
        type: Yup.string().trim(),
        name: Yup.string().trim(),
        url: Yup.string().trim(),
        size: Yup.number(),
      })
      .nullable(true)
      .test('imageInfo', 'This field is required', (fieldData) => {
        if (isDrowingMode) {
          return true;
        }
        return !!(fieldData?.type || fieldData?.url || fieldData?.name);
      }),
  });

import React, { useCallback, useEffect, useMemo } from 'react';
import { MenuItem } from '@material-ui/core';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { HOT_SPOT_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import { useDebounce } from 'utils/hooks/useDebounce';
import { treeCompare } from 'utils/treeCompare';
import { pageListOrderSelector } from 'common/selectors/pages';
import { storyListEditItemSelector } from 'common/selectors/editStory';
import {
  addHotSpot as addHotSpotAction,
  removeHotSpotAction,
  updateHotSpot as updateHotSpotAction,
} from 'common/actions/hotSpotsListActions';
import { editItemAction, setEditAction } from 'common/actions/editStoryActions';
import { targetFramesListSelector } from 'common/selectors/frames';
import { storyListLanguageSelector } from 'common/selectors/storyList';
import SelectField from 'components/forms/SelectField';
import StyledCheckbox from 'components/forms/StyledCheckbox';
import StyledInput from 'components/forms/StyledInput';
import FormWrap from 'components/forms/FormWrap';
import ControlBarBlock from '../ControlBarBlock';
import ControlButton from '../ControlButton';
import {
  actionOnClickMenu,
  actionOnHoverMenu,
  defaultValues,
  mandatoryMenu,
  triggerMenu,
} from './HotspotControl.constants';
import { validationSchema } from './HotspotControl.validation';

const selector = createSelector(
  pageListOrderSelector,
  storyListEditItemSelector,
  targetFramesListSelector,
  storyListLanguageSelector,
  (pagesList, editHotspot, targetFramesList, currentLang) => ({
    pagesList,
    editHotspot,
    targetFramesList,
    currentLang,
  })
);

const HotspotControl = () => {
  const dispatch = useDispatch();
  const { pagesList, editHotspot, targetFramesList, currentLang } = useSelector(
    selector
  );

  const { control, handleSubmit, reset, formState, setValue } = useForm({
    defaultValues: {
      id: editHotspot?.id || '',
      name: editHotspot.name || defaultValues.name,
      triggerType: editHotspot.triggerType || defaultValues.triggerType,
      actionType: editHotspot.actionType || defaultValues.actionType,
      target: editHotspot.target || defaultValues.target,
      url: editHotspot.url || defaultValues.url,
      text: editHotspot.text || defaultValues.text,
      visible: editHotspot.visible || defaultValues.visible,
      mandatory: editHotspot.mandatory || defaultValues.mandatory,
      mandatoryType: editHotspot.mandatoryType || defaultValues.mandatoryType,
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({
    control,
  });

  const formFieldDebounced = useDebounce(formField, 300);

  const showTarget = useMemo(() => formField.actionType === 'navigate', [
    formField.actionType,
  ]);

  const isTriggerClick = useMemo(() => formField.triggerType === 'click', [
    formField.triggerType,
  ]);

  const showTooltipText = useMemo(() => formField.actionType === 'tool-tip', [
    formField.actionType,
  ]);

  const showPopupUrl = useMemo(() => formField.actionType === 'pop-up', [
    formField.actionType,
  ]);

  useEffect(() => {
    if (editHotspot && editHotspot?.id !== formField.id) {
      reset({
        id: editHotspot?.id || editHotspot?._id || '',
        name: editHotspot.name || defaultValues.name,
        triggerType: editHotspot.triggerType || defaultValues.triggerType,
        actionType: editHotspot.actionType || defaultValues.actionType,
        target: editHotspot.target || defaultValues.target,

        url: editHotspot.url || defaultValues.url,
        text: editHotspot.text || defaultValues.text,
        visible: editHotspot.visible || defaultValues.visible,
        mandatory: editHotspot.mandatory || defaultValues.mandatory,
        mandatoryType: editHotspot.mandatoryType || defaultValues.mandatoryType,
      });
    }
  }, [editHotspot, formField, reset]);

  // const currentPage = useMemo(
  //   () => pagesList.find((item) => item.id === formField.target.page),
  //   [formField, pagesList]
  // );

  useEffect(() => {
    if (formState.isDirty) {
      dispatch(setEditAction(true));
    }
  }, [dispatch, formState.isDirty]);

  const onSubmit = useCallback(
    (data) => {
      const hotspot = {
        ...editHotspot,
        ...data,
      };
      const { target, text, url, ...otherData } = hotspot;

      if (showTarget) {
        otherData.target = target;
      }

      if (showTooltipText) {
        otherData.text = text;
      }

      if (showPopupUrl) {
        otherData.url = url;
      }

      const uploadHotspot =
        otherData.actionType === 'navigate' || otherData.actionType === 'notes'
          ? otherData
          : { ...otherData, language: currentLang };

      if (editHotspot.id || editHotspot._id) {
        dispatch(
          updateHotSpotAction(
            {
              ...uploadHotspot,
              name: data.name.trim(),
            },
            UPDATE_TYPE.UPDATE
          )
        );
      } else {
        dispatch(
          addHotSpotAction(
            {
              ...uploadHotspot,
              name: data.name.trim(),
            },
            UPDATE_TYPE.STANDART
          )
        );
      }
    },
    [
      dispatch,
      editHotspot,
      showTarget,
      showTooltipText,
      showPopupUrl,
      currentLang,
    ]
  );

  const handleRemove = useCallback(() => {
    dispatch(removeHotSpotAction());
  }, [dispatch]);

  const handleUpdateChapter = useCallback(() => {
    const { id, _id, ...fieldsData } = formFieldDebounced;
    // the order of the elements must be the same like in formField
    const fieldsForCompare = {
      id: editHotspot?.id || editHotspot?._id || '',
      name: editHotspot.name || defaultValues.name,
      triggerType: editHotspot.triggerType || defaultValues.triggerType,
      actionType: editHotspot.actionType || defaultValues.actionType,
      target: editHotspot.target || defaultValues.target,
      url: editHotspot.url || defaultValues.url,
      text: editHotspot.text || defaultValues.text,
      visible: editHotspot.visible || defaultValues.visible,
      mandatory: editHotspot.mandatory || defaultValues.mandatory,
      mandatoryType: editHotspot.mandatoryType || defaultValues.mandatoryType,
    };
    if (!treeCompare(formFieldDebounced, fieldsForCompare)) {
      dispatch(setEditAction(true));
      dispatch(
        editItemAction({
          item: { ...editHotspot, ...fieldsData },
          type: HOT_SPOT_TYPE,
        })
      );
    }
  }, [dispatch, editHotspot, formFieldDebounced]);

  useEffect(() => {
    handleUpdateChapter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced]);

  const handleOnChangeText = useCallback(
    (name, value) => {
      setValue(name, value);
      dispatch(
        editItemAction({
          item: { ...editHotspot, [name]: value },
          type: HOT_SPOT_TYPE,
        })
      );
    },
    [setValue, dispatch, editHotspot]
  );

  return (
    <FormWrap id='hotspot-form' onSubmit={handleSubmit(onSubmit)}>
      <ControlBarBlock blockTitle='Details'>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              disableUnderline
              label='Name'
              placeholder='Hotspot name'
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='name'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <SelectField
              displayEmpty
              label='Trigger'
              error={error ? error.message : null}
              {...field}
              control={control}
            >
              <MenuItem value='' disabled>
                Select trigger...
              </MenuItem>
              {triggerMenu.map((item) => {
                const keys = Object.keys(item);
                return (
                  <MenuItem key={keys[0]} value={keys[0]}>
                    {item[keys[0]]}
                  </MenuItem>
                );
              })}
            </SelectField>
          )}
          name='triggerType'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <SelectField
              displayEmpty
              label='Action'
              error={error ? error.message : null}
              {...field}
              control={control}
            >
              <MenuItem value='' disabled>
                Select action...
              </MenuItem>
              {(isTriggerClick ? actionOnClickMenu : actionOnHoverMenu).map(
                (item) => {
                  const keys = Object.keys(item);
                  return (
                    <MenuItem key={keys[0]} value={keys[0]}>
                      {item[keys[0]]}
                    </MenuItem>
                  );
                }
              )}
            </SelectField>
          )}
          name='actionType'
          control={control}
        />
        {showTarget && isTriggerClick && (
          <>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <SelectField
                  displayEmpty
                  label='Target page'
                  error={error ? error.message : null}
                  {...field}
                  control={control}
                  onChange={(e) => {
                    setValue('target.frame', '');
                    setValue('target.page', e.target.value);
                  }}
                >
                  <MenuItem value='' disabled>
                    Select page...
                  </MenuItem>
                  {pagesList.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </SelectField>
              )}
              name='target.page'
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <SelectField
                  displayEmpty
                  label='Target frame'
                  error={error ? error.message : null}
                  {...field}
                  control={control}
                >
                  <MenuItem value='' disabled>
                    Select frame...
                  </MenuItem>
                  {targetFramesList
                    ?.filter(
                      (frame) => frame.originPage === formField.target.page
                    )
                    .map((item) => {
                      // ?
                      return (
                        <MenuItem
                          key={item.id || item._id}
                          value={item.id || item._id}
                        >
                          {item.title || item._id}
                        </MenuItem>
                      );
                    })}
                </SelectField>
              )}
              name='target.frame'
              control={control}
            />
          </>
        )}
        {showPopupUrl && isTriggerClick && (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                error={error ? error.message : null}
                placeholder='Popup url'
                disableUnderline
                label='URL'
                {...field}
                fullWidth
              />
            )}
            name='url'
            control={control}
          />
        )}

        {showTooltipText && !isTriggerClick && (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth
                disableUnderline
                label='Tooltip'
                placeholder='Tooltip text'
                error={error ? error.message : ''}
                onChangeValue={handleOnChangeText}
                inputValue={editHotspot.text}
                {...field}
              />
            )}
            name='text'
            control={control}
          />
        )}
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Advanced'>
        <Controller
          name='visible'
          control={control}
          render={({ field }) => (
            <StyledCheckbox
              label='Hotspot visible to user'
              onChange={(e) => field.onChange(e.target.checked)}
              checked={!!field.value}
            />
          )}
        />
        <Controller
          name='mandatory'
          control={control}
          render={({ field }) => (
            <StyledCheckbox
              label='Mandatory hotspot'
              onChange={(e) => field.onChange(e.target.checked)}
              checked={!!field.value}
            />
          )}
        />
        {formField.mandatory && (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <SelectField
                displayEmpty
                label='Require'
                error={error ? error.message : null}
                {...field}
                control={control}
              >
                <MenuItem value='' disabled>
                  Select frame...
                </MenuItem>
                {mandatoryMenu.map((item) => {
                  const keys = Object.keys(item);
                  return (
                    <MenuItem key={keys[0]} value={keys[0]}>
                      {item[keys[0]]}
                    </MenuItem>
                  );
                })}
              </SelectField>
            )}
            name='mandatoryType'
            control={control}
          />
        )}
      </ControlBarBlock>
      <ControlBarBlock>
        <ControlButton onClick={handleRemove} text='Delete Hotspot' />
      </ControlBarBlock>
    </FormWrap>
  );
};

export default HotspotControl;

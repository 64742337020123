import {
  COLS,
  GRID_WIDTH_LANDSCAPE,
  GRID_WIDTH_PORTRAIT,
} from 'pages/admin/StoryEdit/components/EditGridPage/EditGridPage.constants';

export const getPreviewLink = (storyId, isStudent, page) => {
  const clientURL = process.env.REACT_APP_CLIENT_URL;
  const pageParam = page ? `&page=${page}` : '';
  return isStudent
    ? `${clientURL}student_preview?id=${storyId}`
    : `${clientURL}preview?id=${storyId}${pageParam}`;
};

export const uploadProgress = (progressEvent, setProgress) =>
  setProgress(() =>
    Math.round((progressEvent.loaded * 100) / progressEvent.total)
  );

export const getImageSize = (width, height, isLandscape) => {
  const imageSize = {
    imageHeight: height,
    imageWidth: width,
  };
  const maxWidth = isLandscape
    ? GRID_WIDTH_LANDSCAPE / 1.6
    : GRID_WIDTH_PORTRAIT / 1.6;
  const coef = isLandscape ? 2 : 1;
  const maxHeight = COLS / 1.6;
  for (
    let i = 4;
    width > height
      ? imageSize.imageWidth >= maxWidth
      : imageSize.imageHeight >= maxHeight;
    // eslint-disable-next-line no-plusplus
    i++
  ) {
    imageSize.imageWidth = width / (coef * i);
    imageSize.imageHeight = height / i;
  }
  return imageSize;
};

export const newStoryListAfterRemove = (storyList, chaptersList, pageItem) => {
  const newStoryList = [...storyList];
  const chapterId = chaptersList.find(
    (chapter) => chapter.children.find((page) => page.id === pageItem.id) // finding the chapter ID where the page belongs to
  )?.id;
  if (chapterId) {
    const chapterIndex = storyList.findIndex(
      // find the chapter's index in the story list
      (item) => item.id === chapterId
    );
    const pageIndexes = chaptersList.map((chapter) =>
      chapter.children.findIndex(
        (page) => page.id === pageItem.id // array of indexes from chapters' children
      )
    );
    const pageIndex = pageIndexes.find((index) => index !== -1); // pick the index which is not -1
    if (pageIndex !== -1) {
      newStoryList[chapterIndex].children.splice(pageIndex, 1); // delete the page from the chapter
      if (!newStoryList[chapterIndex].children.length)
        return newStoryList[chapterIndex];
      return newStoryList;
    }
  }
  return newStoryList;
};

export const handlePageTitle = (data, originTitle = 'Frame') => {
  let pageIndex = 1;
  if (!data.length) return `${originTitle} ${pageIndex}`;

  const titleArray = data.filter((frame) =>
    frame.title?.startsWith(originTitle)
  );
  // eslint-disable-next-line no-extra-boolean-cast
  if (!!titleArray.length) {
    const number = titleArray.map((frame) => {
      const frameNumber = frame.title.split(' ')[1];
      if (frameNumber) return parseInt(frameNumber, 10);
      return 0;
    });
    if (number.length === 0) return `${originTitle} ${pageIndex}`;
    pageIndex = Math.max(...number);
    return `${originTitle} ${pageIndex + 1}`;
  }
  return `${originTitle} ${pageIndex}`;
};

export const arraysAreEqual = (arr1, arr2) => {
  return (
    Array.isArray(arr1) &&
    Array.isArray(arr2) &&
    arr1.length === arr2.length &&
    arr1.every((val, index) => val === arr2[index])
  );
};

// storing fixed items in objects to create for games
export const getGameItems = (originPage, originFrame, story) => [
  {
    politicalCards: [
      {
        backpackAttachmentInfo: {
          name: '800px-Flag_of_the_United_States_(1848–1851).svg.png',
          size: 24295,
          type: 'image',
          url:
            'https://storage.googleapis.com/download/storage/v1/b/digital-universe-files/o/upload%2F2021-07-23T09:28:51.976Z.png?generation=1627032532453479&alt=media',
        },
        binAttachmentInfo: {},
        coordinates: {
          x: 0,
          y: 0,
          w: 0,
          h: 0,
        },
        description: 'USA flag',
        frameAttachmentInfo: {
          name: '800px-Flag_of_the_United_States_(1848–1851).svg.png',
          size: 24295,
          type: 'image',
          url:
            'https://storage.googleapis.com/download/storage/v1/b/digital-universe-files/o/upload%2F2021-07-23T09:28:51.976Z.png?generation=1627032532453479&alt=media',
        },
        gltfAttachmentInfo: {},
        isGameItem: true,
        notes: [''],
        originFrame: `${originFrame}`,
        originPage: `${originPage}`,
        story: `${story}`,
        title: 'USA',
        type: 'Flag',
      },
      {
        backpackAttachmentInfo: {
          name: 'Flag_of_Brazil.svg.png',
          size: 57139,
          type: 'image',
          url:
            'https://storage.googleapis.com/download/storage/v1/b/digital-universe-files/o/upload%2F2021-07-23T09:29:18.722Z.png?generation=1627032559248452&alt=media',
        },
        binAttachmentInfo: {},
        coordinates: {
          x: 0,
          y: 0,
          w: 0,
          h: 0,
        },
        description: 'Brazil flag',
        frameAttachmentInfo: {
          name: 'Flag_of_Brazil.svg.png',
          size: 57139,
          type: 'image',
          url:
            'https://storage.googleapis.com/download/storage/v1/b/digital-universe-files/o/upload%2F2021-07-23T09:29:18.722Z.png?generation=1627032559248452&alt=media',
        },
        gltfAttachmentInfo: {},
        isGameItem: true,
        notes: [''],
        originFrame: `${originFrame}`,
        originPage: `${originPage}`,
        story: `${story}`,
        title: 'Brazil',
        type: 'Flag',
      },
      {
        backpackAttachmentInfo: {
          name: 'The Ivory Coast.jpg',
          size: 14895,
          type: 'image',
          url:
            'https://storage.googleapis.com/download/storage/v1/b/digital-universe-files/o/upload%2F2021-07-23T11:10:53.290Z.jpg?generation=1627038653784212&alt=media',
        },
        binAttachmentInfo: {},
        coordinates: {
          x: 0,
          y: 0,
          w: 0,
          h: 0,
        },
        description: 'Elfensbenkysten flag',
        frameAttachmentInfo: {
          name: 'The Ivory Coast.jpg',
          size: 14895,
          type: 'image',
          url:
            'https://storage.googleapis.com/download/storage/v1/b/digital-universe-files/o/upload%2F2021-07-23T11:10:53.290Z.jpg?generation=1627038653784212&alt=media',
        },
        gltfAttachmentInfo: {},
        isGameItem: true,
        notes: [''],
        originFrame: `${originFrame}`,
        originPage: `${originPage}`,
        story: `${story}`,
        title: 'Elfenbenskysten',
        type: 'Flag',
      },
    ],
  },
];

export const capitalizeFirstLetter = (input = '') => {
  const result = input.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const removeFileExtension = (fileName = '') => {
  return fileName.replace(/\.[^/.]+$/, '');
};

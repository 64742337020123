import React from 'react';
import { Box, IconButton, Toolbar, Tooltip } from '@material-ui/core';
import useMobile from 'utils/hooks/useMobile';
import FrameMenu from 'components/Header/FrameMenu/FrameMenu';
import LayerMenu from 'components/Header/LayerMenu/LayerMenu';
import { AddHotspotIcon } from 'components/Icons/AddHotspotIcon';
import { AddDecisionIcon } from 'components/Icons/AddDecisionIcon';
import { CheckpointHeaderIcon } from 'components/Icons/CheckpointHeaderIcon';
import useStyles from '../../styles';
import { StoryTitleBlock } from '../StoryTitleBlock/index';

export const MobileHeaderContent = ({
  isEdit,
  actionButtons,
  selectedFrame,
  couldAddFrame,
  handleAddLayer,
  handleAddNewFrame,
  handleAddNewHotSpot,
  handleAddNewDecision,
  handleAddNewCheckpoint,
}) => {
  const { isSmallDesktop } = useMobile();

  const classes = useStyles({ isSmallDesktop, isEdit });

  return (
    <Toolbar className={classes.toolbar}>
      <Box className={classes.logoWrap}>
        <StoryTitleBlock />
        {couldAddFrame && <FrameMenu onAddFrame={handleAddNewFrame} />}
      </Box>
      {couldAddFrame && (
        <Box display='inline-flex' className={classes.menuIcons}>
          {selectedFrame?.attachmentInfo?.type === 'image' &&
            selectedFrame?.id && (
              <>
                <LayerMenu onAddLayer={handleAddLayer} />
                <Tooltip title='Add Hotspot' placement='bottom'>
                  <IconButton onClick={handleAddNewHotSpot}>
                    <AddHotspotIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Add Decision' placement='bottom'>
                  <IconButton onClick={handleAddNewDecision}>
                    <AddDecisionIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Checkpoint' placement='bottom'>
                  <IconButton onClick={handleAddNewCheckpoint}>
                    <CheckpointHeaderIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
        </Box>
      )}
      <Box className={classes.mobileButtonsWrap}>{actionButtons}</Box>
    </Toolbar>
  );
};

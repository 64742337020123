import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(2.375)}px`,
    fontWeight: 700,

    '& > svg': {
      marginRight: theme.spacing(0.75),
    },
  },
}));

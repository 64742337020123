import React from 'react';
import classNames from 'classnames';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

export const CloseIcon = ({ fill = 'white', className, ...props }) => {
  return (
    <CloseOutlinedIcon
      style={{
        fill,
        width: 18,
        height: 18,
        stroke: '#fff',
        strokeWidth: '2',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};

/* <svg width='12' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.27 6l3.42-3.41c.41-.42.41-1.1 0-1.52l-.76-.76a1.07 1.07 0 00-1.52 0L6 3.73 2.59.3a1.07 1.07 0 00-1.52 0l-.76.76c-.41.42-.41 1.1 0 1.52L3.73 6 .3 9.41c-.41.42-.41 1.1 0 1.52l.76.76c.42.41 1.1.41 1.52 0L6 8.27l3.41 3.42c.42.41 1.1.41 1.52 0l.76-.76c.41-.42.41-1.1 0-1.52L8.27 6z'
        fill='#fff'
      />
    </svg> */

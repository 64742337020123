import React from 'react';
import classNames from 'classnames';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

export const PageIcon = ({
  fillOpacity = 0.5,
  fill = 'white',
  className,
  ...props
}) => {
  return (
    <InsertDriveFileIcon
      style={{ fill, fillOpacity, width: 13, height: 13 }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};

/* <svg
  width='12'
  height='10'
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
>
  <path
    d='M5.75 3.19V0H1.06A.56.56 0 00.5.56v10.88c0 .3.25.56.56.56h7.88c.3 0 .56-.25.56-.56V3.75H6.31a.56.56 0 01-.56-.56zm3.75-.33V3h-3V0h.14c.15 0 .3.06.4.16l2.3 2.3c.1.1.16.25.16.4z'
    fill='#fff'
    fillOpacity={fillOpacity}
  />
</svg> */

import React from 'react';
import classnames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';

export const AddDecisionIcon = ({ className, ...props }) => {
  return (
    <SvgIcon
      viewBox='0 0 17 17'
      style={{ width: 17, height: 17, fill: 'none' }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classnames({
        [className]: !!className,
      })}
      {...props}
    >
      <path
        opacity='.8'
        d='M15.14 7.14L8.86.86a1.21 1.21 0 00-1.72 0L.86 7.14a1.21 1.21 0 000 1.72l6.28 6.28c.48.48 1.24.48 1.72 0l6.28-6.28c.48-.48.48-1.24 0-1.72zM7.9 14.18L1.82 8.1a.15.15 0 010-.22L7.9 1.82a.15.15 0 01.22 0l6.07 6.07c.06.06.06.16 0 .22L8.1 14.18a.15.15 0 01-.22 0z'
        fill='#fff'
      />
      <path
        d='M6.87 10.26V5.74h1.36c.4 0 .74.09 1.05.27.31.18.55.43.72.76.18.33.26.7.26 1.13v.2c0 .43-.08.8-.25 1.13-.17.33-.41.58-.72.76-.3.18-.66.27-1.05.27H6.87zm.91-3.77v3.02h.45c.35 0 .63-.12.82-.35.18-.24.28-.58.29-1.03V7.9c0-.46-.1-.8-.29-1.04-.18-.24-.46-.36-.82-.36h-.45z'
        fill='#fff'
      />
    </SvgIcon>
  );
};

/* <svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='.8'
        d='M15.14 7.14L8.86.86a1.21 1.21 0 00-1.72 0L.86 7.14a1.21 1.21 0 000 1.72l6.28 6.28c.48.48 1.24.48 1.72 0l6.28-6.28c.48-.48.48-1.24 0-1.72zM7.9 14.18L1.82 8.1a.15.15 0 010-.22L7.9 1.82a.15.15 0 01.22 0l6.07 6.07c.06.06.06.16 0 .22L8.1 14.18a.15.15 0 01-.22 0z'
        fill='#fff'
      />
      <path
        d='M6.87 10.26V5.74h1.36c.4 0 .74.09 1.05.27.31.18.55.43.72.76.18.33.26.7.26 1.13v.2c0 .43-.08.8-.25 1.13-.17.33-.41.58-.72.76-.3.18-.66.27-1.05.27H6.87zm.91-3.77v3.02h.45c.35 0 .63-.12.82-.35.18-.24.28-.58.29-1.03V7.9c0-.46-.1-.8-.29-1.04-.18-.24-.46-.36-.82-.36h-.45z'
        fill='#fff'
      />
    </svg> */

import { GRID_HEIGHT } from './EditGridPage.constants';

export const generateLayoutFromFrames = (frames = []) => {
  return frames.map((frame) => ({
    i: frame.id || frame.fid,
    x: frame.x,
    y: frame.y,
    w: frame.w,
    h: frame.h,
    maxH: GRID_HEIGHT,
  }));
};

export const framesChanged = (frame1, frame2) => {
  return (
    Math.round(frame1.x) !== Math.round(frame2.x) ||
    Math.round(frame1.y) !== Math.round(frame2.y) ||
    Math.round(frame1.h) !== Math.round(frame2.h) ||
    Math.round(frame1.w) !== Math.round(frame2.w)
  );
};

// get distance btw side of frame2 to frame1's center point
const minDistanceToSide = (A, B, E) => {
  // vector AB
  const AB = [];
  AB.push(B[0] - A[0]);
  AB.push(B[1] - A[1]);

  // vector BP
  const BE = [];
  BE.push(E[0] - B[0]);
  BE.push(E[1] - B[1]);

  // vector AP
  const AE = [];
  AE.push(E[0] - A[0]);
  AE.push(E[1] - A[1]);

  // Variables to store dot product

  // Calculating the dot product
  const AB_BE = AB[0] * BE[0] + AB[1] * BE[1];
  const AB_AE = AB[0] * AE[0] + AB[1] * AE[1];

  // Minimum distance from
  // point E to the line segment
  let reqAns = 0;

  // Case 1
  if (AB_BE > 0) {
    // Finding the magnitude
    const y = E[1] - B[1];
    const x = E[0] - B[0];
    reqAns = Math.sqrt(x * x + y * y);
  }

  // Case 2
  else if (AB_AE < 0) {
    const y = E[1] - A[1];
    const x = E[0] - A[0];
    reqAns = Math.sqrt(x * x + y * y);
  }

  // Case 3
  else {
    // Finding the perpendicular distance
    const x1 = AB[0];
    const y1 = AB[1];
    const x2 = AE[0];
    const y2 = AE[1];
    const mod = Math.sqrt(x1 * x1 + y1 * y1);
    reqAns = Math.abs(x1 * y2 - y1 * x2) / mod;
  }
  return reqAns;
};

// get the smallest distance between 2 frames by comparing frame1's center point with frame2's each side
const minDistance = (fr1, fr2, direction) => {
  const xc = fr1.x + fr1.w / 2; // center of frame 1 x axis
  const yc = fr1.y + fr1.h / 2; // center of frame 1 y axis

  const { x, y, w, h } = fr2;

  const fr2points = [
    [x, y],
    [x + w, y],
    [x + w, y + h],
    [x, y + h],
  ];

  const d1 = minDistanceToSide(fr2points[0], fr2points[1], [xc, fr1.y + fr1.h]); // min distance from bottom center of frame1 to top side of frame2
  if (direction === 'S') return d1;
  const d2 = minDistanceToSide(fr2points[1], fr2points[2], [fr1.x, yc]); // min distance from left center of frame1 to right side of frame2
  if (direction === 'W') return d2;
  const d3 = minDistanceToSide(fr2points[3], fr2points[2], [xc, fr1.y]); // min distance from top center of frame1 to bottom side of frame2
  if (direction === 'N') return d3;
  const d4 = minDistanceToSide(fr2points[0], fr2points[3], [fr1.x + fr1.w, yc]); // min distance from right center of frame1 to left side of frame2
  if (direction === 'E') return d4;

  return Math.min(d1, d2, d3, d4);
};

const calculateSnap = (frame1, frame2) => {
  const x1 = frame1.x + frame1.w / 2;
  const y1 = frame1.y + frame1.h / 2;
  const x2 = frame2.x + frame2.w / 2;
  const y2 = frame2.y + frame2.h / 2;

  const dx = Math.abs(x1 - x2);
  const dy = Math.abs(y1 - y2);
  let direction = 'none';
  let distance = -1;

  // S - south, N - north, E - east, W - west
  // closest edge is the other frame's top or bottom edge (depends on y1 - y2)
  if (dx < (frame1.w + frame2.w) / 2 && dy >= (frame1.h + frame2.h) / 2) {
    distance = dy - (frame1.h + frame2.h) / 2;
    if (y1 - y2 < 0) direction = 'S';
    else direction = 'N';
  }

  // closest edge is the other frame's left or right edge (depends on x1 - x2)
  if (dx >= (frame1.w + frame2.w) / 2 && dy < (frame1.h + frame2.h) / 2) {
    distance = dx - (frame1.w + frame2.w) / 2;
    if (x1 - x2 < 0) direction = 'E';
    else direction = 'W';
  }

  // closest is the other frame's corner (depends on x1 - x2 and y1 - y2)
  if (dx >= (frame1.w + frame2.w) / 2 && dy >= (frame1.h + frame2.h) / 2) {
    const deltaX = dx - (frame1.w + frame2.w) / 2;
    const deltaY = dy - (frame1.h + frame2.h) / 2;
    distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    if (x1 - x2 < 0 && y1 - y2 < 0) direction = 'SE';
    if (x1 - x2 > 0 && y1 - y2 < 0) direction = 'SW';
    if (x1 - x2 > 0 && y1 - y2 > 0) direction = 'NW';
    if (x1 - x2 < 0 && y1 - y2 > 0) direction = 'NE';
  }
  if (distance !== -1 && distance <= 5) return [direction, frame2];
  return [null, null];
};

export const snapFrames = (frame, layout) => {
  if (layout.length <= 1) return [null, null];
  const other = layout.filter((item) => item.i !== frame.i);

  const minDistanceFrame =
    other.length === 1
      ? other[0]
      : other.reduce((a, b) => {
          const oldDistance = minDistance(frame, a);
          const newDistance = minDistance(frame, b);
          return oldDistance < newDistance ? a : b;
        });

  return calculateSnap(frame, minDistanceFrame);
};

export const getSnapCoords = (direction, layoutItem, snapTo) => {
  switch (direction) {
    case 'S': {
      return { x: layoutItem.x, y: snapTo.y - layoutItem.h };
    }
    case 'N': {
      return { x: layoutItem.x, y: snapTo.y + snapTo.h };
    }
    case 'E': {
      return { x: snapTo.x - layoutItem.w, y: layoutItem.y };
    }
    case 'W': {
      return { x: snapTo.x + snapTo.w, y: layoutItem.y };
    }
    case 'SE': {
      return {
        x: snapTo.x - layoutItem.w,
        y: snapTo.y - layoutItem.h,
      };
    }
    case 'SW': {
      return { x: snapTo.x + snapTo.w, y: snapTo.y - layoutItem.h };
    }
    case 'NW': {
      return { x: snapTo.x + snapTo.w, y: snapTo.y + snapTo.h };
    }
    case 'NE': {
      return { x: snapTo.x - layoutItem.w, y: snapTo.y + snapTo.h };
    }
    default:
      return { x: layoutItem.x, y: layoutItem.y };
  }
};

const filterByAxis = ({ frames, selectedFrame, axis, greater }) => {
  return frames.filter(
    (o) =>
      (selectedFrame.id
        ? selectedFrame.id !== (o.id || o.fid)
        : selectedFrame.fid !== (o.id || o.fid)) &&
      (greater ? o[axis] > selectedFrame[axis] : o[axis] < selectedFrame[axis])
  );
};

export const getClosestFrame = (
  currentFrames,
  direction,
  selectedEditFrame
) => {
  let otherFrames = [];
  let closestFrame = null;
  let smallestByAxis = null;

  if (direction === 'N') {
    otherFrames = filterByAxis({
      frames: currentFrames,
      selectedFrame: selectedEditFrame,
      axis: 'y',
      greater: false,
    });
    smallestByAxis = currentFrames.reduce((a, b) => (a.y > b.y ? a : b));
  }

  if (direction === 'S') {
    otherFrames = filterByAxis({
      frames: currentFrames,
      selectedFrame: selectedEditFrame,
      axis: 'y',
      greater: true,
    });
    smallestByAxis = currentFrames.reduce((a, b) => (a.y < b.y ? a : b));
  }

  if (direction === 'E') {
    otherFrames = filterByAxis({
      frames: currentFrames,
      selectedFrame: selectedEditFrame,
      axis: 'x',
      greater: true,
    });
    smallestByAxis = currentFrames.reduce((a, b) => (a.x < b.x ? a : b));
  }

  if (direction === 'W') {
    otherFrames = filterByAxis({
      frames: currentFrames,
      selectedFrame: selectedEditFrame,
      axis: 'x',
      greater: false,
    });
    smallestByAxis = currentFrames.reduce((a, b) => (a.x > b.x ? a : b));
  }

  if (otherFrames.length)
    closestFrame = otherFrames.reduce((a, b) => {
      const oldDistance = minDistance(selectedEditFrame, a, direction);
      const newDistance = minDistance(selectedEditFrame, b, direction);
      return oldDistance < newDistance ? a : b;
    });
  else closestFrame = smallestByAxis;

  return closestFrame;
};

export const loadAllImageSizes = async (sources) => {
  return Promise.all(
    sources.map(({ original }) => {
      const { name } = original;
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = original.url;
        img.onload = (e) => {
          const { width, height } = e.target;
          resolve({ width, height, name });
        };
        img.onerror = () => reject();
      });
    })
  );
};

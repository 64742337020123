const formater = (item, framesList) => {
  if (item.children) {
    return item.children.reduce((acc, currentItem) => {
      if (currentItem.children) {
        return [
          ...acc,
          {
            type: currentItem.type,
            id: currentItem?.id || currentItem?._id,
            children: formater(currentItem.children || currentItem.frames),
          },
        ];
      }

      if (framesList[currentItem.id]) {
        return [
          ...acc,
          {
            type: currentItem.type,
            id: currentItem?.id || currentItem?._id,
            children: (framesList[currentItem.id] || []).map(
              (frame) => frame.id
            ),
          },
        ];
      }

      return [
        ...acc,
        {
          type: currentItem.type,
          id: currentItem?.id || currentItem?._id,
          children: [],
        },
      ];
    }, []);
  }
  return framesList.map((frame) => frame.id);
};

export const formatListForService = (list) => {
  if (list.length) {
    return list.reduce(
      (acc, item) => [
        ...acc,
        { type: item.type, id: item?.id, children: formater(item, list) },
      ],
      []
    );
  }
  return [];
};

import React from 'react';
import classnames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';

export const LayerIcon = ({ className, ...props }) => {
  return (
    <SvgIcon
      viewBox='0 0 16 16'
      style={{ width: 16, height: 16, fill: 'none' }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classnames({
        [className]: !!className,
      })}
      {...props}
    >
      <path
        d='M15.4 11.08L12.91 10l2.5-1.08a.75.75 0 00-.6-1.38L8 10.5 2.26 8l5.33-2.31a.75.75 0 00-.6-1.38L.6 7.08A1 1 0 000 8c0 .4.24.76.6.92L3.09 10l-2.5 1.08A1 1 0 000 12c0 .4.24.76.6.92l6.82 2.96a1.45 1.45 0 001.16 0l6.82-2.96A1 1 0 0016 12a1 1 0 00-.6-.92zM8.02 14.5L2.25 12l2.72-1.18 2.45 1.06a1.45 1.45 0 001.16 0l2.45-1.06L13.76 12l-5.74 2.5zM15.5 2.75h-2.25V.5a.5.5 0 00-.5-.5h-.5a.5.5 0 00-.5.5v2.25H9.5a.5.5 0 00-.5.5v.5c0 .28.22.5.5.5h2.25V6.5c0 .28.22.5.5.5h.5a.5.5 0 00.5-.5V4.25h2.25a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5z'
        fill='#fff'
      />
    </SvgIcon>
  );
};

/* <svg
        width='16'
        height='16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15.4 11.08L12.91 10l2.5-1.08a.75.75 0 00-.6-1.38L8 10.5 2.26 8l5.33-2.31a.75.75 0 00-.6-1.38L.6 7.08A1 1 0 000 8c0 .4.24.76.6.92L3.09 10l-2.5 1.08A1 1 0 000 12c0 .4.24.76.6.92l6.82 2.96a1.45 1.45 0 001.16 0l6.82-2.96A1 1 0 0016 12a1 1 0 00-.6-.92zM8.02 14.5L2.25 12l2.72-1.18 2.45 1.06a1.45 1.45 0 001.16 0l2.45-1.06L13.76 12l-5.74 2.5zM15.5 2.75h-2.25V.5a.5.5 0 00-.5-.5h-.5a.5.5 0 00-.5.5v2.25H9.5a.5.5 0 00-.5.5v.5c0 .28.22.5.5.5h2.25V6.5c0 .28.22.5.5.5h.5a.5.5 0 00.5-.5V4.25h2.25a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5z'
          fill='#fff'
        />
      </svg> */

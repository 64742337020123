import React from 'react';
import classNames from 'classnames';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';

export const AddFrameIcon = ({ fill = 'white', className, ...props }) => {
  return (
    <AddBoxOutlinedIcon
      style={{ fill, width: 20, height: 20 }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};

/* <svg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.4 0H1.6C.73 0 0 .72 0 1.6v11.8c0 .88.72 1.6 1.6 1.6h11.8c.88 0 1.6-.72 1.6-1.6V1.6c0-.88-.72-1.6-1.6-1.6zm-.2 13.4H1.8a.2.2 0 01-.2-.2V1.8c0-.1.1-.2.2-.2h11.4c.1 0 .2.1.2.2v11.4a.2.2 0 01-.2.2z'
        fill='#fff'
      />
      <path
        d='M8.02 7.07h2.27v.98H8.02v2.56H7V8.05H4.72v-.98H7V4.7h1.03v2.37z'
        fill='#fff'
      />
    </svg> */

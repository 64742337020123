import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object().shape({
    isOverlay: Yup.boolean(),
    zIndex: Yup.number()
      .typeError('Must be a number')
      .positive('Index must positive'),
    title: Yup.string().trim().required('This field is required'),
    imageInfo: Yup.object()
      .shape({
        type: Yup.string().trim(),
        name: Yup.string().trim(),
        url: Yup.string().trim(),
        size: Yup.number(),
      })
      .nullable(true)
      .test(
        'imageInfo',
        'This field is required',
        (fieldData) => !!(fieldData?.type || fieldData?.url || fieldData?.name)
      ),
  });

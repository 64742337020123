import React from 'react';
import classnames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';

export const AddHotspotIcon = ({ className, ...props }) => {
  return (
    <SvgIcon
      viewBox='0 0 16 16'
      style={{ width: 16, height: 16, fill: 'none' }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classnames({
        [className]: !!className,
      })}
      {...props}
    >
      <rect
        x='.5'
        y='.5'
        width='15'
        height='15'
        rx='1.5'
        stroke='#fff'
        strokeDasharray='2 2'
      />
      <path
        d='M11 12H9.65V8.52h-3.5V12H4.8V4.18h1.36v3.25h3.5V4.18H11V12z'
        fill='#fff'
      />
    </SvgIcon>
  );
};

/* <svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='.5'
        y='.5'
        width='15'
        height='15'
        rx='1.5'
        stroke='#fff'
        strokeDasharray='2 2'
      />
      <path
        d='M11 12H9.65V8.52h-3.5V12H4.8V4.18h1.36v3.25h3.5V4.18H11V12z'
        fill='#fff'
      />
    </svg> */

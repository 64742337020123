import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '18px',
    width: '100%',
    padding: '10px 9px',
    justifyContent: 'space-between',

    '@media (min-width: 1350px)': {
      gap: '20px',
    },

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      padding: '72px 9px 10px',
      gap: '10px',
    },

    [theme.breakpoints.between('sm', 'md')]: {
      flexWrap: 'wrap',
      marginBottom: '7px',
      padding: '10px 9px',
    },

    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
  },
  actionContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    justifyContent: 'space-between',

    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },

    '& .MuiInputBase-input': {
      maxWidth: '147px',
    },

    '@media (min-width: 1250px)': {
      gap: '20px',
    },

    '@media (min-width: 1350px)': {
      gap: '20px',

      '& .MuiInputBase-input': {
        maxWidth: '175px',
      },
    },
    '@media (min-width: 1550px)': {
      '& .MuiInputBase-input': {
        maxWidth: '100%',
      },
    },
  },

  mainAddBtn: {
    minWidth: '106px',
  },

  title: {
    fontSize: theme.spacing(4),

    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: theme.spacing(3),
    },
  },
}));

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useStyles from './styles';

const GridHotspot = ({ width, height, left, top }) => {
  const classes = useStyles({ width, height, left, top });

  return <Box className={classes.wrap} />;
};

GridHotspot.defaultProps = {
  top: '',
  left: '',
  width: '',
  height: '',
};

GridHotspot.propTypes = {
  top: PropTypes.string,
  left: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default GridHotspot;

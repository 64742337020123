import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import LockIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { Avatar, Box, Button } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch } from 'react-redux';
import { ROUTES } from 'constants/index';
import { signUpAction } from 'common/actions/auth';
import StyledInput from 'components/forms/StyledInput';
import AuthWrap from '../AuthWrap';
import { validationSchema } from './SignUp.validation';
import useStyles from './styles';

const SignUp = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(validationSchema()),
  });

  const handleShowPassword = useCallback(
    () => setShowPassword((prevState) => !prevState),
    []
  );

  const handleRedirectSignIn = useCallback(() => history.push(ROUTES.HOME), [
    history,
  ]);

  const onSubmit = useCallback(
    (data) => {
      dispatch(signUpAction(data));
    },
    [dispatch]
  );

  return (
    <AuthWrap>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign up
        </Typography>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              disableUnderline
              label='Email address'
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='email'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              disableUnderline
              label='Password'
              type={showPassword ? 'text' : 'password'}
              error={error ? error.message : ''}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='Toggle password visibility'
                    onClick={handleShowPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              {...field}
            />
          )}
          name='email'
          control={control}
        />
        <Button type='submit' color='primary' variant='contained'>
          Sign up
        </Button>
        <Box>
          <Button size='small' onClick={handleRedirectSignIn}>
            Already have an account?
          </Button>
        </Box>
      </form>
    </AuthWrap>
  );
};

export default SignUp;

import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@material-ui/core';
import useStyles from './styles';

const TabsWrapper = ({ children, justifyContent, ...props }) => {
  const { flexContainer } = useStyles({ justifyContent });

  return (
    <Tabs {...props} classes={{ flexContainer }}>
      {children}
    </Tabs>
  );
};

TabsWrapper.defaultProps = {
  justifyContent: 'space-between',
};

TabsWrapper.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  justifyContent: PropTypes.oneOf([
    'flex-end',
    'flex-start',
    'space-around',
    'space-between',
    'center',
  ]),
};

export default TabsWrapper;

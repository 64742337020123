import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import GridOnIcon from '@material-ui/icons/GridOn';
import SnapIcon from '@mui/icons-material/AutoAwesomeMosaic';
import { Box, Button, IconButton, Toolbar, Tooltip } from '@material-ui/core';
import { ROUTES } from 'constants/index';
import colors from 'constants/colors';
import useMobile from 'utils/hooks/useMobile';
import {
  setShowGridLinesAction,
  setSnapToFramesAction,
} from 'common/actions/editStoryActions';
import { RedoArrow } from 'components/Icons/RedoArrow';
import { UndoArrow } from 'components/Icons/UndoArrow';
import FrameMenu from 'components/Header/FrameMenu/FrameMenu';
import StyledCheckbox from 'components/forms/StyledCheckbox/StyledCheckbox';
import LayerMenu from 'components/Header/LayerMenu/LayerMenu';
import { AddHotspotIcon } from 'components/Icons/AddHotspotIcon';
import { AddDecisionIcon } from 'components/Icons/AddDecisionIcon';
import { CheckpointHeaderIcon } from 'components/Icons/CheckpointHeaderIcon';
import { PreviewIcon } from 'components/Icons/PreviewIcon';
import useStyles from '../../styles';

export const DesktopHeaderContent = ({
  isEdit,
  title,
  couldAddFrame,
  handleAddNewFrame,
  isEdited,
  handleUndo,
  undoHistory,
  handleRedo,
  redoHistory,
  showGridLines,
  currentStory,
  snapFrames,
  selectedFrame,
  handleAddLayer,
  handleAddNewHotSpot,
  handleAddNewDecision,
  handleAddNewCheckpoint,
  toggleStoryDraft,
  story,
  previewStory,
  actionButtons,
  onStoryExit,
}) => {
  const { isSmallDesktop } = useMobile();
  const dispatch = useDispatch();

  const classes = useStyles({ isSmallDesktop, isEdit });

  return (
    <Toolbar className={classes.toolbar}>
      <Box className={classes.logoWrap}>
        <Link to={ROUTES.HOME} className={classes.logo} onClick={onStoryExit}>
          {title}
        </Link>
        {couldAddFrame && <FrameMenu onAddFrame={handleAddNewFrame} />}

        {isEdit && !isEdited && (
          <Box className={classes.historyArrows}>
            <Tooltip title='Undo' placement='bottom'>
              <span>
                <IconButton
                  onClick={handleUndo}
                  disabled={!undoHistory?.length}
                  className={classes.arrow}
                  style={{ transform: 'rotate(-25deg)' }}
                >
                  <UndoArrow />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Redo' placement='bottom'>
              <span>
                <IconButton
                  onClick={handleRedo}
                  disabled={!redoHistory?.length}
                  className={classes.arrow}
                  style={{ transform: 'rotate(25deg)', padding: '8px' }}
                >
                  <RedoArrow />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        )}
        {isEdit && (
          <>
            <Tooltip title='Grid Lines' placement='bottom'>
              <IconButton
                onClick={() => dispatch(setShowGridLinesAction(!showGridLines))}
              >
                <GridOnIcon
                  style={{
                    fill: showGridLines
                      ? 'rgba(255, 255, 255, 0.86)'
                      : colors.grey,
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title='Snap Frames' placement='bottom'>
              <IconButton
                onClick={() => dispatch(setSnapToFramesAction(!snapFrames))}
              >
                <SnapIcon
                  style={{
                    fill: snapFrames
                      ? 'rgba(255, 255, 255, 0.86)'
                      : colors.grey,
                  }}
                />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
      {couldAddFrame && (
        <Box display='inline-flex'>
          {selectedFrame?.attachmentInfo?.type === 'image' &&
            selectedFrame?.id && (
              <>
                <LayerMenu onAddLayer={handleAddLayer} />
                <Tooltip title='Add Hotspot' placement='bottom'>
                  <IconButton onClick={handleAddNewHotSpot}>
                    <AddHotspotIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Add Decision' placement='bottom'>
                  <IconButton onClick={handleAddNewDecision}>
                    <AddDecisionIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Checkpoint' placement='bottom'>
                  <IconButton onClick={handleAddNewCheckpoint}>
                    <CheckpointHeaderIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
        </Box>
      )}
      <Box className={classes.buttonsWrap}>
        {story && isEdit && (
          <>
            <StyledCheckbox
              label='Save as Draft'
              name='draft'
              onChange={toggleStoryDraft}
              checked={!!story?.draft}
            />
          </>
        )}
        {(currentStory?.id || story?.id) && (
          <Button
            className={classes.preview}
            disableElevation
            disableFocusRipple
            disableRipple
            onClick={previewStory}
            startIcon={<PreviewIcon />}
          >
            Preview
          </Button>
        )}
        {actionButtons}
      </Box>
    </Toolbar>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { setUserAction, signOutAction } from 'common/actions/auth';
import { getCurrentUser as getCurrentUserService } from 'services/auth';
import PublicRoutes from './PublicRoutes';
import ProtectedRoutes from './ProtectedRoutes';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    width: '100%',
    height: '100vh',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
  },
}));

const Routes = () => {
  const classes = useStyles();

  const [isFetched, setIsFetched] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const isAuthorized = useMemo(() => !!user, [user]);

  const fetchCurrentUser = useCallback(() => {
    getCurrentUserService()
      .then((currentUser) => {
        dispatch(setUserAction(currentUser));
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          setIsFetched(false);
          dispatch(signOutAction());
        }
      });
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthorized) {
      fetchCurrentUser();
    } else {
      setIsFetched(false);
    }
  }, [fetchCurrentUser, isAuthorized]);

  const routes = useMemo(() => {
    if (isFetched) {
      return (
        <Box className={classes.loader}>
          <CircularProgress className='loading' />
        </Box>
      );
    }

    if (isAuthorized) {
      return <ProtectedRoutes />;
    }

    return <PublicRoutes />;
  }, [isFetched, isAuthorized, classes.loader]);

  return <Box className={classes.root}>{routes}</Box>;
};

export default Routes;

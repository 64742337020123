import React from 'react';
import {
  InputAdornment,
  IconButton,
  Input,
  FormControl,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './styles';

const StyledSearchInput = ({ state, setSearchTerm }) => {
  const { searchIcon, inputControll } = useStyles();

  const handleSearchTermChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };
  return (
    <FormControl className={inputControll}>
      <Input
        name='search'
        value={state}
        placeholder='Search'
        onChange={handleSearchTermChange}
        disableUnderline
        startAdornment={
          <InputAdornment>
            <IconButton>
              <SearchIcon
                style={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: 19 }}
              />
            </IconButton>
          </InputAdornment>
        }
        className={searchIcon}
      />
    </FormControl>
  );
};

export default StyledSearchInput;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import useStyles from './styles';

const StyledCheckbox = forwardRef(
  ({ onChange, color, checked, label, disabled, ...props }, ref) => {
    const classes = useStyles();

    return (
      <FormControlLabel
        className={classes.label}
        control={
          <Checkbox
            disabled={disabled}
            innerRef={ref}
            color={color}
            onChange={onChange}
            checked={checked}
            icon={<span className={classes.icon} />}
            checkedIcon={<span className={classes.checkedIcon} />}
            {...props}
          />
        }
        label={label}
      />
    );
  }
);
StyledCheckbox.displayName = 'StyledCheckbox';

StyledCheckbox.defaultProps = {
  color: 'primary',
  label: '',
  disabled: false,
};

StyledCheckbox.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  color: PropTypes.string,
};

export default StyledCheckbox;

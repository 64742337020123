import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    borderTop: `${theme.spacing(0.125)}px solid ${theme.colors.divider}`,
    padding: ({ padding }) => padding || theme.spacing(2),
    margin: ({ margin }) => margin || '0',
    position: ({ position }) => position,
    overflow: 'hidden',
  },
  title: {
    paddingBottom: ({ noPaddingTitle }) =>
      noPaddingTitle ? '0' : theme.spacing(1),
    lineHeight: `${theme.spacing(1.5)}px`,
    fontSize: theme.spacing(1.25),
    fontWeight: 500,
  },
}));

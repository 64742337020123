import axios from 'axios';

const getStoriesList = (debouncedSearchTerm) => {
  const params = {
    limit: 100,
    page: 1,
    sort: 'updatedAt',
    search: debouncedSearchTerm,
  };
  return axios.get('/stories/cms', { params }).then((res) => res.data);
};

const getStoryTree = (id) =>
  axios.get(`/stories/tree/${id}`).then((res) => res.data);

const updateStoryTreePosition = (body) =>
  axios.post('/stories/list', body).then((res) => res.data);

const getStory = (id) =>
  axios.get(`/stories/${id}`).then((res) => res.data?.story);

const createStory = (body) =>
  axios.post('/stories', body).then((res) => {
    return res.data;
  });

const updateStory = (body) =>
  axios.put(`/stories/${body.id}`, body).then((res) => {
    return res;
  });

const deleteStory = (id) => axios.delete(`/stories/${id}`);

const duplicateStory = (id) =>
  axios.put(`/stories/${id}/duplicate`).then((res) => res.data);

const copyStoryToEnvService = (body) =>
  axios.post('/scripts', body).then((res) => res.data);

export {
  updateStoryTreePosition,
  getStoriesList,
  getStory,
  createStory,
  updateStory,
  deleteStory,
  getStoryTree,
  duplicateStory,
  copyStoryToEnvService,
};

export default {
  getStoriesList,
  getStoryTree,
  getStory,
  createStory,
  updateStory,
  deleteStory,
  duplicateStory,
  copyStoryToEnvService,
};

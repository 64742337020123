import { FRAME_TYPE, PAGE_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import { getGameItems } from 'utils/helpers/helpers';
import {
  storyListItemsSelector,
  storyListSelectedIdsSelector,
} from 'common/selectors/editStory';
import {
  CREATE_FRAME,
  CREATE_FRAME_MULTIPLE,
  DELETE_FRAME,
  FETCHED_FRAMES,
  FETCHED_TARGET_FRAMES,
  LOADING_FRAMES,
  UPDATE_CREATED_FRAMES,
  UPDATE_CREATED_MULTIPLE_FRAMES,
  UPDATE_FRAME,
  UPDATE_FRAME_LIST,
} from 'common/types/framesList';
import { storyItemsSelector } from 'common/selectors/story';
import { pagesListSelector } from 'common/selectors/pages';
import {
  createFrameService,
  createMultipleFramesService,
  deleteFrameService,
  getPageFramesService,
  updateFrameService,
  updateFramesListService,
} from 'services/frames';
import { errorToast, successToast } from 'services/toast';
import { duplicatePage } from 'services/pages';
import {
  addGameItemAction,
  editItemAction,
  fetchHistoryListAction,
  setEditAction,
  setLockFramesFetch,
  setSelectedAction,
  setWarningAction,
  setWarningCheckpointsAction,
  setWarningDecisionsAction,
  setWarningHotspotsAction,
  updateListPositionSuccessAction,
} from './editStoryActions';
import { fetchPagesAction } from './pagesListActions';
import { fetchLayersListAction } from './layersListAction';
import { fetchBackpackListAction } from './backpackListAction';
import { fetchHotSpotsAction } from './hotSpotsListActions';
import { fetchDecisionsAction } from './decisionsListActions';
import { fetchCheckpointListAction } from './checkpointListAction';

export const fetchedFramesAction = (payload) => ({
  type: FETCHED_FRAMES,
  payload,
});

export const fetchedTargetFramesAction = (payload) => ({
  type: FETCHED_TARGET_FRAMES,
  payload,
});

export const loadingFramesAction = (payload) => ({
  type: LOADING_FRAMES,
  payload,
});

export const createFrameSuccessAction = (payload) => ({
  type: CREATE_FRAME,
  payload,
});

export const createMultipleFramesSuccessAction = (payload) => ({
  type: CREATE_FRAME_MULTIPLE,
  payload,
});

export const updateFrameSuccessAction = (payload) => ({
  type: UPDATE_FRAME,
  payload,
});

export const updateFrameListSuccessAction = (payload) => ({
  type: UPDATE_FRAME_LIST,
  payload,
});

export const updateCreatedFrames = (payload) => ({
  type: UPDATE_CREATED_FRAMES,
  payload,
});

export const updateCreatedMultipleFrames = (payload) => ({
  type: UPDATE_CREATED_MULTIPLE_FRAMES,
  payload,
});

export const removedFrameSuccessAction = (payload) => ({
  type: DELETE_FRAME,
  payload,
});

export const fetchFramesAction = (pageId) => {
  return (dispatch) => {
    dispatch(loadingFramesAction(true));
    getPageFramesService({ originPage: pageId })
      .then((res) => {
        dispatch(loadingFramesAction(false));
        dispatch(fetchedFramesAction(res.data.rows));
      })
      .catch((error) => {
        dispatch(loadingFramesAction(false));
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const fetchTargetFramesAction = (storyId) => {
  return (dispatch) => {
    getPageFramesService({ story: storyId })
      .then((res) => {
        dispatch(fetchedTargetFramesAction(res.data.rows));
      })
      .catch((error) => {
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const addNewFrameAction = (
  data,
  type = UPDATE_TYPE.STANDART,
  isCardGame,
  gameName
) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadingFramesAction(true));
    const selectedIds = storyListSelectedIdsSelector(state);

    createFrameService(data, type)
      .then((res) => {
        dispatch(setEditAction(false));
        dispatch(createFrameSuccessAction(res));
        dispatch(updateCreatedFrames(res));
        dispatch(
          setSelectedAction({
            ...selectedIds,
            page: res.originPage,
            frame: res.id || '',
            frameItem: '',
          })
        );
        dispatch(editItemAction({ item: res, type: FRAME_TYPE }));
        if (isCardGame) {
          getGameItems(res.originPage, res.id, res.story)[0][gameName].map(
            (item) => {
              return dispatch(addGameItemAction(item));
            }
          );
        }
        dispatch(loadingFramesAction(false));
        dispatch(fetchHistoryListAction(data.story));
        if (data.fid) {
          dispatch(removedFrameSuccessAction(data));
        }
        successToast('Frame creation successful');
      })
      .catch((error) => {
        dispatch(loadingFramesAction(false));
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const createMultipleFramesAction = (frames) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadingFramesAction(true));
    const selectedIds = storyListSelectedIdsSelector(state);
    createMultipleFramesService({ frames })
      .then((res) => {
        dispatch(setEditAction(false));
        dispatch(createMultipleFramesSuccessAction(res));
        dispatch(
          setSelectedAction({
            ...selectedIds,
            frame: '',
            frameItem: '',
          })
        );
        dispatch(updateCreatedMultipleFrames(res));
        dispatch(loadingFramesAction(false));
        successToast('Frames created successfully!');
      })
      .catch((error) => {
        dispatch(loadingFramesAction(false));
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const updateFrameAction = (
  data,
  updateType = UPDATE_TYPE.STANDART,
  isCardGame,
  gameName
) => {
  return (dispatch) => {
    dispatch(loadingFramesAction(true));
    updateFrameService(data, updateType)
      .then((res) => {
        if (isCardGame) {
          getGameItems(res.originPage, res.id, res.story)[0][gameName].map(
            (item) => {
              return dispatch(addGameItemAction(item));
            }
          );
        }
        dispatch(loadingFramesAction(false));
        dispatch(setEditAction(false));
        dispatch(fetchHistoryListAction(res.story));
        dispatch(updateFrameSuccessAction(res));
        successToast('Frame updated successfully');
      })
      .catch((error) => {
        dispatch(loadingFramesAction(false));
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const updateFramesListAction = (frameIds, frames) => {
  return (dispatch, getState) => {
    const state = getState();
    const { id } = storyItemsSelector(state);

    updateFramesListService({ story: id, ids: frameIds })
      .then(() => {
        dispatch(loadingFramesAction(false));
        dispatch(updateFrameListSuccessAction(frames));
      })
      .catch((error) => {
        dispatch(loadingFramesAction(false));
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const deleteFrameAction = (frame, type = UPDATE_TYPE.STANDART) => {
  return (dispatch, getState) => {
    const state = getState();
    const pagesList = pagesListSelector(state);
    if (!frame.id) {
      dispatch(removedFrameSuccessAction(frame));
      dispatch(
        setSelectedAction({
          chapter: '',
          page: frame.originPage,
          frame: '',
          frameItem: '',
        })
      );
      dispatch(
        editItemAction({
          item: pagesList.find((page) => page.id === frame.originPage),
          type: PAGE_TYPE,
        })
      );
      return;
    }

    dispatch(loadingFramesAction(true));
    deleteFrameService(frame.id, type)
      .then(() => {
        dispatch(
          setSelectedAction({
            chapter: '',
            page: frame.originPage,
            frame: '',
            frameItem: '',
          })
        );
        dispatch(
          editItemAction({
            item: pagesList.find((page) => page.id === frame.originPage),
            type: PAGE_TYPE,
          })
        );
        dispatch(removedFrameSuccessAction(frame));
        dispatch(loadingFramesAction(false));
        dispatch(fetchHistoryListAction(frame.story));
        successToast('Frame deleted successfully');
      })
      .catch((error) => {
        dispatch(loadingFramesAction(false));
        if (error?.response?.data) {
          dispatch(setWarningAction(true));
          dispatch(setWarningHotspotsAction(error.response.data.hotspots));
          dispatch(setWarningDecisionsAction(error.response.data.decisions));
          dispatch(
            setWarningCheckpointsAction(error.response.data.checkpoints)
          );
        } else errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const duplicatePageAction = (
  pageId,
  callBack,
  type = UPDATE_TYPE.STANDART
) => {
  return (dispatch, getState) => {
    const state = getState();
    const list = storyListItemsSelector(state);
    const { id } = storyItemsSelector(state);
    dispatch(setLockFramesFetch(true));
    duplicatePage(pageId, type)
      .then((res) => {
        // dispatch(fetchHistoryListAction(id));
        dispatch(
          setSelectedAction({
            chapter: '',
            page: res.duplicate.id,
            frame: '',
            frameItem: '',
          })
        );
        dispatch(
          editItemAction({
            item: res.duplicate,
            type: PAGE_TYPE,
          })
        );
        dispatch(setEditAction(false));
        dispatch(updateListPositionSuccessAction([...list, res.duplicate]));
        dispatch(fetchLayersListAction(id));
        dispatch(fetchBackpackListAction(id));
        dispatch(fetchHotSpotsAction(id));
        dispatch(fetchDecisionsAction(id));
        dispatch(fetchCheckpointListAction(id));
        dispatch(fetchFramesAction(res.duplicate.id));
        dispatch(fetchPagesAction(id));
        dispatch(fetchHistoryListAction(id));
        if (callBack) {
          callBack(res.duplicate);
        }
        successToast('Page duplication success');
        dispatch(setLockFramesFetch(false));
      })
      .catch((error) => {
        dispatch(setLockFramesFetch(false));
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

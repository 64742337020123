import { createSelector } from 'reselect';
import { CHAPTER_TYPE } from 'constants/editTypes';
import { framesListSelector } from './frames';

export const storyListItemsSelector = (state) => [...state.editStory.list];
export const storyListSelectedIdsSelector = (state) => state.editStory.selected;
export const storyListOnSaveSelector = (state) => state.editStory.onSave;

export const storyListLoadingSelector = (state) => state.editStory.loading;
export const lockFramesFetchSelector = (state) =>
  state.editStory.lockFramesFetch;

export const storyListEditTypeSelector = (state) => state.editStory.editType;
export const storyListEditItemSelector = (state) => state.editStory.editedItem;
export const storyListEditStatusSelector = (state) => state.editStory.edited;
export const storyListConfirmOpenSelector = (state) =>
  state.editStory.confirmOpen;

export const storyListNextItemSelector = (state) =>
  state.editStory.nextEditItem;

export const storyListNextSelectedSelector = (state) =>
  state.editStory.nextSelected;

export const storyListWarningStatusSelector = (state) =>
  state.editStory.warning;
export const storyListWarningHotspotsSelector = (state) =>
  state.editStory.warningHotspots;
export const storyListWarningDecisionsSelector = (state) =>
  state.editStory.warningDecisions;
export const storyListWarningCheckpointsSelector = (state) =>
  state.editStory.warningCheckpoints;
export const storyListEditHistorySelector = (state) => state.editStory.history;
export const frameAnimationSelector = (state) => state.editStory.frameAnimation;
export const storyListEditSettingsSelector = (state) =>
  state.editStory.settingsOpen;
export const storyListSelectTabSelector = (state) =>
  state.editStory.storySelectedTab;

export const storyListShowGridLinesSelector = (state) =>
  state.editStory.showGridLines;

export const storyListSnapFramesSelector = (state) =>
  state.editStory.snapFramesEnabled;

export const storyListSelectedChapterSelector = createSelector(
  storyListItemsSelector,
  storyListSelectedIdsSelector,
  (list, selectedIds) => {
    if (selectedIds?.chapter) {
      return list.find((item) => item.id === selectedIds.chapter);
    }
    return null;
  }
);

export const storyListAllChaptersSelector = createSelector(
  storyListItemsSelector,
  (list) => {
    return list.filter((item) => item.type === CHAPTER_TYPE);
  }
);

export const storyListSelectedPageSelector = createSelector(
  storyListItemsSelector,
  storyListSelectedIdsSelector,
  storyListSelectedChapterSelector,
  (list, selectedIds, selectedChapter) => {
    if (selectedIds?.page) {
      if (!selectedChapter) {
        return list.find((item) => item.id === selectedIds.page);
      }
      return selectedChapter.children?.find(
        (item) => item.id === selectedIds.page
      );
    }

    return null;
  }
);

export const storyListSelectedEditFrame = createSelector(
  storyListSelectedIdsSelector,
  framesListSelector,
  (selectedIds, framesList) => {
    if (selectedIds?.frame) {
      return framesList[selectedIds.page]?.find(
        (frame) =>
          frame?.id === selectedIds.frame || frame?.fid === selectedIds.frame
      );
    }

    return null;
  }
);

export const storyListSelectedFrameIndexSelector = createSelector(
  storyListSelectedIdsSelector,
  storyListSelectedPageSelector,
  framesListSelector,
  (selectedIds, selectedPage, framesList) => {
    if (selectedIds?.frame) {
      if (!selectedPage) {
        return null;
      }
      return framesList[selectedPage.id]?.findIndex(
        (frame) => (frame.id || frame?.fid) === selectedIds.frame
      );
    }

    return null;
  }
);

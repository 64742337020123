import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import DialogWrapper from 'components/DialogWrapper';
import useStyles from './styles';

const SaveDialog = ({ onClose, isOpen, warningText, warningTitle }) => {
  const classes = useStyles();

  return (
    <DialogWrapper open={!!isOpen} onClose={onClose}>
      <DialogTitle disableTypography>
        <Box component='h3' fontSize='1rem' fontWeight='fontWeightMedium' m={0}>
          {warningTitle}
        </Box>
      </DialogTitle>
      <DialogContent
        style={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
        }}
      >
        {warningText}
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button variant='contained' color='primary' fullWidth onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};

SaveDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SaveDialog;

import React from 'react';
import { Box } from '@material-ui/core';
import soundIcon from 'assets/icons/sound.svg';
import Image from 'components/Image';
import useStyles from './styles';

const GridAudio = () => {
  const classes = useStyles();
  return (
    <Box className={classes.wrap}>
      <Image src={soundIcon} alt='sound' />
    </Box>
  );
};

export default GridAudio;

const PREFIX = '@@EDIT_STORY';

export const FETCH_LIST_SUCCESS = `${PREFIX}/FETCH_LIST_SUCCESS`;
export const FETCH_LIST_ERROR = `${PREFIX}/FETCH_LIST_ERROR`;
export const FETCHED_HISTORY = `${PREFIX}/FETCHED_HISTORY`;
export const UPDATE_LIST_POSITION_SUCCESS = `${PREFIX}/UPDATE_LIST_POSITION_SUCCESS`;
export const UPDATE_LIST_POSITION_ERROR = `${PREFIX}/UPDATE_LIST_POSITION_ERROR`;
export const SET_SELECTED = `${PREFIX}/SET_SELECTED`;
export const NEXT_SET_SELECTED = `${PREFIX}/SET_NEXT_SET_SELECTEDSELECTED`;
export const START_LOADING = `${PREFIX}/START_LOADING`;
export const END_LOADING = `${PREFIX}/END_LOADING`;
export const EDIT_ITEM = `${PREFIX}/EDIT_ITEM`;
export const SET_EDIT = `${PREFIX}/SET_EDIT`;
export const SET_WARNING = `${PREFIX}/SET_WARNING`;
export const CLEAR_EDIT_ITEM = `${PREFIX}/CLEAR_EDIT_ITEM`;
export const SET_SETTINGS = `${PREFIX}/SET_SETTINGS`;
export const SET_SELECTED_TAB = `${PREFIX}/SET_SELECTED_TAB`;
export const SET_FRAME_ANIMATIOIN = `${PREFIX}/SET_FRAME_ANIMATION`;
export const SET_WARNING_HOTSPOTS = `${PREFIX}/SET_WARNING_HOTSPOTS`;
export const SET_WARNING_DECISIONS = `${PREFIX}/SET_WARNING_DECISIONS`;
export const SET_WARNING_CHECKPOINTS = `${PREFIX}/SET_WARNING_CHECKPOINTS`;
export const SET_LOCK_FRAMES_FETCH = `${PREFIX}/SET_LOCK_FRAMES_FETCH`;
export const SET_SHOW_GRID_LINES = `${PREFIX}/SET_SHOW_GRID_LINES`;
export const CONFIRM_OPEN = `${PREFIX}/CONFIRM_OPEN`;
export const NEXT_EDIT_ITEM = `${PREFIX}/NEXT_EDIT_ITEM`;
export const SET_SNAP_TO_FRAMES = `${PREFIX}/SET_SNAP_TO_FRAMES`;

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  picker: {
    position: 'absolute',
    right: theme.spacing(27),
    zIndex: 1,
  },
  '@media(max-height: 870px)': {
    picker: {
      bottom: 10,
    },
  },
}));

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Box } from '@material-ui/core';
import { useKeyboardEvents } from 'utils/hooks/useKeyboardEvents';
import {
  storyListEditItemSelector,
  storyListEditTypeSelector,
} from 'common/selectors/editStory';
import { editItemAction, setEditAction } from 'common/actions/editStoryActions';
import {
  controlSpeechCoords,
  controlTaleCoords,
  defaultFCoords,
} from './Speechbubble.constants';
import useStyles from './styles';

const selector = createSelector(
  storyListEditItemSelector,
  storyListEditTypeSelector,
  (editItem, editType) => ({
    editItem,
    editType,
  })
);

const DraggableComponent = ({ position, layer, saveForm, ...props }) => {
  const ref = useRef();
  const [pressed, setPressed] = useState(false);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.top = `${position.y - 2}%`;
      ref.current.style.left = `${position.x - 2}%`;
    }
  }, [position]);

  const onMouseMove = (event) => {
    if (pressed) {
      props.onDrag(event, props.i);
    }
  };

  const handleMouseUp = () => {
    setPressed(false);

    saveForm();
  };

  return (
    <div
      onMouseDown={() => setPressed(true)}
      onMouseUp={handleMouseUp}
      onMouseMove={onMouseMove}
      style={{
        top: `${position.y - 2}%`,
        left: `${position.x - 2}%`,
      }}
      {...props}
      ref={ref}
    />
  );
};

const Speechbubble = () => {
  const { editItem, editType } = useSelector(selector);
  const { horisontal, vertical } = editItem?.speechbubble?.taleDirection;
  const { borderColor, backgroundColor } = editItem?.speechbubble;

  const dispatch = useDispatch();
  const { isPressedAlt, isPressedCtrl } = useKeyboardEvents();

  const taleControls = useMemo(() => {
    return controlTaleCoords[`${horisontal}-${vertical}`];
  }, [horisontal, vertical]);

  const speechControls = useMemo(() => {
    return controlSpeechCoords[`${horisontal}-${vertical}`];
  }, [horisontal, vertical]);

  const [fCoords, setFCoords] = useState(
    editItem?.speechbubble?.formCoords ||
      defaultFCoords[`${horisontal}-${vertical}`]
  );

  const setNewSpeechForm = useCallback(
    (formCoords) => {
      dispatch(
        editItemAction({
          item: {
            ...editItem,
            speechbubble: {
              ...editItem.speechbubble,
              formCoords: formCoords || fCoords,
            },
          },
          type: editType,
        })
      );
      dispatch(setEditAction(true));
    },
    [dispatch, editItem, editType, fCoords]
  );

  useEffect(() => {
    setFCoords(defaultFCoords[`${horisontal}-${vertical}`]);
    setNewSpeechForm(defaultFCoords[`${horisontal}-${vertical}`]);
    // eslint-disable-next-line
  }, [horisontal, vertical]);

  const classes = useStyles({
    backgroundColor: backgroundColor || '#fff',
    borderColor: borderColor || '#000',
    textStyle: editItem.font,
    speechControls,
    taleControls,
    vertical,
    fCoords,
  });

  const handleFormChange = useCallback(
    (e, i) => {
      e.preventDefault();
      const parent = e.target.parentElement;
      const rect = parent.getBoundingClientRect();

      const pixelX = e.clientX - rect.left;
      const pixelY = e.clientY - rect.top;

      let percentX = (pixelX / parent.offsetWidth) * 100;
      let percentY = (pixelY / parent.offsetHeight) * 100;

      if (percentX > 100) percentX = 100;
      if (percentX < 0) percentX = 0;
      if (percentY > 100) percentY = 100;
      if (percentY < 0) percentY = 0;

      const newCoords = [...fCoords];
      const yDiff = newCoords[i].y - percentY;

      if (isPressedCtrl) {
        taleControls.forEach((item, idx) => {
          let changedY = newCoords[item].y - yDiff;
          if (changedY > 100) changedY = 100;
          if (changedY < 0) changedY = 0;
          if (!idx && vertical === 'top') changedY = 0;
          if (!idx && vertical === 'bottom') changedY = 100;

          newCoords[item] = {
            x: newCoords[item].x,
            y: changedY,
          };
          newCoords[i] = { x: newCoords[i].x, y: percentY };
        });

        speechControls.forEach((item) => {
          newCoords[item] = { x: newCoords[item].x, y: percentY };
        });
      }

      if (isPressedAlt) {
        if (horisontal !== 'center') {
          if (taleControls.indexOf(i) === 0) {
            newCoords[i] = { x: percentX, y: percentY };
          } else {
            newCoords[i] = { x: percentX, y: newCoords[i].y };
          }
        }
      }

      setFCoords(newCoords);
    },
    [
      speechControls,
      isPressedCtrl,
      isPressedAlt,
      taleControls,
      horisontal,
      vertical,
      fCoords,
    ]
  );

  const clipPath = useMemo(() => {
    return `polygon(${fCoords
      ?.map((coords) => `${coords.x}% ${coords.y}%`)
      .join(', ')})`;
  }, [fCoords]);

  return (
    <>
      <Box className={classes.pathBackground} style={{ clipPath }}>
        <div className={classes.speechTextContainer}>
          <Box
            className={`${classes.layerText} ${classes.speechBubbleText}`}
            style={{
              fontSize: `${editItem.font.fontSize}pt`,
              fontFamily: `${editItem.font.fontStyle}`,
            }}
            draggable='false'
            component='p'
          >
            {editItem?.content}
          </Box>
        </div>
      </Box>
      {(isPressedAlt || isPressedCtrl) && (
        <Box className={classes.formHandlesBlock}>
          {(isPressedAlt ? taleControls : speechControls).map((i) => (
            <DraggableComponent
              saveForm={setNewSpeechForm}
              onDrag={handleFormChange}
              position={fCoords[i]}
              layer={editItem}
              key={i}
              i={i}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default Speechbubble;

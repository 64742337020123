import axios from 'axios';
import { io } from 'socket.io-client';

export const viewSocket = () => {
  return io(`${process.env.REACT_APP_BACKEND_ENDPOINT_URL}story`, {
    transportOptions: {
      polling: {
        extraHeaders: {
          authorization: axios.defaults.headers.common.Authorization,
        },
      },
    },
  });
};

import React, { useCallback, useMemo, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import {
  BACKPACK_TYPE,
  CHAPTER_TYPE,
  CHECKPOINT_TYPE,
  DECISION_TYPE,
  FRAME_ITEM_TYPE,
  FRAME_TYPE,
  HOT_SPOT_TYPE,
  IMAGE_LAYER_TYPE,
  IMAGE_VIDEO_TYPE,
  PAGE_TYPE,
  SPEECHBUBBLE_LAYER_TYPE,
  TEXT_LAYER_TYPE,
} from 'constants/editTypes';
import { getDefaultChapter } from 'constants/defaultItems';
import { formatListForService } from 'utils/formatTreeForService';
import { useDebounceFunction } from 'utils/hooks/useDebounceFunction';
import { useKeyboardEvents } from 'utils/hooks/useKeyboardEvents';
import { treeCompare } from 'utils/treeCompare';
import { arraysAreEqual } from 'utils/helpers/helpers';
import {
  deleteChapterAction,
  deletePageAction,
  editItemAction,
  setSelectedAction,
  updateChapterListAction,
  updateListPositionAction,
  updateServerPositionAction,
} from 'common/actions/editStoryActions';
import {
  storyListItemsSelector,
  storyListSelectedIdsSelector,
} from 'common/selectors/editStory';
import { mappedHotSpotsObject } from 'common/selectors/hotSpots';
import { mappedDecisionObject } from 'common/selectors/decisions';
import { mappedLayersObject } from 'common/selectors/layers';
import { mappedBackpackObject } from 'common/selectors/backpack';
import { mappedCheckpointObject } from 'common/selectors/checkpoint';
import { framesListSelector } from 'common/selectors/frames';
import {
  deleteFrameAction,
  updateFramesListAction,
} from 'common/actions/framesListActions';
import {
  removeLayerAction,
  updateLayersListAction,
} from 'common/actions/layersListAction';
import { removeHotSpotAction } from 'common/actions/hotSpotsListActions';
import { handleResetSpeechbubbleToDefault } from 'common/actions/speechbubble';
import { errorToast } from 'services/toast';
import CollapseWrap from '../CollapseWrap';
import ListItemTitle from '../ListItemTitle';
import { sortableOptions } from './StoryList.constants';

const selector = createSelector(
  storyListItemsSelector,
  storyListSelectedIdsSelector,
  framesListSelector,
  mappedHotSpotsObject,
  mappedDecisionObject,
  mappedLayersObject,
  mappedBackpackObject,
  mappedCheckpointObject,
  (
    pagesList,
    selectedIds,
    framesList,
    hotSpotsObject,
    decisionObject,
    layersObject,
    backpackObject,
    checkpointObject
  ) => ({
    pagesList,
    selectedIds,
    framesList,
    hotSpotsObject,
    decisionObject,
    layersObject,
    backpackObject,
    checkpointObject,
  })
);

const StoryList = () => {
  const [openCollapse, setOpenCollapse] = useState([]);
  const [checkedPage, setCheckedPage] = useState([]);
  const [checkedFrame, setCheckedFrame] = useState([]);

  const {
    pagesList,
    selectedIds,
    framesList,
    hotSpotsObject,
    decisionObject,
    layersObject,
    backpackObject,
    checkpointObject,
  } = useSelector(selector);

  const { id: storyId } = useParams();
  const dispatch = useDispatch();

  const { isPressedShift } = useKeyboardEvents();

  const handleUpdateServerPosition = useCallback(
    (list) => {
      const ids = list.map((item) => item.id);

      dispatch(
        updateServerPositionAction({
          story: storyId,
          ids, // : formatListForService(list, framesList),
        })
      );
    },
    [dispatch, storyId]
  );

  const handleUpdateServerPositionDebounced = useDebounceFunction(
    (list) => handleUpdateServerPosition(list),
    500
  );

  const memoizedPageList = useMemo(() => {
    // ReactSortable update list=[pageList] by link
    // should memoize prev version
    const pageListString = JSON.stringify(pagesList);
    return [...JSON.parse(pageListString)];
  }, [pagesList]);

  const handleUpdatePosition = useCallback(
    (list) => {
      if (
        !treeCompare(
          formatListForService(memoizedPageList, framesList),
          formatListForService(list, framesList)
        )
      ) {
        dispatch(updateListPositionAction(list));
        handleUpdateServerPositionDebounced(list);
      }
    },
    [
      dispatch,
      handleUpdateServerPositionDebounced,
      memoizedPageList,
      framesList,
    ]
  );

  // update pages (children) positions inside chapters
  const handleUpdateChapterPosition = useCallback(
    (newList, oldList) => {
      const newListIds = newList.map((page) => page.id);
      const oldListIds = oldList.map((page) => page.id);
      if (!arraysAreEqual(newListIds, oldListIds)) {
        dispatch(updateChapterListAction(newListIds));
      }
    },
    [dispatch]
  );

  const handleUpdateFramesPosition = useCallback(
    (newList, oldList) => {
      const newListIds = newList.map((frame) => frame.id);
      const oldListIds = oldList.map((frame) => frame.id);

      if (!arraysAreEqual(newListIds, oldListIds)) {
        if (newListIds.findIndex((id) => id === undefined) === -1)
          dispatch(updateFramesListAction(newListIds, newList));
        else errorToast('Save the frame before reordering!');
      }
    },
    [dispatch]
  );

  const handleUpdateSpeechPosition = useCallback(
    (newList, oldList) => {
      const newListIds = newList.map((bubble) => bubble.id);
      const oldListIds = oldList.map((bubble) => bubble.id);
      if (!arraysAreEqual(newListIds, oldListIds)) {
        dispatch(updateLayersListAction(newListIds, newList));
      }
    },
    [dispatch]
  );

  const handleSelectTreeItem = useCallback(
    (items) => {
      const selected = {
        chapter: '',
        page: '',
        frame: '',
        frameItem: '',
      };

      if (items[1]?.type === FRAME_TYPE && isPressedShift) {
        setCheckedFrame([...checkedFrame, items[1].id]);
        dispatch(setSelectedAction({ ...selectedIds, frame: '' }));
      } else if (items[0].type === PAGE_TYPE && isPressedShift) {
        // multiselect only for pages
        if (!checkedPage.some((pageId) => items[0].id === pageId)) {
          // set not similar pages
          setCheckedPage([
            ...checkedPage,
            { id: items[0].id, order: items[0].order },
          ]);
        }
        dispatch(setSelectedAction({ ...selectedIds, page: '' }));
      } else {
        items.forEach((item) => {
          if (item.type === CHAPTER_TYPE) {
            selected.chapter = item.id;
          }

          if (item.type === PAGE_TYPE) {
            selected.page = item.id;
            setCheckedPage([{ id: item.id, order: item.order }]);
          } else {
            setCheckedPage([]);
          }

          if (item.type === FRAME_TYPE || !item.type) {
            selected.frame = item.id || item.fid || item._id;
            setCheckedFrame([item.id]);
          } else setCheckedFrame([]);
          if (
            item.type === IMAGE_LAYER_TYPE ||
            item.type === TEXT_LAYER_TYPE ||
            item.type === IMAGE_VIDEO_TYPE ||
            item.type === FRAME_ITEM_TYPE ||
            item.type === BACKPACK_TYPE
          ) {
            selected.frameItem = item.id;
          }
        });
        dispatch(setSelectedAction(selected));
      }
    },
    [dispatch, isPressedShift, checkedPage, checkedFrame, selectedIds]
  );

  const handleFrameSelect = useCallback(
    (items) => {
      const [firstItem, ...otherItems] = items;
      if (firstItem.id === otherItems[0].id) {
        handleSelectTreeItem(otherItems);
      } else {
        handleSelectTreeItem(items);
      }
    },
    [handleSelectTreeItem]
  );

  const handleEditItem = useCallback(
    (item, type) => {
      if (type !== 'layer_speechbubble') {
        dispatch(handleResetSpeechbubbleToDefault());
      }
      if (type) {
        return dispatch(
          editItemAction({
            item,
            type,
          })
        );
      }

      return null;
    },
    [dispatch]
  );

  const collapseIsOpen = useCallback((id) => openCollapse.indexOf(id) !== -1, [
    openCollapse,
  ]);

  const activeItem = useCallback(
    (item) => {
      if (item.type === PAGE_TYPE || item.type === FRAME_TYPE) {
        if (selectedIds[item.type]) {
          return (
            selectedIds[item.type] === item.id ||
            selectedIds[item.type] === item.fid
          );
        }

        if (checkedPage.length) {
          return checkedPage.map((page) => page.id).indexOf(item.id) !== -1;
        }
        if (checkedFrame.length) {
          return checkedFrame.indexOf(item.id) !== -1;
        }
      }
      return selectedIds[item.type] === (item.id || item?.fid || item?._id);
    },
    [selectedIds, checkedPage, checkedFrame]
  );

  const handleOnDelete = useCallback(
    (item) => {
      switch (item.type) {
        case 'page': {
          // get sorter checked pages array with ids only.
          const sorterCheckedPages = checkedPage
            .sort((a, b) => a.order - b.order)
            .map((page) => page.id);

          dispatch(
            deletePageAction(checkedPage.length ? sorterCheckedPages : item.id)
          );
          setCheckedPage([]);
          return null;
        }
        case 'chapter': {
          dispatch(deleteChapterAction(item));
          return null;
        }
        default:
          return null;
      }
    },
    [dispatch, checkedPage]
  );

  const handleOnExpand = useCallback(
    (id) => {
      const newCollapseArray = [...openCollapse];
      const currentIndex = openCollapse.indexOf(id);

      if (currentIndex !== -1) {
        newCollapseArray.splice(currentIndex, 1);
      } else {
        newCollapseArray.push(id);
      }

      setOpenCollapse(newCollapseArray);
    },
    [openCollapse]
  );

  const handleCreateChapter = useCallback(() => {
    // get sorter checked pages array with ids only.
    const sorterCheckedPages = checkedPage
      .sort((a, b) => a.order - b.order)
      .map((page) => page.id);

    dispatch(
      editItemAction({
        item: getDefaultChapter(storyId, sorterCheckedPages),
        type: CHAPTER_TYPE,
      })
    );
    setCheckedPage([]);
  }, [dispatch, storyId, checkedPage]);

  const handleOnFrameDelete = useCallback(
    (items) => {
      // eslint-disable-next-line no-unused-vars
      const [firstItem, ...otherItems] = items;

      const page = otherItems[0];
      const frame = otherItems[1];
      const index = framesList[page.id].findIndex(
        (frames) => frames.id === frame.id
      );
      if (index !== -1) dispatch(deleteFrameAction(frame));
    },
    [dispatch, framesList]
  );

  const handleDeleteHotspot = useCallback(
    (hotspot) => {
      dispatch(removeHotSpotAction(hotspot));
    },
    [dispatch]
  );

  const handleDeleteLayer = useCallback(
    (layer) => {
      dispatch(removeLayerAction(layer));
    },
    [dispatch]
  );

  const handleOnChangePosition = useCallback(
    (currentList, blockIndex) => {
      const tempList = [...pagesList];
      const _blockIndex = [...blockIndex];

      const lastIndex = _blockIndex.pop();
      const lastArr = _blockIndex.reduce((arr, i) => arr[i].children, tempList);

      if (lastArr[lastIndex].type === 'chapter') {
        handleUpdateChapterPosition(currentList, lastArr[lastIndex].children);
        lastArr[lastIndex].children = currentList;
        return;
      }
      if (lastArr[lastIndex].type === 'page') {
        handleUpdateFramesPosition(
          currentList,
          framesList[lastArr[lastIndex].id]
        );
      }
      handleUpdatePosition(tempList);
      // handleUpdateFramesPosition(currentList);
    },
    [
      handleUpdatePosition,
      handleUpdateFramesPosition,
      handleUpdateChapterPosition,
      pagesList,
      framesList,
    ]
  );

  const handleChangeSpeechOrder = useCallback(
    (newList, oldList) => {
      handleUpdateSpeechPosition(newList, oldList);
    },
    [handleUpdateSpeechPosition]
  );

  const sortableContent = useCallback(
    (item, blockIndex) => {
      switch (item.type) {
        case 'chapter':
          return (
            <ReactSortable
              list={item.children}
              key={item.id}
              setList={(currentList) =>
                handleOnChangePosition(currentList, blockIndex)
              }
              {...sortableOptions}
            >
              {item?.children.map((currentItem, currentIndex) => {
                const isOpen = collapseIsOpen(currentItem.id);
                const isSelected = activeItem(currentItem);
                return (
                  <CollapseWrap
                    isOpen={isOpen}
                    id={currentItem.id}
                    key={currentItem.id}
                    title={currentItem.title}
                    type={currentItem.type}
                    isActive={isSelected}
                    item={currentItem}
                    onDelete={() => handleOnDelete(currentItem)}
                    onClick={() => {
                      handleSelectTreeItem([item, currentItem]);
                      handleEditItem(currentItem, currentItem.type);
                    }}
                    onExpand={handleOnExpand}
                  >
                    {sortableContent(currentItem, [
                      ...blockIndex,
                      currentIndex,
                    ])}
                  </CollapseWrap>
                );
              })}
            </ReactSortable>
          );
        case 'page':
          if (framesList[item.id]?.length) {
            return (
              <ReactSortable
                key={item.id}
                setList={(currentFramesList) => {
                  handleOnChangePosition(currentFramesList, blockIndex);
                }}
                list={framesList[item.id]}
              >
                {framesList[item.id].map((currentItem, currentIndex) => {
                  const isOpen = collapseIsOpen(currentItem.id);
                  const isSelected = activeItem(currentItem);
                  return (
                    <CollapseWrap
                      isOpen={isOpen}
                      id={currentItem.id || currentItem.fid || currentItem._id}
                      key={currentItem.id || currentItem.fid || currentItem._id}
                      title={currentItem.title || 'Frame'}
                      type={currentItem.type || FRAME_TYPE}
                      isActive={!!isSelected}
                      isCircleFrame={currentItem.isCircle}
                      listItem={currentItem}
                      checkedItems={checkedFrame}
                      onClick={() => {
                        handleFrameSelect([
                          pagesList[blockIndex[0]],
                          item,
                          currentItem,
                        ]);
                        handleEditItem(currentItem, FRAME_TYPE);
                      }}
                      setCheckedItems={setCheckedFrame}
                      onDelete={() =>
                        handleOnFrameDelete([
                          pagesList[blockIndex[0]],
                          item,
                          currentItem,
                        ])
                      }
                      onExpand={() => handleOnExpand(currentItem.id)}
                    >
                      {sortableContent(currentItem, [
                        ...blockIndex,
                        currentIndex,
                      ])}
                    </CollapseWrap>
                  );
                })}
              </ReactSortable>
            );
          }
          return null;
        case 'frame':
          const frameItem = [];
          const selectedItem =
            pagesList[blockIndex[0]].type === CHAPTER_TYPE
              ? pagesList[blockIndex[0]].children[blockIndex[1]]
              : framesList[pagesList[blockIndex[0]].id][blockIndex[1]];

          const currentFrameLayers = layersObject[item.id];
          if (layersObject && currentFrameLayers) {
            const speechBubbles = currentFrameLayers.filter(
              (layer) => layer.type === SPEECHBUBBLE_LAYER_TYPE
            );
            const speechbubbleItems = speechBubbles
              .sort((a, b) => a.order - b.order)
              .map((bubble) => {
                const isActive = activeItem({ ...bubble, type: 'frameItem' });
                return (
                  <ListItemTitle
                    key={bubble.id}
                    isActive={isActive}
                    title={bubble.title}
                    listItem={bubble}
                    onDelete={() => handleDeleteLayer(bubble)}
                    onClick={() => {
                      handleFrameSelect([
                        pagesList[blockIndex[0]],
                        selectedItem,
                        item,
                        { ...bubble, type: 'frameItem' },
                      ]);
                      handleEditItem(bubble, SPEECHBUBBLE_LAYER_TYPE);
                    }}
                    type={SPEECHBUBBLE_LAYER_TYPE}
                  />
                );
              });

            frameItem.push(
              <ReactSortable
                key={`${item.id}-speeches`}
                setList={(currentList) => {
                  handleChangeSpeechOrder(currentList, speechBubbles);
                }}
                list={speechBubbles}
                className='sortable'
                {...sortableOptions}
              >
                {speechbubbleItems.map((s) => (
                  <React.Fragment key={s.key}>{s}</React.Fragment>
                ))}
              </ReactSortable>
            );

            if (speechBubbles.length)
              frameItem.push(
                <Divider
                  key={`${item.id}-divider`}
                  className='collapse-divider'
                />
              );

            layersObject[item.id]
              .filter((l) => l.type !== SPEECHBUBBLE_LAYER_TYPE)
              .forEach((layer) => {
                const isActive = activeItem({ ...layer, type: 'frameItem' });
                frameItem.push(
                  <ListItemTitle
                    key={`${layer.id}-layer`}
                    isActive={isActive}
                    title={layer.title}
                    onDelete={() => handleDeleteLayer(layer)}
                    listItem={layer}
                    onClick={() => {
                      handleFrameSelect([
                        pagesList[blockIndex[0]],
                        selectedItem,
                        item,
                        { ...layer, type: 'frameItem' },
                      ]);
                      handleEditItem(layer, layer.type);
                    }}
                    type={layer.type}
                  />
                );
              });
          }

          if (hotSpotsObject && hotSpotsObject[item.id]) {
            hotSpotsObject[item.id].forEach((hotspot) => {
              const isActive = activeItem({ ...hotspot, type: 'frameItem' });
              frameItem.push(
                <ListItemTitle
                  key={`${hotspot.id || hotspot._id}-hotspot`}
                  isActive={isActive}
                  title={hotspot.name}
                  listItem={hotspot}
                  onDelete={() => handleDeleteHotspot(hotspot)}
                  onClick={() => {
                    handleFrameSelect([
                      pagesList[blockIndex[0]],
                      selectedItem,
                      item,
                      { ...hotspot, type: 'frameItem' },
                    ]);
                    handleEditItem(hotspot, HOT_SPOT_TYPE);
                  }}
                  type={HOT_SPOT_TYPE}
                />
              );
            });
          }

          if (decisionObject && decisionObject[item.id]) {
            decisionObject[item.id].forEach((decision) => {
              const isActive = activeItem({ ...decision, type: 'frameItem' });
              frameItem.push(
                <ListItemTitle
                  key={`${decision.id}-decision`}
                  isActive={isActive}
                  title={decision.decisionName}
                  listItem={decision}
                  onClick={() => {
                    handleFrameSelect([
                      pagesList[blockIndex[0]],
                      selectedItem,
                      item,
                      { ...decision, type: 'frameItem' },
                    ]);
                    handleEditItem(decision, DECISION_TYPE);
                  }}
                  type={DECISION_TYPE}
                />
              );
            });
          }

          if (backpackObject && backpackObject[item.id]) {
            backpackObject[item.id].forEach((backpack) => {
              const isActive = activeItem({ ...backpack, type: 'frameItem' });

              frameItem.push(
                <ListItemTitle
                  key={`${backpack.id}-backpack`}
                  isActive={isActive}
                  title={backpack.title}
                  listItem={backpack}
                  onClick={() => {
                    handleFrameSelect([
                      pagesList[blockIndex[0]],
                      selectedItem,
                      item,
                      { ...backpack, type: 'frameItem' },
                    ]);
                    handleEditItem(backpack, BACKPACK_TYPE);
                  }}
                  type={BACKPACK_TYPE}
                />
              );
            });
          }

          if (checkpointObject && checkpointObject[item.id]) {
            checkpointObject[item.id].forEach((checkpoint) => {
              const isActive = activeItem({ ...checkpoint, type: 'frameItem' });

              frameItem.push(
                <ListItemTitle
                  key={`${checkpoint.id}-backpack`}
                  isActive={isActive}
                  title={checkpoint.title}
                  listItem={checkpoint}
                  onClick={() => {
                    handleFrameSelect([
                      pagesList[blockIndex[0]],
                      selectedItem,
                      item,
                      { ...checkpoint, type: 'frameItem' },
                    ]);
                    handleEditItem(checkpoint, CHECKPOINT_TYPE);
                  }}
                  type={CHECKPOINT_TYPE}
                />
              );
            });
          }

          if (item?.attachmentInfo?.type) {
            frameItem.push(
              <ListItemTitle
                key={`${item.id}-attachment-${item?.attachmentInfo.type}`}
                isActive={false}
                title={item?.attachmentInfo.type}
                type={item?.attachmentInfo.type}
              />
            );
          }
          return frameItem;
        // case 'speechbubble': {
        //   const speechBubbleItem = [];
        //   item.speechBubbles.forEach((bubble) => {
        //     const isActive = activeItem({ ...bubble, type: 'frameItem' });
        //     speechBubbleItem.push(
        //       <ListItemTitle
        //         key={`${bubble.id}-speechbubble`}
        //         isActive={isActive}
        //         title={bubble.title}
        //         listItem={bubble}
        //         onClick={() => {
        //           handleFrameSelect([
        //             pagesList[blockIndex[0]],
        //             item.selectedItem,
        //             item,
        //             { ...bubble, type: 'frameItem' },
        //           ]);
        //           handleEditItem(bubble, SPEECHBUBBLE_LAYER_TYPE);
        //         }}
        //         type={SPEECHBUBBLE_LAYER_TYPE}
        //       />
        //     );
        //   });
        //   return (
        //     <ReactSortable
        //       key={item.id}
        //       setList={() => {
        //         // handleOnChangePosition(currentFramesList, blockIndex);
        //       }}
        //       list={item.speechBubbles}
        //     >
        //       {speechBubbleItem}
        //     </ReactSortable>
        //   );
        // }
        default:
          return null;
      }
    },
    [
      checkpointObject,
      backpackObject,
      layersObject,
      hotSpotsObject,
      handleOnFrameDelete,
      handleOnDelete,
      handleEditItem,
      decisionObject,
      handleFrameSelect,
      pagesList,
      handleOnChangePosition,
      activeItem,
      handleSelectTreeItem,
      handleOnExpand,
      collapseIsOpen,
      framesList,
      checkedFrame,
      handleChangeSpeechOrder,
      handleDeleteHotspot,
      handleDeleteLayer,
    ]
  );

  return (
    <ReactSortable
      key='main-sortable'
      list={pagesList}
      setList={handleUpdatePosition}
      {...sortableOptions}
      {...{ group: 'main' }}
    >
      {pagesList.map((page, pageIndex) => {
        const isOpen = collapseIsOpen(page.id);
        const isSelected = activeItem(page);

        return (
          <React.Fragment key={page.id}>
            <CollapseWrap
              isOpen={isOpen}
              title={page.title}
              type={page.type}
              isActive={isSelected}
              id={page.id}
              onClick={() => {
                handleSelectTreeItem([page]);
                if (!isPressedShift) {
                  handleEditItem(page, page.type);
                }
              }}
              onDelete={() => handleOnDelete(page)}
              onExpand={handleOnExpand}
              isGroupSelection={!!checkedPage.length}
              onGroupSelectionClick={handleCreateChapter}
              item={page}
              onDuplicate={(duplacatedPage) =>
                setCheckedPage([
                  { id: duplacatedPage.id, order: duplacatedPage.order },
                ])
              }
            >
              {sortableContent(page, [pageIndex])}
            </CollapseWrap>
          </React.Fragment>
        );
      })}
    </ReactSortable>
  );
};

export default StoryList;

export const convertPathToString = (pathArray) => {
  if (!pathArray) return '';
  const pathString = pathArray.reduce((acc, curr) => {
    return `${acc} ${curr}`;
  }, '');

  return pathString.trim();
};

export const fromNumberToOpacity = (number) => {
  if (number === 1) return '';
  if (number === 0) return '00';
  const opacity = number.toString().slice(2);
  return `${opacity}0`;
};

export const fromOpacityToNumber = (str) => {
  const hexStr = str.slice(7);

  if (hexStr.length) {
    return parseInt(hexStr.slice(0, 1), 16) / 10;
  }
  return 1;
};

export const defaultValues = {
  id: '',
  title: '',
  content: '',
  isSpeechbubble: false,
  speechbubble: {
    taleDirection: {
      vertical: 'bottom',
      horisontal: 'right',
    },
    taleCoords: { x: 0, y: 0, w: 25, h: 50 },
    formCoords: [
      { x: 0, y: 0 },
      { x: 100, y: 0 },
      { x: 100, y: 75 },
      { x: 100, y: 100 },
      { x: 80, y: 75 },
      { x: 0, y: 75 },
    ],
    backgroundColor: '#ffffff',
    borderColor: '#000000',
  },
  isOverlay: false,
  zIndex: 1,
  soundInfo: {},
};

export const sizes = Array.from(Array(21), (_, index) => index + 6);

export const fonts = [
  {
    comicNeue: 'Comic Neue',
  },
  {
    piekosToons: 'Piekos ToonsBB',
  },
];

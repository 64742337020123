const PREFIX = '@@SHEECHBUBBLE';

export const SET_SHEECHBUBBLE = `${PREFIX}/SET_SHEECHBUBBLE`;
export const SET_DROWINGMODE = `${PREFIX}/SET_DROWINGMODE`;
export const RESET_SHEECHBUBBLE = `${PREFIX}/RESET_SHEECHBUBBLE`;
export const SET_MODE_CHANGE = `${PREFIX}/SET_MODE_CHANGE`;
export const SET_FILE_CHANGE = `${PREFIX}/SET_FILE_CHANGE`;
export const SET_CLEANED = `${PREFIX}/SET_CLEANED`;
export const SET_IMPORT_STATUS = `${PREFIX}/SET_IMPORT_STATUS`;
export const RESET_TO_DEFAULT = `${PREFIX}/RESET_TO_DEFAULT`;

import { createSelector } from 'reselect';
import { mappedArrayToPageObject } from 'utils/mappedArrayToPageObject';

export const checkpointListSelector = (state) =>
  state.checkpointList.checkpoints;

export const checkpointLoadingSelector = (state) =>
  state.checkpointList.loading;

export const mappedCheckpointObject = createSelector(
  checkpointListSelector,
  (list) => mappedArrayToPageObject(list)
);

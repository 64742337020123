import { useCallback, useEffect, useRef, useState } from 'react';
import { ARROW_DIRECTIONS, defaultKeyboardActions } from 'constants/hooks';

export const useKeyboardEvents = () => {
  const [isPressedShift, setIsPressedShift] = useState(false);
  const [isPressedAlt, setIsPressedAlt] = useState(false);
  const [isPressedCtrl, setIsPressedCtrl] = useState(false);
  const [isPressedEnter, setIsPressedEnter] = useState(false);
  const [isPressedBackspace, setIsPressedBackspace] = useState(false);
  const [triggerFunction, setTriggerFunction] = useState(
    defaultKeyboardActions
  );

  const finishedPressing = useRef(true);

  const pressArrow = (e, direction) => {
    if (!direction) return;
    e.preventDefault();
    finishedPressing.current = false;
    setTriggerFunction((prev) => ({ ...prev, selectFrame: [direction] }));
  };

  const handleOnKeyDown = useCallback(
    (e) => {
      if (!finishedPressing.current && e.repeat) return;
      if (e.key === 'Shift') {
        finishedPressing.current = false;
        setIsPressedShift(true);
      }
      if (e.key === 'Alt') {
        e.preventDefault();
        finishedPressing.current = false;
        setIsPressedAlt(true);
      }
      if (e.key === 'Enter') {
        finishedPressing.current = false;
        setIsPressedEnter(true);
      }
      if (e.key === 'Backspace') {
        finishedPressing.current = false;
        setIsPressedBackspace(true);
      }
      if (e.ctrlKey || e.metaKey) {
        setIsPressedCtrl(true);
        if (e.key === 'p') {
          e.preventDefault();
          finishedPressing.current = false;
          setTriggerFunction((prev) => ({ ...prev, page: true }));
          return;
        }
        if (e.key === 'f') {
          e.preventDefault();
          finishedPressing.current = false;
          setTriggerFunction((prev) => ({ ...prev, frame: true }));
          return;
        }
        if (e.key === 's') {
          e.preventDefault();
          finishedPressing.current = false;
          setTriggerFunction((prev) => ({ ...prev, save: true }));
          return;
        }
        pressArrow(e, ARROW_DIRECTIONS[e.key]);
      }
    },
    [finishedPressing]
  );

  const handleOnKeyUp = useCallback(() => {
    finishedPressing.current = true;
    setIsPressedShift(false);
    setIsPressedAlt(false);
    setIsPressedCtrl(false);
    setIsPressedEnter(false);
    setIsPressedBackspace(false);
    setTriggerFunction(defaultKeyboardActions);
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleOnKeyDown);
    document.addEventListener('keyup', handleOnKeyUp);

    return () => {
      document.removeEventListener('keydown', handleOnKeyDown);
      document.removeEventListener('keyup', handleOnKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isPressedShift,
    isPressedAlt,
    isPressedCtrl,
    isPressedEnter,
    triggerFunction,
    isPressedBackspace,
  };
};

import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_TYPE } from 'constants/updateTypes';
import { PAGE_TYPE } from 'constants/editTypes';
import {
  storyListAllChaptersSelector,
  storyListItemsSelector,
  storyListSelectedIdsSelector,
} from 'common/selectors/editStory';
import {
  clearEditItemAction,
  setSelectedAction,
  updateListPositionAction,
  updatePageAction,
} from 'common/actions/editStoryActions';
import { pagesListSelector } from 'common/selectors/pages';
import { updateFrameAction } from 'common/actions/framesListActions';
import { storyListFramesSelector } from 'common/selectors/frames';
import MenuWrap from 'components/MenuWrap';
import useStyles from './styles';

const selector = createSelector(
  storyListAllChaptersSelector,
  storyListItemsSelector,
  storyListSelectedIdsSelector,
  pagesListSelector,
  storyListFramesSelector,
  (chaptersList, storyList, selectedIds, pagesList, framesList) => ({
    chaptersList,
    storyList,
    selectedIds,
    pagesList,
    framesList,
  })
);

const MovePageMenu = forwardRef(({ item, onClose, checkedItems }, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  const { chaptersList, storyList, pagesList, framesList } = useSelector(
    selector
  );

  const checkedFrames = useMemo(
    () =>
      checkedItems?.map((checkedFrame) =>
        framesList.find((frame) => checkedFrame === frame.id)
      ),
    [checkedItems, framesList]
  );

  const moveToChapter = useCallback(
    (chapterIndex) => {
      const newStoryList = [...storyList];
      const indexOfCurrentPage = newStoryList.findIndex(
        (page) => page.id === item.id
      );

      if (indexOfCurrentPage !== -1) {
        const chapterId = chaptersList[chapterIndex].id;
        newStoryList.splice(indexOfCurrentPage, 1);
        chaptersList[chapterIndex].children.push(item);
        dispatch(updateListPositionAction(newStoryList));
        dispatch(
          setSelectedAction({
            chapter: chapterId,
            page: '',
            frame: '',
            frameItem: '',
          })
        );
        dispatch(
          updatePageAction(
            {
              ...item,
              isInChapter: true,
              originChapter: chapterId,
            },
            UPDATE_TYPE.UPDATE
          )
        );
        dispatch(clearEditItemAction());
        setAnchorEl(null);
        onClose();
      }
    },
    [storyList, item, dispatch, onClose, chaptersList]
  );

  const moveToPage = useCallback(
    (pageIndex) => {
      if (pageIndex !== -1) {
        const pageId = pagesList[pageIndex].id;
        if (checkedItems.length > 1) {
          checkedFrames.forEach((frame) => {
            dispatch(
              updateFrameAction(
                {
                  ...frame,
                  originPage: pageId,
                },
                UPDATE_TYPE.UPDATE
              )
            );
          });
          dispatch(
            setSelectedAction({
              chapter: '',
              page: pageId,
              frame: '',
              frameItem: '',
            })
          );
        } else {
          dispatch(
            setSelectedAction({
              chapter: '',
              page: pageId,
              frame: item.id,
              frameItem: '',
            })
          );
          dispatch(
            updateFrameAction(
              {
                ...item,
                originPage: pageId,
              },
              UPDATE_TYPE.UPDATE
            )
          );
        }
        dispatch(clearEditItemAction());
        setAnchorEl(null);
        onClose();
      }
    },
    [item, dispatch, onClose, pagesList, checkedItems, checkedFrames]
  );

  const isPageType = useMemo(() => item?.type === PAGE_TYPE, [item?.type]);

  return (
    <MenuWrap
      ref={ref}
      anchorEl={anchorEl}
      onToggle={setAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      toggler={
        <MenuItem className={classes.toggler}>
          {isPageType ? 'Move to chapter' : 'Move to page'}
        </MenuItem>
      }
    >
      {isPageType
        ? chaptersList.map((chapter, index) => (
            <MenuItem key={chapter.id} onClick={() => moveToChapter(index)}>
              {chapter.title}
            </MenuItem>
          ))
        : pagesList.map((page, index) => (
            <MenuItem key={page.id} onClick={() => moveToPage(index)}>
              {page.title}
            </MenuItem>
          ))}
      {isPageType
        ? !chaptersList.length && (
            <MenuItem disabled>Chapters not found</MenuItem>
          )
        : pagesList.length < 2 && <MenuItem disabled>Pages not found</MenuItem>}
    </MenuWrap>
  );
});

MovePageMenu.displayName = 'MovePageMenu';

export default MovePageMenu;

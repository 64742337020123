import { FETCHED_PAGES, LOADING_PAGES } from 'common/types/pagesList';
import { getPagesList as getPagesListService } from 'services/pages';
import { errorToast } from 'services/toast';

export function fetchedPages(pages) {
  return { type: FETCHED_PAGES, pages };
}

export const loadingPagesAction = (payload) => ({
  type: LOADING_PAGES,
  payload,
});

export const fetchPagesAction = (storyId) => {
  return (dispatch) => {
    dispatch(loadingPagesAction(true));

    return getPagesListService({ story: storyId })
      .then((res) => {
        dispatch(fetchedPages((res.data && res.data.rows) || []));
        dispatch(loadingPagesAction(false));
      })
      .catch((error) => {
        dispatch(loadingPagesAction(false));
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { URL_REGEX } from 'constants/regex';
import Image from 'components/Image';
import useStyles from './styles';

const GridUrl = ({
  attachmentInfo,
  newWindow,
  onGoClick,
  isCircle,
  clipPath,
}) => {
  const classes = useStyles({ isCircle, clipPath });
  return (
    <Box className={classes.wrap}>
      {newWindow ? (
        <>
          <Box className={classes.go} onClick={onGoClick}>
            Go
          </Box>
          <Image src={attachmentInfo.url} alt={attachmentInfo.name} />
        </>
      ) : (
        <iframe
          title={attachmentInfo.name}
          className={classes.frame}
          {...(URL_REGEX.test(attachmentInfo.url) && {
            src: attachmentInfo.url,
          })}
          sandbox='allow-same-origin allow-presentation allow-scripts allow-popups allow-forms'
          allow='fullscreen'
          allowFullScreen
        />
      )}
    </Box>
  );
};

GridUrl.propTypes = {
  attachmentInfo: PropTypes.shape({
    type: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  // newWindow: PropTypes.bool.isRequired,
  onGoClick: PropTypes.func.isRequired,
};

export default GridUrl;

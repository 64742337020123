export const clearObject = (obj) => {
  return obj
    ? Object.fromEntries(
        Object.entries(obj).filter(
          // eslint-disable-next-line no-unused-vars
          ([key, value]) => value && Object.keys(value).length > 0
        )
      )
    : {};
};

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { AppBar, Box, Button, Tooltip, Typography } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { ROUTES } from 'constants/index';
import {
  BACKPACK_TYPE,
  CHAPTER_TYPE,
  CHECKPOINT_TYPE,
  DECISION_TYPE,
  FRAME_TYPE,
  HOT_SPOT_TYPE,
  IMAGE_LAYER_TYPE,
  PAGE_TYPE,
  SPEECHBUBBLE_LAYER_TYPE,
  TEXT_LAYER_TYPE,
} from 'constants/editTypes';
import {
  getDefaultCheckpointItem,
  getDefaultDecision,
  getDefaultFrame,
  getDefaultHotSpot,
  getDefaultLayerImage,
  getDefaultLayerText,
  getDefaultPage,
  getDefaultSpeechbubble,
} from 'constants/defaultItems';
import { HISTORY_ACTIONS, UPDATE_TYPE } from 'constants/updateTypes';
import { CIRCULAR_FRAME, SIMPLE_FRAME } from 'constants/frameTypes';
import {
  getPreviewLink,
  handlePageTitle,
  newStoryListAfterRemove,
} from 'utils/helpers/helpers';
import { formatListForService } from 'utils/formatTreeForService';
import { useKeyboardEvents } from 'utils/hooks/useKeyboardEvents';
import useMobile from 'utils/hooks/useMobile';
import {
  fetchStorySuccessAction,
  updateCurrentStoryAction,
} from 'common/actions/storyActions';
import {
  storyListAllChaptersSelector,
  storyListEditHistorySelector,
  storyListEditItemSelector,
  storyListEditSettingsSelector,
  storyListEditStatusSelector,
  storyListEditTypeSelector,
  storyListItemsSelector,
  storyListSelectedEditFrame,
  storyListSelectedIdsSelector,
  storyListSelectedPageSelector,
  storyListShowGridLinesSelector,
  storyListSnapFramesSelector,
} from 'common/selectors/editStory';
import {
  addNewChapterAction,
  addNewPageAction,
  clearEditItemAction,
  createNewPageInChapterAction,
  deleteChapterAction,
  deletePageAction,
  editItemAction,
  setEditAction,
  setSelectedAction,
  updateChapterAction,
  updateListPositionAction,
  updatePageAction,
  updateServerPositionAction,
} from 'common/actions/editStoryActions';
import { storyItemsSelector } from 'common/selectors/story';
import {
  addHotSpot,
  removeHotSpotAction,
  updateHotSpot as updateHotSpotAction,
} from 'common/actions/hotSpotsListActions';
import {
  addLayerAction,
  removeLayerAction,
  updateLayerAction,
} from 'common/actions/layersListAction';
import {
  addDecisionAction,
  removeDecisionAction,
  updateDecisionAction,
} from 'common/actions/decisionsListActions';
import {
  addBackpackItemAction,
  removeBackpackItemAction,
  updateBackpackItemAction,
} from 'common/actions/backpackListAction';
import {
  createCheckpointItemAction,
  deleteCheckpointAction,
  updateCheckpointItemAction,
} from 'common/actions/checkpointListAction';
import {
  framesListSelector,
  storyListFramesSelector,
  targetFramesListSelector,
} from 'common/selectors/frames';
import {
  storyListLanguageSelector,
  storyListCurrentStorySelector,
} from 'common/selectors/storyList';
import {
  addNewFrameAction,
  createFrameSuccessAction,
  deleteFrameAction,
  updateFrameAction,
} from 'common/actions/framesListActions';
import { currentUserSelector } from 'common/selectors/auth';
import { pagesListSelector } from 'common/selectors/pages';
import { ArrowLeft, LogoIcon } from 'components/Icons';
import { errorToast } from 'services/toast';
import useStyles from './styles';
import { DesktopHeaderContent } from './components/DesktopHeaderContent/index';
import { MobileHeaderContent } from './components/MobileHeaderContent/index';
import { ActionMenu } from './components/ActionMenu/index';

const selector = createSelector(
  storyListSelectedEditFrame,
  framesListSelector,
  storyListEditTypeSelector,
  storyListEditItemSelector,
  storyListSelectedIdsSelector,
  storyItemsSelector,
  storyListEditStatusSelector,
  storyListEditHistorySelector,
  storyListEditSettingsSelector,
  storyListItemsSelector,
  storyListAllChaptersSelector,
  currentUserSelector,
  pagesListSelector,
  storyListShowGridLinesSelector,
  storyListSnapFramesSelector,
  storyListSelectedPageSelector,
  storyListLanguageSelector,
  storyListCurrentStorySelector,
  targetFramesListSelector,
  storyListFramesSelector,
  (
    selectedFrame,
    framesList,
    editType,
    editItem,
    selectedIds,
    story,
    isEdited,
    editHistory,
    settingsOpen,
    storyList,
    chaptersList,
    currentUser,
    pagesList,
    showGridLines,
    snapFrames,
    currentPage,
    currentLang,
    currentStory,
    storyFrames,
    frames
  ) => ({
    selectedFrame,
    framesList,
    editType,
    editItem,
    selectedIds,
    story,
    isEdited,
    editHistory,
    settingsOpen,
    storyList,
    chaptersList,
    currentUser,
    pagesList,
    showGridLines,
    snapFrames,
    currentPage,
    currentLang,
    currentStory,
    storyFrames,
    frames,
  })
);

const Header = ({ loading }) => {
  const {
    isEdited,
    framesList,
    selectedFrame,
    editType,
    editItem,
    selectedIds,
    story,
    editHistory,
    settingsOpen,
    storyList,
    chaptersList,
    pagesList,
    showGridLines,
    snapFrames,
    currentPage,
    currentLang,
    currentStory,
    storyFrames,
    frames,
  } = useSelector(selector);
  const { isSmallDesktop } = useMobile();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const params = useParams();

  const { triggerFunction } = useKeyboardEvents();
  const submitRef = useRef(null);

  const isEdit = useMemo(() => pathname !== ROUTES.HOME, [pathname]);
  const undoHistory = useMemo(() => editHistory.undo, [editHistory]);
  const redoHistory = useMemo(() => editHistory.redo, [editHistory]);

  const classes = useStyles({ isSmallDesktop, isEdit });

  const couldAddFrame = useMemo(
    () =>
      isEdit &&
      editItem &&
      (editItem?.type === PAGE_TYPE || editItem?.type === FRAME_TYPE) &&
      (editItem?.id || editItem?.fid),
    [editItem, isEdit]
  );

  const handleUnload = useCallback(
    (e) => {
      const message = 'o/';

      if (isEdited) {
        (e || window.event).returnValue = message;
      }
      return message;
    },
    [isEdited]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);

    return () => window.removeEventListener('beforeunload', handleUnload);
  }, [handleUnload]);

  const discardLastChanges = useCallback(() => {
    dispatch(clearEditItemAction());
    dispatch(setSelectedAction({ ...selectedIds, frameItem: '' }));
    dispatch(setEditAction(false));
  }, [dispatch, selectedIds]);

  const discardLastPageChanges = useCallback(() => {
    dispatch(clearEditItemAction());
    dispatch(
      setSelectedAction({ ...selectedIds, page: '', frame: '', frameItem: '' })
    );
    dispatch(setEditAction(false));
  }, [dispatch, selectedIds]);

  const toggleStoryDraft = useCallback(
    (e) => {
      dispatch(updateCurrentStoryAction(story, e.target.name, !story?.draft));
    },
    [story, dispatch]
  );

  const previewStory = useCallback(() => {
    if (currentStory?.id || story?.id) {
      window.open(
        getPreviewLink(currentStory?.id || story?.id, false, currentPage?.id)
      );
    }
  }, [currentPage?.id, currentStory?.id, story?.id]);

  const handleAddNewFrame = useCallback(
    (type) => {
      const originPage = editItem.originPage || editItem.id;
      const pageTitle = handlePageTitle([...storyFrames, ...frames], 'Frame');
      const frame = getDefaultFrame(
        originPage,
        editItem.story,
        type === CIRCULAR_FRAME,
        pageTitle
      );
      dispatch(createFrameSuccessAction(frame));
      dispatch(
        editItemAction({
          item: frame,
          type: FRAME_TYPE,
        })
      );
      dispatch(setSelectedAction({ ...selectedIds, frame: frame.fid }));
    },
    [editItem, storyFrames, frames, dispatch, selectedIds]
  );

  useEffect(() => {
    if (couldAddFrame && triggerFunction.frame) handleAddNewFrame(SIMPLE_FRAME);
    if (!settingsOpen && triggerFunction.save) submitRef.current.click();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFunction.frame, triggerFunction.save]);

  const handleAddNewHotSpot = useCallback(() => {
    const newHotSpot = getDefaultHotSpot(selectedFrame, editItem);

    dispatch(
      editItemAction({
        item: newHotSpot,
        type: HOT_SPOT_TYPE,
      })
    );
  }, [dispatch, selectedFrame, editItem]);

  const handleAddNewDecision = useCallback(() => {
    const decision = getDefaultDecision(
      params.id,
      editItem,
      selectedFrame,
      currentLang
    );

    dispatch(
      editItemAction({
        item: decision,
        type: DECISION_TYPE,
      })
    );
  }, [params.id, editItem, selectedFrame, currentLang, dispatch]);

  const handleAddNewCheckpoint = useCallback(() => {
    const checkpoint = getDefaultCheckpointItem();

    dispatch(
      editItemAction({
        item: checkpoint,
        type: CHECKPOINT_TYPE,
      })
    );
  }, [dispatch]);

  const handleAddLayer = useCallback(
    (type) => {
      let item;
      switch (type) {
        case SPEECHBUBBLE_LAYER_TYPE:
          item = getDefaultSpeechbubble();
          break;
        case IMAGE_LAYER_TYPE:
          item = getDefaultLayerImage();
          break;
        case TEXT_LAYER_TYPE:
          item = getDefaultLayerText();
          break;
        default:
          item = {};
      }

      dispatch(
        editItemAction({
          item,
          type,
        })
      );
    },
    [dispatch]
  );

  const handlePageCreate = useCallback(
    (changeToApply, updateType) => {
      const pageTitle = handlePageTitle(pagesList, 'Page');
      if (changeToApply.isInChapter) {
        dispatch(
          createNewPageInChapterAction(
            getDefaultPage(changeToApply.story, pageTitle),
            changeToApply.originChapter
          )
        );
      } else {
        dispatch(
          addNewPageAction(
            getDefaultPage(changeToApply.story, pageTitle),
            updateType,
            changeToApply.id
          )
        );
      }
    },
    [dispatch, pagesList]
  );

  const handleUndoUpdate = useCallback(
    (type, changeToApply, updateType, action) => {
      if (action === HISTORY_ACTIONS.UPDATE)
        switch (type) {
          case HOT_SPOT_TYPE: {
            dispatch(updateHotSpotAction(changeToApply, updateType));
            break;
          }
          case PAGE_TYPE: {
            dispatch(updatePageAction(changeToApply, updateType));
            const applyToPage = pagesList.find(
              (page) => page.id === changeToApply.id
            );
            if (!changeToApply.isInChapter && applyToPage.isInChapter) {
              const newStoryList = newStoryListAfterRemove(
                storyList,
                chaptersList,
                changeToApply
              );
              dispatch(
                updateServerPositionAction(
                  formatListForService(newStoryList, framesList)
                )
              );
              dispatch(updateListPositionAction(newStoryList));
            }
            changeToApply.items?.map((item) => {
              return dispatch(updateBackpackItemAction(item, updateType));
            });
            break;
          }

          case DECISION_TYPE: {
            dispatch(updateDecisionAction(changeToApply, updateType));
            break;
          }

          case FRAME_TYPE: {
            dispatch(updateFrameAction(changeToApply, updateType));
            break;
          }

          case IMAGE_LAYER_TYPE.slice(0, 5): {
            dispatch(updateLayerAction(changeToApply, updateType));
            break;
          }

          case CHECKPOINT_TYPE: {
            dispatch(updateCheckpointItemAction(changeToApply, updateType));
            break;
          }

          case BACKPACK_TYPE: {
            dispatch(updateBackpackItemAction(changeToApply, updateType));
            break;
          }

          case CHAPTER_TYPE: {
            dispatch(updateChapterAction(changeToApply, updateType));
            break;
          }

          default:
            errorToast('Something went wrong.');
        }
      else if (action === HISTORY_ACTIONS.CREATE) {
        switch (type) {
          case HOT_SPOT_TYPE: {
            dispatch(removeHotSpotAction(changeToApply, updateType));
            break;
          }
          case FRAME_TYPE: {
            dispatch(deleteFrameAction(changeToApply, updateType));
            break;
          }
          case PAGE_TYPE: {
            dispatch(deletePageAction(changeToApply.id, updateType));
            break;
          }

          case DECISION_TYPE: {
            dispatch(removeDecisionAction(changeToApply, updateType));
            break;
          }
          case IMAGE_LAYER_TYPE.slice(0, 5): {
            dispatch(removeLayerAction(changeToApply, updateType));
            break;
          }

          case CHECKPOINT_TYPE: {
            dispatch(deleteCheckpointAction(changeToApply, updateType));
            break;
          }

          case BACKPACK_TYPE: {
            dispatch(removeBackpackItemAction(changeToApply, updateType));
            break;
          }

          case CHAPTER_TYPE: {
            dispatch(deleteChapterAction(changeToApply, updateType));
            break;
          }

          default:
            errorToast('Something went wrong.');
        }
      } else {
        switch (type) {
          case HOT_SPOT_TYPE: {
            dispatch(addHotSpot(changeToApply, updateType));
            break;
          }
          case FRAME_TYPE: {
            dispatch(addNewFrameAction(changeToApply, updateType));
            break;
          }
          case PAGE_TYPE: {
            handlePageCreate(changeToApply, updateType);
            break;
          }

          case DECISION_TYPE: {
            dispatch(addDecisionAction(changeToApply, updateType));
            break;
          }
          case IMAGE_LAYER_TYPE.slice(0, 5): {
            dispatch(addLayerAction(changeToApply, updateType));
            break;
          }

          case CHECKPOINT_TYPE: {
            dispatch(createCheckpointItemAction(changeToApply, updateType));
            break;
          }

          case BACKPACK_TYPE: {
            dispatch(addBackpackItemAction(changeToApply, updateType));
            break;
          }

          case CHAPTER_TYPE: {
            dispatch(addNewChapterAction(changeToApply, updateType));
            break;
          }

          default:
            errorToast('Something went wrong.');
        }
      }
    },
    [dispatch, pagesList, storyList, chaptersList, framesList, handlePageCreate]
  );

  const handleUndo = useCallback(() => {
    const lastChange = undoHistory[undoHistory.length - 1];
    const { action, ...changeToApply } = lastChange;
    handleUndoUpdate(
      lastChange.itemType,
      changeToApply,
      UPDATE_TYPE.UNDO,
      action
    );
  }, [handleUndoUpdate, undoHistory]);

  const handleRedo = useCallback(() => {
    const lastChange = redoHistory[redoHistory.length - 1];
    const { itemType, action, ...changeToApply } = lastChange;
    handleUndoUpdate(
      lastChange.itemType,
      changeToApply,
      UPDATE_TYPE.REDO,
      action
    );
  }, [handleUndoUpdate, redoHistory]);

  const onStoryExit = useCallback(() => {
    dispatch(fetchStorySuccessAction(null));
  }, [dispatch]);

  const actionButtons = useMemo(() => {
    if (!settingsOpen && isEdit)
      return (
        <>
          <Button
            type='submit'
            form={`${editType}-form`}
            variant='contained'
            color='secondary'
            disableElevation
            disabled={!isEdited || loading}
            ref={submitRef}
          >
            Save
          </Button>
          {isSmallDesktop && isEdit ? (
            <ActionMenu
              showGridLines={showGridLines}
              snapFrames={snapFrames}
              previewStory={previewStory}
              onClose={
                [CHAPTER_TYPE, PAGE_TYPE].some((t) => t === editType)
                  ? discardLastPageChanges
                  : discardLastChanges
              }
              handleUndo={handleUndo}
              handleRedo={handleRedo}
              undoHistory={undoHistory}
              redoHistory={redoHistory}
              onStoryExit={onStoryExit}
            />
          ) : (
            <Button
              disableElevation
              onClick={
                [CHAPTER_TYPE, PAGE_TYPE].some((t) => t === editType)
                  ? discardLastPageChanges
                  : discardLastChanges
              }
            >
              Exit
            </Button>
          )}
        </>
      );
    return null;
  }, [
    settingsOpen,
    isEdit,
    editType,
    isEdited,
    loading,
    isSmallDesktop,
    showGridLines,
    snapFrames,
    previewStory,
    discardLastPageChanges,
    discardLastChanges,
    handleUndo,
    handleRedo,
    undoHistory,
    redoHistory,
    onStoryExit,
  ]);

  const title = useMemo(() => {
    if (isEdit) {
      return (
        <Tooltip title='Back' placement='bottom'>
          <Box display='flex' alignItems='center'>
            <ArrowLeft />
            <p style={{ marginLeft: 10 }}> Back</p>
          </Box>
        </Tooltip>
      );
    }

    return (
      <>
        <LogoIcon />
        <Typography variant='h1' noWrap className={classes.logoTitle}>
          igital Universe
        </Typography>
      </>
    );
  }, [classes, isEdit]);

  return (
    <AppBar position='static' className={classes.root}>
      {isSmallDesktop && isEdit ? (
        <MobileHeaderContent
          isEdit={isEdit}
          couldAddFrame={couldAddFrame}
          selectedFrame={selectedFrame}
          actionButtons={actionButtons}
          handleAddLayer={handleAddLayer}
          handleAddNewFrame={handleAddNewFrame}
          handleAddNewHotSpot={handleAddNewHotSpot}
          handleAddNewDecision={handleAddNewDecision}
          handleAddNewCheckpoint={handleAddNewCheckpoint}
        />
      ) : (
        <DesktopHeaderContent
          story={story}
          title={title}
          isEdit={isEdit}
          isEdited={isEdited}
          snapFrames={snapFrames}
          redoHistory={redoHistory}
          undoHistory={undoHistory}
          onStoryExit={onStoryExit}
          currentStory={currentStory}
          couldAddFrame={couldAddFrame}
          showGridLines={showGridLines}
          actionButtons={actionButtons}
          selectedFrame={selectedFrame}
          handleRedo={handleRedo}
          handleUndo={handleUndo}
          previewStory={previewStory}
          handleAddLayer={handleAddLayer}
          toggleStoryDraft={toggleStoryDraft}
          handleAddNewFrame={handleAddNewFrame}
          handleAddNewHotSpot={handleAddNewHotSpot}
          handleAddNewDecision={handleAddNewDecision}
          handleAddNewCheckpoint={handleAddNewCheckpoint}
        />
      )}
    </AppBar>
  );
};

export default Header;

import { PAGE_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import {
  CREATE_DECISION,
  DELETE_DECISION,
  END_LOADING_DECISIONS,
  FETCHED_DECISIONS,
  START_LOADING_DECISIONS,
  UPDATE_DECISION,
} from 'common/types/decisionsList';
import {
  storyListEditItemSelector,
  storyListSelectedIdsSelector,
  storyListSelectedPageSelector,
} from 'common/selectors/editStory';
import { storyItemsSelector } from 'common/selectors/story';
import { pagesListSelector } from 'common/selectors/pages';
import {
  createDecision as createDecisionService,
  deleteDecision as deleteDecisionService,
  getDecisionsList as getDecisionsListService,
  updateDecision as updateDecisionService,
} from 'services/decisions';
import { errorToast, successToast } from 'services/toast';
import {
  clearEditItemAction,
  editItemAction,
  fetchHistoryListAction,
  setEditAction,
  setSelectedAction,
} from './editStoryActions';

export const fetchedDecisionsSuccessAction = (decisions) => {
  return { type: FETCHED_DECISIONS, decisions };
};

export const createDecisionSuccess = (decision) => ({
  type: CREATE_DECISION,
  decision,
});

export const updateDecisionSuccess = (decision) => ({
  type: UPDATE_DECISION,
  decision,
});

export const startLoadingDecisionsAction = () => {
  return { type: START_LOADING_DECISIONS };
};

export const endLoadingDecisionsAction = () => {
  return { type: END_LOADING_DECISIONS };
};

export const removedDecisionSuccessAction = (decision) => {
  return { type: DELETE_DECISION, decision };
};

export const fetchDecisionsAction = (storyId) => {
  return (dispatch) => {
    dispatch(startLoadingDecisionsAction());

    getDecisionsListService({ story: storyId })
      .then((res) => {
        dispatch(fetchedDecisionsSuccessAction(res.rows || []));
      })
      .catch((error) => {
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const removeDecisionAction = (decision, type = UPDATE_TYPE.STANDART) => {
  return (dispatch, getState) => {
    dispatch(startLoadingDecisionsAction());
    const state = getState();
    const selectedIds = storyListSelectedIdsSelector(state);
    const editDecision = storyListEditItemSelector(state);
    const { id } = storyItemsSelector(state);

    deleteDecisionService(decision?.id || editDecision.id, type)
      .then(() => {
        dispatch(fetchHistoryListAction(id));
        dispatch(removedDecisionSuccessAction(decision || editDecision));
        dispatch(clearEditItemAction());
        dispatch(setEditAction(false));
        dispatch(setSelectedAction({ ...selectedIds, frameItem: '' }));
        dispatch(endLoadingDecisionsAction());
      })
      .catch((error) => {
        dispatch(endLoadingDecisionsAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const addDecisionAction = (body, type = UPDATE_TYPE.STANDART) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = storyListSelectedIdsSelector(state);
    const selectedPage = storyListSelectedPageSelector(state);
    const { id } = storyItemsSelector(state);

    dispatch(startLoadingDecisionsAction());

    createDecisionService(
      {
        ...body,
        originPage:
          type !== UPDATE_TYPE.STANDART ? body.originPage : selectedIds.page,
        originFrame:
          type !== UPDATE_TYPE.STANDART ? body.originFrame : selectedIds.frame,
      },
      type
    )
      .then((decision) => {
        dispatch(setEditAction(false));
        dispatch(fetchHistoryListAction(id));
        dispatch(createDecisionSuccess(decision));
        dispatch(
          editItemAction({
            item: selectedPage,
            type: PAGE_TYPE,
          })
        );
        dispatch(setSelectedAction({ ...selectedIds, frameItem: '' }));
        successToast('Decision create success');
        dispatch(endLoadingDecisionsAction());
      })
      .catch((error) => {
        dispatch(endLoadingDecisionsAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const updateDecisionAction = (body, updateType) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = storyListSelectedIdsSelector(state);
    const pagesList = pagesListSelector(state);
    const { id } = storyItemsSelector(state);

    dispatch(startLoadingDecisionsAction());

    updateDecisionService(body, updateType)
      .then((decision) => {
        dispatch(setEditAction(false));
        dispatch(endLoadingDecisionsAction());
        dispatch(fetchHistoryListAction(id));
        dispatch(fetchDecisionsAction(id));
        dispatch(updateDecisionSuccess(decision));
        dispatch(
          setSelectedAction({
            ...selectedIds,
            page: decision.originPage,
            frame: '',
            frameItem: '',
          })
        );
        dispatch(
          editItemAction({
            item: pagesList.find((page) => page.id === decision.originPage),
            type: PAGE_TYPE,
          })
        );
        successToast('Decision updated successfully');
      })
      .catch((error) => {
        dispatch(endLoadingDecisionsAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

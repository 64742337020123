import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  wrap: {
    width: '100%',
    height: '100%',
    clipPath: ({ clipPath }) => clipPath,
    '& > video': {
      width: '100%',
      height: '100%',
      borderRadius: ({ isCircle }) => isCircle && '50%',
    },
  },
}));

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  btn: {
    minWidth: '20px',
    width: '25px',
    height: '24px',
    margin: '0px',
    marginTop: '3px',
    padding: '4px 2px',
  },
  trash: {
    cursor: 'pointer',
    marginTop: '3px',
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: '6px',
  },
}));

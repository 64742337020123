import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrap: {
    maxWidth: theme.spacing(27.625),
    width: '100%',
    height: '100%',
    minWidth: '200px',
    backgroundColor: theme.colors.sidebarBackground,
    transform: 'translateX(0)',
    transition: 'all .5s ease-in-out',
  },

  closedWrap: {
    maxWidth: '42px',
    minWidth: 0,
    width: '42px',
    height: '100%',
    backgroundColor: theme.colors.sidebarBackground,
    overflowY: 'auto',
    transform: 'translateX(calc(100% - 38px))',
    transition: 'all .5s ease-in-out',
  },

  showMenu: {
    display: 'flex',
    justifyContent: ({ showLeftMenu }) =>
      showLeftMenu ? 'space-between' : 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'all .5s ease-in-out',
    padding: ({ showLeftMenu }) =>
      showLeftMenu ? '69px 16px 2px 16px' : '69px 13px 2px 13px',

    '& p': {
      fontSize: theme.spacing(1.25),
      opacity: ({ showLeftMenu }) => (showLeftMenu ? 1 : 0),
      color: 'rgba(255, 255, 255, 0.5)',
    },

    '& svg': {
      transform: 'rotate(180deg)',
    },
  },

  contentWrapper: {
    height: '100%',
    overflowY: 'scroll',
  },

  content: {
    display: ({ showLeftMenu }) => (showLeftMenu ? 'block' : 'none'),
    transition: 'all .5s ease-in-out',
  },
}));

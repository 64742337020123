const PREFIX = '@@FRAMES';

export const FETCHED_FRAMES = `${PREFIX}/FETCHED_FRAMES`;
export const LOADING_FRAMES = `${PREFIX}/LOADING_FRAMES`;
export const CREATE_FRAME = `${PREFIX}/CREATE_FRAME`;
export const CREATE_FRAME_MULTIPLE = `${PREFIX}/CREATE_FRAME_MULTIPLE`;
export const UPDATE_FRAME = `${PREFIX}/UPDATE_FRAME`;
export const UPDATE_FRAME_LIST = `${PREFIX}/UPDATE_FRAME_LIST`;
export const DELETE_FRAME = `${PREFIX}/REMOVE_FRAME`;
export const FETCHED_TARGET_FRAMES = `${PREFIX}/FETCHED_TARGET_FRAMES`;
export const UPDATE_CREATED_FRAMES = `${PREFIX}/UPDATE_CREATED_FRAMES`;
export const UPDATE_CREATED_MULTIPLE_FRAMES = `${PREFIX}/UPDATE_CREATED_MULTIPLE_FRAMES`;

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '1 1 100%',
    justifyContent: 'center',
    height: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(3.875)}px`,

    [theme.breakpoints.down('sm')]: {
      padding: '60px 10px',
    },
  },
}));

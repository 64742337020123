import React from 'react';
import classNames from 'classnames';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';

export const GamepadIcon = ({ fill, size = 50, className, ...props }) => {
  return (
    <SportsEsportsIcon
      style={{ fill, width: size, height: size }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};

/* <svg
xmlns='http://www.w3.org/2000/svg'
width={size}
height={size}
viewBox='0 0 24 24'
fill={fill}
>
<path d='M6 1l1.172.203c-.356 2.245.791 2.519 2.697 2.874 1.469.273 3.131.622 3.131 3.284v.639h-1.183v-.639c0-1.556-.479-1.809-2.163-2.122-2.584-.48-4.097-1.391-3.654-4.239zm15.563 9.38c-.202-.811-.931-1.38-1.767-1.38h-15.592c-.836 0-1.565.569-1.768 1.38-.146.603-2.436 9.824-2.436 10.377 0 1.379 1.094 2.243 2.286 2.243.633 0 1.263-.274 1.83-.781 3.591-3.208 3.77-4.219 4.759-4.219h6.264c.971 0 1.163 1.018 4.745 4.219.567.507 1.197.781 1.83.781 1.192 0 2.286-.864 2.286-2.243 0-.553-2.29-9.774-2.437-10.377zm-12.563 3.62h-2v2h-1v-2h-2v-1h2v-2h1v2h2v1zm4 1h-2v-1h2v1zm2.75-.75c-.414 0-.75-.335-.75-.75s.336-.75.75-.75.75.335.75.75-.336.75-.75.75zm1.75 1.75c-.414 0-.75-.335-.75-.75s.336-.75.75-.75.75.335.75.75-.336.75-.75.75zm0-3.531c-.414 0-.75-.335-.75-.75s.336-.75.75-.75.75.335.75.75-.336.75-.75.75zm1.734 1.781c-.414 0-.75-.335-.75-.75s.336-.75.75-.75.75.335.75.75-.336.75-.75.75z' />
</svg> */

import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';
import { layerFont } from 'constants/defaultItems';

export default makeStyles((theme) => ({
  wrap: {
    position: 'absolute',
    top: ({ top }) => top || 0,
    left: ({ left }) => left || 0,
    width: ({ width }) => width || theme.spacing(6.25),
    height: ({ height }) => height || theme.spacing(6.25),
    zIndex: 1,
  },
  textBorder: {
    border: `${theme.spacing(0.125)}px solid ${theme.colors.layerDropZone}`,
    background: transparentize(0.67, theme.colors.layerDropZone),
  },
  sound: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '30%',
    height: 'auto',
    transform: 'translate(-50%, -50%)',
  },
  layerText: {
    color: theme.colors.black,
    width: '100%',
    fontFamily: ({ textStyle }) => `${textStyle}` || layerFont.fontStyle,
    fontSize: ({ textSize }) => `${textSize}pt` || layerFont.fontSize,
    margin: 0,
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  pathBackground: {
    backgroundColor: ({ borderColor }) => borderColor,
    position: 'absolute',
    borderRadius: 6,
    height: '100%',
    width: '100%',
    '&::after': {
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 4px)',
      backgroundColor: ({ backgroundColor }) => backgroundColor,
      position: 'absolute',
      borderRadius: 6,
      content: '""',
      left: 1,
      top: ({ vertical }) => (vertical === 'top' ? 3.5 : 2),
      clipPath: ({ fCoords }) =>
        `polygon(${fCoords
          .map((coords) => `${coords.x}% ${coords.y - 1}%`)
          .join(', ')})`,
    },
  },
  formHandlesBlock: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 10,
    '& > *': {
      backgroundColor: 'fuchsia',
      position: 'absolute',
      borderRadius: '50%',
      cursor: 'grab',
      height: 10,
      zIndex: 10,
      width: 10,
    },
  },
  speechTextContainer: {
    bottom: ({ vertical }) => vertical === 'top' && 0,
    height: ({ speechControls, vertical, fCoords }) =>
      `${
        vertical === 'top' && speechControls && fCoords
          ? 100 - fCoords[speechControls[0]]?.y
          : fCoords[speechControls[0]]?.y
      }%`,
    position: 'absolute',
    width: '100%',
    padding: 7,
  },
  speechBubbleText: {
    transform: 'translate(0, -50%)',
    pointerEvents: 'none',
    position: 'absolute',
    textAlign: 'left',
    top: '50%',
    zIndex: 2,
  },
}));

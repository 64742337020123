export default {
  crimson: '#fa2471',
  ebonyClay: '#212538',
  secondary: '#3AC6E0',
  white: '#fff',
  black: '#000',
  error: '#EF3E3E',
  dropZone: '#fccc24',
  layerDropZone: '#FC2472',
  backpackDropZone: '#12be3a',

  itemBackground: '#d1d8ff',
  grey: '#9e9e9e',
  lightGrey: '#dfdee4',
  lightestGrey: '#FAFAFA',
  darkGrey: '#16192a',
  menuBackground: '#15192A',
  sidebarBackground: '#282E42',
  background: '#181d30',
  divider: 'rgba(255,255,255,.12)',
  backpackBtnActive: 'rgba(31, 37, 58, 0.23)',
  storyListItemBg: '#393F51',
};

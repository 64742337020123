import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { FileDrop } from 'react-file-drop';
import { Box } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { ATTACHMENTS_TYPE } from 'constants/attachmentsType';
import { getDefaultFrame } from 'constants/defaultItems';
import { FRAME_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import { MAX_IMAGE_SIZE } from 'constants/frameTypes';
import { useDebounce } from 'utils/hooks/useDebounce';
import { treeCompare } from 'utils/treeCompare';
import {
  getImageSize,
  removeFileExtension,
  uploadProgress,
} from 'utils/helpers/helpers';
import { useKeyboardEvents } from 'utils/hooks/useKeyboardEvents';

import { mappedHotSpotsObject } from 'common/selectors/hotSpots';
import { mappedLayersObject } from 'common/selectors/layers';
import { mappedBackpackObject } from 'common/selectors/backpack';
import {
  storyListEditItemSelector,
  storyListEditTypeSelector,
  storyListSelectedEditFrame,
  storyListSelectedFrameIndexSelector,
} from 'common/selectors/editStory';
import { editItemAction, setEditAction } from 'common/actions/editStoryActions';
import { storyItemsSelector } from 'common/selectors/story';
import { framesListSelector } from 'common/selectors/frames';
import {
  addNewFrameAction,
  updateFrameAction,
  updateFrameSuccessAction,
} from 'common/actions/framesListActions';
import { storyListLanguageSelector } from 'common/selectors/storyList';
import { uploadFile as uploadFileService } from 'services/files';
import { errorToast } from 'services/toast';
import GridVideo from '../GridItems/GridVideo';
import GridImage from '../GridItems/GridImage';
import GridAudio from '../GridItems/GridAudio';
import GridUrl from '../GridItems/GridUrl';
import GridGame from '../GridItems/GridGame';
import useStyles from './styles';

const selector = createSelector(
  framesListSelector,
  storyItemsSelector,
  storyListEditTypeSelector,
  storyListSelectedEditFrame,
  storyListEditItemSelector,
  storyListSelectedFrameIndexSelector,
  mappedHotSpotsObject,
  mappedLayersObject,
  mappedBackpackObject,
  storyListLanguageSelector,
  (
    framesList,
    editStory,
    editType,
    editFrame,
    editFrameItem,
    editFrameIndex,
    hotSpotsObject,
    layersObject,
    backpackObject,
    currentLang
  ) => ({
    framesList,
    editStory,
    editType,
    editFrame,
    editFrameItem,
    editFrameIndex,
    hotSpotsObject,
    layersObject,
    backpackObject,
    currentLang,
  })
);

const GridItemWrap = ({
  onClick,
  isActive,
  onGoClick,
  setFormChanged,
  ...frame
}) => {
  const dispatch = useDispatch();
  const defaultValue = useMemo(() => getDefaultFrame(), []);

  const [fCoords, setFCoords] = useState(
    frame.formCoords || defaultValue.formCoords
  );

  const isCircle = useMemo(() => frame.isCircle, [frame.isCircle]);

  const {
    framesList,
    editStory,
    editType,
    editFrame,
    editFrameItem,
    editFrameIndex,
    hotSpotsObject,
    layersObject,
    backpackObject,
    currentLang,
  } = useSelector(selector);

  const classes = useStyles({
    isActive,
    fCoords,
    isCircle,
    hideBorder: frame.hideBorder,
  });

  const [dropTarget, setDropTarget] = useState();
  const [progress, setProgress] = useState(0);
  const [uploadingStatus, setUploadingStatus] = useState(false);
  const { isPressedAlt } = useKeyboardEvents();

  const { control, reset, setValue } = useForm({
    defaultValues: {
      additionalAttachmentInfo:
        editFrame?.additionalAttachmentInfo ||
        defaultValue.additionalAttachmentInfo,
      attachmentInfo: editFrame?.attachmentInfo || defaultValue.attachmentInfo,
      formCoords: editFrame?.formCoords || defaultValue.formCoords,
    },
  });

  const formField = useWatch({
    control,
  });

  const formFieldDebounced = useDebounce(formField, 300);

  const handleUpdateFrame = useCallback(() => {
    if (!editFrameItem) return;
    if (dropTarget) {
      dropTarget.click();
      setDropTarget(null);
    }
    const { id, ...fieldsData } = formField;
    const fieldsForCompare = {
      additionalAttachmentInfo:
        editFrame?.additionalAttachmentInfo ||
        defaultValue.additionalAttachmentInfo,
      attachmentInfo: editFrame?.attachmentInfo || defaultValue.attachmentInfo,
      formCoords: editFrame?.formCoords || defaultValue.formCoords,
    };
    const newEditFrameItem = { ...editFrameItem, ...fieldsData };
    if (!treeCompare(formFieldDebounced, fieldsForCompare)) {
      if (editFrameIndex > -1 && editFrameItem.originPage) {
        framesList[editFrameItem.originPage][editFrameIndex] = {
          ...newEditFrameItem,
        };
      }
      dispatch(setEditAction(true));
      dispatch(
        editItemAction({
          item: newEditFrameItem,
          type: editType,
        })
      );
    }
  }, [
    defaultValue,
    editFrameItem,
    dispatch,
    editFrameIndex,
    formFieldDebounced,
    formField,
    dropTarget,
    editType,
    framesList,
    editFrame,
  ]);

  useEffect(() => {
    handleUpdateFrame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced, dropTarget]);

  useEffect(() => {
    if (formFieldDebounced) setFCoords(frame?.formCoords);
  }, [formFieldDebounced, frame?.formCoords]);

  useEffect(() => {
    if (editFrame !== formField) {
      reset({
        additionalAttachmentInfo:
          editFrame?.additionalAttachmentInfo ||
          defaultValue.additionalAttachmentInfo,
        attachmentInfo:
          editFrame?.attachmentInfo || defaultValue.attachmentInfo,
        formCoords: editFrame?.formCoords || defaultValue.formCoords,
      });
    }
    // eslint-disable-next-line
  }, [editFrame, reset, defaultValue, formField.id]);

  const hotSpot = useMemo(() => {
    if (hotSpotsObject && Object.keys(hotSpotsObject).length) {
      return hotSpotsObject[frame?.id];
    }

    return [];
  }, [frame, hotSpotsObject]);

  const layer = useMemo(() => {
    if (layersObject && Object.keys(layersObject).length) {
      return layersObject[frame?.id];
    }

    return [];
  }, [layersObject, frame]);

  const backPackItem = useMemo(() => {
    if (backpackObject && Object.keys(backpackObject).length) {
      return backpackObject[frame?.id];
    }

    return [];
  }, [backpackObject, frame]);

  const handleClick = useCallback(() => onClick(frame), [onClick, frame]);

  const handleEndUploading = useCallback(() => {
    setProgress(0);
    setUploadingStatus(false);
  }, []);

  const handleFormChange = useCallback(
    (e, direction) => {
      e.preventDefault();
      const parent = e.target.parentElement;
      if (!editFrame) parent.click();
      const rect = parent.getBoundingClientRect();
      const pixelX = e.clientX - rect.left;
      const pixelY = e.clientY - rect.top;
      let percentX = (pixelX / parent.offsetWidth) * 100;
      let percentY = (pixelY / parent.offsetHeight) * 100;
      if (percentX > 100) percentX = 100;
      if (percentX < 0) percentX = 0;
      if (percentY > 100) percentY = 100;
      if (percentY < 0) percentY = 0;

      const newCoords = { ...fCoords };
      switch (direction) {
        // nw, we, sw, se - directions
        case 'nw':
          newCoords.nw = { x: percentX, y: percentY };
          break;
        case 'ne':
          newCoords.ne = { x: percentX, y: percentY };
          break;
        case 'se':
          newCoords.se = { x: percentX, y: percentY };
          break;
        case 'sw':
          newCoords.sw = { x: percentX, y: percentY };
          break;
        default:
          break;
      }
      setValue('formCoords', newCoords);
      setFCoords(newCoords);
    },
    [fCoords, setValue, editFrame]
  );

  const handleFormUpdate = useCallback(() => {
    const updatedFrame = { ...frame, formCoords: fCoords };
    if (frame?.id)
      dispatch(updateFrameAction(updatedFrame, UPDATE_TYPE.UPDATE));
    else {
      dispatch(
        editItemAction({
          item: updatedFrame,
          type: FRAME_TYPE,
        })
      );
      dispatch(updateFrameSuccessAction(updatedFrame));
    }
  }, [dispatch, frame, fCoords]);

  const clipPath = useMemo(
    () =>
      `polygon(${fCoords.nw.x}% ${fCoords.nw.y}%, ${fCoords.ne.x}% ${fCoords.ne.y}%, ${fCoords.se.x}% ${fCoords.se.y}%, ${fCoords.sw.x}% ${fCoords.sw.y}%)`,
    [fCoords]
  );

  const handleImageValidation = useCallback(
    (file) => {
      const fileSizeKiloBytes = file[0].size / 1024;

      if (fileSizeKiloBytes > MAX_IMAGE_SIZE) {
        errorToast('The maximum image size is 5 mb');
        handleEndUploading();
        return false;
      }
      return true;
    },
    [handleEndUploading]
  );

  const handleDropFile = useCallback(
    async (files, event) => {
      event.preventDefault();
      event.stopPropagation();
      setUploadingStatus(true);
      setDropTarget(event.target);
      if (files && files[0]) {
        const valid = handleImageValidation(files);
        if (valid) {
          await uploadFileService(files[0], {
            onUploadProgress: (progressEvent) =>
              uploadProgress(progressEvent, setProgress),
          })
            .then((res) => {
              switch (res.original.type) {
                case ATTACHMENTS_TYPE.IMAGE: {
                  if (editFrame?.fid || !editFrame?.attachmentInfo) {
                    const img = new Image();
                    img.src = res.original.url;
                    img.onload = (e) => {
                      const sizes = getImageSize(
                        e.target.width,
                        e.target.height,
                        editStory.isLandscape
                      );
                      setValue('h', sizes.imageHeight);
                      setValue('w', sizes.imageWidth);
                      if (editFrame) {
                        const updatedFrame = {
                          ...editFrame,
                          title: removeFileExtension(res.original.name),
                          h: sizes.imageHeight,
                          w: sizes.imageWidth,
                          attachmentInfo: res.original,
                          mobileAttachmentInfo: res.compressed,
                        };
                        dispatch(
                          addNewFrameAction(updatedFrame, UPDATE_TYPE.STANDART)
                        );
                      }
                    };
                  }
                  setValue('attachmentInfo', res.original);
                  setValue('mobileAttachmentInfo', res.compressed);
                  const updatedFrame = {
                    ...editFrame,
                    attachmentInfo: res.original,
                    mobileAttachmentInfo: res.compressed,
                  };
                  if (editFrame?.id)
                    dispatch(
                      updateFrameAction(updatedFrame, UPDATE_TYPE.UPDATE)
                    );
                  break;
                }
                case ATTACHMENTS_TYPE.AUDIO: {
                  if (Object.keys(frame.attachmentInfo).length)
                    setValue('additionalAttachmentInfo', res);
                  else setValue('attachmentInfo', res);
                  break;
                }
                default: {
                  errorToast('Invalid file attachment');
                }
              }
              handleEndUploading();
            })
            .catch(() => {
              handleEndUploading();
              errorToast('Invalid file attachment');
            });
        }
      }
    },
    [
      handleImageValidation,
      handleEndUploading,
      editFrame,
      setValue,
      dispatch,
      editStory.isLandscape,
      frame.attachmentInfo,
    ]
  );

  const content = useMemo(() => {
    switch (frame?.attachmentInfo?.type) {
      case ATTACHMENTS_TYPE.IMAGE: {
        return (
          <GridImage
            hotSpot={hotSpot}
            layer={layer}
            backPackItem={backPackItem}
            attachmentInfo={frame?.attachmentInfo}
            additionalAttachmentInfo={frame?.additionalAttachmentInfo}
            clipPath={clipPath}
            isCircle={isCircle}
            frameId={frame?.id}
            currentLang={currentLang}
          />
        );
      }
      case ATTACHMENTS_TYPE.AUDIO: {
        return <GridAudio />;
      }
      case ATTACHMENTS_TYPE.VIDEO: {
        return (
          <GridVideo
            attachmentInfo={frame?.attachmentInfo}
            videoImage={frame?.videoImage}
            clipPath={clipPath}
            isCircle={frame?.isCircle}
          />
        );
      }
      case ATTACHMENTS_TYPE.URL: {
        return (
          <GridUrl
            attachmentInfo={frame?.attachmentInfo}
            newWindow={frame?.newWindow}
            onGoClick={onGoClick}
            clipPath={clipPath}
            isCircle={frame?.isCircle}
          />
        );
      }
      case ATTACHMENTS_TYPE.GAME: {
        return (
          <GridGame
            attachmentInfo={frame?.attachmentInfo}
            newWindow={frame?.newWindow}
            onGoClick={onGoClick}
          />
        );
      }
      default:
        return null;
    }
  }, [
    hotSpot,
    layer,
    backPackItem,
    frame,
    onGoClick,
    clipPath,
    isCircle,
    currentLang,
  ]);

  if (isActive || !editFrame)
    return (
      <FileDrop
        className={classes.drop}
        targetClassName={classes.dropTarget}
        draggingOverTargetClassName={classes.dropOverTarget}
        draggingOverFrameClassName={classes.dropOverFrame}
        onDrop={handleDropFile}
      >
        {isPressedAlt && editFrame && (
          <Box className={classes.formHandlesBlock}>
            <Box
              draggable
              className={classes.topLeftHandle}
              onDrag={(e) => handleFormChange(e, 'nw')}
              onDragEnd={handleFormUpdate}
            />
            <Box
              draggable
              className={classes.topRightHandle}
              onDrag={(e) => handleFormChange(e, 'ne')}
              onDragEnd={handleFormUpdate}
            />
            <Box
              draggable
              className={classes.bottomRightHandle}
              onDrag={(e) => handleFormChange(e, 'se')}
              onDragEnd={handleFormUpdate}
            />
            <Box
              draggable
              onDrag={(e) => handleFormChange(e, 'sw')}
              onDragEnd={handleFormUpdate}
              className={classes.bottomLeftHandle}
            />
          </Box>
        )}
        {(!frame?.hideBorder || isActive) && (
          <Box className={classes.pathBackground} />
        )}
        <Box className={classes.wrap} onClick={handleClick}>
          <Box className={classes.folderWrap} />
          {uploadingStatus && (
            <Box className={classes.uploadWrap}>
              <p className={classes.dropText}>{progress}%</p>
            </Box>
          )}
          {content ||
            (!uploadingStatus && (
              <p className={classes.dropText}>Drop files here</p>
            ))}
        </Box>
      </FileDrop>
    );
  return (
    <>
      {!frame?.hideBorder && <Box className={classes.pathBackground} />}
      <Box className={classes.wrap} onClick={handleClick}>
        {content}
      </Box>
    </>
  );
};

GridItemWrap.defaultProps = {
  isActive: false,
};

GridItemWrap.propTypes = {
  onClick: PropTypes.func.isRequired,
  onGoClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};

export default GridItemWrap;

import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  frame: {
    borderRadius: ({ isCircle }) => (isCircle ? '50%' : theme.spacing(3)),
    width: '100%',
    height: '100%',
    clipPath: ({ clipPath }) => clipPath,
  },
  go: {
    position: 'absolute',
    display: 'inline-flex',
    top: theme.spacing(1.6),
    right: theme.spacing(1.6),
    height: theme.spacing(4),
    width: theme.spacing(4),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: transparentize(0.4, theme.colors.black),
    cursor: 'pointer',
  },
}));

const PREFIX = '@@STORY_LIST';

export const FETCH_STORIES = `${PREFIX}/FETCH_STORIES`;
export const FETCH_USERS = `${PREFIX}/FETCH_USERS`;
export const START_LOADING = `${PREFIX}/START_LOADING`;
export const END_LOADING = `${PREFIX}/END_LOADING`;
export const CREATE_STORY_SUCCESS = `${PREFIX}/CREATE_STORY_SUCCESS`;
export const DELETE_STORY_SUCCESS = `${PREFIX}/DELETE_STORY_SUCCESS`;
export const SET_CURRENT_STORY = `${PREFIX}/SET_CURRENT_STORY`;
export const EDIT_STATUS = `${PREFIX}/EDIT_STATUS`;
export const SET_CURRENT_LANGUAGE = `${PREFIX}/SET_CURRENT_LANGUAGE`;

export const getLanguageField = (field, lang) => {
  return field?.[lang] || field?.default || field || null;
};

export const getFileFromSvg = (svgCode) => {
  const svgBlob = new Blob([svgCode], { type: 'image/svg+xml' });
  const file = new File([svgBlob], 'image', { type: 'image/svg+xml' });

  return file;
};

import React, { forwardRef } from 'react';
import { Divider, ListItemText, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import StyledCheckbox from '../StyledCheckbox';
import useStyle from './styles';

const MultiselectItem = forwardRef(
  ({ value, checked, title, hasDivider, ...props }, ref) => {
    const classes = useStyle();

    return (
      <>
        {hasDivider && <Divider />}
        <MenuItem
          value={value}
          className={classes.root}
          {...props}
          innerRef={ref}
        >
          <StyledCheckbox size='small' onChange={() => {}} checked={checked} />
          <ListItemText primary={title} />
        </MenuItem>
      </>
    );
  }
);

MultiselectItem.displayName = 'MultiselectItem';

MultiselectItem.defaultProps = {
  hasDivider: false,
  value: '',
};

MultiselectItem.propTypes = {
  value: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  hasDivider: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default MultiselectItem;

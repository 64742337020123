import React, { useCallback, useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { CHAPTER_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import {
  SELECT_TAB_TYPE,
  SELECT_TAB_TYPE_INDEX,
} from 'constants/selectTabType';
import { useDebounce } from 'utils/hooks/useDebounce';
import { treeCompare } from 'utils/treeCompare';
import { storyListEditItemSelector } from 'common/selectors/editStory';
import {
  addNewChapterAction,
  editItemAction,
  setEditAction,
  updateChapterAction,
} from 'common/actions/editStoryActions';
import FormWrap from 'components/forms/FormWrap';
import StyledInput from 'components/forms/StyledInput';
import FileInput from 'components/forms/FileInput';
import StyledCheckbox from 'components/forms/StyledCheckbox';
import TabPanel from 'components/TabPanel/TabPanel';
import StyledTab from 'components/StyledTab/StyledTab';
import TabsWrapper from 'components/TabsWrapper/TabsWrapper';
import ControlBarBlock from '../ControlBarBlock';
import { defaultValues } from './ChapterControl.constants';
import { validationSchema } from './ChapterControl.validation';

const selector = createSelector(storyListEditItemSelector, (editChapter) => ({
  editChapter,
}));

const ChapterControl = () => {
  const { editChapter } = useSelector(selector);
  const dispatch = useDispatch();
  const [selectTab, setSelectTab] = useState(0);

  const {
    control,
    handleSubmit,
    setError,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: editChapter.id || '',
      title: editChapter.title || defaultValues.title,
      soundInfo: editChapter.soundInfo || defaultValues.soundInfo,
      loopAudio: editChapter.loopAudio || defaultValues.loopAudio,
      backgroundAttachment:
        editChapter.backgroundAttachment || defaultValues.backgroundAttachment,
      fadeIn: editChapter.fadeIn || defaultValues.fadeIn,
      fadeOut: editChapter.fadeOut || defaultValues.fadeOut,
    },
    mode: 'onChange',
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({
    control,
  });

  const formFieldDebounced = useDebounce(formField, 300);

  const handleUpdateChapter = useCallback(() => {
    const { id, ...fieldsData } = formFieldDebounced;
    // the order of the elements must be the same like in formField
    const fieldsForCompare = {
      id: editChapter.id,
      title: editChapter.title,
      soundInfo: editChapter.soundInfo || defaultValues.soundInfo,
      loopAudio: editChapter.loopAudio || defaultValues.loopAudio,
      backgroundAttachment:
        editChapter.backgroundAttachment || defaultValues.backgroundAttachment,
      fadeIn: editChapter.fadeIn || defaultValues.fadeIn,
      fadeOut: editChapter.fadeOut || defaultValues.fadeOut,
    };

    if (!treeCompare(formFieldDebounced, fieldsForCompare)) {
      dispatch(setEditAction(true));
      dispatch(
        editItemAction({
          item: { ...editChapter, ...fieldsData },
          type: CHAPTER_TYPE,
        })
      );
    }
  }, [dispatch, editChapter, formFieldDebounced]);

  useEffect(() => {
    if (formField.id !== editChapter.id) {
      reset({
        id: editChapter.id,
        title: editChapter.title,
        soundInfo: editChapter.soundInfo || defaultValues.soundInfo,
        loopAudio: editChapter.loopAudio || defaultValues.loopAudio,
        backgroundAttachment:
          editChapter.backgroundAttachment ||
          defaultValues.backgroundAttachment,
        fadeIn: editChapter.fadeIn || defaultValues.fadeIn,
        fadeOut: editChapter.fadeOut || defaultValues.fadeOut,
      });
    }
  }, [editChapter, formField, reset]);

  useEffect(() => {
    if (editChapter?.backgroundAttachment?.type) {
      setSelectTab(
        SELECT_TAB_TYPE_INDEX[editChapter?.backgroundAttachment?.type]
      );
    }
  }, [editChapter]);

  const onSubmit = useCallback(
    (data) => {
      const pages =
        editChapter?.children?.map((item) => item.id) ||
        editChapter?.pages ||
        [];
      const newEditedChapter = {
        id: data.id,
        title: data.title,
        story: editChapter.story,
        pages,
        ...data,
      };

      if (!editChapter.id) {
        dispatch(addNewChapterAction(newEditedChapter));
      } else {
        dispatch(updateChapterAction(newEditedChapter, UPDATE_TYPE.UPDATE));
      }
    },
    [editChapter, dispatch]
  );

  const handleChange = (event, newValue) => {
    setSelectTab(newValue);
  };

  useEffect(() => {
    handleUpdateChapter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced]);

  return (
    <FormWrap id='chapter-form' onSubmit={handleSubmit(onSubmit)}>
      <ControlBarBlock blockTitle='Details'>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              disableUnderline
              label='Name'
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='title'
          control={control}
        />
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Chapter sound'>
        <Controller
          render={(controlProps) => (
            <FileInput
              value={controlProps.field.value?.name || ''}
              label='Select file'
              placeholder='No file'
              accept={['audio/mp3']}
              onChange={(data) =>
                setValue('soundInfo', data.original, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              onError={setError}
              {...controlProps}
            />
          )}
          name='soundInfo'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              error={error ? error.message : ''}
              disableUnderline
              label='Fade in duration'
              {...field}
              fullWidth
            />
          )}
          name='fadeIn'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              error={error ? error.message : ''}
              disableUnderline
              label='Fade out duration'
              {...field}
              fullWidth
            />
          )}
          name='fadeOut'
          control={control}
        />
        <Controller
          name='loopAudio'
          control={control}
          render={({ field }) => (
            <StyledCheckbox
              disabled={!formField.soundInfo?.name}
              label='Loop audio'
              onChange={(e) => field.onChange(e.target.checked)}
              checked={!!field.value}
            />
          )}
        />
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Chapter background'>
        <TabsWrapper
          justifyContent='space-between'
          value={selectTab}
          onChange={handleChange}
        >
          <StyledTab label='Image/GIF' maxWidth={80} />
          <StyledTab label='Video' maxWidth={80} />
        </TabsWrapper>
        <TabPanel value={selectTab} index={0}>
          <Controller
            render={(controlProps) => (
              <FileInput
                value={
                  SELECT_TAB_TYPE[selectTab] ===
                  editChapter?.backgroundAttachment?.type
                    ? editChapter?.backgroundAttachment?.name
                    : ''
                }
                label='Select file (5mb max.)'
                placeholder='No file'
                accept={['image/*']}
                clearErrors={clearErrors}
                errors={errors}
                validateImage
                onError={setError}
                onChange={(data) => {
                  setValue('backgroundAttachment', data.original);
                  setValue('mobileBackgroundAttachment', data.compressed);
                }}
                onDelete={() => {
                  setValue('backgroundAttachment', {});
                  setValue('mobileBackgroundAttachment', {});
                }}
                {...controlProps}
              />
            )}
            name='backgroundAttachment'
            control={control}
          />
        </TabPanel>
        <TabPanel value={selectTab} index={1}>
          <Controller
            render={(controlProps) => (
              <FileInput
                value={
                  SELECT_TAB_TYPE[selectTab] ===
                  editChapter?.backgroundAttachment?.type
                    ? editChapter?.backgroundAttachment?.name
                    : ''
                }
                label='Select file'
                placeholder='No file'
                accept={['video/*']}
                onError={setError}
                onChange={(data) =>
                  setValue('backgroundAttachment', data.original)
                }
                onDelete={() => setValue('backgroundAttachment', {})}
                {...controlProps}
              />
            )}
            name='backgroundAttachment'
            control={control}
          />
        </TabPanel>
      </ControlBarBlock>
    </FormWrap>
  );
};

export default ChapterControl;

import React from 'react';
import classnames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';

export const LogoIcon = ({ className, ...props }) => {
  return (
    <SvgIcon
      viewBox='0 0 13 14'
      style={{ width: 13, height: 14 }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classnames({
        [className]: !!className,
      })}
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.2.41c3.12.54 5.02 3.4 4.89 6.77a6.37 6.37 0 01-5.45 5.98c-1.86.21-3.75.13-5.63.14-.55 0-.96-.46-.96-1.04-.02-2.45-.01-2.55-.02-5 0-.35.13-.45.47-.4a2.96 2.96 0 012.46 2.85c0 .65.26.94.9.93.78-.01 1.57 0 2.32-.13C8.22 10.13 9.21 8.17 9 6.25 8.75 4 6.91 2.73 4.89 2.82c-1 .05-2 .03-2.98-.14C.76 2.48-.02 1.33.08 0c2.4.12 4.8.02 7.12.41z'
        fill='url(#paint0_linear)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M.2 6.13c1.86 0 2.26 1.39 2.63 1.14.38-.25-.27-1.55-.98-2.25a2.77 2.77 0 00-1.53-.66c-.1-.01-.3-.05-.3.32 0 .5-.12 1.45.19 1.45z'
        fill='#FD2473'
      />
      <defs>
        <linearGradient
          id='paint0_linear'
          x1='.03'
          y1='0'
          x2='.03'
          y2='13.31'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#EC286F' />
          <stop offset='1' stopColor='#FF2373' />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

// <svg width='13' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'>
//       <path
//         fillRule='evenodd'
//         clipRule='evenodd'
//         d='M7.2.41c3.12.54 5.02 3.4 4.89 6.77a6.37 6.37 0 01-5.45 5.98c-1.86.21-3.75.13-5.63.14-.55 0-.96-.46-.96-1.04-.02-2.45-.01-2.55-.02-5 0-.35.13-.45.47-.4a2.96 2.96 0 012.46 2.85c0 .65.26.94.9.93.78-.01 1.57 0 2.32-.13C8.22 10.13 9.21 8.17 9 6.25 8.75 4 6.91 2.73 4.89 2.82c-1 .05-2 .03-2.98-.14C.76 2.48-.02 1.33.08 0c2.4.12 4.8.02 7.12.41z'
//         fill='url(#paint0_linear)'
//       />
//       <path
//         fillRule='evenodd'
//         clipRule='evenodd'
//         d='M.2 6.13c1.86 0 2.26 1.39 2.63 1.14.38-.25-.27-1.55-.98-2.25a2.77 2.77 0 00-1.53-.66c-.1-.01-.3-.05-.3.32 0 .5-.12 1.45.19 1.45z'
//         fill='#FD2473'
//       />
//       <defs>
//         <linearGradient
//           id='paint0_linear'
//           x1='.03'
//           y1='0'
//           x2='.03'
//           y2='13.31'
//           gradientUnits='userSpaceOnUse'
//         >
//           <stop stopColor='#EC286F' />
//           <stop offset='1' stopColor='#FF2373' />
//         </linearGradient>
//       </defs>
//     </svg>

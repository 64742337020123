import React from 'react';
import classnames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';

export const BackpackIcon = ({ fillOpacity = 0.5, className, ...props }) => {
  return (
    <SvgIcon
      viewBox='0 0 12 12'
      style={{ width: 12, height: 12, fill: 'none' }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classnames({
        [className]: !!className,
      })}
      {...props}
    >
      <path
        d='M5.6.17L.74 2C.29 2.17 0 2.6 0 3.06v5.27c0 .43.24.82.62 1.01l4.88 2.44c.31.16.69.16 1 0l4.88-2.44c.38-.19.62-.58.62-1V3.05c0-.47-.3-.9-.73-1.05L6.4.18a1.1 1.1 0 00-.78 0zM6 1.63l4.5 1.69v.02L6 5.17 1.5 3.34v-.02L6 1.63zm.75 8.35v-3.5l3.75-1.52V8.1L6.75 9.98z'
        fill='#fff'
        fillOpacity={fillOpacity}
      />
    </SvgIcon>
  );
};

/* <svg width='12' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'>
<path
  d='M5.6.17L.74 2C.29 2.17 0 2.6 0 3.06v5.27c0 .43.24.82.62 1.01l4.88 2.44c.31.16.69.16 1 0l4.88-2.44c.38-.19.62-.58.62-1V3.05c0-.47-.3-.9-.73-1.05L6.4.18a1.1 1.1 0 00-.78 0zM6 1.63l4.5 1.69v.02L6 5.17 1.5 3.34v-.02L6 1.63zm.75 8.35v-3.5l3.75-1.52V8.1L6.75 9.98z'
  fill='#fff'
  fillOpacity={fillOpacity}
/>
</svg> */

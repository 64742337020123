import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  wrap: {
    position: 'relative',
    width: '100%',
    height: '100%',

    '& > img': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
    },
  },
}));

import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.lazy((value) => {
    if (value.gltfAttachmentInfo && value.gltfAttachmentInfo.name) {
      return Yup.object().shape({
        title: Yup.string().trim().required('This field is required'),
        description: Yup.string().trim().required('This field is required'),
        type: Yup.string().trim().required('This field is required'),
        link: Yup.string().trim(),
        originPage: Yup.string().trim().required('This field is required'),
        originFrame: Yup.string().trim().required('This field is required'),
        backpackAttachmentInfo: Yup.object()
          .shape({
            type: Yup.string().trim(),
            name: Yup.string().trim(),
            url: Yup.string().trim(),
            size: Yup.number(),
          })
          .nullable(true)
          .test(
            'backpackAttachmentInfo',
            'This field is required',
            (fieldData) =>
              !!(fieldData?.type || fieldData?.url || fieldData?.name)
          ),
        frameAttachmentInfo: Yup.object()
          .shape({
            type: Yup.string().trim(),
            name: Yup.string().trim(),
            url: Yup.string().trim(),
            size: Yup.number(),
          })
          .nullable(true)
          .test(
            'frameAttachmentInfo',
            'This field is required',
            (fieldData) =>
              !!(fieldData?.type || fieldData?.url || fieldData?.name)
          ),
        gltfAttachmentInfo: Yup.object()
          .shape({
            type: Yup.string().trim(),
            name: Yup.string().trim(),
            url: Yup.string().trim(),
            size: Yup.number(),
          })
          .test(
            'gltfAttachmentInfo',
            'This field is required',
            (fieldData) =>
              !!(fieldData?.type || fieldData?.url || fieldData?.name)
          )
          .test('gltfAttachmentInfo', 'Must be *.obj format', (fieldData) => {
            if (!fieldData?.name) {
              return true;
            }
            const nameArray = fieldData.name.split('.');
            return (
              nameArray[nameArray.length - 1] === 'obj' ||
              nameArray[nameArray.length - 1] === 'gltf'
            );
          }),
        binAttachmentInfo: Yup.object()
          .shape({
            type: Yup.string().trim(),
            name: Yup.string().trim(),
            url: Yup.string().trim(),
            size: Yup.number(),
          })
          .test(
            'binAttachmentInfo',
            'This field is required',
            (fieldData) =>
              !!(fieldData?.type || fieldData?.url || fieldData?.name)
          )
          .test('binAttachmentInfo', 'Must be *.mtl', (fieldData) => {
            if (!fieldData?.name) {
              return true;
            }
            const nameArray = fieldData.name.split('.');

            return nameArray[nameArray.length - 1] === 'mtl';
          }),
      });
    }

    return Yup.object().shape({
      title: Yup.string().trim().required('This field is required'),
      description: Yup.string().trim().required('This field is required'),
      type: Yup.string().trim().required('This field is required'),
      link: Yup.string().trim(),
      originPage: Yup.string().trim().required('This field is required'),
      originFrame: Yup.string().trim().required('This field is required'),
      backpackAttachmentInfo: Yup.object()
        .shape({
          type: Yup.string().trim(),
          name: Yup.string().trim(),
          url: Yup.string().trim(),
          size: Yup.number(),
        })
        .nullable(true)
        .test(
          'backpackAttachmentInfo',
          'This field is required',
          (fieldData) =>
            !!(fieldData?.type || fieldData?.url || fieldData?.name)
        ),
      frameAttachmentInfo: Yup.object()
        .shape({
          type: Yup.string().trim(),
          name: Yup.string().trim(),
          url: Yup.string().trim(),
          size: Yup.number(),
        })
        .nullable(true)
        .test(
          'frameAttachmentInfo',
          'This field is required',
          (fieldData) =>
            !!(fieldData?.type || fieldData?.url || fieldData?.name)
        ),
      gltfAttachmentInfo: Yup.object()
        .shape({
          type: Yup.string().trim(),
          name: Yup.string().trim(),
          url: Yup.string().trim(),
          size: Yup.number(),
        })
        .nullable(true),
    });
  });

import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import soundIcon from 'assets/icons/sound.svg';
import {
  IMAGE_LAYER_TYPE,
  SPEECHBUBBLE_LAYER_TYPE,
  TEXT_LAYER_TYPE,
} from 'constants/editTypes';
import { layerFont } from 'constants/defaultItems';
import Image from 'components/Image';
import {
  controlSpeechCoords,
  defaultFCoords,
} from '../../../PagesList/Speechbubble/Speechbubble.constants';
import useStyles from './styles';

const GridLayer = ({
  width,
  height,
  left,
  top,
  imageUrl,
  imageName,
  type,
  content,
  textSize,
  textStyle,
  isSpeechbubble,
  speechbubble,
  hasSound,
}) => {
  const { vertical, horisontal } = speechbubble?.taleDirection || {
    vertical: '',
    horisontal: '',
  };

  const { borderColor, backgroundColor } = speechbubble;

  const speechControls = useMemo(
    () => controlSpeechCoords[`${horisontal}-${vertical}`],
    [vertical, horisontal]
  );

  const fCoords = useMemo(
    () =>
      speechbubble?.formCoords || defaultFCoords[`${horisontal}-${vertical}`],
    [speechbubble, vertical, horisontal]
  );

  const classes = useStyles({
    width,
    height,
    left,
    top,
    textSize,
    textStyle,
    vertical: vertical || '',
    speechControls: speechControls || [],
    fCoords: fCoords || [],
    borderColor: borderColor || '#000',
    backgroundColor: backgroundColor || '#fff',
  });
  const textLayerRef = useRef();

  const innerElement = useMemo(() => {
    if (type === IMAGE_LAYER_TYPE || type === SPEECHBUBBLE_LAYER_TYPE) {
      return (
        <>
          <Image src={imageUrl} alt={imageName} />
          {hasSound && (
            <Image src={soundIcon} className={classes.sound} alt='sound-ico' />
          )}
        </>
      );
    }

    if (type === TEXT_LAYER_TYPE) {
      return (
        <>
          <Box
            component='p'
            className={`${classes.layerText} ${
              isSpeechbubble && classes.speechBubbleText
            }`}
            draggable='false'
          >
            {content}
          </Box>
        </>
      );
    }

    return null;
  }, [
    type,
    content,
    imageUrl,
    imageName,
    isSpeechbubble,
    classes.layerText,
    classes.speechBubbleText,
    classes.sound,
    hasSound,
  ]);

  const clipPath = useMemo(() => {
    return `polygon(${fCoords
      ?.map((coords) => `${coords.x}% ${coords.y}%`)
      .join(', ')})`;
  }, [fCoords]);

  if (isSpeechbubble) {
    return (
      <Box
        className={`${classes.wrap} ${classes.textBorder}`}
        ref={textLayerRef}
      >
        <Box className={classes.pathBackground} style={{ clipPath }}>
          <div className={classes.speechTextContainer}>
            <Box
              className={`${classes.layerText} ${classes.speechBubbleText}`}
              style={{
                fontSize: `${textSize}pt`,
                fontFamily: `${textStyle}`,
              }}
              draggable='false'
              component='p'
            >
              {content}
            </Box>
          </div>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={`${classes.wrap} ${classes.textBorder}`} ref={textLayerRef}>
      {innerElement}
    </Box>
  );
};

GridLayer.defaultProps = {
  top: '',
  left: '',
  width: '',
  height: '',
  imageUrl: '',
  imageName: '',
  content: '',
  type: '',
  textSize: layerFont.fontSize,
  textStyle: layerFont.fontStyle,
  hasSound: false,
};

GridLayer.propTypes = {
  top: PropTypes.string,
  left: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  imageUrl: PropTypes.string,
  imageName: PropTypes.string,
  content: PropTypes.string,
  type: PropTypes.oneOf([
    IMAGE_LAYER_TYPE,
    TEXT_LAYER_TYPE,
    SPEECHBUBBLE_LAYER_TYPE,
  ]),
  textSize: PropTypes.number,
  textStyle: PropTypes.string,
  hasSound: PropTypes.bool,
};

export default GridLayer;

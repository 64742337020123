import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import {
  BACKPACK_TYPE,
  CHAPTER_TYPE,
  CHECKPOINT_TYPE,
  DECISION_TYPE,
  FRAME_TYPE,
  HOT_SPOT_TYPE,
  IMAGE_LAYER_TYPE,
  IMAGE_VIDEO_TYPE,
  PAGE_TYPE,
  SETTINGS_TYPE,
  SPEECHBUBBLE_LAYER_TYPE,
  TEXT_LAYER_TYPE,
} from 'constants/editTypes';
import { speechbubbleSelector } from 'common/selectors/speechbubble';
import {
  AddDecisionIcon,
  AddHotspotIcon,
  ImageIcon,
  AddLayerTextIcon,
  BackpackIcon,
  CheckpointControlIcon,
  ClosedFolderIcon,
  FrameIcon,
  PageIcon,
  SettingsIcon,
} from 'components/Icons';
import { SpeechbubbleIcon } from 'components/Icons/SpeechbubbleIcon';
import { SpeechbubbleDrowIcon } from 'components/Icons/SpeechbubbleDrowIcon';
import useStyles from './styles';

const selector = createSelector(speechbubbleSelector, (speechBubbleState) => ({
  speechBubbleState,
}));

const ControlBarTitle = ({ type }) => {
  const classes = useStyles();

  const { speechBubbleState } = useSelector(selector);

  return useMemo(() => {
    switch (type) {
      case HOT_SPOT_TYPE: {
        return (
          <Box className={classes.title}>
            <AddHotspotIcon /> Hotspot
          </Box>
        );
      }
      case FRAME_TYPE: {
        return (
          <Box className={classes.title}>
            <FrameIcon fillOpacity={1} /> Frame
          </Box>
        );
      }
      case DECISION_TYPE: {
        return (
          <Box className={classes.title}>
            <AddDecisionIcon /> Decision
          </Box>
        );
      }
      case CHAPTER_TYPE: {
        return (
          <Box className={classes.title}>
            <ClosedFolderIcon /> Chapter
          </Box>
        );
      }
      case PAGE_TYPE: {
        return (
          <Box className={classes.title}>
            <PageIcon fillOpacity={1} /> Page
          </Box>
        );
      }
      case IMAGE_LAYER_TYPE: {
        return (
          <Box className={classes.title}>
            <ImageIcon fillOpacity={1} /> Image Layer
          </Box>
        );
      }
      case TEXT_LAYER_TYPE: {
        return (
          <Box className={classes.title}>
            <AddLayerTextIcon fillOpacity={1} /> Text Layer
          </Box>
        );
      }
      case SPEECHBUBBLE_LAYER_TYPE: {
        return (
          <Box className={classes.title}>
            {speechBubbleState.isDrowingMode ? (
              <>
                <SpeechbubbleDrowIcon />
                Drawing Speech Bubble
              </>
            ) : (
              <>
                <SpeechbubbleIcon fillOpacity={1} />
                Speech Bubble
              </>
            )}
          </Box>
        );
      }
      case BACKPACK_TYPE: {
        return (
          <Box className={classes.title}>
            <BackpackIcon fillOpacity={1} /> Item
          </Box>
        );
      }
      case SETTINGS_TYPE: {
        return (
          <Box className={classes.title}>
            <SettingsIcon /> Story settings
          </Box>
        );
      }
      case CHECKPOINT_TYPE: {
        return (
          <Box className={classes.title}>
            <CheckpointControlIcon /> Checkpoint
          </Box>
        );
      }

      default:
        return null;
    }
  }, [type, classes.title, speechBubbleState.isDrowingMode]);
};

ControlBarTitle.propTypes = {
  type: PropTypes.oneOf([
    HOT_SPOT_TYPE,
    FRAME_TYPE,
    DECISION_TYPE,
    PAGE_TYPE,
    CHAPTER_TYPE,
    IMAGE_LAYER_TYPE,
    TEXT_LAYER_TYPE,
    IMAGE_VIDEO_TYPE,
    BACKPACK_TYPE,
    SETTINGS_TYPE,
    CHECKPOINT_TYPE,
    SPEECHBUBBLE_LAYER_TYPE,
  ]).isRequired,
};

export default ControlBarTitle;

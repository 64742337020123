import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'block',
    maxWidth: '100%',
    width: '100%',
    height: '100%',
    objectFit: 'fill',
  },
}));

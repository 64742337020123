import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

const borderColor = (isLayer, isBackpack, theme) => {
  if (isLayer) {
    return theme.colors.layerDropZone;
  }
  if (isBackpack) {
    return theme.colors.backpackDropZone;
  }

  return theme.colors.dropZone;
};

export default makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: ({ isLayer, isBackpack }) =>
      isLayer || isBackpack
        ? 'transparent'
        : transparentize(0.67, theme.colors.dropZone),
    border: ({ isLayer, isBackpack, isDrawing }) =>
      `1px solid  ${
        isDrawing ? '#ffffff' : borderColor(isLayer, isBackpack, theme)
      }`,
    touchAction: 'none',
    zIndex: '1',
  },
  layerText: {
    width: '100%',
    color: theme.colors.black,
    margin: 0,
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  parent: {
    position: 'absolute',
    borderRadius: ({ isCircle }) => isCircle && '50%',
  },
  child: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
  },
  zIndex: {
    zIndex: 10,
  },
  mediaIcon: {
    position: 'absolute',
    top: '20%',
    left: '25%',
    height: '50%',
  },
  spotFold: {
    position: 'relative',
    background: theme.colors.ebonyClay,
    borderRadius: ({ isCircle }) => isCircle && '50%',
  },
}));

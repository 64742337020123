import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '1 1 100%',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      padding: '0 0 0px',
    },

    [theme.breakpoints.up('sm')]: {
      padding: '72px 7px 30px',
    },

    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(3)}px ${theme.spacing(3.875)}px`,
    },

    '& > .MuiBox-root': {
      [theme.breakpoints.down('sm')]: {
        padding: '7px',
        flex: '0 0 100%',
        width: '100%',
      },

      [theme.breakpoints.up('sm')]: {
        padding: '0px',
        flex: '0 0 49.5%',
        width: '49.5%',
      },

      [theme.breakpoints.up('md')]: {
        flex: '0 0 49%',
        width: '49%',
      },
    },
  },
}));

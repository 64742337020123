import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object().shape({
    decisionName: Yup.string().trim().required('This field is required'),
    storyEnd: Yup.boolean(),
  });

export const customSchema = () =>
  Yup.array().of(
    Yup.object().shape({
      page: Yup.string().trim().required('This field is required'),
      frame: Yup.string().trim().required('This field is required'),
    })
  );

import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const FormWrap = ({ id, onSubmit, children }) => {
  const classes = useStyles();

  return (
    <form className={classes.wrap} id={id} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

FormWrap.defaultProps = {
  onSubmit: () => {},
};

FormWrap.propTypes = {
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

export default FormWrap;

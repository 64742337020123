import React, { useMemo } from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import {
  BACKPACK_TYPE,
  CHAPTER_TYPE,
  CHECKPOINT_TYPE,
  DECISION_TYPE,
  FRAME_TYPE,
  HOT_SPOT_TYPE,
  IMAGE_LAYER_TYPE,
  PAGE_TYPE,
  SETTINGS_TYPE,
  SPEECHBUBBLE_LAYER_TYPE,
  TEXT_LAYER_TYPE,
} from 'constants/editTypes';
import {
  storyListEditSettingsSelector,
  storyListEditTypeSelector,
} from 'common/selectors/editStory';
import ControlBarWrap from './ControlBarWrap';
import HotspotControl from './HotspotControl';
import DecisionControl from './DecisionControl';
import FrameControl from './FrameControl';
import PageControl from './PageControl';
import ChapterControl from './ChapterControl';
import LayerImageControl from './LayerImageControl';
import LayerTextControl from './LayerTextControl';
import ControlBackpackItem from './ControlBackpackItem';
import SettingsControl from './SettingsControl/SettingsControl';
import CheckpointControl from './CheckpointControl';
import LayerSpeechbubbleControl from './LayerSpeechbubbleControl/LayerSpeechbubbleControl';

const selector = createSelector(
  storyListEditTypeSelector,
  storyListEditSettingsSelector,
  (editType, settingsOpen) => ({
    editType,
    settingsOpen,
  })
);

const ControlBar = () => {
  const { editType, settingsOpen } = useSelector(selector);

  const content = useMemo(() => {
    switch (editType) {
      case HOT_SPOT_TYPE:
        return <HotspotControl />;
      case DECISION_TYPE:
        return <DecisionControl />;
      case FRAME_TYPE:
        return <FrameControl />;
      case PAGE_TYPE:
        return <PageControl />;
      case CHAPTER_TYPE:
        return <ChapterControl />;
      case IMAGE_LAYER_TYPE:
        return <LayerImageControl />;
      case TEXT_LAYER_TYPE:
        return <LayerTextControl />;
      case SPEECHBUBBLE_LAYER_TYPE:
        return <LayerSpeechbubbleControl />;
      case BACKPACK_TYPE:
        return <ControlBackpackItem />;
      case CHECKPOINT_TYPE:
        return <CheckpointControl />;
      default:
        return null;
    }
  }, [editType]);

  if (!editType && !settingsOpen) {
    return null;
  }

  return (
    <ControlBarWrap type={settingsOpen ? SETTINGS_TYPE : editType}>
      {settingsOpen ? <SettingsControl /> : content}
    </ControlBarWrap>
  );
};

export default ControlBar;

import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@material-ui/core';
import useStyles from './styles';

const StyledTab = ({ maxWidth, padding, ...props }) => {
  const { root } = useStyles({ maxWidth, padding });

  return <Tab className={root} {...props} />;
};

StyledTab.defaultProps = {
  maxWidth: 'auto',
};

StyledTab.propTypes = {
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default StyledTab;

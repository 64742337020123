import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrap: {
    '& .MuiFormControl-root': {
      position: 'relative',
      paddingBottom: theme.spacing(2),
    },

    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      fontSize: theme.spacing(1.25),
    },

    '& .MuiInputBase-inputMultiline': {
      minHeight: `${theme.spacing(10.25)}px !important`,
    },
  },
}));

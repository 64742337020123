import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    backgroundColor: theme.colors.menuBackground,
    boxShadow: 'none',

    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      left: 0,
    },
    [theme.breakpoints.up('md')]: {
      position: 'static',
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2.125)}px`,
    height: ({ isSmallDesktop, isEdit }) =>
      !isEdit && isSmallDesktop ? '62px' : theme.spacing(6),
    minHeight: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      padding: '10px 12px',
    },
  },
  logoWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',

    '& svg': {
      width: '20px !important',
      height: '20px !important',
    },
  },
  logoTitle: {
    marginLeft: theme.spacing(0.5),
    fontSize: theme.spacing(2),
    fontWeight: 500,
  },
  storyTitle: {
    maxWidth: theme.spacing(14.375),
    fontSize: theme.spacing(1.375),
    marginLeft: theme.spacing(1.125),
  },
  arrow: {
    '&:disabled': {
      opacity: 0.5,
      padding: '10px',
    },
  },
  buttonsWrap: {
    '& > .MuiButton-root': {
      fontSize: theme.spacing(1.25),
      margin: `0 ${theme.spacing(0.5)}px`,
    },
  },
  mobileButtonsWrap: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: '2px',

    '& > .MuiButton-root': {
      fontSize: theme.spacing(1.25),
    },
  },
  menuIcons: {
    '& .MuiIconButton-root': {
      padding: '12px 9px',
    },
  },

  draft: {
    '& > .MuiTypography-root': {
      fontSize: theme.spacing(1.25),
    },
  },
  preview: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      transform: 'scale(1)',
    },
  },
}));

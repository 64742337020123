import {
  CLEAR_EDIT_ITEM,
  CONFIRM_OPEN,
  EDIT_ITEM,
  END_LOADING,
  FETCH_LIST_ERROR,
  FETCH_LIST_SUCCESS,
  FETCHED_HISTORY,
  NEXT_EDIT_ITEM,
  SET_EDIT,
  SET_FRAME_ANIMATIOIN,
  SET_LOCK_FRAMES_FETCH,
  SET_SELECTED,
  SET_SELECTED_TAB,
  SET_SETTINGS,
  SET_SHOW_GRID_LINES,
  SET_SNAP_TO_FRAMES,
  SET_WARNING,
  SET_WARNING_CHECKPOINTS,
  SET_WARNING_DECISIONS,
  SET_WARNING_HOTSPOTS,
  START_LOADING,
  UPDATE_LIST_POSITION_ERROR,
  UPDATE_LIST_POSITION_SUCCESS,
  NEXT_SET_SELECTED,
} from 'common/types/editStory';

export const defaultSelected = {
  chapter: '',
  page: '',
  frame: '',
  frameItem: '',
};

const initialState = {
  list: [],
  edited: false,
  loading: false,
  warning: false,
  warningHotspots: [],
  warningDecisions: [],
  warningCheckpoints: [],
  settingsOpen: false,
  storySelectedTab: 0,
  selected: defaultSelected,
  nextSelected: defaultSelected,
  editType: '',
  editedItem: null,
  nextEditItem: null,
  history: {},
  lockFramesFetch: false,
  showGridLines: false,
  frameAnimation: {
    frameId: '',
    animationType: '',
  },
  confirmOpen: false,
  snapFramesEnabled: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        list: [...action.payload],
      };
    case FETCHED_HISTORY:
      return {
        ...state,
        history: action.payload,
      };
    case SET_LOCK_FRAMES_FETCH:
      return { ...state, lockFramesFetch: action.payload };
    case FETCH_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        list: [],
      };
    case SET_SELECTED:
      return {
        ...state,
        selected: action.payload,
      };
    case NEXT_SET_SELECTED:
      return {
        ...state,
        nextSelected: action.payload,
      };
    case UPDATE_LIST_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        list: [...action.payload],
      };
    case UPDATE_LIST_POSITION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EDIT_ITEM: {
      return {
        ...state,
        settingsOpen: false,
        editType: action.payload.type,
        editedItem: action.payload.item,
      };
    }
    case CONFIRM_OPEN: {
      return {
        ...state,
        confirmOpen: action.payload,
      };
    }

    case NEXT_EDIT_ITEM: {
      return {
        ...state,
        nextEditItem: action.payload,
      };
    }
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case END_LOADING:
      return {
        ...state,
        loading: false,
      };
    case SET_EDIT: {
      return {
        ...state,
        edited: action.payload,
      };
    }
    case SET_WARNING: {
      return {
        ...state,
        warning: action.payload,
      };
    }
    case SET_WARNING_HOTSPOTS: {
      return {
        ...state,
        warningHotspots: action.payload,
      };
    }
    case SET_WARNING_DECISIONS: {
      return {
        ...state,
        warningDecisions: action.payload,
      };
    }
    case SET_WARNING_CHECKPOINTS: {
      return {
        ...state,
        warningCheckpoints: action.payload,
      };
    }
    case CLEAR_EDIT_ITEM:
      return {
        ...state,
        settingsOpen: false,
        editType: '',
        editedItem: null,
        edited: false,
      };
    case SET_SETTINGS:
      return {
        ...state,
        settingsOpen: action.payload,
      };

    case SET_SELECTED_TAB:
      return {
        ...state,
        storySelectedTab: action.payload,
      };

    case SET_FRAME_ANIMATIOIN:
      return {
        ...state,
        frameAnimation: action.payload,
      };

    case SET_SHOW_GRID_LINES:
      return {
        ...state,
        showGridLines: action.payload,
      };

    case SET_SNAP_TO_FRAMES:
      return {
        ...state,
        snapFramesEnabled: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;

import React, { useCallback, useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { PAGE_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import {
  SELECT_TAB_TYPE,
  SELECT_TAB_TYPE_INDEX,
} from 'constants/selectTabType';
import { useDebounce } from 'utils/hooks/useDebounce';
import { treeCompare } from 'utils/treeCompare';
import { storyListEditItemSelector } from 'common/selectors/editStory';
import {
  editItemAction,
  setEditAction,
  updatePageAction,
} from 'common/actions/editStoryActions';
import { framesListSelector } from 'common/selectors/frames';
import FormWrap from 'components/forms/FormWrap';
import StyledInput from 'components/forms/StyledInput';
import FileInput from 'components/forms/FileInput';
import StyledCheckbox from 'components/forms/StyledCheckbox';
import TabsWrapper from 'components/TabsWrapper/TabsWrapper';
import StyledTab from 'components/StyledTab/StyledTab';
import TabPanel from 'components/TabPanel/TabPanel';
import ControlBarBlock from '../ControlBarBlock';
import { defaultValues } from './PageControl.constants';
import { validationSchema } from './PageControl.validation';

const selector = createSelector(
  storyListEditItemSelector,
  framesListSelector,
  (editPage, framesList) => ({
    editPage,
    framesList,
  })
);

const PageControl = () => {
  const { editPage } = useSelector(selector);
  const dispatch = useDispatch();
  const [selectTab, setSelectTab] = useState(0);
  const {
    control,
    setError,
    reset,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      id: editPage.id || '',
      backgroundAttachment:
        editPage.backgroundAttachment || defaultValues.backgroundAttachment,
      title: editPage.title || defaultValues.title,
      soundInfo: editPage.soundInfo || defaultValues.soundInfo,
      loopAudio: editPage.loopAudio || defaultValues.loopAudio,
      isHideNavigation:
        editPage.isHideNavigation || defaultValues.isHideNavigation,
      fadeIn: editPage.fadeIn || defaultValues.fadeIn,
      fadeOut: editPage.fadeOut || defaultValues.fadeOut,
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({
    control,
  });

  const formFieldDebounced = useDebounce(formField, 300);

  const handleChange = (event, newValue) => {
    setSelectTab(newValue);
  };

  useEffect(() => {
    if (formField.id !== editPage.id) {
      reset({
        id: editPage.id,
        backgroundAttachment:
          editPage.backgroundAttachment || defaultValues.backgroundAttachment,
        title: editPage.title,
        soundInfo: editPage.soundInfo || defaultValues.soundInfo,
        loopAudio: editPage.loopAudio || defaultValues.loopAudio,
        isHideNavigation:
          editPage.isHideNavigation || defaultValues.isHideNavigation,
        fadeIn: editPage.fadeIn || defaultValues.fadeIn,
        fadeOut: editPage.fadeOut || defaultValues.fadeOut,
      });
    }
  }, [reset, editPage, formField]);

  const handleUpdatePage = useCallback(() => {
    const { id, ...fieldsData } = formFieldDebounced;
    // the order of the elements must be the same like in formField
    const fieldsForCompare = {
      id: editPage.id,
      backgroundAttachment:
        editPage.backgroundAttachment || defaultValues.backgroundAttachment,
      title: editPage.title,
      soundInfo: editPage.soundInfo || defaultValues.soundInfo,
      loopAudio: editPage.loopAudio || defaultValues.loopAudio,
      isHideNavigation:
        editPage.isHideNavigation || defaultValues.isHideNavigation,
      fadeIn: editPage.fadeIn || defaultValues.fadeIn,
      fadeOut: editPage.fadeOut || defaultValues.fadeOut,
    };
    if (!treeCompare(formFieldDebounced, fieldsForCompare)) {
      dispatch(
        editItemAction({
          item: { ...editPage, ...fieldsData },
          type: PAGE_TYPE,
        })
      );
      dispatch(setEditAction(true));
    }
  }, [editPage, dispatch, formFieldDebounced]);

  useEffect(() => {
    handleUpdatePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced]);

  useEffect(() => {
    if (editPage?.backgroundAttachment?.type) {
      setSelectTab(SELECT_TAB_TYPE_INDEX[editPage?.backgroundAttachment?.type]);
    }
  }, [editPage]);

  const onSubmit = useCallback(
    (data) => {
      const { id, ...fieldsData } = data;
      dispatch(
        updatePageAction({ ...editPage, ...fieldsData }, UPDATE_TYPE.UPDATE)
      );
    },
    [dispatch, editPage]
  );

  return (
    <FormWrap id='page-form' onSubmit={handleSubmit(onSubmit)}>
      <ControlBarBlock blockTitle='Details'>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              disableUnderline
              label='Name'
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='title'
          control={control}
        />
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Page sound'>
        <Controller
          render={(controlProps) => (
            <FileInput
              value={controlProps.field.value?.name || ''}
              label='Select file'
              placeholder='No file'
              accept={['audio/mp3']}
              onError={setError}
              onChange={(data) =>
                setValue('soundInfo', data.original, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              {...controlProps}
            />
          )}
          name='soundInfo'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              error={error ? error.message : ''}
              disableUnderline
              label='Fade in duration'
              {...field}
              fullWidth
            />
          )}
          name='fadeIn'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              error={error ? error.message : ''}
              disableUnderline
              label='Fade out duration'
              {...field}
              fullWidth
            />
          )}
          name='fadeOut'
          control={control}
        />
        <Controller
          name='loopAudio'
          control={control}
          render={({ field }) => (
            <StyledCheckbox
              disabled={!formField.soundInfo?.name}
              label='Loop audio'
              onChange={(e) => field.onChange(e.target.checked)}
              checked={!!field.value}
            />
          )}
        />
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Page background'>
        <TabsWrapper
          justifyContent='space-between'
          value={selectTab}
          onChange={handleChange}
        >
          <StyledTab label='Image/GIF' maxWidth={80} />
          <StyledTab label='Video' maxWidth={80} />
        </TabsWrapper>
        <TabPanel value={selectTab} index={0}>
          <Controller
            render={(controlProps) => (
              <FileInput
                value={
                  SELECT_TAB_TYPE[selectTab] ===
                  editPage?.backgroundAttachment?.type
                    ? editPage?.backgroundAttachment?.name
                    : ''
                }
                label='Select file (5mb max.)'
                placeholder='No file'
                accept={['image/*']}
                clearErrors={clearErrors}
                errors={errors}
                validateImage
                onError={setError}
                onChange={(data) => {
                  setValue('backgroundAttachment', data.original);
                  setValue('mobileBackgroundAttachment', data.compressed);
                }}
                onDelete={() => {
                  setValue('backgroundAttachment', {});
                  setValue('mobileBackgroundAttachment', {});
                }}
                {...controlProps}
              />
            )}
            name='backgroundAttachment'
            control={control}
          />
        </TabPanel>
        <TabPanel value={selectTab} index={1}>
          <Controller
            render={(controlProps) => (
              <FileInput
                value={
                  SELECT_TAB_TYPE[selectTab] ===
                  editPage?.backgroundAttachment?.type
                    ? editPage?.backgroundAttachment?.name
                    : ''
                }
                label='Select file'
                placeholder='No file'
                accept={['video/*']}
                onError={setError}
                onChange={(data) =>
                  setValue('backgroundAttachment', data.original)
                }
                onDelete={() => setValue('backgroundAttachment', {})}
                {...controlProps}
              />
            )}
            name='backgroundAttachment'
            control={control}
          />
        </TabPanel>
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Advanced'>
        <Controller
          name='isHideNavigation'
          control={control}
          render={({ field }) => (
            <StyledCheckbox
              label='Hide navigation buttons on this page'
              onChange={(e) => field.onChange(e.target.checked)}
              checked={!!field.value}
            />
          )}
        />
      </ControlBarBlock>
    </FormWrap>
  );
};

export default PageControl;

import { GRID_HEIGHT } from 'pages/admin/StoryEdit/components/EditGridPage/EditGridPage.constants';
import { errorToast } from 'services/toast';

// FRAMES

// Validate frame height overflow
const frameOverflowValid = (frame, maxRows = GRID_HEIGHT) => {
  return frame.y + frame.h <= maxRows;
};

// Validate frame empty attachment
const frameAttachmentValid = (frame) => !!frame?.attachmentInfo?.type;

export const frameValidation = (frames) => {
  const validState = {
    overflow: true,
    attachment: true,
    title: true,
  };

  frames.forEach((frame) => {
    if (validState.overflow) {
      validState.overflow = frameOverflowValid(frame);
    }
    if (validState.attachment) {
      validState.attachment = frameAttachmentValid(frame);
    }
  });

  if (!validState.overflow) {
    errorToast(
      'Oops. Some frame is off the canvas',
      'Please fix frame before save.'
    );
  }

  if (!validState.attachment) {
    errorToast('Oops. Empty frame', 'Some frame have no attachment!');
  }

  return !(!validState.overflow || !validState.attachment);
};

export const isOutOfArea = (
  parentW,
  parentH,
  elPositionX,
  elPositionY,
  currentPosition
) => {
  return (
    elPositionX < 0 ||
    elPositionY < 0 ||
    parentW < currentPosition.x ||
    parentH < currentPosition.y
  );
};

export const returnNewPosition = (
  currentPosition,
  elPositionX,
  elPositionY,
  parentW,
  parentH
) => {
  const newPosition = {};
  if (elPositionX < 0) newPosition.x = currentPosition.x - elPositionX;
  if (elPositionY < 0) newPosition.y = currentPosition.y - elPositionY;
  if (parentW < currentPosition.x) newPosition.x = parentW;
  if (parentH < currentPosition.y) newPosition.y = parentH;

  return { ...currentPosition, ...newPosition };
};

export const returnSpeechTaleCoords = (
  speechPosition,
  taleDirection,
  taleSize
) => {
  const { vertical, horisontal } = taleDirection;
  const talePosition = {};
  switch (vertical) {
    case 'top':
      talePosition.y = -taleSize.h + 1;
      break;
    case 'center':
      talePosition.y = (speechPosition.h - taleSize.h) / 2;
      break;
    case 'bottom':
      talePosition.y = speechPosition.h - 1;
      break;
    default:
      talePosition.y = 0;
  }

  switch (horisontal) {
    case 'left':
      talePosition.x = 0;
      break;
    case 'center':
      talePosition.x = (speechPosition.w - taleSize.w) / 2;
      break;
    case 'right':
      talePosition.x = speechPosition.w - taleSize.w;
      break;
    default:
      talePosition.x = -taleSize.w;
  }

  return talePosition;
};

export const returnSpeechTaleCoordsOnFrame = (
  speechSize,
  taleDirection,
  taleSize
) => {
  const { vertical, horisontal } = taleDirection;
  const talePosition = {};
  switch (vertical) {
    case 'top':
      talePosition.y = -taleSize.h;
      break;
    case 'center':
      talePosition.y = (speechSize.h - taleSize.h) / 2;
      break;
    case 'bottom':
      talePosition.y = speechSize.h - 2;
      break;
    default:
      talePosition.y = 0;
  }

  switch (horisontal) {
    case 'left':
      talePosition.x = -1;
      break;
    case 'center':
      talePosition.x = (speechSize.w - taleSize.w) / 2;
      break;
    case 'right':
      talePosition.x = speechSize.w - taleSize.w - 1;
      break;
    default:
      talePosition.x = -taleSize.w;
  }

  return talePosition;
};

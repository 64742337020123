import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { createSelector } from 'reselect';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, MenuItem, useTheme } from '@material-ui/core';
import { BACKPACK_TYPE } from 'constants/editTypes';
import { getDefaultBackpackItem } from 'constants/defaultItems';
import {
  SELECT_TAB_TYPE,
  SELECT_TAB_TYPE_INDEX,
} from 'constants/selectTabType';
import { UPDATE_TYPE } from 'constants/updateTypes';
import { useDebounce } from 'utils/hooks/useDebounce';
import { treeCompare } from 'utils/treeCompare';
import { getImageSize } from 'utils/helpers/helpers';
import {
  storyListEditItemSelector,
  storyListEditTypeSelector,
  storyListSelectedIdsSelector,
  storyListSelectedPageSelector,
} from 'common/selectors/editStory';
import { pageListOrderSelector } from 'common/selectors/pages';
import {
  addBackpackItemAction,
  removeBackpackItemAction,
  updateBackpackItemAction,
} from 'common/actions/backpackListAction';
import {
  editItemAction,
  setEditAction,
  setSelectedAction,
} from 'common/actions/editStoryActions';
import { framesListSelector } from 'common/selectors/frames';
import FormWrap from 'components/forms/FormWrap';
import StyledInput from 'components/forms/StyledInput';
import FileInput from 'components/forms/FileInput';
import SelectField from 'components/forms/SelectField';
import { AddPageIcon, TrashIcon } from 'components/Icons';
import TabsWrapper from 'components/TabsWrapper/TabsWrapper';
import StyledTab from 'components/StyledTab/StyledTab';
import TabPanel from 'components/TabPanel/TabPanel';
import ControlBarBlock from '../ControlBarBlock';
import ControlButton from '../ControlButton';
import BlockTitle from '../DecisionControl/BlockTitle';
import { validationSchema } from './ControlBackpackItem.validation';
import useStyles from './styles';

const selector = createSelector(
  pageListOrderSelector,
  storyListEditItemSelector,
  storyListEditTypeSelector,
  storyListSelectedIdsSelector,
  framesListSelector,
  storyListSelectedPageSelector,
  (pagesList, editItem, editType, selectedIds, framesList, currentPage) => ({
    pagesList,
    editItem,
    editType,
    selectedIds,
    framesList,
    currentPage,
  })
);

const ControlBackpackItem = () => {
  const { spacing } = useTheme();
  const {
    pagesList,
    editItem,
    selectedIds,
    framesList,
    currentPage,
  } = useSelector(selector);
  const dispatch = useDispatch();
  const classes = useStyles();
  const defaultFields = useMemo(() => getDefaultBackpackItem(), []);
  const [selectTab, setSelectTab] = useState(0);
  const { control, handleSubmit, setValue, reset, setError } = useForm({
    mode: 'onChange',
    defaultValues: {
      id: editItem.id || defaultFields.id,
      title: editItem.title || defaultFields.title,
      description: editItem.description || defaultFields.description,
      type: editItem.type || defaultFields.type,
      originPage:
        editItem.originPage || currentPage?.id || defaultFields.originPage,
      originFrame: editItem.originFrame || defaultFields.originFrame,
      originChapter: editItem.originChapter || null,
      backpackAttachmentInfo:
        editItem.backpackAttachmentInfo || defaultFields.backpackAttachmentInfo,
      frameAttachmentInfo:
        editItem.frameAttachmentInfo || defaultFields.frameAttachmentInfo,
      gltfAttachmentInfo:
        editItem.gltfAttachmentInfo || defaultFields.gltfAttachmentInfo,
      binAttachmentInfo:
        editItem.binAttachmentInfo || defaultFields.binAttachmentInfo,
      texturesAttachmentInfo:
        editItem.texturesAttachmentInfo || defaultFields.texturesAttachmentInfo,
      audioAttachmentInfo:
        editItem.audioAttachmentInfo || defaultFields.audioAttachmentInfo,
      videoAttachmentInfo:
        editItem.videoAttachmentInfo || defaultFields.videoAttachmentInfo,
      link: editItem.link || defaultFields.link,
      notes: editItem.notes || defaultFields.notes,
      coordinates: editItem.coordinates || defaultFields.coordinates,
    },
    resolver: yupResolver(validationSchema()),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'texturesAttachmentInfo',
    keyName: 'textsAttachKey',
  });

  const fieldsData = useWatch({ control });

  const formFieldDebounced = useDebounce(fieldsData, 300);

  const onSubmit = useCallback(
    (data) => {
      const { id, originChapter, ...otherData } = data;
      const newFieldsData = {
        ...otherData,
        texturesAttachmentInfo:
          !otherData.fileAttachmentInfo ||
          !Object.keys(otherData.fileAttachmentInfo).length
            ? []
            : otherData.fileAttachmentInfo,
      };
      if (editItem.id) {
        dispatch(
          updateBackpackItemAction(
            { ...newFieldsData, ...editItem },
            UPDATE_TYPE.UPDATE
          )
        );
      } else {
        dispatch(addBackpackItemAction({ ...newFieldsData, ...editItem }));
      }
    },
    [dispatch, editItem]
  );

  const handleRemove = useCallback(() => {
    dispatch(removeBackpackItemAction());
  }, [dispatch]);

  // const currentPage = useMemo(
  //   () => pagesList.find((item) => item.id === fieldsData?.originPage),
  //   [fieldsData, pagesList]
  // );

  const handleUpdateBackpackItem = useCallback(() => {
    const { id, ...otherData } = formFieldDebounced;
    // the order of the elements must be the same like in formField
    const fieldsForCompare = {
      id: editItem.id || defaultFields.id,
      title: editItem.title || defaultFields.title,
      description: editItem.description || defaultFields.description,
      type: editItem.type || defaultFields.type,
      originPage: editItem.originPage || defaultFields.originPage,
      originFrame: editItem.originFrame || defaultFields.originFrame,
      originChapter: editItem.originChapter || null,
      backpackAttachmentInfo:
        editItem.backpackAttachmentInfo || defaultFields.backpackAttachmentInfo,
      frameAttachmentInfo:
        editItem.frameAttachmentInfo || defaultFields.frameAttachmentInfo,
      gltfAttachmentInfo:
        editItem.gltfAttachmentInfo || defaultFields.gltfAttachmentInfo,
      binAttachmentInfo:
        editItem.binAttachmentInfo || defaultFields.binAttachmentInfo,
      texturesAttachmentInfo:
        editItem.texturesAttachmentInfo || defaultFields.texturesAttachmentInfo,
      audioAttachmentInfo:
        editItem.audioAttachmentInfo || defaultFields.audioAttachmentInfo,
      videoAttachmentInfo:
        editItem.videoAttachmentInfo || defaultFields.videoAttachmentInfo,
      link: editItem.link || defaultFields.link,
      notes: editItem.notes || defaultFields.notes,
      coordinates: editItem.coordinates || defaultFields.coordinates,
    };

    if (!treeCompare(formFieldDebounced, fieldsForCompare)) {
      dispatch(setEditAction(true));
      dispatch(
        editItemAction({
          item: { ...editItem, ...otherData },
          type: BACKPACK_TYPE,
        })
      );
    }
  }, [formFieldDebounced, editItem, defaultFields, dispatch]);

  useEffect(() => {
    if (!editItem?.originFrame && fieldsData?.originFrame) {
      dispatch(
        setSelectedAction(
          { ...selectedIds, frame: fieldsData.originFrame },
          true
        )
      );
    }

    if (editItem?.originFrame !== fieldsData?.originFrame) {
      dispatch(
        setSelectedAction(
          {
            ...selectedIds,
            frame: fieldsData?.originFrame,
          },
          true
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, editItem?.originFrame, fieldsData?.originFrame]);

  useEffect(() => {
    if (!editItem?.originPage && fieldsData?.originPage) {
      dispatch(
        setSelectedAction({
          ...selectedIds,
          page: fieldsData.originPage,
          chapter: fieldsData.originChapter || '',
        })
      );
    }

    if (editItem?.originPage !== fieldsData?.originPage) {
      dispatch(
        setSelectedAction({
          ...selectedIds,
          page: fieldsData.originPage,
          frame: '',
          chapter: fieldsData.originChapter || '',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, editItem?.originPage, fieldsData.originPage]);

  useEffect(() => {
    if (editItem.id !== fieldsData.id) {
      reset({
        id: editItem.id,
        title: editItem.title,
        description: editItem.description,
        type: editItem.type,
        originPage: editItem.originPage || currentPage?.id,
        originFrame: editItem.originFrame,
        originChapter: editItem.originChapter || null,
        backpackAttachmentInfo: editItem.backpackAttachmentInfo,
        frameAttachmentInfo: editItem.frameAttachmentInfo,
        gltfAttachmentInfo: editItem.gltfAttachmentInfo,
        binAttachmentInfo: editItem.binAttachmentInfo,
        texturesAttachmentInfo: editItem.texturesAttachmentInfo,
        audioAttachmentInfo:
          editItem.audioAttachmentInfo || defaultFields.audioAttachmentInfo,
        videoAttachmentInfo:
          editItem.videoAttachmentInfo || defaultFields.videoAttachmentInfo,
        link: editItem.link,
        notes: editItem.notes,
        coordinates: editItem.coordinates,
      });
    }
  }, [editItem, fieldsData.id, reset, defaultFields, currentPage?.id]);

  useEffect(() => {
    handleUpdateBackpackItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced]);

  useEffect(() => {
    if (editItem?.videoAttachmentInfo?.url === '')
      setValue('videoAttachmentInfo', {});

    if (editItem?.audioAttachmentInfo?.type) {
      setSelectTab(SELECT_TAB_TYPE_INDEX[editItem.audioAttachmentInfo?.type]);
      return;
    }
    if (editItem?.videoAttachmentInfo?.url) {
      setSelectTab(SELECT_TAB_TYPE_INDEX[editItem.videoAttachmentInfo?.type]);
      return;
    }
    if (editItem?.backpackAttachmentInfo?.type)
      setSelectTab(
        SELECT_TAB_TYPE_INDEX[editItem.backpackAttachmentInfo?.type]
      );
  }, [
    editItem.videoAttachmentInfo,
    editItem.audioAttachmentInfo,
    editItem.backpackAttachmentInfo,
    setValue,
  ]);

  const handleChange = (event, newValue) => {
    setSelectTab(newValue);
  };

  const handleOnChangeURL = useCallback(
    (name, value) => {
      setValue(name, {
        type: SELECT_TAB_TYPE[selectTab],
        name: 'video',
        url: value,
        size: 0,
      });
      setValue('audioAttachmentInfo', {});
      setValue('binAttachmentInfo', {});
      setValue('gltfAttachmentInfo', {});
    },
    [setValue, selectTab]
  );

  const handleUploadChange = useCallback(
    async (data) => {
      if (!editItem.id) {
        const img = new Image();
        img.src = data.original.url;
        img.onload = (e) => {
          const sizes = getImageSize(e.target.width, e.target.height);
          setValue('coordinates', {
            ...editItem.coordinates,
            w: sizes.imageWidth,
            h: sizes.imageHeight,
          });
        };
      }
      setValue('frameAttachmentInfo', data.original, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('mobileFrameAttachmentInfo', data.compressed, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [setValue, editItem.coordinates, editItem.id]
  );

  return (
    <FormWrap id='item-form' onSubmit={handleSubmit(onSubmit)}>
      <ControlBarBlock blockTitle='Details'>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              disabled={editItem.isGameItem}
              disableUnderline
              label='Name'
              placeholder='Item name'
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='title'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              multiline
              rows={4}
              disableUnderline
              label='Description'
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='description'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              disableUnderline
              label='Type'
              placeholder='Type'
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='type'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <>
              <SelectField
                displayEmpty
                label='Target page'
                error={error ? error.message : null}
                control={control}
                defaultValue=''
                {...field}
                onChange={(e, el) => {
                  setValue('originFrame', '');
                  setValue('originPage', e.target.value);
                  setValue('originChapter', el.props.chapter);
                }}
              >
                <MenuItem value=''>Select page...</MenuItem>
                {pagesList.map((item) => {
                  return (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      chapter={item.originChapter}
                    >
                      {item.title}
                    </MenuItem>
                  );
                })}
              </SelectField>
            </>
          )}
          name='originPage'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <SelectField
              displayEmpty
              label='Target frame'
              disabled={editItem.isGameItem}
              error={error ? error.message : null}
              {...field}
              control={control}
            >
              <MenuItem value='' disabled>
                Select frame...
              </MenuItem>
              {(framesList[currentPage?.id] || []).map((item) => {
                return (
                  <MenuItem
                    key={item.id || item._id}
                    value={item.id || item._id}
                  >
                    {item.title || 'Frame'}
                  </MenuItem>
                );
              })}
            </SelectField>
          )}
          name='originFrame'
          control={control}
        />
      </ControlBarBlock>
      <ControlBarBlock
        blockTitle='Content'
        padding={`${spacing(2)}px ${spacing(2)}px 0`}
      >
        <Controller
          render={(controlProps) => (
            <FileInput
              value={controlProps.field.value?.name || ''}
              label='Image in Frame'
              placeholder='No file'
              onChange={handleUploadChange}
              accept={['image/*']}
              onError={setError}
              {...controlProps}
            />
          )}
          name='frameAttachmentInfo'
          control={control}
        />
        <Controller
          render={(controlProps) => (
            <FileInput
              value={controlProps.field.value?.name || ''}
              label='Image in Backpack'
              placeholder='No file'
              accept={['image/*']}
              onError={setError}
              onChange={(data) => {
                setValue('backpackAttachmentInfo', data.original, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              {...controlProps}
            />
          )}
          name='backpackAttachmentInfo'
          control={control}
        />
        <TabsWrapper
          justifyContent='space-between'
          value={selectTab}
          onChange={handleChange}
        >
          <StyledTab label='Image' maxWidth={40} />
          <StyledTab label='Video' maxWidth={40} />
          <StyledTab label='Audio' maxWidth={40} />
        </TabsWrapper>
        <TabPanel value={selectTab} index={0}>
          <Controller
            render={(controlProps) => (
              <FileInput
                value={controlProps.field.value?.name || ''}
                label='3D file *.obj'
                placeholder='No file'
                accept={['*']}
                onError={setError}
                onChange={(data) => {
                  setValue('gltfAttachmentInfo', data, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setValue('videoAttachmentInfo', {});
                  setValue('audioAttachmentInfo', {});
                }}
                {...controlProps}
                isModel
              />
            )}
            name='gltfAttachmentInfo'
            control={control}
          />
          <Controller
            render={(controlProps) => (
              <FileInput
                value={controlProps.field.value?.name || ''}
                label='3D file *.mtl'
                placeholder='No file'
                accept={['*']}
                onError={setError}
                onChange={(data) => {
                  setValue('binAttachmentInfo', data, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setValue('videoAttachmentInfo', {});
                  setValue('audioAttachmentInfo', {});
                }}
                {...controlProps}
                isModel
              />
            )}
            name='binAttachmentInfo'
            control={control}
          />

          {fields.map((item, index) => (
            <ControlBarBlock
              padding={`${spacing(2)}px ${spacing(2)}px 0`}
              margin={`0 -${spacing(2)}px`}
              key={item.key}
              blockTitle={
                <BlockTitle
                  title='File Textures'
                  icon={
                    fields.length > 1 && (
                      <Box
                        className={classes.optionDelete}
                        onClick={() => remove(index)}
                      >
                        <TrashIcon />
                      </Box>
                    )
                  }
                />
              }
            >
              <Controller
                key={item.key}
                render={(controlProps) => (
                  <FileInput
                    value={controlProps.field.value?.name || ''}
                    label='3D file texture'
                    placeholder='No file'
                    accept={['image/*']}
                    onError={setError}
                    {...controlProps}
                    isModel
                  />
                )}
                name={`texturesAttachmentInfo.${index}`}
                control={control}
              />
            </ControlBarBlock>
          ))}
          <ControlBarBlock
            padding={`${spacing(2)}px ${spacing(2)}px 0`}
            margin={`0 -${spacing(2)}px`}
            blockTitle={
              <BlockTitle
                title='Texture'
                icon={
                  <Box className={classes.addOption} onClick={() => append({})}>
                    <AddPageIcon />
                  </Box>
                }
              />
            }
          />
        </TabPanel>
        <TabPanel value={selectTab} index={1}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth
                disableUnderline
                label='Video url in Backpack'
                error={error ? error.message : ''}
                onChangeValue={handleOnChangeURL}
                {...field}
                value={field.value?.url ? field.value?.url : ''}
              />
            )}
            name='videoAttachmentInfo'
            control={control}
          />
        </TabPanel>
        <TabPanel value={selectTab} index={2}>
          <Controller
            render={(controlProps) => (
              <FileInput
                value={controlProps.field.value?.name || ''}
                label='Audio in Backpack'
                placeholder='No file'
                accept={['audio/mp3']}
                onChange={(data) => {
                  setValue('audioAttachmentInfo', data.original, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setValue('videoAttachmentInfo', {});
                  setValue('binAttachmentInfo', {});
                  setValue('gltfAttachmentInfo', {});
                }}
                onError={setError}
                {...controlProps}
              />
            )}
            name='audioAttachmentInfo'
            control={control}
          />
        </TabPanel>
      </ControlBarBlock>
      <ControlBarBlock noPadding>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              disableUnderline
              label='Link'
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='link'
          control={control}
        />
        <ControlButton
          onClick={handleRemove}
          text='Delete Item'
          disabled={!editItem.id}
        />
      </ControlBarBlock>
    </FormWrap>
  );
};

export default ControlBackpackItem;

import React from 'react';
import classNames from 'classnames';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';

export const ArrowLeft = ({ fill = 'white', className, ...props }) => {
  return (
    <ArrowBackOutlinedIcon
      style={{ fill, width: 24, height: 24 }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};

/* <svg
      width={24}
      height={22}
      viewBox='0 0 24 22'
      style={{ cursor: 'pointer', fill: '#fff' }}
      {...props}
    >
      <path
        d='M9.353 8.146L8.5 9h13c1.102 0 2 .898 2 2 0 1.102-.898 2-2 2h-13l.853.854 3.648 3.646a2.124 2.124 0 010 3c-.415.415-.956.621-1.501.621a2.114 2.114 0 01-1.5-.62L.5 11 10 1.5a2.124 2.124 0 013 0 2.124 2.124 0 010 3L9.352 8.145z'
        stroke='#181D30'
      />
    </svg> */

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  deleteBtn: {
    width: '100%',
    padding: `${theme.spacing(0.625)}px ${theme.spacing(1)}px`,
    fontSize: theme.spacing(1),
    margin: (props) => (props.noMargin ? 0 : `${theme.spacing(1.25)}px 0`),
    backgroundColor: (props) => (props.isActive ? '#fb2571' : '#393e52'),
  },
}));

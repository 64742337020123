import React, { useCallback, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_LAYER_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import { useDebounce } from 'utils/hooks/useDebounce';
import { treeCompare } from 'utils/treeCompare';
import { getImageSize } from 'utils/helpers/helpers';
import {
  storyListEditItemSelector,
  storyListEditTypeSelector,
} from 'common/selectors/editStory';
import { editItemAction, setEditAction } from 'common/actions/editStoryActions';
import {
  addLayerAction,
  removeLayerAction,
  updateLayerAction,
} from 'common/actions/layersListAction';
import StyledCheckbox from 'components/forms/StyledCheckbox/StyledCheckbox';
import FormWrap from 'components/forms/FormWrap';
import StyledInput from 'components/forms/StyledInput';
import FileInput from 'components/forms/FileInput';
import ControlBarBlock from '../ControlBarBlock';
import ControlButton from '../ControlButton';
import { defaultValues } from './LayerImageControl.constants';
import { validationSchema } from './LayerImageControl.validation';

const selector = createSelector(
  storyListEditItemSelector,
  storyListEditTypeSelector,
  (editItem, editType) => ({
    editItem,
    editType,
  })
);

const LayerImageControl = () => {
  const { editItem, editType } = useSelector(selector);
  const dispatch = useDispatch();

  const {
    control,
    setError,
    reset,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      coordinates: editItem?.coordinates || defaultValues.coordinates,
      id: editItem?.id || '',
      title: editItem?.title || defaultValues.title,
      imageInfo: editItem?.imageInfo || defaultValues.imageInfo,
      isOverlay: editItem?.isOverlay || defaultValues.isOverlay,
      zIndex: editItem?.zIndex || defaultValues.zIndex,
      type: editType,
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({
    control,
  });

  const formFieldDebounced = useDebounce(formField, 300);

  useEffect(() => {
    if (
      editItem &&
      (editItem?.id !== formField.id ||
        editItem?.coordinates !== formField.coordinates)
    ) {
      reset({
        coordinates: editItem?.coordinates || defaultValues.coordinates,
        id: editItem.id,
        title: editItem.title || defaultValues.title,
        imageInfo: editItem.imageInfo || defaultValues.imageInfo,
        isOverlay: editItem.isOverlay || defaultValues.isOverlay,
        zIndex: editItem.zIndex || defaultValues.zIndex,
        type: editType,
      });
    }
    // eslint-disable-next-line
  }, [reset, editItem, formField.id, editType]);

  const handleUpdateLayer = useCallback(() => {
    const { id, ...fieldsData } = formFieldDebounced;
    // the order of the elements must be the same like in formField
    const fieldsForCompare = {
      coordinates: editItem?.coordinates || defaultValues.coordinates,
      id: editItem?.id || '',
      title: editItem?.title || defaultValues.title,
      imageInfo: editItem?.imageInfo || defaultValues.imageInfo,
      isOverlay: editItem?.isOverlay || defaultValues.isOverlay,
      zIndex: editItem?.zIndex || defaultValues.zIndex,
      type: editType,
    };

    if (!treeCompare(formFieldDebounced, fieldsForCompare)) {
      dispatch(
        editItemAction({
          item: {
            ...editItem,
            ...fieldsData,
          },
          type: IMAGE_LAYER_TYPE,
        })
      );
      dispatch(setEditAction(true));
    }
  }, [formFieldDebounced, editItem, editType, dispatch]);

  useEffect(() => {
    handleUpdateLayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced]);

  const onSubmit = useCallback(
    (data) => {
      const { id, ...fieldsData } = data;
      if (editItem.id) {
        dispatch(
          updateLayerAction(
            { ...editItem, ...fieldsData, soundInfo: {} },
            UPDATE_TYPE.UPDATE
          )
        );
      } else {
        dispatch(
          addLayerAction({
            ...editItem,
            ...fieldsData,
            soundInfo: {},
          })
        );
      }
    },
    [dispatch, editItem]
  );

  const handleUploadChange = useCallback(
    async (data) => {
      if (!editItem.id) {
        const img = new Image();
        img.src = data.original.url;
        img.onload = (e) => {
          const sizes = getImageSize(e.target.width, e.target.height);
          setValue('coordinates', {
            ...editItem.coordinates,
            w: sizes.imageWidth,
            h: sizes.imageHeight,
          });
        };
      }

      setValue('imageInfo', data.original, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('mobileImageInfo', data.compressed, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [setValue, editItem.coordinates, editItem.id]
  );

  const handleRemove = useCallback(() => {
    dispatch(removeLayerAction());
  }, [dispatch]);

  return (
    <FormWrap id='layer_image-form' onSubmit={handleSubmit(onSubmit)}>
      <ControlBarBlock blockTitle='Details'>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              disableUnderline
              label='Name'
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='title'
          control={control}
        />
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Content'>
        <Controller
          render={(controlProps) => (
            <FileInput
              value={controlProps.field.value?.name || ''}
              label='Image (5mb max.)'
              placeholder='No file'
              clearErrors={clearErrors}
              errors={errors}
              validateImage
              accept={['image/*']}
              onChange={handleUploadChange}
              onError={setError}
              {...controlProps}
            />
          )}
          name='imageInfo'
          control={control}
        />
        <Controller
          name='isOverlay'
          control={control}
          render={({ field }) => (
            <StyledCheckbox
              label='Is overlay'
              onChange={(e) => field.onChange(e.target.checked)}
              checked={!!field.value}
            />
          )}
        />
        {formField.isOverlay && (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                type='number'
                fullWidth
                disableUnderline
                label='Overlay index'
                error={error ? error.message : ''}
                {...field}
              />
            )}
            name='zIndex'
            control={control}
          />
        )}
      </ControlBarBlock>
      <ControlBarBlock>
        <ControlButton
          onClick={handleRemove}
          text='Delete Layer'
          disabled={!editItem.id}
        />
      </ControlBarBlock>
    </FormWrap>
  );
};

export default LayerImageControl;

import {
  CREATE_HOT_SPOT,
  DELETE_HOT_SPOT,
  END_LOADING_HOT_SPOT,
  FETCHED_HOT_SPOT,
  START_LOADING_HOT_SPOT,
  UPDATE_HOT_SPOT,
} from 'common/types/hotSpotsList';
import { RESET_STORE } from 'common/types/common';

const initialState = {
  hotSpots: [],
  loading: false,
};

const hotSpotsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_HOT_SPOT:
      return {
        ...state,
        hotSpots: action.hotSpots.map(({ _id, ...h }) => {
          return { ...h, id: _id || h.id };
        }),
        loading: false,
      };

    case START_LOADING_HOT_SPOT:
      return {
        ...state,
        loading: true,
      };
    case END_LOADING_HOT_SPOT:
      return {
        ...state,
        loading: false,
      };
    case DELETE_HOT_SPOT:
      const { hotSpots } = state;

      const filtredData = hotSpots.filter(
        (hotspot) =>
          hotspot.id !== action.hotSpot.id || hotspot._id !== action.hotSpot._id
      );

      return { ...state, hotSpots: filtredData, loading: false };
    case UPDATE_HOT_SPOT:
      const hotSpotsList = [...state.hotSpots];

      const updateIndex = hotSpotsList.findIndex(
        (hotSpot) =>
          hotSpot.id === action.hotSpot.id || hotSpot._id === action.hotSpot._id
      );

      if (updateIndex >= 0) {
        hotSpotsList.splice(updateIndex, 1, action.hotSpot);
      }

      return { ...state, hotSpot: { ...hotSpotsList }, loading: false };

    case CREATE_HOT_SPOT:
      return {
        ...state,
        hotSpots: [...state.hotSpots, action.hotSpot],
        loading: false,
      };

    case RESET_STORE:
      return {
        initialState,
      };
    default:
      return state;
  }
};

export default hotSpotsReducer;

import React from 'react';
import classNames from 'classnames';
import VideocamIcon from '@material-ui/icons/Videocam';

export const VideoIcon = ({ fill = 'white', className, ...props }) => (
  <VideocamIcon
    style={{ fill, width: 15, height: 15, fillOpacity: '.5' }}
    className={classNames({
      [className]: !!className,
    })}
    {...props}
  />
);

/* <svg width='12' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'>
<path
  d='M7 0H1a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1V1a1 1 0 00-1-1zm3.95.79L8.67 2.36v3.28l2.28 1.57c.44.3 1.05 0 1.05-.54V1.33c0-.53-.6-.84-1.05-.53z'
  fill='#fff'
  fillOpacity='.5'
/>
</svg> */

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  select: {
    width: '100%',

    '&>svg': {
      marginRight: theme.spacing(1),
    },
  },
  icon: {
    fill: theme.colors.white,
  },
}));

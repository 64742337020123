import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  menu: {
    paddingTop: theme.spacing(0.625),
    paddingBottom: theme.spacing(0.625),
    minWidth: theme.spacing(14.25),
    borderRadius: theme.spacing(0.5),

    '&>.MuiList-root': {
      padding: 0,
      backgroundColor: theme.colors.menuBackground,

      '&>.MuiMenuItem-root': {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.625),

        '&>.MuiListItemIcon-root': {
          minWidth: theme.spacing(2.75),
        },
      },
    },
  },
  menuWrapper: {
    '& .MuiMenuItem-root': {
      [theme.breakpoints.down('sm')]: {
        minHeight: '40px',
      },
    },
  },
}));

import {
  CREATE_DECISION,
  DELETE_DECISION,
  END_LOADING_DECISIONS,
  FETCHED_DECISIONS,
  START_LOADING_DECISIONS,
  UPDATE_DECISION,
} from 'common/types/decisionsList';
import { RESET_STORE } from 'common/types/common';

const initialState = {
  decisions: [],
  loading: false,
};

const decisionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_DECISIONS:
      return {
        ...state,
        decisions: action.decisions,
        loading: false,
      };

    case START_LOADING_DECISIONS:
      return {
        ...state,
        loading: true,
      };
    case END_LOADING_DECISIONS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_DECISION:
      const decisions = [...state.decisions];

      const index = decisions.findIndex(
        (decision) => decision.id === action.decision.id
      );

      if (index >= 0) {
        decisions.splice(index, 1);
      }

      return { ...state, decisions, loading: false };

    case UPDATE_DECISION:
      const decisionsList = [...state.decisions];

      const updateIndex = decisionsList.findIndex(
        (decision) => decision.id === action.decision.id
      );

      if (updateIndex >= 0) {
        decisionsList.splice(updateIndex, 1, action.decision);
      }

      return { ...state, decisions: decisionsList, loading: false };

    case CREATE_DECISION:
      return {
        ...state,
        decisions: [...state.decisions, action.decision],
        loading: false,
      };

    case RESET_STORE:
      return {
        initialState,
      };
    default:
      return state;
  }
};

export default decisionsReducer;

import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getDefaultStory } from 'constants/defaultItems';
import useMobile from 'utils/hooks/useMobile';
import {
  setCurrentStoryAction,
  setCurrentStoryIsEditAction,
} from 'common/actions/storyListActions';
import {
  storyListCurrentStorySelector,
  storyListEditStatusSelector,
  storyListItemsSelector,
  storyListLoadingSelector,
  storyListLanguageSelector,
} from 'common/selectors/storyList';
import ConfirmDialog from '../ConfirmDialog';
import StoryListItem from './SoryListItem';
import useStyles from './styles';
import { SearchContainer } from './components/SearchContainer/index';

const selector = createSelector(
  storyListItemsSelector,
  storyListCurrentStorySelector,
  storyListEditStatusSelector,
  storyListLoadingSelector,
  storyListLanguageSelector,
  (storyList, currentStory, wasEdit, loading, currentLang) => ({
    storyList,
    currentStory,
    wasEdit,
    loading,
    currentLang,
  })
);

const StoriesList = ({ searchTerm, setSearchTerm, setOpenDrawer }) => {
  const classes = useStyles();
  const { isMobile } = useMobile();
  const dispatch = useDispatch();

  const {
    storyList,
    currentStory,
    wasEdit,
    loading,
    currentLang,
  } = useSelector(selector);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const handleOpenConfirmDialog = useCallback(
    () => setOpenConfirmDialog(true),
    []
  );

  const handleCloseConfirmDialog = useCallback(() => {
    setOpenConfirmDialog(false);
    setEditItem(null);
  }, []);

  const handleAddNewStory = useCallback(() => {
    if (wasEdit) {
      setEditItem(getDefaultStory());
      handleOpenConfirmDialog();
    } else {
      dispatch(setCurrentStoryAction(getDefaultStory()));
      setEditItem(null);
    }
    if (isMobile) setOpenDrawer(true);
  }, [dispatch, handleOpenConfirmDialog, isMobile, setOpenDrawer, wasEdit]);

  const handleSetCurrentStory = useCallback(() => {
    dispatch(setCurrentStoryAction(editItem));
    dispatch(setCurrentStoryIsEditAction(false));
    handleCloseConfirmDialog();
  }, [dispatch, editItem, handleCloseConfirmDialog]);

  const handleEditStory = useCallback(
    (item) => {
      if (wasEdit) {
        setEditItem(item);
        handleOpenConfirmDialog();
      } else {
        dispatch(setCurrentStoryAction(item));
        setEditItem(null);
      }
      setOpenDrawer(true);
    },
    [dispatch, handleOpenConfirmDialog, setOpenDrawer, wasEdit]
  );

  return (
    <Box>
      <SearchContainer
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleAddNewStory={handleAddNewStory}
      />

      {isMobile && (
        <Typography variant='h2' noWrap className={classes.listTitle}>
          My stories
        </Typography>
      )}

      {!!storyList.length && (
        <Box className={classes.listWrap}>
          {storyList?.map((item) => (
            <StoryListItem
              key={item.id}
              isActive={item.id === currentStory?.id}
              title={item.title ? item.title[currentLang] : ''}
              about={item.about && item.about[currentLang]}
              image={item.imageInfo?.url}
              languages={item.languages}
              onClick={() => handleEditStory(item)}
            />
          ))}
        </Box>
      )}
      {!storyList.length && !loading && (
        <Box className={classes.message}>
          <Typography variant='subtitle2' noWrap>
            There is no stories
          </Typography>
        </Box>
      )}
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onSubmit={handleSetCurrentStory}
      />
    </Box>
  );
};

export default StoriesList;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch, useFieldArray } from 'react-hook-form';
import {
  Box,
  Grid,
  ListSubheader,
  MenuItem,
  useTheme,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { yupResolver } from '@hookform/resolvers/yup';
import { getEnglishName } from 'all-iso-language-codes';
import { FRAME_TYPE } from 'constants/editTypes';
import { getDefaultFrame } from 'constants/defaultItems';
import { SELECT_TAB_TYPE } from 'constants/selectTabType';
import { UPDATE_TYPE } from 'constants/updateTypes';
import { ATTACHMENTS_TYPE } from 'constants/attachmentsType';
import { useDebounce } from 'utils/hooks/useDebounce';
import { frameValidation } from 'utils/validations';
import { treeCompare } from 'utils/treeCompare';
import {
  capitalizeFirstLetter,
  getImageSize,
  removeFileExtension,
} from 'utils/helpers/helpers';
import { useKeyboardEvents } from 'utils/hooks/useKeyboardEvents';
import { clearObject } from 'utils/clearObject';
import {
  storyListEditItemSelector,
  storyListSelectedFrameIndexSelector,
} from 'common/selectors/editStory';
import {
  editItemAction,
  setAnimation,
  setEditAction,
} from 'common/actions/editStoryActions';
import { pagesListSelector } from 'common/selectors/pages';
import { removeGameItemsAction } from 'common/actions/backpackListAction';
import { storyItemsSelector } from 'common/selectors/story';
import {
  addNewFrameAction,
  deleteFrameAction,
  updateFrameAction,
} from 'common/actions/framesListActions';
import { framesListSelector } from 'common/selectors/frames';
import FormWrap from 'components/forms/FormWrap';
import StyledInput from 'components/forms/StyledInput';
import StyledTab from 'components/StyledTab';
import TabsWrapper from 'components/TabsWrapper';
import TabPanel from 'components/TabPanel';
import StyledCheckbox from 'components/forms/StyledCheckbox';
import FileInput from 'components/forms/FileInput';
import SelectField from 'components/forms/SelectField/SelectField';
import { TrashIcon } from 'components/Icons/TrashIcon';
import { StyledTooltip } from 'components/StyledTooltip/StyledTooltip';
import ControlBarBlock from '../ControlBarBlock';
import { animationTypes, GAMES } from './FrameControl.constants';
import { validationSchema } from './FrameControl.validation';
import useStyles from './styles';

const selector = createSelector(
  storyItemsSelector,
  storyListEditItemSelector,
  storyListSelectedFrameIndexSelector,
  pagesListSelector,
  framesListSelector,
  (editStory, editFrame, editFrameIndex, pagesList, framesList) => ({
    editStory,
    editFrame,
    editFrameIndex,
    pagesList,
    framesList,
  })
);
const FrameControl = () => {
  const { spacing } = useTheme();
  const {
    editStory,
    editFrame,
    editFrameIndex,
    pagesList,
    framesList,
  } = useSelector(selector);
  const defaultValue = useMemo(() => getDefaultFrame(), []);
  const subTitlesData = useMemo(
    () => clearObject(editFrame?.attachmentInfo?.subtitles),
    [editFrame?.attachmentInfo?.subtitles]
  );

  const classes = useStyles();

  const [autoDelay, setAutoDelay] = useState(editFrame?.animation?.delay || 2);

  const { isPressedBackspace } = useKeyboardEvents();

  const orientationCoef = useMemo(() => {
    return editStory.isLandscape ? 2 : 1;
  }, [editStory.isLandscape]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isPressedBackspace) dispatch(deleteFrameAction(editFrame));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isPressedBackspace]);

  const [selectTab, setSelectTab] = useState(0);
  const [isCardGame, setIsCardGame] = useState(false);
  const [gameName, setGameName] = useState('');

  const {
    control,
    setError,
    setValue,
    reset,
    handleSubmit,
    clearErrors,
    formState: { isDirty, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      id: editFrame?.id || '',
      fid: editFrame?.fid || '',
      title: editFrame?.title || defaultValue.title,
      attachmentInfo: editFrame?.attachmentInfo || defaultValue.attachmentInfo,
      additionalAttachmentInfo:
        editFrame?.additionalAttachmentInfo ||
        defaultValue.additionalAttachmentInfo,
      attachmentVideoInfo:
        editFrame?.attachmentInfo || defaultValue.attachmentInfo,
      videoImage: editFrame?.videoImage || defaultValue.videoImage,
      loopAudio: editFrame?.loopAudio || defaultValue.loopAudio,
      fullScreen: editFrame?.fullScreen || defaultValue.fullScreen,
      h: editFrame?.h || 0,
      w: editFrame?.w || 0,
      x: editFrame?.x || 0,
      y: editFrame?.y || 0,
      windowHeight: editFrame?.windowHeight || defaultValue.windowHeight,
      windowWidth: editFrame?.windowWidth || defaultValue.windowWidth,
      storyEnd: editFrame?.storyEnd || defaultValue.storyEnd,
      canReturn: editFrame?.canReturn || defaultValue.canReturn,
      newWindow: editFrame?.newWindow || defaultValue.newWindow,
      animation: {
        ...defaultValue.animation,
        ...editFrame?.animation,
      },
      hideBorder: editFrame?.hideBorder || false,
      isHideNavigation:
        editFrame?.isHideNavigation || defaultValue.isHideNavigation,
      fadeIn: editFrame?.fadeIn || defaultValue.fadeIn,
      fadeOut: editFrame?.fadeOut || defaultValue.fadeOut,
      vvtLanguages: editFrame?.vvtLanguages || defaultValue.vvtLanguages,
    },
    resolver: yupResolver(validationSchema()),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'vvtLanguages',
    keyName: 'lngCtrlKey',
  });

  const formField = useWatch({
    control,
  });

  const formFieldDebounced = useDebounce(formField, 300);

  // useEffect(() => {
  //   if (editFrame?.attachmentInfo?.type) {
  //     setSelectTab(SELECT_TAB_TYPE_INDEX[editFrame?.attachmentInfo?.type]);
  //   }
  // }, [editFrame]);

  useEffect(() => {
    if (editFrame !== formField) {
      reset({
        id: editFrame?.id || '',
        fid: editFrame?.fid || '',
        title: editFrame?.title || defaultValue.title,
        attachmentInfo:
          editFrame?.attachmentInfo || defaultValue.attachmentInfo,
        additionalAttachmentInfo:
          editFrame?.additionalAttachmentInfo ||
          defaultValue.additionalAttachmentInfo,
        attachmentVideoInfo:
          editFrame?.attachmentInfo || defaultValue.attachmentInfo,
        videoImage: editFrame?.videoImage || defaultValue.videoImage,
        loopAudio: editFrame?.loopAudio || defaultValue.loopAudio,
        fullScreen: editFrame?.fullScreen || defaultValue.fullScreen,
        h: editFrame?.h || 0,
        w: editFrame?.w || 0,
        x: editFrame?.x || 0,
        y: editFrame?.y || 0,
        windowHeight: editFrame?.windowHeight || defaultValue.windowHeight,
        windowWidth: editFrame?.windowWidth || defaultValue.windowWidth,
        storyEnd: editFrame?.storyEnd || defaultValue.storyEnd,
        canReturn: editFrame?.canReturn || defaultValue.canReturn,
        newWindow: editFrame?.newWindow || defaultValue.newWindow,
        animation: {
          ...defaultValue.animation,
          ...editFrame?.animation,
        },
        hideBorder: editFrame?.hideBorder || false,
        isHideNavigation:
          editFrame?.isHideNavigation || defaultValue.isHideNavigation,
        fadeIn: editFrame?.fadeIn || defaultValue.fadeIn,
        fadeOut: editFrame?.fadeOut || defaultValue.fadeOut,
        vvtLanguages: editFrame?.vvtLanguages || defaultValue.vvtLanguages,
      });
    }
    // eslint-disable-next-line
  }, [
    editFrame.id,
    reset,
    defaultValue,
    formField.id,
    formField.fid,
    editFrame.x,
    editFrame.y,
    editFrame.h,
    editFrame.w,
  ]);

  const handleUpdateFrame = useCallback(() => {
    const { id, ...fieldsData } = formFieldDebounced;
    const fieldsForCompare = {
      id: editFrame?.id || '',
      fid: editFrame?.fid || '',
      title: editFrame?.title || defaultValue.title,
      attachmentInfo: editFrame?.attachmentInfo || defaultValue.attachmentInfo,
      additionalAttachmentInfo:
        editFrame?.additionalAttachmentInfo ||
        defaultValue.additionalAttachmentInfo,
      attachmentVideoInfo:
        editFrame?.attachmentInfo || defaultValue.attachmentInfo,
      videoImage: editFrame?.videoImage || defaultValue.videoImage,
      loopAudio: editFrame?.loopAudio || defaultValue.loopAudio,
      fullScreen: editFrame?.fullScreen || defaultValue.fullScreen,
      h: editFrame?.h || 0,
      w: editFrame?.w || 0,
      x: editFrame?.x || 0,
      y: editFrame?.y || 0,
      windowHeight: editFrame?.windowHeight || defaultValue.windowHeight,
      windowWidth: editFrame?.windowWidth || defaultValue.windowWidth,
      storyEnd: editFrame?.storyEnd || defaultValue.storyEnd,
      canReturn: editFrame?.canReturn || defaultValue.canReturn,
      newWindow: editFrame?.newWindow || defaultValue.newWindow,
      animation: {
        ...defaultValue.animation,
        ...editFrame?.animation,
      },
      hideBorder: editFrame?.hideBorder || false,
      isHideNavigation:
        editFrame?.isHideNavigation || defaultValue.isHideNavigation,
      fadeIn: editFrame?.fadeIn || defaultValue.fadeIn,
      fadeOut: editFrame?.fadeOut || defaultValue.fadeOut,
      vvtLanguages: editFrame?.vvtLanguages || defaultValue.vvtLanguages,
    };

    if (!treeCompare(formFieldDebounced, fieldsForCompare)) {
      if (
        ((editFrameIndex && editFrameIndex > -1) || editFrameIndex === 0) &&
        (editFrame.id || editFrame.fid)
      ) {
        const newEditFrame = {
          ...editFrame,
          ...fieldsData,
          attachmentInfo: {
            ...fieldsData.attachmentInfo,
            subtitles: {
              ...editFrame.attachmentInfo.subtitles,
              ...fieldsData.attachmentInfo.subtitles,
            },
          },
          x: parseFloat(fieldsData.x),
          y: parseFloat(fieldsData.y),
          w: parseFloat(fieldsData.w),
          h: parseFloat(fieldsData.h),
        };

        dispatch(
          editItemAction({
            item: newEditFrame,
            type: FRAME_TYPE,
          })
        );
        framesList[editFrame.originPage][editFrameIndex] = newEditFrame;
      }
    }
  }, [
    defaultValue,
    formFieldDebounced,
    editFrameIndex,
    dispatch,
    editFrame,
    framesList,
  ]);

  useEffect(() => {
    handleUpdateFrame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced]);

  useEffect(() => {
    dispatch(setEditAction(isDirty));
  }, [isDirty, dispatch]);

  const handleChange = (event, newValue) => {
    setSelectTab(newValue);
  };

  const handleOnChangeURL = useCallback(
    (name, value) => {
      setIsCardGame(false);
      setValue(name, {
        type: SELECT_TAB_TYPE[selectTab],
        name: '',
        url: value,
        size: 0,
      });
      const media = new Audio(value);
      media.onloadedmetadata = () => {
        const delay = Math.round(media.duration);
        setAutoDelay(delay);
        setValue('animation.delay', delay);
      };
    },
    [setValue, selectTab]
  );

  const handleUploadChange = useCallback(
    async (data) => {
      const frameTitle = removeFileExtension(data.original.name);
      setIsCardGame(false);
      if (editFrame.fid || !editFrame.attachmentInfo) {
        const img = new Image();
        img.src = data.original.url;
        img.onload = (e) => {
          const sizes = getImageSize(
            e.target.width,
            e.target.height,
            editStory.isLandscape
          );
          setValue('h', sizes.imageHeight);
          setValue('w', sizes.imageWidth);
          const updatedFrame = {
            ...editFrame,
            title: frameTitle,
            h: sizes.imageHeight,
            w: sizes.imageWidth,
            attachmentInfo: data.original,
            mobileAttachmentInfo: data.compressed,
          };

          dispatch(addNewFrameAction(updatedFrame, UPDATE_TYPE.STANDART));
        };
      }
      setValue('attachmentInfo', data.original, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('mobileAttachmentInfo', data.compressed, {
        shouldDirty: true,
        shouldValidate: true,
      });
      const updatedFrame = {
        ...editFrame,
        title: frameTitle,
        attachmentInfo: data.original,
        mobileAttachmentInfo: data.compressed,
      };
      if (editFrame.id)
        dispatch(updateFrameAction(updatedFrame, UPDATE_TYPE.UPDATE));
    },
    [editStory?.isLandscape, setValue, editFrame, dispatch]
  );

  const handleSetGame = useCallback(
    async (e) => {
      const gamekey = e.target.getAttribute('gamekey');
      setValue('attachmentInfo', {
        type: 'game',
        name: e.target.dataset.value,
        url: e.target.getAttribute('datakey'),
        key: gamekey,
        size: 0,
      });
      dispatch(setEditAction(true));
      if (gamekey === 'politicalCards') setIsCardGame(true);
      setGameName(gamekey);
    },
    [dispatch, setValue]
  );

  const handleAnimationChange = useCallback(
    async (option, type) => {
      dispatch(
        setAnimation({
          frameId: editFrame.id || editFrame.fid,
          type,
        })
      );

      const animationToSave = {
        ...formFieldDebounced.animation,
        delay: autoDelay,
      };

      if (type === 'animation') {
        setValue('animation.animation', option, {
          shouldDirty: true,
          shouldValidate: true,
        });
        animationToSave.animation = option;
        animationToSave.duration = animationToSave.duration || 3;
      }

      if (type === 'kenBurns') {
        setValue('animation.focus', option, {
          shouldDirty: true,
          shouldValidate: true,
        });
        animationToSave.focus = option;
        animationToSave.focusSpeed = animationToSave.focusSpeed || 10;
      }

      if (type === 'appear') {
        setValue('animation.appear', option, {
          shouldDirty: true,
          shouldValidate: true,
        });
        animationToSave.appear = option;
        animationToSave.delay = animationToSave.delay || 2;
      }

      const updatedFrame = {
        ...editFrame,
        ...formFieldDebounced,
        animation: {
          ...defaultValue.animation,
          ...formFieldDebounced.animation,
          ...animationToSave,
        },
      };

      if (editFrame.id) {
        reset({
          ...editFrame,
          ...formFieldDebounced,
          animation: {
            ...defaultValue.animation,
            ...formFieldDebounced.animation,
            ...animationToSave,
          },
        });
        dispatch(updateFrameAction(updatedFrame, UPDATE_TYPE.UPDATE));
      }
    },
    [
      dispatch,
      editFrame,
      autoDelay,
      setValue,
      reset,
      formFieldDebounced,
      defaultValue.animation,
    ]
  );

  const onSubmit = useCallback(
    (data) => {
      const { id, ...fieldsData } = data;
      const newEditFrame = {
        ...editFrame,
        ...fieldsData,
        attachmentInfo: {
          ...editFrame.attachmentInfo,
          ...fieldsData.attachmentInfo,
          subtitles: {
            ...editFrame.attachmentInfo.subtitles,
            ...fieldsData.attachmentInfo.subtitles,
          },
        },
      };

      const animationToSave = newEditFrame.animation;

      if (!animationToSave.animation) {
        delete animationToSave.animation;
        delete animationToSave.duration;
      }

      if (!animationToSave.focus) {
        delete animationToSave.focus;
        delete animationToSave.focusSpeed;
      }

      if (animationToSave.appear === 'manual') delete animationToSave.delay;

      if (
        editFrame?.attachmentInfo.name !==
          'FREMTIDSFRØ - GAME 3: POLITICAL CARD GAME' &&
        editFrame?.id
      )
        dispatch(removeGameItemsAction(editFrame?.id));
      if (editFrame.fid)
        dispatch(
          addNewFrameAction(
            { ...newEditFrame, animation: animationToSave },
            UPDATE_TYPE.STANDART,
            isCardGame,
            gameName
          )
        );
      else if (frameValidation(framesList[editFrame?.originPage])) {
        dispatch(
          updateFrameAction(
            { ...newEditFrame, animation: animationToSave },
            UPDATE_TYPE.UPDATE,
            isCardGame,
            gameName
          )
        );
      }
    },
    [editFrame, dispatch, isCardGame, gameName, framesList]
  );

  const lastPageFrames = useMemo(
    () => framesList[pagesList[pagesList?.length - 1]?.id],
    [pagesList, framesList]
  );

  const isLastFrame = useMemo(() => {
    return (
      lastPageFrames?.length &&
      lastPageFrames[lastPageFrames?.length - 1]?.id === editFrame?.id
    );
  }, [editFrame?.id, lastPageFrames]);

  const handleDimensionsChange = useCallback(
    (field, val) => {
      if (field.name === 'x' || field.name === 'w')
        return setValue(field.name, val / (4 * orientationCoef));
      return setValue(field.name, val / 4);
    },
    [setValue, orientationCoef]
  );

  const handleFindSame = useCallback(
    (newValue) => {
      const fieldData = fields.filter(({ value }) => value === newValue);
      const editData = subTitlesData
        ? Object.keys(subTitlesData).filter((item) => item === newValue)
        : [];

      return !![...fieldData, ...editData].length;
    },
    [fields, subTitlesData]
  );

  const getData = useCallback(() => {
    const getFieldLng = fields?.map(({ value }) => value);
    return Object.fromEntries(
      Object.entries(subTitlesData).filter(
        ([key]) => !getFieldLng.includes(key)
      )
    );
  }, [fields, subTitlesData]);

  const formattedData = getData();

  return (
    <FormWrap id='frame-form' onSubmit={handleSubmit(onSubmit)}>
      <ControlBarBlock blockTitle='Details'>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              disableUnderline
              label='Name'
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='title'
          control={control}
        />
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Dimensions'>
        <Box display='flex' justifyContent='space-between'>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                disableUnderline
                label='X'
                fullWidth={false}
                error={error ? error.message : ''}
                {...field}
                width='45%'
                onChange={(e) => handleDimensionsChange(field, e)}
                value={(field.value * 4 * orientationCoef).toFixed(0) || '0'}
              />
            )}
            name='x'
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth={false}
                disableUnderline
                label='Y'
                error={error ? error.message : ''}
                {...field}
                width='45%'
                onChange={(e) => handleDimensionsChange(field, e)}
                value={(field.value * 4).toFixed(0) || '0'}
              />
            )}
            name='y'
            control={control}
          />
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth={false}
                disableUnderline
                label='W'
                error={error ? error.message : ''}
                {...field}
                width='45%'
                onChange={(e) => handleDimensionsChange(field, e)}
                value={(field.value * 4 * orientationCoef).toFixed(0) || '0'}
              />
            )}
            name='w'
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth={false}
                disableUnderline
                label='H'
                error={error ? error.message : ''}
                {...field}
                width='45%'
                onChange={(e) => handleDimensionsChange(field, e)}
                value={(field.value * 4).toFixed(0) || '0'}
              />
            )}
            name='h'
            control={control}
          />
        </Box>
      </ControlBarBlock>

      <ControlBarBlock
        blockTitle='Content'
        padding={`${spacing(2)}px ${spacing(2)}px 0`}
      >
        <TabsWrapper
          justifyContent='space-between'
          value={selectTab}
          onChange={handleChange}
        >
          <StyledTab label='Image' maxWidth={34} />
          <StyledTab label='Video' maxWidth={34} />
          <StyledTab label='Audio' maxWidth={34} />
          <StyledTab label='URL' maxWidth={34} />
          <StyledTab label='Game' maxWidth={34} />
        </TabsWrapper>
        <TabPanel value={selectTab} index={0}>
          <Controller
            render={(controlProps) => (
              <FileInput
                value={
                  SELECT_TAB_TYPE[selectTab] === editFrame?.attachmentInfo?.type
                    ? controlProps.field.value?.name
                    : ''
                }
                label='Select file (5mb max.)'
                clearErrors={clearErrors}
                errors={errors}
                validateImage
                placeholder='No file'
                accept={['image/*']}
                onError={setError}
                onChange={handleUploadChange}
                {...controlProps}
              />
            )}
            name='attachmentInfo'
            control={control}
          />
          <ControlBarBlock
            blockTitle='Frame sound'
            padding={`${spacing(2)}px ${spacing(2)}px 0`}
            margin={`0 -${spacing(2)}px`}
          >
            <Controller
              render={(controlProps) => (
                <FileInput
                  value={controlProps.field.value?.name || ''}
                  label='Select file'
                  onChange={(data) => {
                    const media = new Audio(data.original.url);
                    media.onloadedmetadata = () => {
                      const delay = Math.round(media.duration);
                      setAutoDelay(delay);
                      setValue('animation.delay', delay);
                    };
                    setValue(controlProps.field.name, data.original, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  placeholder='No file'
                  accept={['audio/mp3']}
                  onError={setError}
                  {...controlProps}
                />
              )}
              name='additionalAttachmentInfo'
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledInput
                  error={error ? error.message : ''}
                  disableUnderline
                  label='Fade in duration'
                  {...field}
                  fullWidth
                />
              )}
              name='fadeIn'
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledInput
                  error={error ? error.message : ''}
                  disableUnderline
                  label='Fade out duration'
                  {...field}
                  fullWidth
                />
              )}
              name='fadeOut'
              control={control}
            />
            <Controller
              name='loopAudio'
              control={control}
              render={({ field }) => (
                <StyledCheckbox
                  disabled={!formField.additionalAttachmentInfo}
                  label='Loop audio'
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={!!field.value}
                />
              )}
            />
          </ControlBarBlock>
        </TabPanel>
        <TabPanel value={selectTab} index={1}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth
                disableUnderline
                label='Video url'
                error={error ? error.message : ''}
                onChangeValue={handleOnChangeURL}
                {...field}
                value={
                  SELECT_TAB_TYPE[selectTab] === editFrame?.attachmentInfo?.type
                    ? editFrame?.attachmentInfo?.url
                    : ''
                }
              />
            )}
            name='attachmentInfo'
            control={control}
          />
          <Controller
            render={(controlProps) => (
              <FileInput
                value={controlProps.field.value?.name || ''}
                label='Placeholder image (5mb max.)'
                placeholder='No file'
                onError={setError}
                accept={['image/*']}
                clearErrors={clearErrors}
                errors={errors}
                validateImage
                onChange={(data) => {
                  setValue('videoImage', data.original, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                {...controlProps}
              />
            )}
            name='videoImage'
            control={control}
          />
          <ControlBarBlock
            blockTitle='Video subtitles (.VTT)'
            padding={`${spacing(2)}px ${spacing(2)}px 0`}
            margin={`0 -${spacing(1.5)}px`}
          >
            <SelectField
              displayEmpty
              value=''
              control={control}
              label='Select language for subtitle'
              onChange={(e) => {
                if (!handleFindSame(e.target.value))
                  append({ value: e.target.value });
              }}
            >
              <MenuItem value='' disabled>
                Select language...
              </MenuItem>
              {editStory?.languages?.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={index} value={item}>
                  {getEnglishName(item)}
                </MenuItem>
              ))}
            </SelectField>
            {!!fields.length &&
              fields.map(({ value }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid key={index} className={classes.wrapper}>
                  <Box width='100%'>
                    <Controller
                      render={(controlProps) => (
                        <FileInput
                          value={controlProps.field.value?.name || ''}
                          label={getEnglishName(value)}
                          placeholder='No file'
                          accept={['.vtt']}
                          onError={setError}
                          onChange={(data) => {
                            setValue(
                              `attachmentInfo.subtitles.${value}`,
                              data.original,
                              {
                                shouldDirty: true,
                                shouldValidate: true,
                              }
                            );
                          }}
                          onDelete={() =>
                            setValue(`attachmentInfo.subtitles.${value}`, {})
                          }
                          {...controlProps}
                        />
                      )}
                      name={`attachmentInfo.subtitles.${value}`}
                      control={control}
                    />
                  </Box>
                  <Box
                    className={classes.trash}
                    onClick={() => {
                      setValue(`attachmentInfo.subtitles.${value}`, {});
                      remove(index);
                    }}
                  >
                    <TrashIcon />
                  </Box>
                </Grid>
              ))}
            {formattedData &&
              Object.keys(formattedData).map((value) => (
                <Grid key={value} className={classes.wrapper}>
                  <Box width='100%'>
                    <Controller
                      render={(controlProps) => (
                        <FileInput
                          value={controlProps.field.value?.name || ''}
                          label={getEnglishName(value)}
                          placeholder='No file'
                          accept={['.vtt']}
                          onError={setError}
                          onChange={(data) => {
                            setValue(
                              `attachmentInfo.subtitles.${value}`,
                              data.original,
                              {
                                shouldDirty: true,
                                shouldValidate: true,
                              }
                            );
                          }}
                          onDelete={() =>
                            setValue(`attachmentInfo.subtitles.${value}`, {})
                          }
                          {...controlProps}
                        />
                      )}
                      name={`attachmentInfo.subtitles.${value}`}
                      control={control}
                    />
                  </Box>
                  <Box
                    className={classes.trash}
                    onClick={() => {
                      setValue(`attachmentInfo.subtitles.${value}`, {});
                    }}
                  >
                    <TrashIcon />
                  </Box>
                </Grid>
              ))}
          </ControlBarBlock>
        </TabPanel>
        <TabPanel value={selectTab} index={2}>
          <Controller
            render={(controlProps) => (
              <FileInput
                {...controlProps}
                value={
                  SELECT_TAB_TYPE[selectTab] === editFrame?.attachmentInfo?.type
                    ? editFrame?.attachmentInfo?.name
                    : ''
                }
                onChange={(data) => {
                  setIsCardGame(false);
                  setValue('attachmentInfo', data.original, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                label='Select file'
                placeholder='No file'
                accept={['audio/mp3']}
                onError={setError}
              />
            )}
            name='attachmentInfo'
            control={control}
          />
        </TabPanel>
        <TabPanel value={selectTab} index={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth
                disableUnderline
                label='Frame url'
                error={error ? error.message : ''}
                placeholder='https://'
                onChangeValue={handleOnChangeURL}
                {...field}
                value={
                  SELECT_TAB_TYPE[selectTab] === editFrame?.attachmentInfo?.type
                    ? editFrame?.attachmentInfo?.url
                    : ' '
                }
              />
            )}
            name='attachmentInfo'
            control={control}
          />
          <Controller
            name='newWindow'
            control={control}
            render={({ field }) => (
              <StyledCheckbox
                label='Open frame in new Window'
                onChange={(e) => field.onChange(e.target.checked)}
                checked={!!field.value}
              />
            )}
          />
          {formField.newWindow && (
            <>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledInput
                    fullWidth
                    disableUnderline
                    label='Height'
                    error={error ? error.message : ''}
                    {...field}
                  />
                )}
                name='windowHeight'
                control={control}
              />
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledInput
                    fullWidth
                    disableUnderline
                    label='Width'
                    error={error ? error.message : ''}
                    {...field}
                  />
                )}
                name='windowWidth'
                control={control}
              />
            </>
          )}
        </TabPanel>
        <TabPanel value={selectTab} index={4}>
          <Controller
            render={({ fieldState: { error } }) => (
              <SelectField
                displayEmpty
                defaultValue=''
                value={
                  editFrame?.attachmentInfo?.type === ATTACHMENTS_TYPE.GAME
                    ? editFrame?.attachmentInfo.name
                    : ''
                }
                label='Game name'
                error={error ? error.message : null}
                control={control}
              >
                {GAMES.map((item) => {
                  const keys = Object.keys(item);
                  return (
                    <MenuItem
                      key={item[keys][1]}
                      datakey={item[keys][1]}
                      value={item[keys][0]}
                      onClick={handleSetGame}
                      gamekey={keys}
                    >
                      {item[keys][0]}
                    </MenuItem>
                  );
                })}
              </SelectField>
            )}
            name='attachmentInfo'
            control={control}
          />
        </TabPanel>
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Animation'>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledTooltip
              placement='top-start'
              title='Type of animation for frame'
            >
              <SelectField
                error={error ? error.message : null}
                control={control}
                defaultValue=''
                label='Animation'
                displayEmpty
                {...field}
              >
                <MenuItem
                  value=''
                  onClick={() => handleAnimationChange('', 'animation')}
                >
                  Without animation
                </MenuItem>
                <ListSubheader>Back In</ListSubheader>
                {animationTypes.backIn.map((option) => (
                  <MenuItem
                    onClick={() => handleAnimationChange(option, 'animation')}
                    value={option}
                    key={option}
                  >
                    {capitalizeFirstLetter(option)}
                  </MenuItem>
                ))}
                <ListSubheader>Bouncing</ListSubheader>
                {animationTypes.bouncing.map((option) => (
                  <MenuItem
                    onClick={() => handleAnimationChange(option, 'animation')}
                    value={option}
                    key={option}
                  >
                    {capitalizeFirstLetter(option)}
                  </MenuItem>
                ))}
                <ListSubheader>Fade In</ListSubheader>
                {animationTypes.fadeIn.map((option) => (
                  <MenuItem
                    onClick={() => handleAnimationChange(option, 'animation')}
                    value={option}
                    key={option}
                  >
                    {capitalizeFirstLetter(option)}
                  </MenuItem>
                ))}
                <ListSubheader>Flip</ListSubheader>
                {animationTypes.flip.map((option) => (
                  <MenuItem
                    onClick={() => handleAnimationChange(option, 'animation')}
                    value={option}
                    key={option}
                  >
                    {capitalizeFirstLetter(option)}
                  </MenuItem>
                ))}
                <ListSubheader>Zoom</ListSubheader>
                {animationTypes.zoom.map((option) => (
                  <MenuItem
                    onClick={() => handleAnimationChange(option, 'animation')}
                    value={option}
                    key={option}
                  >
                    {capitalizeFirstLetter(option)}
                  </MenuItem>
                ))}
                <ListSubheader>Slide In</ListSubheader>
                {animationTypes.slideIn.map((option) => (
                  <MenuItem
                    onClick={() => handleAnimationChange(option, 'animation')}
                    value={option}
                    key={option}
                  >
                    {capitalizeFirstLetter(option)}
                  </MenuItem>
                ))}
              </SelectField>
            </StyledTooltip>
          )}
          name='animation.animation'
          control={control}
        />
        {formFieldDebounced?.animation?.animation && (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTooltip
                title='Duration of animation in seconds'
                placement='top-start'
              >
                <StyledInput
                  error={error ? error.message : ''}
                  disableUnderline
                  label='Duration'
                  {...field}
                  fullWidth
                />
              </StyledTooltip>
            )}
            name='animation.duration'
            control={control}
          />
        )}
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledTooltip
              title='Type of Ken Burns animation effect'
              placement='top-start'
            >
              <SelectField
                error={error ? error.message : null}
                label='Ken Burns effect'
                control={control}
                defaultValue=''
                displayEmpty
                {...field}
              >
                <MenuItem
                  value=''
                  onClick={() => handleAnimationChange('', 'kenBurns')}
                >
                  Without effect
                </MenuItem>
                {[
                  'leftTop',
                  'leftBottom',
                  'rightTop',
                  'rightBottom',
                  'zoomIn',
                  'zoomOut',
                ].map((option) => {
                  return (
                    <MenuItem
                      onClick={() => handleAnimationChange(option, 'kenBurns')}
                      value={option}
                      key={option}
                    >
                      {capitalizeFirstLetter(option)}
                    </MenuItem>
                  );
                })}
              </SelectField>
            </StyledTooltip>
          )}
          name='animation.focus'
          control={control}
        />
        {formFieldDebounced?.animation?.focus && (
          <>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTooltip
                  title='Focus speed of Ken Burns animation effect'
                  placement='top-start'
                >
                  <StyledInput
                    error={error ? error.message : ''}
                    label='Focus speed'
                    disableUnderline
                    {...field}
                    fullWidth
                  />
                </StyledTooltip>
              )}
              name='animation.focusSpeed'
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTooltip
                  title='Zoom of Ken Burns animation effect in percentage'
                  placement='top-start'
                >
                  <StyledInput
                    error={error ? error.message : ''}
                    label='Zoom in %'
                    disableUnderline
                    {...field}
                    fullWidth
                  />
                </StyledTooltip>
              )}
              name='animation.zoomIn'
              control={control}
            />
          </>
        )}
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledTooltip
              title='Auto or manual switch between frames'
              placement='top-start'
            >
              <SelectField
                error={error ? error.message : null}
                defaultValue='manual'
                control={control}
                label='Next frame'
                displayEmpty
                {...field}
              >
                <MenuItem
                  value=''
                  disabled
                  onClick={() => handleAnimationChange('', 'appear')}
                >
                  Select action...
                </MenuItem>
                {['manual', 'automated'].map((option) => {
                  return (
                    <MenuItem
                      value={option}
                      key={option}
                      onClick={() => handleAnimationChange(option, 'appear')}
                    >
                      {option}
                    </MenuItem>
                  );
                })}
              </SelectField>
            </StyledTooltip>
          )}
          name='animation.appear'
          control={control}
        />
        {formFieldDebounced?.animation?.appear === 'automated' && (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTooltip
                title='Time delay between frames when auto switching selected, (seconds). minimum value 0.1'
                placement='top-start'
              >
                <StyledInput
                  error={error ? error.message : ''}
                  disableUnderline
                  label='Time delay'
                  {...field}
                  fullWidth
                />
              </StyledTooltip>
            )}
            name='animation.delay'
            control={control}
          />
        )}
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Advanced'>
        <Controller
          name='storyEnd'
          control={control}
          render={({ field }) => (
            <StyledCheckbox
              label='Story ends with this frame'
              onChange={(e) => field.onChange(e.target.checked)}
              checked={!!field.value}
            />
          )}
        />
        <Controller
          name='fullScreen'
          control={control}
          render={({ field }) => (
            <StyledCheckbox
              label='Show fullscreen'
              onChange={(e) => field.onChange(e.target.checked)}
              checked={!!field.value}
            />
          )}
        />
        <Controller
          name='hideBorder'
          control={control}
          render={({ field }) => (
            <StyledCheckbox
              label='Hide frame border'
              onChange={(e) => field.onChange(e.target.checked)}
              checked={!!field.value}
            />
          )}
        />
        {(editFrame?.storyEnd || !!isLastFrame) && (
          <Controller
            name='canReturn'
            control={control}
            render={({ field }) => (
              <StyledCheckbox
                label='Player can return to previous decision'
                onChange={(e) => field.onChange(e.target.checked)}
                checked={!!field.value}
              />
            )}
          />
        )}
        <Controller
          name='isHideNavigation'
          control={control}
          render={({ field }) => (
            <StyledCheckbox
              label='Hide navigation'
              onChange={(e) => field.onChange(e.target.checked)}
              checked={isLastFrame || !!field.value}
            />
          )}
        />
      </ControlBarBlock>
    </FormWrap>
  );
};

export default FrameControl;

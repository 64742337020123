import React from 'react';
import classNames from 'classnames';
import PanoramaIcon from '@material-ui/icons/Panorama';

export const ImageIcon = ({
  fill = 'white',
  fillOpacity = 1,
  className,
  ...props
}) => {
  return (
    <PanoramaIcon
      style={{ fill, width: 14, height: 14 }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
      fillOpacity={fillOpacity}
    />
  );
};

/* <svg width='12' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.88 9.5H1.12C.5 9.5 0 9 0 8.37V1.63C0 1 .5.5 1.13.5h9.75C11.5.5 12 1 12 1.63v6.75c0 .62-.5 1.12-1.13 1.12zM2.62 1.81a1.31 1.31 0 100 2.63 1.31 1.31 0 000-2.63zM1.5 8h9V5.37L8.45 3.33a.28.28 0 00-.4 0L4.87 6.5l-1.3-1.3a.28.28 0 00-.4 0L1.5 6.87V8z'
        fill='#fff'
        fillOpacity='.5'
      />
    </svg> */

import axios from 'axios';

const getHotSpotsList = (params = {}) => {
  return axios
    .get('/hotspots', { params: { limit: 100, sort: 'createdAt', ...params } })
    .then((res) => res.data);
};

const getHotSpot = (id) => {
  return axios.get(`/hotspots/${id}`).then((res) => res.data);
};

const createHotSpot = (body, type) =>
  axios.post(`/hotspots?type=${type}`, body).then((res) => res.data);

const updateHotSpot = (body, updateType) =>
  axios
    .put(`/hotspots/${body.id}`, body, { params: { type: updateType } })
    .then((res) => res.data && res.data.updatedHotspot);

const deleteHotSpot = (id, type) =>
  axios.delete(`/hotspots/${id}?type=${type}`);

export {
  getHotSpotsList,
  getHotSpot,
  createHotSpot,
  updateHotSpot,
  deleteHotSpot,
};

export default {
  getHotSpotsList,
  getHotSpot,
  createHotSpot,
  updateHotSpot,
  deleteHotSpot,
};

import React, { useCallback } from 'react';
import { Box, Collapse } from '@material-ui/core';
import PropTypes from 'prop-types';
import ListItemTitle from '../ListItemTitle';
import useStyles from './styles';

const CollapseWrap = ({
  id,
  title,
  type,
  isOpen,
  isActive,
  isCircleFrame,
  onExpand,
  onClick,
  onDelete,
  children,
  isGroupSelection,
  onGroupSelectionClick,
  setCheckedItems,
  ...props
}) => {
  const classes = useStyles();

  const handleActiveElement = useCallback(
    (e) => {
      onClick(id, e);
    },
    [onClick, id]
  );

  const handleOnExpand = useCallback(() => {
    onExpand(id);
  }, [id, onExpand]);

  return (
    <Box className={classes.root}>
      <ListItemTitle
        setCheckedItems={setCheckedItems}
        isOpen={isOpen}
        isActive={isActive}
        isCircleFrame={isCircleFrame}
        type={type}
        title={title}
        onDelete={onDelete}
        onClick={handleActiveElement}
        onExpand={handleOnExpand}
        onGroupSelectionClick={onGroupSelectionClick}
        isGroupSelection={isGroupSelection}
        {...props}
      />
      <Collapse in={isOpen}>{children}</Collapse>
    </Box>
  );
};

CollapseWrap.defaultProps = {
  onClick: () => {},
  onExpand: () => {},
  onDelete: () => {},
  onGroupSelectionClick: () => {},
  isGroupSelection: false,
};

CollapseWrap.propTypes = {
  isGroupSelection: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onExpand: PropTypes.func,
  onDelete: PropTypes.func,
  onGroupSelectionClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'chapter',
    'page',
    'frame',
    'decision',
    'hotspot',
    'image',
    'sound',
    'layer_speechbubble',
  ]).isRequired,
};

export default CollapseWrap;

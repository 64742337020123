import React from 'react';
import { cssTransition, toast } from 'react-toastify';
import '../styles/_main.scss';

// Toast pruning
const successToast = (title, description) => {
  toast.success(
    <div>
      <div style={{ fontWeight: 400, fontSize: '8px' }}> {title} </div>
      <div>{description}</div>
    </div>,
    {
      position: 'bottom-right',
      autoClose: 3000,
      transition: cssTransition({
        enter: 'fadeIn',
        exit: 'fadeOut',
      }),
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    }
  );
};

const errorToast = (title, description) => {
  toast.error(
    <div>
      <div style={{ fontWeight: 400, fontSize: '8px' }}> {title} </div>
      <div>{description}</div>
    </div>,
    {
      position: 'bottom-right',
      autoClose: 3000,
      transition: cssTransition({
        enter: 'fadeIn',
        exit: 'fadeOut',
      }),
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    }
  );
};

export { successToast, errorToast };

export default {
  successToast,
  errorToast,
};

import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ClickAwayListener, MenuItem } from '@material-ui/core';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { SketchPicker } from 'react-color';
import { TEXT_LAYER_TYPE } from 'constants/editTypes';
import { layerFont } from 'constants/defaultItems';
import { UPDATE_TYPE } from 'constants/updateTypes';
import { useDebounce } from 'utils/hooks/useDebounce';
import { treeCompare } from 'utils/treeCompare';
import {
  storyListEditItemSelector,
  storyListEditTypeSelector,
} from 'common/selectors/editStory';
import { editItemAction, setEditAction } from 'common/actions/editStoryActions';
import {
  addLayerAction,
  removeLayerAction,
  updateLayerAction,
} from 'common/actions/layersListAction';
import FormWrap from 'components/forms/FormWrap';
import StyledInput from 'components/forms/StyledInput';
import SelectField from 'components/forms/SelectField/SelectField';
import StyledCheckbox from 'components/forms/StyledCheckbox/StyledCheckbox';
import StyledColorInput from 'components/forms/StyledColorInput/StyledColorInput';
import ControlBarBlock from '../ControlBarBlock';
import ControlButton from '../ControlButton';
import { defaultValues, fonts, sizes } from './LayerTextControl.constants';
import { validationSchema } from './LayerTextControl.validation';
import useStyles from './styles';

const selector = createSelector(
  storyListEditItemSelector,
  storyListEditTypeSelector,
  (editItem, editType) => ({
    editItem,
    editType,
  })
);

const LayerTextControl = () => {
  const { editItem, editType } = useSelector(selector);
  const dispatch = useDispatch();

  const [pickerOpened, setPickerOpened] = useState(false);
  const [pickerColor, setPickerColor] = useState('');
  const [changeField, setChangeField] = useState('');

  const classes = useStyles();

  const { control, reset, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      id: editItem?.id || '',
      title: editItem?.title || defaultValues.title,
      content: editItem?.content || defaultValues.content,
      isSpeechbubble: editItem?.isSpeechbubble || defaultValues.isSpeechbubble,
      font: editItem?.font || layerFont,
      speechbubble: editItem?.speechbubble || defaultValues.speechbubble,
      isOverlay: editItem?.isOverlay || defaultValues.isOverlay,
      zIndex: editItem?.zIndex || defaultValues.zIndex,
      type: editType,
      soundInfo: {},
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({
    control,
  });

  const formFieldDebounced = useDebounce(formField, 300);

  useEffect(() => {
    if (editItem && editItem?.id !== formField.id) {
      reset({
        id: editItem?.id || '',
        title: editItem?.title || defaultValues.title,
        content: editItem?.content || defaultValues.content,
        isSpeechbubble:
          editItem?.isSpeechbubble || defaultValues.isSpeechbubble,
        font: editItem?.font || layerFont,
        speechbubble: editItem?.speechbubble || defaultValues.speechbubble,
        isOverlay: editItem?.isOverlay || defaultValues.isOverlay,
        zIndex: editItem?.zIndex || defaultValues.zIndex,
        type: editType,
        soundInfo: {},
      });
    }
  }, [reset, editItem, formField.id, editType]);

  const handleUpdateLayer = useCallback(() => {
    const { id, ...fieldsData } = formFieldDebounced;
    // the order of the elements must be the same like in formField
    const fieldsForCompare = {
      id: editItem?.id || '',
      title: editItem?.title || defaultValues.title,
      content: editItem?.content || defaultValues.content,
      isSpeechbubble: editItem?.isSpeechbubble || defaultValues.isSpeechbubble,
      font: editItem?.font || layerFont,
      speechbubble: editItem?.speechbubble || defaultValues.speechbubble,
      isOverlay: editItem?.isOverlay || defaultValues.isOverlay,
      zIndex: editItem?.zIndex || defaultValues.zIndex,
      type: editType,
      soundInfo: {},
    };

    if (!treeCompare(formFieldDebounced, fieldsForCompare)) {
      const speechbubble = {
        ...editItem?.speechbubble,
        ...fieldsData.speechbubble,
      };
      dispatch(
        editItemAction({
          item: {
            ...editItem,
            ...fieldsData,
            speechbubble,
          },
          type: TEXT_LAYER_TYPE,
        })
      );
      dispatch(setEditAction(true));
    }
  }, [formFieldDebounced, editItem, editType, dispatch]);

  useEffect(() => {
    handleUpdateLayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced]);

  const onSubmit = useCallback(
    (data) => {
      const { id, ...fieldsData } = data;

      const speechbubble = {
        ...editItem?.speechbubble,
        ...fieldsData.speechbubble,
      };

      if (editItem.id) {
        dispatch(
          updateLayerAction(
            {
              ...editItem,
              ...fieldsData,
              speechbubble,
              soundInfo: {},
            },
            UPDATE_TYPE.UPDATE
          )
        );
      } else {
        dispatch(
          addLayerAction({
            ...editItem,
            ...fieldsData,
            speechbubble,
            soundInfo: {},
          })
        );
      }
    },
    [dispatch, editItem]
  );

  const handleRemove = useCallback(() => {
    dispatch(removeLayerAction());
  }, [dispatch]);

  const handleOpenPicker = useCallback((e) => {
    setChangeField(e.target.name);
    setPickerColor(e.target.value);
    setPickerOpened(true);
  }, []);

  const handleColorChange = useCallback((color) => {
    setPickerColor(color.hex);
  }, []);

  const handleColorChangeComplete = useCallback(
    (color) => {
      setValue(changeField, color.hex.toUpperCase());
    },
    [changeField, setValue]
  );

  return (
    <FormWrap id='layer_text-form' onSubmit={handleSubmit(onSubmit)}>
      <ControlBarBlock blockTitle='Details'>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              disableUnderline
              label='Name'
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='title'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              multiline
              rows={4}
              disableUnderline
              label='Content'
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='content'
          control={control}
        />
      </ControlBarBlock>
      <ControlBarBlock blockTitle='Font'>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <SelectField
              displayEmpty
              value={editItem?.font.fontSize}
              label='Font size'
              error={error ? error.message : null}
              {...field}
              control={control}
            >
              {sizes.map((i) => {
                return (
                  <MenuItem key={i} value={i}>
                    {`${i} pt`}
                  </MenuItem>
                );
              })}
            </SelectField>
          )}
          name='font.fontSize'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <SelectField
              displayEmpty
              value={editItem?.font.fontStyle}
              label='Font style'
              error={error ? error.message : null}
              {...field}
              control={control}
            >
              {fonts.map((item) => {
                const keys = Object.keys(item);
                return (
                  <MenuItem key={keys[0]} value={item[keys[0]]}>
                    {item[keys[0]]}
                  </MenuItem>
                );
              })}
            </SelectField>
          )}
          name='font.fontStyle'
          control={control}
        />
      </ControlBarBlock>
      <ControlBarBlock>
        <Controller
          name='isSpeechbubble'
          control={control}
          render={({ field }) => (
            <StyledCheckbox
              label='Speechbubble'
              onChange={(e) => field.onChange(e.target.checked)}
              checked={!!editItem?.isSpeechbubble}
            />
          )}
        />
      </ControlBarBlock>
      {pickerOpened && (
        <ClickAwayListener
          mouseEvent='onMouseUp'
          touchEvent='onTouchStart'
          onClickAway={() => setPickerOpened(false)}
        >
          <SketchPicker
            className={classes.picker}
            color={pickerColor}
            onChange={handleColorChange}
            onChangeComplete={handleColorChangeComplete}
          />
        </ClickAwayListener>
      )}
      {editItem?.isSpeechbubble && (
        <>
          <ControlBarBlock blockTitle='Speech Tale'>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <SelectField
                  error={error ? error.message : null}
                  value={editItem.speechbubble.taleDirection.horisontal}
                  label='Horizontal direction'
                  control={control}
                  onChange={(e) =>
                    setValue(
                      'speechbubble.taleDirection.horisontal',
                      e.target.value
                    )
                  }
                  displayEmpty
                  {...field}
                >
                  <MenuItem value='left'>Left</MenuItem>
                  <MenuItem value='center'>Center</MenuItem>
                  <MenuItem value='right'>Right</MenuItem>
                </SelectField>
              )}
              name='speechbubble.taleDirection.horisontal'
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <SelectField
                  error={error ? error.message : null}
                  value={editItem.speechbubble.taleDirection.vertical}
                  label='Vertical direction'
                  control={control}
                  displayEmpty
                  {...field}
                >
                  <MenuItem value='top'>Top</MenuItem>
                  <MenuItem value='bottom'>Bottom</MenuItem>
                </SelectField>
              )}
              name='speechbubble.taleDirection.vertical'
              control={control}
            />
          </ControlBarBlock>
          <ControlBarBlock blockTitle='Colors' position='relative'>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledColorInput
                  value={editItem?.speechbubble?.backgroundColor || '#ffffff'}
                  error={error ? error.message : ''}
                  onClick={handleOpenPicker}
                  label='Speech background'
                  disableUnderline
                  {...field}
                  fullWidth
                  disabled
                />
              )}
              name='speechbubble.backgroundColor'
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledColorInput
                  value={editItem?.speechbubble?.borderColor || '#000000'}
                  error={error ? error.message : ''}
                  label='Speech border'
                  onClick={handleOpenPicker}
                  disableUnderline
                  {...field}
                  fullWidth
                  disabled
                />
              )}
              name='speechbubble.borderColor'
              control={control}
            />
            <Controller
              name='isOverlay'
              control={control}
              render={({ field }) => (
                <StyledCheckbox
                  label='Is overlay'
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={!!editItem?.isOverlay}
                />
              )}
            />
            {formField.isOverlay && (
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledInput
                    fullWidth
                    type='number'
                    disableUnderline
                    label='Overlay index'
                    error={error ? error.message : ''}
                    {...field}
                  />
                )}
                name='zIndex'
                control={control}
              />
            )}
          </ControlBarBlock>
        </>
      )}
      <ControlBarBlock>
        <ControlButton
          onClick={handleRemove}
          text='Delete Layer'
          disabled={!editItem.id}
        />
      </ControlBarBlock>
    </FormWrap>
  );
};

export default LayerTextControl;

export const treeCompare = (prevTree, currentTree) => {
  try {
    const prevVal = JSON.stringify(prevTree);
    const currentVal = JSON.stringify(currentTree);

    return prevVal === currentVal;
  } catch {
    return false;
  }
};

import React from 'react';
import classnames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';

export const SpeechbubbleIcon = ({ className, ...props }) => {
  return (
    <SvgIcon
      viewBox='0 0 14 12'
      style={{ width: 14, height: 12, fill: 'none' }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classnames({
        [className]: !!className,
      })}
      {...props}
    >
      <path
        d='M6.00078 0C2.68715 0 0.0015553 2.49375 0.0015553 5.57143C0.0015553 6.84643 0.467901 8.01429 1.24124 8.95446C0.892065 10.0098 0.165597 10.9045 0.153879 10.9152C-0.000788143 11.1027 -0.0429702 11.3759 0.0460808 11.6116C0.135132 11.8473 0.339012 12 0.563982 12C2.0052 12 3.14177 11.3116 3.82372 10.7598C4.50097 11.0036 5.23213 11.1429 6.00078 11.1429C9.31441 11.1429 12 8.64911 12 5.57143C12 2.49375 9.31441 0 6.00078 0ZM6.00078 9.85714C5.37508 9.85714 4.75641 9.74732 4.16352 9.53304L3.63155 9.34018L3.17458 9.70982C2.83947 9.98036 2.38015 10.283 1.8271 10.4866C1.99817 10.1625 2.16456 9.79821 2.29345 9.40982L2.54185 8.65714L2.0591 8.07321C1.63494 7.55625 1.12641 6.70179 1.12641 5.57143C1.12641 3.20893 3.31285 1.28571 6.00078 1.28571C8.68871 1.28571 10.8751 3.20893 10.8751 5.57143C10.8751 7.93393 8.68871 9.85714 6.00078 9.85714Z'
        fill='white'
        fillOpacity='0.5'
      />
    </SvgIcon>
  );
};

/* <svg
  width='12'
  height='12'
  viewBox='0 0 12 12'
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
>
  <path
    d='M6.00078 0C2.68715 0 0.0015553 2.49375 0.0015553 5.57143C0.0015553 6.84643 0.467901 8.01429 1.24124 8.95446C0.892065 10.0098 0.165597 10.9045 0.153879 10.9152C-0.000788143 11.1027 -0.0429702 11.3759 0.0460808 11.6116C0.135132 11.8473 0.339012 12 0.563982 12C2.0052 12 3.14177 11.3116 3.82372 10.7598C4.50097 11.0036 5.23213 11.1429 6.00078 11.1429C9.31441 11.1429 12 8.64911 12 5.57143C12 2.49375 9.31441 0 6.00078 0ZM6.00078 9.85714C5.37508 9.85714 4.75641 9.74732 4.16352 9.53304L3.63155 9.34018L3.17458 9.70982C2.83947 9.98036 2.38015 10.283 1.8271 10.4866C1.99817 10.1625 2.16456 9.79821 2.29345 9.40982L2.54185 8.65714L2.0591 8.07321C1.63494 7.55625 1.12641 6.70179 1.12641 5.57143C1.12641 3.20893 3.31285 1.28571 6.00078 1.28571C8.68871 1.28571 10.8751 3.20893 10.8751 5.57143C10.8751 7.93393 8.68871 9.85714 6.00078 9.85714Z'
    fill='white'
    fillOpacity='0.5'
  />
</svg>; */

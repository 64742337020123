import React, { useCallback } from 'react';
import { List } from '@material-ui/core';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { BACKPACK_TYPE } from 'constants/editTypes';
import { backpackListSelector } from 'common/selectors/backpack';
import {
  editItemAction,
  setSelectedAction,
} from 'common/actions/editStoryActions';
import {
  storyListEditItemSelector,
  storyListSelectedIdsSelector,
} from 'common/selectors/editStory';
import { pagesListSelector } from 'common/selectors/pages';
import {
  framesListSelector,
  targetFramesListSelector,
} from 'common/selectors/frames';
import BackpackItem from './BackpackItem';
import useStyles from './styles';

const selector = createSelector(
  backpackListSelector,
  storyListEditItemSelector,
  storyListSelectedIdsSelector,
  pagesListSelector,
  framesListSelector,
  targetFramesListSelector,
  (
    backpackList,
    editBackpackItem,
    selectedIds,
    pagesList,
    framesList,
    targetFrames
  ) => ({
    backpackList,
    editBackpackItem,
    selectedIds,
    pagesList,
    framesList,
    targetFrames,
  })
);

const BackpackList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    backpackList,
    editBackpackItem,
    selectedIds,
    pagesList,
    // framesList,
    targetFrames,
  } = useSelector(selector);
  const handleCheck = useCallback(
    (item) => {
      dispatch(
        setSelectedAction({
          ...selectedIds,
          page: item.originPage,
          frame: item.originFrame,
        })
      );
      dispatch(
        editItemAction({
          item,
          type: BACKPACK_TYPE,
        })
      );
    },
    [dispatch, selectedIds]
  );

  return (
    <List className={classes.wrap} dense disablePadding>
      {backpackList
        .filter((backpack) => {
          return (
            pagesList.find((page) => page.id === backpack.originPage) &&
            targetFrames.find((frame) => frame.id === backpack.originFrame)
          );
        })
        .map((item) => {
          const isSelected = item.id === editBackpackItem?.id;

          return (
            <BackpackItem
              key={item.id}
              text={item.title}
              onCheck={() => handleCheck(item)}
              selected={isSelected}
            />
          );
        })}
    </List>
  );
};

export default BackpackList;

import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { API_URL } from './constants/api';
import App from './App';
import './styles';

axios.defaults.baseURL = `${API_URL}`;
axios.defaults.responseType = 'json';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react';
import classNames from 'classnames';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export const CopyIcon = ({ fill = 'white', className, ...props }) => {
  return (
    <FileCopyIcon
      style={{ fill, width: 14, height: 14 }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};

/* <svg width={14} height={14} viewBox='0 0 14 14' fill='none'>
        <path
          d='M9.625 12.25V13.3438C9.625 13.7062 9.33119 14 8.96875 14H1.53125C1.16881 14 0.875 13.7062 0.875 13.3438V3.28125C0.875 2.91881 1.16881 2.625 1.53125 2.625H3.5V10.7188C3.5 11.5631 4.1869 12.25 5.03125 12.25H9.625ZM9.625 2.84375V0H5.03125C4.66881 0 4.375 0.293809 4.375 0.65625V10.7188C4.375 11.0812 4.66881 11.375 5.03125 11.375H12.4688C12.8312 11.375 13.125 11.0812 13.125 10.7188V3.5H10.2812C9.92031 3.5 9.625 3.20469 9.625 2.84375ZM12.9328 1.9953L11.1297 0.192199C11.0066 0.0691366 10.8397 9.09989e-07 10.6657 0L10.5 0V2.625H13.125V2.45932C13.125 2.28528 13.0559 2.11837 12.9328 1.9953Z'
          fill='white'
        />
      </svg> */

import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default makeStyles((theme) => ({
  // nw, we, sw, se - directions

  pathBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: ({ isCircle }) => isCircle && '50%',
    backgroundColor: ({ isActive }) =>
      isActive ? theme.colors.crimson : theme.colors.itemBackground,
    clipPath: ({ fCoords }) =>
      `polygon(${fCoords.nw.x}% ${fCoords.nw.y}%, ${fCoords.ne.x}% ${fCoords.ne.y}%, ${fCoords.se.x}% ${fCoords.se.y}%, ${fCoords.sw.x}% ${fCoords.sw.y}%)`,
    opacity: ({ hideBorder }) => (hideBorder ? 0.6 : 1),
  },
  wrap: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: `${theme.spacing(0.375)}px`,
    '& ~ .react-resizable-handle': {
      top: ({ fCoords }) => `calc(${fCoords.se.y}% - 20px)`,
      left: ({ fCoords, isCircle }) =>
        isCircle ? '55%' : `calc(${fCoords.se.x}% - 20px)`,
    },
  },
  drop: {
    height: '100%',
    width: '100%',
    '& ~ .react-resizable-handle': {
      top: ({ fCoords }) => `calc(${fCoords.se.y}% - 20px)`,
      left: ({ fCoords, isCircle }) =>
        isCircle ? '55%' : `calc(${fCoords.se.x}% - 20px)`,
    },
  },
  dropTarget: {
    height: '100%',
    width: '100%',
  },

  folderWrap: {
    height: '100%',
    width: '100%',
    position: 'absolute',
  },

  formHandlesBlock: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: '10',
    '& > *': {
      cursor: 'grab',
      position: 'absolute',
      width: '15px',
      height: '15px',
      borderRadius: '50%',
      backgroundColor: 'blue',
      zIndex: '10',
    },
  },

  topLeftHandle: {
    top: ({ fCoords }) => `${fCoords.nw.y - 2}%`,
    left: ({ fCoords }) => `${fCoords.nw.x - 2}%`,
  },

  topRightHandle: {
    top: ({ fCoords }) => `${fCoords.ne.y - 2}%`,
    left: ({ fCoords }) => `${fCoords.ne.x - 4}%`,
  },
  bottomRightHandle: {
    top: ({ fCoords }) => `${fCoords.se.y - 4}%`,
    left: ({ fCoords }) => `${fCoords.se.x - 4}%`,
  },
  bottomLeftHandle: {
    top: ({ fCoords }) => `${fCoords.sw.y - 4}%`,
    left: ({ fCoords }) => `${fCoords.sw.x - 2}%`,
  },

  dropOverFrame: { opacity: 0.7 },
  dropOverTarget: { opacity: 0.4 },
  dropText: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.colors.black,
    zIndex: 2,
  },
  uploadWrap: {
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: transparentize(0.5, theme.colors.white),
  },
}));

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Rnd } from 'react-rnd';
import { Box, useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import soundIcon from 'assets/icons/sound.svg';
import videoIcon from 'assets/icons/video.svg';
import {
  BACKPACK_TYPE,
  IMAGE_LAYER_TYPE,
  SPEECHBUBBLE_LAYER_TYPE,
  TEXT_LAYER_TYPE,
} from 'constants/editTypes';
import { getDefaultFrame } from 'constants/defaultItems';
import { useKeyboardEvents } from 'utils/hooks/useKeyboardEvents';
import {
  storyListEditItemSelector,
  storyListEditTypeSelector,
} from 'common/selectors/editStory';
import { editItemAction, setEditAction } from 'common/actions/editStoryActions';
import { storyItemsSelector } from 'common/selectors/story';
import { pageListItemFrameSelector } from 'common/selectors/pages';
import { speechbubbleSelector } from 'common/selectors/speechbubble';
import Image from 'components/Image';
import Speechbubble from '../PagesList/Speechbubble/Speechbubble';
import {
  COLS,
  GRID_WIDTH_LANDSCAPE,
  GRID_WIDTH_PORTRAIT,
} from '../EditGridPage/EditGridPage.constants';
import DrawMode from '../ControlBar/LayerSpeechbubbleControl/components/DrawMode/DrawMode';
import { defaultPosition } from './SpotView.constants';
import useStyles from './styles';
import { isOutOfArea, returnNewPosition } from './SpotView.helper';

const selector = createSelector(
  pageListItemFrameSelector,
  storyListEditItemSelector,
  storyListEditTypeSelector,
  storyItemsSelector,
  speechbubbleSelector,
  (selectedFrame, editItem, editType, story, speechBubbleState) => ({
    selectedFrame,
    editItem,
    editType,
    story,
    speechBubbleState,
  })
);

const SpotView = () => {
  const { spacing } = useTheme();
  const {
    selectedFrame,
    editItem,
    editType,
    story,
    speechBubbleState,
  } = useSelector(selector);

  const fCoords = useMemo(() => selectedFrame?.formCoords, [
    selectedFrame?.formCoords,
  ]);

  const classes = useStyles({
    isLayer:
      editType === IMAGE_LAYER_TYPE ||
      editType === TEXT_LAYER_TYPE ||
      editType === SPEECHBUBBLE_LAYER_TYPE,
    isBackpack: editType === BACKPACK_TYPE,
    fCoords: fCoords || getDefaultFrame().formCoords,
    isCircle: selectedFrame?.isCircle,
    isDrawing: speechBubbleState.isDrowingMode,
  });
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [spotId, setSpotId] = useState('');
  const [position, setPosition] = useState(defaultPosition);
  const { isPressedShift, isPressedAlt, isPressedCtrl } = useKeyboardEvents();

  const setNewCoordinates = useCallback(
    (newPosition) => {
      const parent = containerRef.current?.getBoundingClientRect();

      if (parent.width && parent.height) {
        const { coordinates } = editItem;
        const xp = (newPosition.x * 100) / parent.width;
        const yp = (newPosition.y * 100) / parent.height;
        const wp = (newPosition.w * 100) / parent.width;
        const hp = (newPosition.h * 100) / parent.height;

        if (
          coordinates.x !== xp ||
          coordinates.y !== yp ||
          coordinates.w !== wp ||
          coordinates.h !== hp
        ) {
          dispatch(
            editItemAction({
              item: {
                ...editItem,
                coordinates: {
                  x: xp,
                  y: yp,
                  w: newPosition.w,
                  h: newPosition.h,
                },
              },
              type: editType,
            })
          );
          dispatch(setEditAction(true));
        }
      }
    },
    [dispatch, editItem, editType]
  );

  const percentToPx = useCallback(() => {
    const { x, y, w, h } = editItem.coordinates;
    const parent = containerRef.current?.getBoundingClientRect();

    if (parent) {
      const xp = (parent.width * x) / 100;
      const yp = (parent.height * y) / 100;
      setPosition({
        x: xp,
        y: yp,
        w,
        h,
      });
    }
  }, [editItem]);

  useEffect(() => {
    if (spotId !== editItem.id) {
      setSpotId(editItem.id);
      percentToPx();
    }
    percentToPx();
  }, [editItem.id, spotId, percentToPx]);

  const handleOnLoad = useCallback(() => {
    percentToPx();
  }, [percentToPx]);

  const isLayout = useMemo(() => {
    return editType === IMAGE_LAYER_TYPE || editType === TEXT_LAYER_TYPE;
  }, [editType]);

  const handleOutOfLayout = useCallback(
    (x, y, w, h) => {
      const { width, height } = containerRef.current?.getBoundingClientRect();
      const elementPoX = w + x;
      const elementPoY = h + y;

      if (isOutOfArea(width, height, elementPoX, elementPoY, { x, y })) {
        setNewCoordinates({
          ...position,
          ...returnNewPosition({ x, y }, elementPoX, elementPoY, width, height),
        });
        setPosition({
          ...position,
          ...returnNewPosition({ x, y }, elementPoX, elementPoY, width, height),
        });
      }
    },
    [containerRef, setNewCoordinates, position]
  );

  const handleOnDrag = useCallback(
    (e, d) => {
      setNewCoordinates({ ...position, x: d.x, y: d.y });
      setPosition({ ...position, x: d.x, y: d.y });
      handleOutOfLayout(d.x, d.y, position.w, position.h);
    },
    [position, setNewCoordinates, handleOutOfLayout]
  );

  const handleOnResize = useCallback(
    (elWidth, elHeight, currentPosition) => {
      setNewCoordinates({
        ...currentPosition,
        w: parseInt(elWidth, 10),
        h: parseInt(elHeight, 10),
      });
      setPosition({
        ...currentPosition,
        w: parseInt(elWidth, 10),
        h: parseInt(elHeight, 10),
      });
      handleOutOfLayout(
        currentPosition.x,
        currentPosition.y,
        parseInt(elWidth, 10),
        parseInt(elHeight, 10)
      );
    },
    [setNewCoordinates, handleOutOfLayout]
  );

  const gridWidth = useMemo(() => {
    return !story?.isLandscape
      ? spacing(GRID_WIDTH_LANDSCAPE)
      : spacing(GRID_WIDTH_PORTRAIT);
  }, [story, spacing]);

  const gridHeight = useMemo(() => {
    return story?.isLandscape
      ? spacing(GRID_WIDTH_LANDSCAPE)
      : spacing(GRID_WIDTH_PORTRAIT);
  }, [story, spacing]);

  const containerDimensions = useMemo(() => {
    if (selectedFrame) {
      const widthCoef = gridWidth / COLS;
      const heightCoef = gridHeight / COLS;
      return {
        width: selectedFrame.w * widthCoef,
        height: story?.isLandscape
          ? selectedFrame.h * heightCoef
          : selectedFrame.h * widthCoef,
      };
    }

    return {
      height: 'min-content',
    };
  }, [selectedFrame, gridWidth, gridHeight, story?.isLandscape]);

  const animationStyle = useCallback(() => {
    if (!editItem.animation) return {};
    const animation = `${editItem.animation.duration || 3}s ease-in-out 0s ${
      editItem.animation.animation
    }`;

    return {
      animation,
    };
  }, [editItem.animation]);

  if (!selectedFrame) {
    return (
      <Box
        position='relative'
        display='flex'
        alignItems='flex-start'
        justifyContent='center'
      >
        Please select frame
      </Box>
    );
  }

  // const animateIt = animationParams(frame);

  return (
    <Box className={classes.spotFold} {...containerDimensions}>
      <Box
        display='flex'
        alignItems='flex-start'
        justifyContent='center'
        ref={containerRef}
        {...containerDimensions}
        className={classes.parent}
      />
      <Rnd
        className={classes.root}
        size={{ width: position.w, height: position.h }}
        position={{ x: position.x, y: position.y }}
        lockAspectRatio={!isPressedShift}
        disableDragging={
          isPressedAlt ||
          (isPressedCtrl && !speechBubbleState.isDrowingMode) ||
          (speechBubbleState.isDrowingMode && !isPressedCtrl)
        }
        enableResizing={
          !isPressedAlt ||
          !isPressedCtrl ||
          (speechBubbleState.isDrowingMode && !isPressedCtrl)
        }
        onDragStop={handleOnDrag}
        onResizeStop={(e, direction, ref, delta, currentPosition) =>
          handleOnResize(ref.style.width, ref.style.height, currentPosition)
        }
        {...(!isLayout && { bounds: 'parent' })}
      >
        {speechBubbleState.isDrowingMode && (
          <DrawMode
            state={speechBubbleState}
            width={position.w}
            height={position.h}
          />
        )}
        {(editItem?.imageInfo?.url || editItem?.frameAttachmentInfo?.url) && (
          <>
            {!speechBubbleState.isDrowingMode && (
              <Image
                src={
                  editItem?.imageInfo?.url || editItem?.frameAttachmentInfo?.url
                }
                alt={
                  editItem?.imageInfo?.name ||
                  editItem?.frameAttachmentInfo?.name
                }
                draggable='false'
                loading='lazy'
                style={animationStyle()}
              />
            )}
            {(editItem?.audioAttachmentInfo?.url ||
              editItem?.videoAttachmentInfo?.url ||
              editItem?.soundInfo?.url) && (
              <Box className={classes.mediaIcon}>
                {(editItem.audioAttachmentInfo?.url ||
                  editItem.soundInfo?.url) && (
                  <Image
                    src={soundIcon}
                    alt={
                      editItem?.audioAttachmentInfo?.name ||
                      editItem.soundInfo?.name
                    }
                  />
                )}
                {editItem.videoAttachmentInfo?.url && (
                  <Image
                    src={videoIcon}
                    alt={editItem?.videoAttachmentInfo.name}
                  />
                )}
              </Box>
            )}
          </>
        )}
        {editItem?.type === TEXT_LAYER_TYPE && !editItem.isSpeechbubble && (
          <Box
            component='p'
            className={classes.layerText}
            style={{
              fontSize: `${editItem.font.fontSize}pt`,
              fontFamily: `${editItem.font.fontStyle}`,
            }}
            draggable='false'
          >
            {editItem?.content}
          </Box>
        )}
        {editItem?.type === TEXT_LAYER_TYPE && editItem?.isSpeechbubble && (
          <Speechbubble />
        )}
      </Rnd>
      {(selectedFrame?.attachmentInfo.type === 'image' ||
        selectedFrame?.attachmentType === 'image') && (
        <Image
          src={selectedFrame.attachmentInfo.url}
          alt={selectedFrame.attachmentInfo.name}
          onLoad={handleOnLoad}
          loading='lazy'
          draggable='false'
          style={{
            borderRadius: selectedFrame.isCircle && '50%',
            clipPath:
              fCoords &&
              `polygon(${fCoords.nw.x}% ${fCoords.nw.y}%, ${fCoords.ne.x}% ${fCoords.ne.y}%, ${fCoords.se.x}% ${fCoords.se.y}%, ${fCoords.sw.x}% ${fCoords.sw.y}%)`,
          }}
        />
      )}
    </Box>
  );
};

export default SpotView;

import {
  CREATE_STORY_SUCCESS,
  DELETE_STORY_SUCCESS,
  EDIT_STATUS,
  END_LOADING,
  FETCH_STORIES,
  FETCH_USERS,
  SET_CURRENT_LANGUAGE,
  SET_CURRENT_STORY,
  START_LOADING,
} from '../types/storyList';

const initialState = {
  storyList: [],
  users: [],
  currentStory: null,
  loading: false,
  wasEdit: false,
  currentLanguage: 'nb',
};

const storyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STORIES: {
      return {
        ...state,
        storyList: action.payload,
      };
    }
    case FETCH_USERS: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case SET_CURRENT_STORY: {
      return {
        ...state,
        currentStory: action.payload,
      };
    }
    case SET_CURRENT_LANGUAGE: {
      return {
        ...state,
        currentLanguage: action.payload,
      };
    }
    case CREATE_STORY_SUCCESS: {
      return {
        ...state,
        storyList: [action.payload, ...state.storyList],
      };
    }
    case DELETE_STORY_SUCCESS: {
      const storyList = [...state.storyList];

      const index = storyList.findIndex((story) => story.id === action.payload);

      if (index >= 0) {
        storyList.splice(index, 1);
      }

      return { ...state, storyList, loading: false };
    }
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case END_LOADING:
      return {
        ...state,
        loading: false,
      };
    case EDIT_STATUS: {
      return {
        ...state,
        wasEdit: action.payload,
      };
    }
    default:
      return state;
  }
};

export default storyListReducer;

import React from 'react';
import classnames from 'classnames';
import BrushIcon from '@material-ui/icons/Brush';

export const SpeechbubbleDrowIcon = ({ className, ...props }) => {
  return (
    <BrushIcon
      style={{ width: 16, height: 14, fill: 'white' }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classnames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};

import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import _isEmpty from 'lodash/isEmpty';
import { MAX_IMAGE_SIZE } from 'constants/frameTypes';
import { uploadProgress } from 'utils/helpers/helpers';
import { FileDownloadIcon } from 'components/Icons/FileDownloadIcon';
import { uploadFile as uploadFileService } from 'services/files';
import { errorToast } from 'services/toast';
import { TrashIcon } from '../../Icons';
import useStyles from './styles';

const FileInput = ({
  validateImage = false,
  errors = {},
  clearErrors,
  placeholder,
  helperText,
  onChange,
  isModel,
  onError,
  accept,
  label,
  value,
  isBig,
  onDelete = () => {},
  ...props
}) => {
  const [uploadingStatus, setUploadingStatus] = useState(false);
  const [progress, setProgress] = useState(0);
  const classes = useStyles({
    active: uploadingStatus || props.field.value?.name,
    isBig,
  });

  const handleImageValidation = useCallback(
    (file) => {
      if (validateImage) {
        const fileSizeKiloBytes = file[0].size / 1024;

        if (fileSizeKiloBytes > MAX_IMAGE_SIZE) {
          onError(props.field.name, {
            type: 'manual',
            message: 'The maximum image size is 5 mb',
          });
        } else {
          clearErrors(props.field.name);
        }
      }
    },
    [clearErrors, onError, props.field.name, validateImage]
  );

  const handleEndUploading = useCallback(() => {
    setProgress(0);
    setUploadingStatus(false);
  }, []);

  const handleOnChange = useCallback(
    (event) => {
      const { files } = event.currentTarget;

      if (files && files[0]) {
        handleImageValidation(files);
        if (_isEmpty(errors)) {
          setUploadingStatus(true);
          uploadFileService(
            files[0],
            {
              onUploadProgress: (progressEvent) =>
                uploadProgress(progressEvent, setProgress),
            },
            isModel
          )
            .then((res) => {
              if (res) {
                if (!onChange) {
                  props.field.onChange(res);
                } else {
                  onChange(res);
                }

                handleEndUploading();
              }
            })
            .catch((serviceError) => {
              onError(props.field.name, {
                type: 'manual',
                message: serviceError.message || 'File upload error',
              });
              handleEndUploading();
            });
        }
      }
    },
    [
      handleImageValidation,
      errors,
      isModel,
      onChange,
      handleEndUploading,
      props.field,
      onError,
    ]
  );

  const handleOnUpload = useCallback(async () => {
    const { url, name } = props.field.value;
    try {
      const isSameOrigin = new URL(url).origin === window.location.origin;
      if (isSameOrigin) {
        const response = await fetch(url, { method: 'GET' });
        const blob = await response.blob();
        const newUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = newUrl;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        window.open(url, '_blank');
      }
    } catch (error) {
      errorToast('Failed to download file.');
    }
  }, [props.field]);

  const handleOnDelete = useCallback(
    (e) => {
      e.preventDefault();
      props.field.onChange({});
      onDelete();
    },
    [props.field, onDelete]
  );

  const inputValue = useMemo(() => {
    if (uploadingStatus) {
      return `Uploading: ${progress}%`;
    }

    if (value) {
      return value;
    }

    return placeholder;
  }, [placeholder, progress, uploadingStatus, value]);

  return (
    <FormControl fullWidth focused error={!!props.fieldState?.error}>
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        className={classes.inputWrap}
        startAdornment={
          <InputAdornment position='start' className={classes.uploadLabel}>
            <InputLabel className={classes.uploadText}>{inputValue}</InputLabel>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment>
            <InputLabel>
              <OutlinedInput
                notched={false}
                className={classes.hiddenInput}
                type='file'
                onChange={handleOnChange}
                display='none'
                inputProps={{ accept }}
              />
              <Button className={classes.uploadButton} component='span'>
                Upload
              </Button>
              {value && (
                <Button
                  className={classes.deleteButton}
                  onClick={handleOnDelete}
                  component='span'
                >
                  <TrashIcon />
                </Button>
              )}
              {value && !accept.includes('video/*') && (
                <Button
                  className={classes.deleteButton}
                  onClick={handleOnUpload}
                  component='span'
                >
                  <FileDownloadIcon />
                </Button>
              )}
            </InputLabel>
          </InputAdornment>
        }
      />
      {(props.fieldState?.error?.message || helperText) && (
        <FormHelperText
          data-testid='fileUpload-helperText'
          error={!!props.fieldState.error}
        >
          {props.fieldState?.error?.message || helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

FileInput.defaultProps = {
  placeholder: '',
  helperText: '',
  accept: '',
  onChange: null,
  isBig: false,
};

FileInput.propTypes = {
  accept: PropTypes.arrayOf(
    PropTypes.oneOf(['image/*', 'video/*', 'audio/mp3', '*', '.svg', '.vtt'])
  ),
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onError: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isBig: PropTypes.bool,
};
export default FileInput;

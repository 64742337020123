import React from 'react';
import classNames from 'classnames';
import FolderIcon from '@material-ui/icons/Folder';

export const ClosedFolderIcon = ({ fill = '#fff', className, ...props }) => {
  return (
    <FolderIcon
      style={{ fill, width: 14, height: 14 }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};

/* <svg
        width='12'
        height='10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.88 2h-4.5L4.88.5H1.13C.5.5 0 1 0 1.63v6.75C0 9 .5 9.5 1.13 9.5h9.75C11.5 9.5 12 9 12 8.37V3.13C12 2.5 11.5 2 10.87 2z'
          fill='#fff'
          fillOpacity='.5'
        />
      </svg> */

export const HOT_SPOT_TYPE = 'hotspot';
export const FRAME_TYPE = 'frame';
export const FRAME_ITEM_TYPE = 'frameItem';
export const PAGE_TYPE = 'page';
export const CHAPTER_TYPE = 'chapter';
export const DECISION_TYPE = 'decision';
export const IMAGE_LAYER_TYPE = 'layer_image';
export const TEXT_LAYER_TYPE = 'layer_text';
export const SPEECHBUBBLE_LAYER_TYPE = 'layer_speechbubble';
export const IMAGE_VIDEO_TYPE = 'layer_video';
export const BACKPACK_TYPE = 'item';
export const CHECKPOINT_TYPE = 'checkpoint';
export const SETTINGS_TYPE = 'settings';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { fabric } from 'fabric';
import { useKeyboardEvents } from 'utils/hooks/useKeyboardEvents';
import {
  handleSpeechbubbleCleanAction,
  handleSpeechbubbleDataAction,
  handleSpeechbubbleFileAction,
} from 'common/actions/speechbubble';
import { setEditAction } from 'common/actions/editStoryActions';
import {
  convertPathToString,
  fromNumberToOpacity,
  fromOpacityToNumber,
} from './DrawMode.helpers';

const DrawMode = ({ width, height, state }) => {
  const canvasRef = useRef(null);
  const dispatch = useDispatch();
  const { isPressedCtrl } = useKeyboardEvents();

  const [canvas, setCanvas] = useState(null);
  const [activeCanvas, setActiveCanvas] = useState(null);

  const { tools, mode, cleaned, importedJSON, imported } = state;
  const {
    width: toolsWidth,
    color,
    textColor,
    textSize,
    textWidth,
    text,
    fillColor,
    fillOpacity,
  } = tools;

  const getDrawingCursor = useCallback(() => {
    const circle = `
      <svg xmlns="http://www.w3.org/2000/svg" height="${17}" width="${17}" fill="${color}">
        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/> 
      </svg>
    `;
    return `data:image/svg+xml;base64,${window.btoa(circle)}`;
  }, [color]);

  const updateCanvasSize = useCallback(() => {
    if (canvas) {
      canvas.setWidth(width);
      canvas.setHeight(height);
      canvas.calcOffset();
      canvas.renderAll();
    }
  }, [canvas, height, width]);

  const setCanvasValue = useCallback(
    (currentCanvas) => {
      dispatch(
        handleSpeechbubbleFileAction({
          canvas: currentCanvas.toSVG(),
          exportedJSON: currentCanvas.toJSON(),
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.code === 'Delete' || event.code === 'Backspace') && canvas) {
        const active = canvas.getActiveObject();
        if (active) {
          canvas.remove(active);
          setCanvasValue(canvas);
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [canvas, setCanvasValue]);

  useEffect(() => {
    if (!canvas) {
      const newCanvas = new fabric.Canvas(canvasRef.current, {
        width,
        height,
        hoverCursor: 'pointer',
        objectCaching: false,
        selection: false,
        isDrawingMode: true,
      });

      setCanvas(newCanvas);
      setCanvasValue(newCanvas);
    } else {
      canvas.on('path:created', (e) => {
        const { path, globalCompositeOperation } = e.path;
        if (path && globalCompositeOperation !== 'destination-out') {
          const lastObject = canvas.getObjects()[
            canvas.getObjects().length - 1
          ];
          canvas.remove(lastObject);

          const canvasPath = new fabric.Path(convertPathToString(path), {
            fill: `${fillColor}${fromNumberToOpacity(fillOpacity)}`,
            strokeWidth: toolsWidth,
            objectCaching: false,
            stroke: color,
          });
          canvas.add(canvasPath);
          setCanvasValue(canvas);
        }
        dispatch(setEditAction(true));
        canvas.renderAll();
      });
      canvas.on('mouse:up', () => {
        setCanvasValue(canvas);
        setActiveCanvas(canvas.getActiveObject());
      });
      updateCanvasSize();
    }
    return () => {
      if (canvas) {
        canvas.off('path:created');
        canvas.off('mouse:up');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canvas,
    color,
    fillColor,
    fillOpacity,
    height,
    setCanvasValue,
    toolsWidth,
    updateCanvasSize,
    width,
  ]);

  useEffect(() => {
    if (canvas) {
      canvas.on('selection:created', () => {
        dispatch(setEditAction(true));
      });
    }

    return () => {
      if (canvas) {
        canvas.off('selection:created');
      }
    };
  }, [canvas, dispatch]);

  useEffect(() => {
    if (!canvas) return;
    if (mode === 'draw') {
      canvas.freeDrawingCursor = `url(${getDrawingCursor()}) ${2} ${14}, crosshair`;
    } else {
      canvas.freeDrawingCursor = 'crosshair';
    }
  }, [canvas, getDrawingCursor, mode]);

  useEffect(() => {
    if (imported && canvas) {
      canvas.loadFromJSON(importedJSON, () => {
        const objects = canvas.getObjects();
        const canvasText = objects.find((obj) => obj.type === 'text');
        if (canvasText) {
          dispatch(
            handleSpeechbubbleDataAction({
              ...tools,
              text: canvasText.text,
              textColor: canvasText.fill,
              textWidth: canvasText.fontWeight,
              textSize: canvasText.fontSize,
            })
          );
        }
        canvas.renderAll();
        setCanvasValue(canvas);
        setActiveCanvas(canvas.getActiveObject());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imported, importedJSON, canvas]);

  useEffect(() => {
    if (activeCanvas && !activeCanvas.text) {
      dispatch(
        handleSpeechbubbleDataAction({
          ...tools,
          fillColor: activeCanvas.fill.slice(0, 7),
          fillOpacity: fromOpacityToNumber(activeCanvas.fill),
          color: activeCanvas.stroke,
          width: activeCanvas.strokeWidth,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCanvas, mode]);

  useEffect(() => {
    if (activeCanvas && !activeCanvas.text) {
      activeCanvas.set({
        fill: `${fillColor}${fromNumberToOpacity(fillOpacity)}`,
        strokeWidth: toolsWidth,
        stroke: color,
      });
      updateCanvasSize();
      setCanvasValue(canvas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCanvas, color, fillColor, fillOpacity, toolsWidth]);

  useEffect(() => {
    if (canvas) {
      const objects = canvas.getObjects();
      let canvasText = objects.find((obj) => obj.type === 'text');

      if (text && canvasText.text && canvasText.text !== text) {
        dispatch(setEditAction(true));
      }

      if (canvasText) {
        canvasText.set({
          text,
          fontSize: textSize,
          fontWeight: textWidth,
          fill: textColor,
        });
      } else if (mode === 'text') {
        canvasText = new fabric.Text(text, {
          left: canvas.getWidth() / 2,
          top: canvas.getHeight() / 2,
          objectCaching: false,
          fontSize: textSize,
          fontWeight: textWidth,
          fill: textColor,
        });
        canvas.add(canvasText);
      }
      canvas.bringToFront(canvasText);
      setCanvasValue(canvas);
      updateCanvasSize();
    }
  }, [
    canvas,
    setCanvasValue,
    text,
    mode,
    textColor,
    textSize,
    textWidth,
    updateCanvasSize,
    dispatch,
  ]);

  useEffect(() => {
    if (cleaned) {
      if (canvas) {
        canvas.clear();
      }
      setCanvas(null);
      setActiveCanvas(null);
      dispatch(handleSpeechbubbleCleanAction(false));
    }
  }, [dispatch, cleaned, canvas]);

  const handleDrawingBrush = useCallback(() => {
    canvas.isDrawingMode = true;
    canvas.freeDrawingBrush.width = toolsWidth;
    canvas.freeDrawingBrush.color = color;
  }, [canvas, toolsWidth, color]);

  const handleCurrentAction = useCallback(() => {
    if (canvas) {
      switch (mode) {
        case 'select':
          canvas.isDrawingMode = false;
          break;
        case 'erase':
          canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
          canvas.isDrawingMode = true;
          canvas.freeDrawingBrush.width = toolsWidth;
          break;
        case 'draw':
          canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
          handleDrawingBrush();
          break;
        case 'text':
          canvas.isDrawingMode = false;
          break;
        case 'spray':
          canvas.freeDrawingBrush = new fabric.SprayBrush(canvas);
          handleDrawingBrush();
          break;
        default:
          break;
      }
    }
  }, [canvas, handleDrawingBrush, mode, toolsWidth]);

  useEffect(() => {
    handleCurrentAction();
  }, [handleCurrentAction, mode]);

  useEffect(() => {
    if (isPressedCtrl) {
      canvas.isDrawingMode = false;
    } else {
      handleCurrentAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPressedCtrl]);

  return (
    <Box id='canvas'>
      <canvas ref={canvasRef} />
    </Box>
  );
};

export default DrawMode;

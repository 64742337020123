export const defaultValues = {
  coordinates: {
    x: 0,
    y: 0,
    w: 10,
    h: 10,
  },
  title: '',
  imageInfo: null,
  soundInfo: {},
  fadeIn: 0.5,
  fadeOut: 0.5,
  animation: {
    appear: 'manual',
    delay: 2,
    animation: '',
    duration: 3,
    focus: '',
    focusSpeed: 10,
  },
  loopAudio: false,
  zIndex: 2,
  isDrawn: false,
  svgJson: null,
};

import React from 'react';
import classnames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';

export const OpenFolderIcon = ({ className, ...props }) => {
  return (
    <SvgIcon
      viewBox='0 0 13 10'
      style={{ width: 13, height: 10, fill: 'none' }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classnames({
        [className]: !!className,
      })}
      {...props}
    >
      <path
        d='M11.93 4.75l-1.5 2.59A1.33 1.33 0 019.26 8H.94a.5.5 0 01-.43-.75L2 4.66A1.33 1.33 0 013.17 4h8.33a.5.5 0 01.43.75zM3.17 3.33H10v-1a1 1 0 00-1-1H5.67L4.33 0H1a1 1 0 00-1 1v5.8l1.44-2.47a2 2 0 011.73-1z'
        fill='#fff'
        fillOpacity='.5'
      />
    </SvgIcon>
  );
};

/* <svg
        width='12'
        height='10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11.93 4.75l-1.5 2.59A1.33 1.33 0 019.26 8H.94a.5.5 0 01-.43-.75L2 4.66A1.33 1.33 0 013.17 4h8.33a.5.5 0 01.43.75zM3.17 3.33H10v-1a1 1 0 00-1-1H5.67L4.33 0H1a1 1 0 00-1 1v5.8l1.44-2.47a2 2 0 011.73-1z'
          fill='#fff'
          fillOpacity='.5'
        />
      </svg> */

import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import DialogWrapper from 'components/DialogWrapper';
import { CloseIcon } from 'components/Icons';

const DeleteConfirmDialogContainer = ({
  open,
  onClose,
  onSubmit,
  storyTitle,
}) => {
  return (
    <DialogWrapper open={open}>
      <DialogTitle disableTypography>
        <Box component='h3' fontSize='1rem' fontWeight='fontWeightMedium' m={0}>
          Confirm
        </Box>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        Are you sure you want to delete {storyTitle}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          No
        </Button>
        <Button onClick={onSubmit} color='primary' variant='contained'>
          Yes
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};

DeleteConfirmDialogContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  storyTitle: PropTypes.string.isRequired,
};

export default DeleteConfirmDialogContainer;

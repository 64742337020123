import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Box, MenuItem } from '@material-ui/core';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import { getEnglishName } from 'all-iso-language-codes';
import { DECISION_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import { useDebounce } from 'utils/hooks/useDebounce';
import {
  storyListEditItemSelector,
  storyListSelectedIdsSelector,
} from 'common/selectors/editStory';
import {
  addDecisionAction,
  removeDecisionAction,
  updateDecisionAction,
} from 'common/actions/decisionsListActions';
import { storyListLanguageSelector } from 'common/selectors/storyList';
import {
  clearEditItemAction,
  editItemAction,
  setEditAction,
  setSelectedAction,
} from 'common/actions/editStoryActions';
import { targetFramesListSelector } from 'common/selectors/frames';
import { pageListOrderSelector } from 'common/selectors/pages';
import FormWrap from 'components/forms/FormWrap';
import StyledInput from 'components/forms/StyledInput';
import SelectField from 'components/forms/SelectField';
import StyledCheckbox from 'components/forms/StyledCheckbox';
import { AddPageIcon, TrashIcon } from 'components/Icons';
import { errorToast } from 'services/toast';
import ControlBarBlock from '../ControlBarBlock';
import ControlButton from '../ControlButton';
import { defaultOption, defaultValues } from './DecisionControl.constants';
import { customSchema, validationSchema } from './DecisionControl.validation';
import BlockTitle from './BlockTitle';
import useStyles from './styles';

const selector = createSelector(
  pageListOrderSelector,
  storyListEditItemSelector,
  storyListSelectedIdsSelector,
  targetFramesListSelector,
  storyListLanguageSelector,
  (pagesList, editDecision, selectedIds, targetFramesList, currentLang) => ({
    pagesList,
    editDecision,
    selectedIds,
    targetFramesList,
    currentLang,
  })
);

const DecisionControl = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    pagesList,
    editDecision,
    selectedIds,
    targetFramesList,
    currentLang,
  } = useSelector(selector);

  const filtredDecisionOptions = useMemo(
    () =>
      // eslint-disable-next-line no-extra-boolean-cast
      !!editDecision.options.length
        ? editDecision.options.map((item, index) => {
            if (!item.text.index)
              return {
                ...item,
                text: { ...item.text, index },
              };
            return item;
          })
        : [],
    [editDecision.options]
  );

  const [currentFieldData, setCurrentFieldData] = useState(
    filtredDecisionOptions || [
      {
        frame: '',
        page: '',
        text: {
          [currentLang]: '',
          index: 0,
        },
      },
    ]
  );

  const handleFilterFieldData = (index) => {
    const data = currentFieldData.filter((item) => {
      return item.text[currentLang] && item.text.index === index;
    });
    // eslint-disable-next-line no-extra-boolean-cast
    return !!data.length ? data[0].text[currentLang] : '';
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      id: editDecision.id || '',
      decisionCase: editDecision.decisionCase || defaultValues.decisionCase,
      decisionName: editDecision.decisionName || defaultValues.decisionName,
      options: editDecision.options
        ? [...editDecision.options]
        : [defaultOption],
      originFrame: editDecision.originFrame || defaultValues.originFrame,
      originPage: editDecision.originPage || defaultValues.originPage,
      story: editDecision.story || defaultValues.story,
      storyEnd: editDecision.storyEnd || defaultValues.storyEnd,
    },
    resolver: yupResolver(validationSchema()),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
    keyName: 'decCtrlKey',
  });

  const formField = useWatch({
    control,
  });

  const formFieldDebounced = useDebounce(formField, 300);

  const currentDecisionId = useMemo(() => formField.id, [formField.id]);

  const options = useMemo(() => formField.options, [formField.options]);
  useEffect(() => {
    if (currentDecisionId && currentDecisionId !== editDecision.id) {
      reset({
        id: editDecision.id || '',
        decisionCase: {
          ...editDecision.decisionCase,
        },
        decisionName: editDecision.decisionName || defaultValues.decisionName,
        options: editDecision.options
          ? [...editDecision.options]
          : [defaultOption],
        originFrame: editDecision.originFrame || defaultValues.originFrame,
        originPage: editDecision.originPage || defaultValues.originPage,
        story: editDecision.story || defaultValues.story,
        storyEnd: editDecision.storyEnd || defaultValues.storyEnd,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDecisionId, editDecision, formFieldDebounced.decisionCase, reset]);

  useEffect(() => {
    reset({
      id: editDecision.id || '',
      decisionCase: {
        ...editDecision.decisionCase,
        ...formFieldDebounced.decisionCase,
      },
      decisionName: editDecision.decisionName || defaultValues.decisionName,
      options: formFieldDebounced.options.map((item, index) => {
        return {
          ...item,
          text: {
            [currentLang]: handleFilterFieldData(index),
          },
        };
      }) || [...editDecision.options],
      originFrame: editDecision.originFrame || defaultValues.originFrame,
      originPage: editDecision.originPage || defaultValues.originPage,
      story: editDecision.story || defaultValues.story,
      storyEnd: editDecision.storyEnd || defaultValues.storyEnd,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang]);

  useEffect(() => {
    dispatch(setEditAction(isDirty));
  }, [isDirty, dispatch]);

  const handleValidateDecisionCase = useCallback(() => {
    if (
      !formFieldDebounced.decisionCase[currentLang] ||
      formFieldDebounced.decisionCase[currentLang] === ''
    ) {
      setError(`decisionCase.${currentLang}`, {
        type: 'custom',
        message: 'This field is required',
      });
      return { errors: true };
    }
    clearErrors(`decisionCase.${currentLang}`);
    return {};
  }, [clearErrors, currentLang, formFieldDebounced.decisionCase, setError]);

  const handleValidation = useCallback(async () => {
    try {
      await customSchema().validate(currentFieldData, {
        abortEarly: false,
      });
      return false;
    } catch ({ ...error }) {
      // eslint-disable-next-line array-callback-return
      error.inner.map(({ ...item }) => {
        setError(`options.${item.path}`, {
          type: 'custom',
          message: item.message,
        });
      });
      return true;
    }
  }, [currentFieldData, setError]);

  const handleValidateDecisionOptions = useCallback(() => {
    return currentFieldData.map(({ text }, index) => {
      if (!text[currentLang] || text[currentLang] === '') {
        setError(`options.${index}.text.${currentLang}`, {
          type: 'custom',
          message: 'This field is required',
        });
        return true;
      }
      clearErrors(`options.${index}.text.${currentLang}`);
      return false;
    });
  }, [clearErrors, currentFieldData, currentLang, setError]);

  const stateValidation = useCallback(
    (obj) => {
      // eslint-disable-next-line no-restricted-syntax, prefer-const
      for (let key in obj) {
        if (
          (!obj[key] && key !== currentLang) ||
          (obj[key] === '' && key !== currentLang)
        ) {
          errorToast(
            `Case field with ${getEnglishName(key)} language is Empty`
          );
          return { errors: true };
        }
      }
      return {};
    },
    [currentLang]
  );

  const findEmptyKeyInOptions = useCallback(
    (arr) => {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < arr.length; i++) {
        const obj = arr[i].text;
        // eslint-disable-next-line no-restricted-syntax, prefer-const, no-plusplus
        for (let key in obj) {
          if (obj[key] === '') {
            if (key !== currentLang)
              errorToast(
                `Option field with ${getEnglishName(key)} language is Empty`
              );
            return { errors: true };
          }
        }
      }
      return {};
    },
    [currentLang]
  );

  const onSubmit = useCallback(
    (data) => {
      const caseErrors = handleValidateDecisionCase();
      const optionsErrors = handleValidateDecisionOptions();
      const { id, ...otherData } = data;
      const lngCase = stateValidation({
        ...editDecision.decisionCase,
        ...otherData.decisionCase,
      });
      const lngOptions = findEmptyKeyInOptions(currentFieldData);

      const validForm =
        // _isEmpty(errors) &&
        _isEmpty(caseErrors) &&
        !optionsErrors.includes(true) &&
        _isEmpty(lngCase) &&
        _isEmpty(lngOptions);

      handleValidation().then((hasErrors) => {
        if (!hasErrors && validForm) {
          if (editDecision.id) {
            dispatch(
              updateDecisionAction(
                {
                  ...editDecision,
                  ...otherData,
                  decisionName: otherData.decisionName.trim(),
                  decisionCase: {
                    ...editDecision.decisionCase,
                    ...otherData.decisionCase,
                  },
                  options: currentFieldData,
                },
                UPDATE_TYPE.UPDATE
              )
            );
          } else {
            dispatch(
              addDecisionAction(
                {
                  ...editDecision,
                  ...otherData,
                  decisionName: otherData.decisionName.trim(),
                  decisionCase: {
                    ...editDecision.decisionCase,
                    ...otherData.decisionCase,
                  },
                  options: currentFieldData,
                },
                UPDATE_TYPE.STANDART
              )
            );
          }
        }
      });
    },
    [
      currentFieldData,
      dispatch,
      editDecision,
      findEmptyKeyInOptions,
      handleValidateDecisionCase,
      handleValidateDecisionOptions,
      handleValidation,
      stateValidation,
    ]
  );

  const handleRemove = useCallback(() => {
    if (editDecision.id) {
      dispatch(removeDecisionAction());
    } else {
      dispatch(setSelectedAction({ ...selectedIds, frameItem: '' }));
      dispatch(clearEditItemAction());
    }
  }, [dispatch, editDecision, selectedIds]);

  const updateState = useCallback((lng, value, index) => {
    setCurrentFieldData((prevData) => {
      const objIndex = prevData.findIndex((obj) => {
        return obj.text.index === index;
      });
      if (objIndex !== -1) {
        const updatedObj = {
          ...prevData[objIndex],
          frame: value.frame,
          page: value.page,
          text: {
            ...prevData[objIndex].text,
            [lng]: value.text[lng],
          },
        };
        return [
          ...prevData.slice(0, objIndex),
          updatedObj,
          ...prevData.slice(objIndex + 1),
        ];
      }

      return [
        ...prevData,
        {
          ...prevData[index],
          text: { [lng]: value.text[lng], index },
        },
      ];
    });
  }, []);

  const removeDataField = (deletedIndex) => {
    setCurrentFieldData(
      currentFieldData.filter(({ text }) => text.index !== deletedIndex)
    );
  };

  const handleUpdateDecision = useCallback(() => {
    const { id, ...fieldsData } = formFieldDebounced;
    // the order of the elements must be the same like in formField
    const fieldsForCompare = {
      id: editDecision.id || '',
      decisionCase: editDecision.decisionCase || defaultValues.decisionCase,
      decisionName: editDecision.decisionName || defaultValues.decisionName,
      options: editDecision.options
        ? [...editDecision.options]
        : [defaultOption],
      originFrame: editDecision.originFrame || defaultValues.originFrame,
      originPage: editDecision.originPage || defaultValues.originPage,
      story: editDecision.story || defaultValues.story,
      storyEnd: editDecision.storyEnd || defaultValues.storyEnd,
    };

    if (!_isEqual(formFieldDebounced, fieldsForCompare)) {
      dispatch(
        editItemAction({
          item: {
            ...editDecision,
            ...fieldsData,
            decisionCase: {
              ...editDecision.decisionCase,
              ...fieldsData.decisionCase,
            },
            options: currentFieldData,
          },
          type: DECISION_TYPE,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, editDecision, formFieldDebounced, currentLang]);

  useEffect(() => {
    handleUpdateDecision();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced]);

  useEffect(() => {
    formFieldDebounced.options.map((item, index) => {
      return (
        !Array.isArray(item.text[currentLang]) &&
        updateState(currentLang, item, index)
      );
    });
  }, [currentLang, formFieldDebounced.options, updateState]);

  return (
    <FormWrap id='decision-form' onSubmit={handleSubmit(onSubmit)}>
      <ControlBarBlock blockTitle='Details'>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              disableUnderline
              label='Name'
              placeholder='Decision name'
              error={error ? error.message : ''}
              {...field}
            />
          )}
          name='decisionName'
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledInput
              fullWidth
              multiline
              rows={4}
              disableUnderline
              label='Case (max 144 characters)'
              error={error ? error.message : ''}
              {...field}
              onChange={(e) => {
                setValue(
                  'decisionCase',
                  {
                    ...formField.decisionCase,
                    [currentLang]: e,
                  },
                  { shouldDirty: true }
                );
              }}
            />
          )}
          name={`decisionCase.${currentLang}`}
          control={control}
        />
        <Controller
          name='storyEnd'
          control={control}
          render={({ field }) => (
            <StyledCheckbox
              label='Story end'
              onChange={(e) => field.onChange(e.target.checked)}
              checked={!!field.value}
            />
          )}
        />
      </ControlBarBlock>
      {fields.map((item, index) => (
        <ControlBarBlock
          key={item.decCtrlKey}
          blockTitle={
            <BlockTitle
              title='Option'
              icon={
                fields.length > 1 && (
                  <Box
                    className={classes.optionDelete}
                    onClick={() => {
                      removeDataField(index);
                      remove(index);
                    }}
                  >
                    <TrashIcon />
                  </Box>
                )
              }
            />
          }
        >
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                fullWidth
                multiline
                rows={3}
                disableUnderline
                label='Option text'
                error={error ? error.message : ''}
                {...field}
              />
            )}
            name={`options.${index}.text.${currentLang}`}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <SelectField
                displayEmpty
                defaultValue=''
                label='Target page'
                error={error ? error.message : null}
                {...field}
                control={control}
                onChange={(e) => {
                  setValue(`options.${index}.frame`, '');
                  setValue(`options.${index}.page`, e.target.value);
                }}
              >
                <MenuItem value='' disabled>
                  Select page...
                </MenuItem>
                {pagesList.map((page) => {
                  return (
                    <MenuItem key={page.id} value={page.id}>
                      {page.title}
                    </MenuItem>
                  );
                })}
              </SelectField>
            )}
            name={`options.${index}.page`}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <SelectField
                displayEmpty
                label='Target frame'
                error={error ? error.message : null}
                {...field}
                control={control}
              >
                <MenuItem value='' disabled>
                  Select frame...
                </MenuItem>
                {targetFramesList
                  .filter((frame) => frame.originPage === options[index]?.page)
                  .map((frame) => {
                    return (
                      <MenuItem
                        key={frame.id || frame._id}
                        value={frame.id || frame._id}
                      >
                        {frame.title || 'Frame' || frame._id}
                      </MenuItem>
                    );
                  })}
              </SelectField>
            )}
            name={`options.${index}.frame`}
            control={control}
          />
        </ControlBarBlock>
      ))}
      <ControlBarBlock
        noPadding
        blockTitle={
          <BlockTitle
            title='Option'
            icon={
              <Box
                className={classes.addOption}
                onClick={() => append(defaultOption)}
              >
                <AddPageIcon />
              </Box>
            }
          />
        }
      />
      <ControlBarBlock>
        <ControlButton onClick={handleRemove} text='Delete Decision' />
      </ControlBarBlock>
    </FormWrap>
  );
};

export default DecisionControl;

import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default makeStyles((theme) => ({
  root: {
    flex: 1,
    maxWidth: ({ maxWidth }) => maxWidth,
    padding: ({ padding }) => padding || theme.spacing(0.25),
    minWidth: theme.spacing(4.25),
    minHeight: 'auto',
    color: transparentize(0.42, theme.colors.white),
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '11.72px',

    '&.Mui-selected': {
      color: theme.colors.white,
      fontWeight: 500,
    },

    '&..MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
  },
}));

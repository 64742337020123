import axios from 'axios';

export const getCheckpointListService = (params = {}) =>
  axios
    .get('/checkpoints', {
      params: { limit: 100, sort: 'createdAt', ...params },
    })
    .then((res) => res.data);

export const createCheckpointService = (body, type) =>
  axios.post(`/checkpoints?type=${type}`, body).then((res) => res.data);

export const updateCheckpointService = (body, updateType) =>
  axios
    .put(`/checkpoints/${body.id}`, body, { params: { type: updateType } })
    .then((res) => res.data);

export const deleteCheckpointService = (id, type) =>
  axios.delete(`/checkpoints/${id}?type=${type}`);

import React, { useState } from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import useMobile from 'utils/hooks/useMobile';
import { storyListCurrentStorySelector } from 'common/selectors/storyList';
import StoriesList from './components/StoriesList';
import StoryForm from './components/StoryForm';
import useStyles from './styles';
import { StoryFormDrawer } from './components/StoryFormDrawer/index';

const selector = createSelector(
  storyListCurrentStorySelector,
  (currentStory) => ({ currentStory })
);

const Stories = ({ searchTerm, setSearchTerm }) => {
  const { currentStory } = useSelector(selector);
  const { isMobile } = useMobile();
  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Box className={classes.root}>
      <StoriesList
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setOpenDrawer={setOpenDrawer}
      />
      {currentStory ? (
        <>
          {isMobile ? (
            <StoryFormDrawer setOpen={setOpenDrawer} open={openDrawer} />
          ) : (
            <StoryForm />
          )}
        </>
      ) : (
        <>{!isMobile && <Box />}</>
      )}
    </Box>
  );
};

export default Stories;

import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object().shape({
    title: Yup.string().trim().required('This field is required'),
    soundInfo: Yup.object()
      .shape({
        type: Yup.string().trim(),
        name: Yup.string().trim(),
        url: Yup.string().trim(),
        size: Yup.number(),
      })
      .nullable(true),
    fadeIn: Yup.number()
      .typeError('You must specify a number')
      .positive('Must be positive'),
    fadeOut: Yup.number()
      .typeError('You must specify a number')
      .positive('Must be positive'),
    crossFade: Yup.number()
      .typeError('You must specify a number')
      .positive('Must be positive'),
  });

import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(3),
    borderRadius: theme.spacing(0.25),
    color: ({ isActive }) =>
      isActive
        ? theme.palette.text.primary
        : transparentize(0.2, theme.palette.text.primary),
    fontSize: theme.spacing(1.25),
    cursor: 'pointer',
    backgroundColor: ({ isActive }) =>
      isActive ? theme.palette.primary.main : 'transparent',
  },
  arrow: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(2.8),
    height: theme.spacing(3),
    transform: ({ isOpen }) => (isOpen ? 'rotate(0)' : 'rotate(-90deg)'),
    transition: 'transform .3s',

    '&>path': {
      fillOpacity: ({ isActive }) => (isActive ? '1' : '0.5'),
    },
  },
  points: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '18px',
    height: '18px',
    marginRight: '2px',
  },
  icon: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.8),
    '& path': {
      fillOpacity: ({ isActive }) => (isActive ? '1' : '0.5'),
    },
  },
  title: {
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  renameInput: {
    marginTop: 0,
    width: '90%',
    '& > input': {
      height: theme.spacing(2.5),
    },
  },
  input: {
    border: 'none',
    background: 'transparent',
    color: theme.palette.text.primary,
    fontSize: theme.spacing(1.25),
    outline: 'none',
  },
  menu: {
    minWidth: theme.spacing(14.25),
    borderRadius: theme.spacing(0.25),

    '&>.MuiList-root': {
      padding: 0,
      backgroundColor: theme.colors.menuBackground,
    },
  },
  deleteList: {
    color: theme.palette.text.error,
    fontWeight: 500,
  },
}));

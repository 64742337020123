import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    maxWidth: theme.spacing(62.5),

    '&>.MuiDialogTitle-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
    },

    '& > .MuiIconButton-root': {
      padding: theme.spacing(2),
    },

    '& > .MuiDialogContent-root': {
      padding: theme.spacing(3),
    },

    '& > .MuiDialogActions-root': {
      justifyContent: 'space-between',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),

      '& > .MuiButton-root': {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      },
    },
  },
}));

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
// eslint-disable-next-line camelcase
import { getAll639_1, getEnglishName } from 'all-iso-language-codes';
import { BACKPACK_TYPE, CHAPTER_TYPE } from 'constants/editTypes';
import { getDefaultBackpackItem, getDefaultPage } from 'constants/defaultItems';
import { detectOperatingSystem } from 'utils/detectOperatingSystem';
import { useKeyboardEvents } from 'utils/hooks/useKeyboardEvents';
import useMobile from 'utils/hooks/useMobile';
import { handlePageTitle } from 'utils/helpers/helpers';
import {
  storyListEditTypeSelector,
  storyListSelectedIdsSelector,
  storyListSelectTabSelector,
  storyListWarningCheckpointsSelector,
  storyListWarningDecisionsSelector,
  storyListWarningHotspotsSelector,
  storyListWarningStatusSelector,
} from 'common/selectors/editStory';
import { storyItemsSelector } from 'common/selectors/story';
import {
  addNewPageAction,
  createNewPageInChapterAction,
  editItemAction,
  fetchListAction,
  setSelectedAction,
  setSelectTabAction,
  setSettingsAction,
  setWarningAction,
  setWarningCheckpointsAction,
  setWarningDecisionsAction,
  setWarningHotspotsAction,
} from 'common/actions/editStoryActions';
import { storyListLanguageSelector } from 'common/selectors/storyList';
import { pagesListSelector } from 'common/selectors/pages';
import { AddPageIcon, OptionsIcon } from 'components/Icons';
import StyledTab from 'components/StyledTab';
import TabsWrapper from 'components/TabsWrapper';
import TabPanel from 'components/TabPanel';
import SaveDialog from 'components/SaveDialog/SaveDialog';
import { ShowMenu } from 'components/Icons/ShowMenu';
import { StoryTitleBlock } from 'components/Header/components/StoryTitleBlock/index';
import useStyles from './styles';
import StoryList from './StoryList';
import BackpackList from './BackpackList';

const selector = createSelector(
  pagesListSelector,
  storyListEditTypeSelector,
  storyListSelectTabSelector,
  storyItemsSelector,
  storyListWarningStatusSelector,
  storyListWarningCheckpointsSelector,
  storyListWarningDecisionsSelector,
  storyListWarningHotspotsSelector,
  storyListSelectedIdsSelector,
  storyListLanguageSelector,
  (
    pagesList,
    editStatus,
    selectTab,
    story,
    warningOpen,
    warningCheckpoints,
    warningDecisions,
    warningHotspots,
    selectedIds,
    currentLang,
    currentPage
  ) => ({
    pagesList,
    editStatus,
    selectTab,
    story,
    warningOpen,
    warningCheckpoints,
    warningDecisions,
    warningHotspots,
    selectedIds,
    currentLang,
    currentPage,
  })
);

const commonLanguages = ['nb', 'nn', 'en', 'ru', 'uk'];

export const renderLanguageList = () => {
  const renderLanguageItem = (languageCode) => {
    const languageName = getEnglishName(languageCode);
    return (
      <MenuItem key={languageCode} value={languageCode}>
        {languageName}
      </MenuItem>
    );
  };

  return [
    <MenuItem value='' key='quick-select' disabled>
      Quick Select...
    </MenuItem>,
    ...commonLanguages.map((code) => renderLanguageItem(code)),
    <MenuItem value='' key='other' disabled>
      Other...
    </MenuItem>,
    ...getAll639_1()
      .filter((code) => !commonLanguages.includes(code))
      .map((code) => renderLanguageItem(code)),
  ];
};

const PagesList = () => {
  const { isSmallDesktop } = useMobile();
  const dispatch = useDispatch();
  const { id: storyId } = useParams();

  const [showLeftMenu, setShowLeftMenu] = useState(!isSmallDesktop);
  const { triggerFunction } = useKeyboardEvents();

  const [anchorEl, setAnchorEl] = useState(null);
  const {
    editStatus,
    selectTab,
    warningOpen,
    warningCheckpoints,
    warningDecisions,
    warningHotspots,
    selectedIds,
    pagesList,
  } = useSelector(selector);

  const classes = useStyles({
    showLeftMenu,
    isSmallDesktop,
  });

  const handleChange = useCallback(
    (event, newValue) => {
      dispatch(setSelectTabAction(newValue));
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    dispatch(fetchListAction(storyId));
  }, [dispatch, storyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddNewPage = useCallback(() => {
    if (storyId) {
      setAnchorEl(null);
      const pageTitle = handlePageTitle(pagesList, 'Page');
      if (editStatus === CHAPTER_TYPE) {
        dispatch(
          createNewPageInChapterAction(getDefaultPage(storyId, pageTitle))
        );
      } else {
        dispatch(addNewPageAction(getDefaultPage(storyId, pageTitle)));
      }
    }
  }, [storyId, pagesList, editStatus, dispatch]);

  useEffect(() => {
    if (triggerFunction.page) handleAddNewPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFunction.page]);

  const handleAddNewBackpack = useCallback(() => {
    dispatch(
      setSelectedAction({
        ...selectedIds,
        chapter: '',
        frame: '',
        frameItem: '',
      })
    );
    dispatch(
      editItemAction({
        item: getDefaultBackpackItem(),
        type: BACKPACK_TYPE,
      })
    );
  }, [dispatch, selectedIds]);

  const handleOnOpenSettings = useCallback(() => {
    dispatch(setSettingsAction(true));
  }, [dispatch]);

  const handleOpenMenu = useCallback((e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  }, []);

  const warningHotspotNames = useMemo(
    () => warningHotspots.map((hotspot) => hotspot.name).join(', '),
    [warningHotspots]
  );

  const warningDecisionNames = useMemo(
    () => warningDecisions.map((decision) => decision.decisionName).join(', '),
    [warningDecisions]
  );

  const warningCheckpointNames = useMemo(
    () => warningCheckpoints.map((checkpoint) => checkpoint.title).join(', '),
    [warningCheckpoints]
  );

  const handleOnCloseMenu = useCallback(() => setAnchorEl(null), []);

  const handleCloseWarning = useCallback(() => {
    dispatch(setWarningHotspotsAction([]));
    dispatch(setWarningDecisionsAction([]));
    dispatch(setWarningCheckpointsAction([]));
    dispatch(setWarningAction(false));
  }, [dispatch]);

  const handleNavigationStatus = useCallback(() => {
    setShowLeftMenu(!showLeftMenu);
  }, [showLeftMenu]);

  const warningObjectsText = useCallback(() => {
    let text = 'Modify the following associated objects, then try again:\n';
    if (warningHotspotNames.length)
      text += `\nHotspots: ${warningHotspotNames}`;
    if (warningDecisionNames.length)
      text += `\nDecisions: ${warningDecisionNames}`;
    if (warningCheckpointNames.length)
      text += `\nCheckpoints: ${warningCheckpointNames}`;
    return text;
  }, [warningCheckpointNames, warningDecisionNames, warningHotspotNames]);

  return (
    <Box
      className={showLeftMenu ? classes.sidebarWrap : classes.closedSidebarWrap}
    >
      {isSmallDesktop ? (
        <Box className={classes.showMenu}>
          <Typography>Navigation</Typography>
          <ShowMenu onClick={handleNavigationStatus} />
        </Box>
      ) : (
        <StoryTitleBlock />
      )}
      <Box className={classes.content}>
        <Box className={classes.header}>
          <TabsWrapper
            justifyContent='space-between'
            value={selectTab}
            onChange={handleChange}
          >
            <StyledTab label='Story' maxWidth={30} />
            <StyledTab label='Items' maxWidth={30} />
          </TabsWrapper>
          <Box className={classes.headerIcons}>
            {!isSmallDesktop && (
              <Tooltip title='Options' placement='bottom'>
                <IconButton
                  className={classes.headerIcon}
                  onClick={handleOnOpenSettings}
                >
                  <OptionsIcon />
                </IconButton>
              </Tooltip>
            )}
            {selectTab === 1 ? (
              <Tooltip title='Add new Backpack' placement='bottom'>
                <IconButton
                  className={classes.addPage}
                  onClick={handleAddNewBackpack}
                >
                  <AddPageIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <>
                <Tooltip
                  title={`Add Page (${detectOperatingSystem()} + P)`}
                  placement='bottom'
                >
                  <IconButton
                    className={classes.addPage}
                    onClick={handleOpenMenu}
                  >
                    <AddPageIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  keepMounted
                  anchorEl={anchorEl}
                  open={!!anchorEl}
                  onClose={handleOnCloseMenu}
                  getContentAnchorEl={null}
                  PaperProps={{
                    className: classes.menu,
                  }}
                >
                  <MenuItem onClick={handleAddNewPage}>New page</MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Box>
        <TabPanel
          value={selectTab}
          index={0}
          py={0}
          classes={{ root: classes.tabWrap }}
        >
          <SaveDialog
            isOpen={warningOpen}
            onClose={handleCloseWarning}
            warningTitle="Warning: can't delete frame"
            warningText={warningObjectsText()}
          />
          <StoryList />
        </TabPanel>
        <TabPanel
          value={selectTab}
          index={1}
          py={0}
          classes={{ root: classes.tabWrap }}
        >
          <BackpackList />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default PagesList;

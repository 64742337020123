import axios from 'axios';

const getDecisionsList = (params = {}) => {
  return axios
    .get('/decisions', { params: { limit: 100, sort: 'createdAt', ...params } })
    .then((res) => res.data);
};

const getDecision = (id) => {
  return axios.get(`/decisions/${id}`).then((res) => res.data);
};

const createDecision = (body, type) =>
  axios.post(`/decisions?type=${type}`, body).then((res) => res.data);

const updateDecision = (body, updateType) =>
  axios
    .put(`/decisions/${body.id}`, body, { params: { type: updateType } })
    .then((res) => res.data && res.data.updatedDecision);

const deleteDecision = (id, type) =>
  axios.delete(`/decisions/${id}?type=${type}`);

export {
  getDecisionsList,
  getDecision,
  createDecision,
  updateDecision,
  deleteDecision,
};

export default {
  getDecisionsList,
  getDecision,
  createDecision,
  updateDecision,
  deleteDecision,
};

import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default makeStyles((theme) => ({
  colorBox: {
    width: '12px',
    height: '12px',
    backgroundColor: ({ color }) => color,
    borderRadius: '2px',
    border: `1px solid ${transparentize(0.88, theme.colors.white)}`,
    position: 'absolute',
    margin: '22px 0px 6px 6px',
    cursor: 'pointer',
  },
  inputWrap: {
    cursor: 'pointer',
    '& > input': {
      cursor: 'pointer',
    },
  },
}));

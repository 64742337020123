import React from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { speechbubbleSelector } from 'common/selectors/speechbubble';
import { handleSpeechbubbleDataAction } from 'common/actions/speechbubble';
import StyledInput from 'components/forms/StyledInput/StyledInput';
import StyledColorInput from 'components/forms/StyledColorInput/StyledColorInput';

const selector = createSelector(speechbubbleSelector, (speechBubbleState) => ({
  speechBubbleState,
}));

export const TextControl = ({ handleOpenPicker }) => {
  const dispatch = useDispatch();
  const { speechBubbleState } = useSelector(selector);
  const { tools } = speechBubbleState;

  const handleTextChange = (e, key) => {
    dispatch(
      handleSpeechbubbleDataAction({
        ...tools,
        [key]: e,
      })
    );
  };

  return (
    <>
      <StyledColorInput
        fullWidth
        disableUnderline
        label='Text color'
        onClick={handleOpenPicker}
        value={tools.textColor}
        error=''
        name='textColor'
      />
      <StyledInput
        disableUnderline
        label='Text'
        fullWidth={false}
        error=''
        multiline
        width='100%'
        onChange={(e) => handleTextChange(e, 'text')}
        value={tools.text}
      />
      <Box display='flex' justifyContent='space-between'>
        <StyledInput
          disableUnderline
          label='Font width'
          fullWidth={false}
          error=''
          width='45%'
          onChange={(e) => handleTextChange(e, 'textWidth')}
          value={tools.textWidth}
        />

        <StyledInput
          fullWidth={false}
          disableUnderline
          label='Font size'
          error=''
          width='45%'
          onChange={(e) => handleTextChange(e, 'textSize')}
          value={tools.textSize}
        />
      </Box>
    </>
  );
};

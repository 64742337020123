import Cookies from 'js-cookie';
import axios from 'axios';
import { END_LOADING, SET_USER, START_LOADING } from 'common/types/auth';
import {
  signIn as signInService,
  signUp as signUpService,
} from 'services/auth';
import { errorToast } from 'services/toast';

export const setUserAction = (user) => ({ type: SET_USER, user });

export const startLoadingAction = () => ({
  type: START_LOADING,
});

export const endLoadingAction = () => ({
  type: END_LOADING,
});

const setToken = (token) => {
  Cookies.set('token', token);
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const signInAction = (data) => {
  return (dispatch) => {
    dispatch(startLoadingAction());

    signInService(data)
      .then((res) => {
        dispatch(setUserAction(res.user));
        dispatch(endLoadingAction());
        setToken(res.token);
      })
      .catch((error) => {
        dispatch(endLoadingAction());
        if (
          error.response &&
          error.response.data &&
          error.response.data.err.message
        ) {
          errorToast(
            error.response.data.err.message || 'Something wrong. Try again'
          );
        }
      });
  };
};

export const signUpAction = (data) => {
  return (dispatch) => {
    dispatch(startLoadingAction());

    signUpService(data)
      .then((res) => {
        dispatch(endLoadingAction());
        dispatch(setUserAction(res));
        setToken(res.token);
      })
      .catch((error) => {
        dispatch(endLoadingAction());
        if (
          error.response &&
          error.response.data &&
          error.response.data.err.message
        ) {
          errorToast(
            error.response.data.err.message || 'Something wrong. Try again'
          );
        }
      });
  };
};

export const signOutAction = () => {
  return (dispatch) => {
    Cookies.remove('token');
    delete axios.defaults.headers.common.Authorization;
    dispatch(setUserAction(null));
  };
};

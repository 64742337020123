export const defaultFCoords = {
  'left-bottom': [
    { x: 0, y: 0 },
    { x: 100, y: 0 },
    { x: 100, y: 75 },
    { x: 20, y: 75 }, //
    { x: 0, y: 100 }, //
    { x: 0, y: 75 },
  ],
  'right-bottom': [
    { x: 0, y: 0 },
    { x: 100, y: 0 },
    { x: 100, y: 75 },
    { x: 100, y: 100 }, //
    { x: 80, y: 75 }, //
    { x: 0, y: 75 },
  ],
  'center-bottom': [
    { x: 0, y: 0 },
    { x: 100, y: 0 },
    { x: 100, y: 75 },
    { x: 60, y: 75 }, //
    { x: 50, y: 100 }, //
    { x: 40, y: 75 }, //
    { x: 0, y: 75 },
  ],
  'left-top': [
    { x: 0, y: 25 },
    { x: 0, y: 0 }, //
    { x: 20, y: 25 }, //
    { x: 100, y: 25 },
    { x: 100, y: 100 },
    { x: 0, y: 100 },
  ],
  'right-top': [
    { x: 0, y: 25 },
    { x: 75, y: 25 }, //
    { x: 100, y: 0 }, //
    { x: 100, y: 25 },
    { x: 100, y: 100 },
    { x: 0, y: 100 },
  ],
  'center-top': [
    { x: 0, y: 25 },
    { x: 40, y: 25 }, //
    { x: 50, y: 0 }, //
    { x: 60, y: 25 }, //
    { x: 100, y: 25 },
    { x: 100, y: 100 },
    { x: 0, y: 100 },
  ],
};

export const controlTaleCoords = {
  'left-bottom': [4, 3],
  'right-bottom': [3, 4],
  'center-bottom': [4, 3, 5],
  'left-top': [1, 2],
  'right-top': [2, 1],
  'center-top': [2, 1, 3],
};

export const controlSpeechCoords = {
  'left-bottom': [2, 5],
  'right-bottom': [2, 5],
  'center-bottom': [2, 6],
  'left-top': [0, 3],
  'right-top': [0, 3],
  'center-top': [0, 4],
};

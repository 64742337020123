import React from 'react';
import classnames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';

export const AddLayerTextIcon = ({
  fillOpacity = 0.5,
  className,
  ...props
}) => {
  return (
    <SvgIcon
      viewBox='0 0 14 16'
      style={{ width: 14, height: 16, fill: 'none' }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classnames({
        [className]: !!className,
      })}
      {...props}
    >
      <path
        d='M11.57 0a.43.43 0 01.43.43V3a.43.43 0 01-.43.43h-.86A.43.43 0 0110.3 3v-.86H7.07v8.15h1.07a.43.43 0 01.43.42v.86a.43.43 0 01-.43.43H3.86a.43.43 0 01-.43-.43v-.86a.43.43 0 01.43-.42h1.07V2.14H1.7V3a.43.43 0 01-.42.43H.43A.43.43 0 010 3V.43A.43.43 0 01.43 0h11.14z'
        fill='#fff'
        fillOpacity={fillOpacity}
      />
    </SvgIcon>
  );
};

// <svg width='12' height='12' fill='none'>
// <path
//   d='M11.57 0a.43.43 0 01.43.43V3a.43.43 0 01-.43.43h-.86A.43.43 0 0110.3 3v-.86H7.07v8.15h1.07a.43.43 0 01.43.42v.86a.43.43 0 01-.43.43H3.86a.43.43 0 01-.43-.43v-.86a.43.43 0 01.43-.42h1.07V2.14H1.7V3a.43.43 0 01-.42.43H.43A.43.43 0 010 3V.43A.43.43 0 01.43 0h11.14z'
//   fill='#fff'
//   fillOpacity={fillOpacity}
// />
// </svg>

import React from 'react';
import classNames from 'classnames';
import ReplayIcon from '@material-ui/icons/Replay';

export const RedoArrow = ({ fill = 'white', className, ...props }) => {
  return (
    <ReplayIcon
      viewBox='0 0 24 24'
      style={{ fill, width: 22, height: 22, transform: 'scaleX(-1)' }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};
/* <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 24 24'
    >
      <path
        fill='#fff'
        d='M12 0c3.31 0 6.291 1.353 8.459 3.522l2.48-2.48 1.061 7.341-7.437-.966 2.489-2.489c-1.808-1.807-4.299-2.928-7.052-2.928-5.514 0-10 4.486-10 10s4.486 10 10 10c3.872 0 7.229-2.216 8.89-5.443l1.717 1.046c-2.012 3.803-6.005 6.397-10.607 6.397-6.627 0-12-5.373-12-12s5.373-12 12-12z'
      />
    </svg> */

import React, { useCallback, useMemo, useState } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Grid } from '@material-ui/core';
import { SketchPicker } from 'react-color';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {
  handleResetSpeechbubbleAction,
  handleSpeechbubbleDataAction,
  handleSpeechbubbleModeAction,
} from 'common/actions/speechbubble';
import { speechbubbleSelector } from 'common/selectors/speechbubble';
import StyledColorInput from 'components/forms/StyledColorInput/StyledColorInput';
import { RestartIcon } from 'components/Icons/RestartIcon';
import { WidthSlider } from '../WidthSlider/WidthSlider';
import ControlBarBlock from '../../../ControlBarBlock/ControlBarBlock';
import ControlButton from '../../../ControlButton/ControlButton';
import useStyles from '../../styles';
import { TextControl } from '../TextControl/TextControl';

const selector = createSelector(speechbubbleSelector, (speechBubbleState) => ({
  speechBubbleState,
}));

export const DrawControl = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { speechBubbleState } = useSelector(selector);

  const { tools, mode } = speechBubbleState;

  const showTextController = useMemo(
    () => mode === 'text' || tools.text !== '',
    [mode, tools.text]
  );

  const [changeField, setChangeField] = useState('');
  const [pickerOpened, setPickerOpened] = useState(false);

  const handleResetSpeechbubble = useCallback(() => {
    dispatch(handleResetSpeechbubbleAction(true));
  }, [dispatch]);

  const handleOpenPicker = useCallback((e) => {
    setPickerOpened(true);
    setChangeField(e.target.name);
  }, []);

  const handleColorChange = useCallback(
    (color) => {
      dispatch(
        handleSpeechbubbleDataAction({
          ...tools,
          [changeField]: color.hex,
        })
      );
    },
    [changeField, dispatch, tools]
  );

  const handleClosePicker = useCallback(() => {
    setPickerOpened(false);
  }, []);

  const handleDrawMode = (drawMode) => {
    dispatch(handleSpeechbubbleModeAction(drawMode));
  };

  return (
    <>
      {pickerOpened && (
        <ClickAwayListener
          mouseEvent='onMouseUp'
          touchEvent='onTouchStart'
          onClickAway={handleClosePicker}
        >
          <SketchPicker
            disableAlpha
            className={classes.picker}
            color={tools[changeField]}
            onChange={handleColorChange}
          />
        </ClickAwayListener>
      )}
      <ControlBarBlock blockTitle='Tools' position='relative'>
        <IconButton
          className={classes.restartIcon}
          onClick={handleResetSpeechbubble}
        >
          <RestartIcon />
        </IconButton>

        <StyledColorInput
          fullWidth
          disableUnderline
          label='Pencil color'
          onClick={handleOpenPicker}
          value={tools.color}
          error=''
          name='color'
        />
        <StyledColorInput
          fullWidth
          disableUnderline
          label='Fill color'
          onClick={handleOpenPicker}
          value={tools.fillColor}
          error=''
          name='fillColor'
        />
        {showTextController && (
          <TextControl handleOpenPicker={handleOpenPicker} />
        )}
        <WidthSlider
          state={tools}
          label='Pencil width'
          min={0}
          max={100}
          name='width'
          step={1}
        />

        <WidthSlider
          state={tools}
          label='Fill opacity color'
          min={0}
          max={1}
          step={0.1}
          name='fillOpacity'
        />
        <Grid container spacing={2} className={classes.wrapper}>
          <Grid item xs={6} className={classes.wrapperItem}>
            <ControlButton
              noMargin
              onClick={() => handleDrawMode('draw')}
              text='draw'
              isActive={mode === 'draw'}
            />
          </Grid>
          <Grid item xs={6} className={classes.wrapperItem}>
            <ControlButton
              noMargin
              onClick={() => handleDrawMode('select')}
              text='select'
              isActive={mode === 'select'}
            />
          </Grid>
          <Grid item xs={6} className={classes.wrapperItem}>
            <ControlButton
              noMargin
              onClick={() => handleDrawMode('erase')}
              text='erase'
              isActive={mode === 'erase'}
            />
          </Grid>
          <Grid item xs={6} className={classes.wrapperItem}>
            <ControlButton
              noMargin
              onClick={() => handleDrawMode('text')}
              text='text'
              isActive={mode === 'text'}
            />
          </Grid>
        </Grid>
      </ControlBarBlock>
    </>
  );
};

/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { createSelector } from 'reselect';
import {
  Box,
  CircularProgress,
  IconButton,
  useTheme,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { FileDrop } from 'react-file-drop';
import { FRAME_TYPE, PAGE_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import { getDefaultFrame } from 'constants/defaultItems';
import { MAX_IMAGE_SIZE } from 'constants/frameTypes';
import { useKeyboardEvents } from 'utils/hooks/useKeyboardEvents';
import {
  getImageSize,
  removeFileExtension,
  uploadProgress,
} from 'utils/helpers/helpers';
import {
  storyListEditItemSelector,
  storyListEditTypeSelector,
  storyListSelectedEditFrame,
  storyListSelectedIdsSelector,
  storyListSelectedPageSelector,
  storyListShowGridLinesSelector,
  storyListSnapFramesSelector,
} from 'common/selectors/editStory';
import {
  editItemAction,
  setAnimation,
  setSelectedAction,
} from 'common/actions/editStoryActions';
import { storyItemsSelector } from 'common/selectors/story';
import {
  framesListLoadingSelector,
  framesListSelector,
} from 'common/selectors/frames';
import {
  createMultipleFramesAction,
  updateFrameAction,
  updateFrameSuccessAction,
} from 'common/actions/framesListActions';
import { ArrowUpIcon } from 'components/Icons/ArrowUpIcon';
import { ArrowDownIcon } from 'components/Icons/ArrowDownIcon';
import { errorToast } from '../../../../../services/toast';
import { uploadMultipleFiles } from '../../../../../services/files';
import {
  framesChanged,
  generateLayoutFromFrames,
  getSnapCoords,
  loadAllImageSizes,
  snapFrames,
} from './EditGridPage.helpers';
import GridItemWrap from './GridItemWrap';
import {
  COLS,
  GRID_HEIGHT,
  GRID_MARGIN,
  GRID_WIDTH_LANDSCAPE,
  GRID_WIDTH_PORTRAIT,
  ROW_HEIGHT,
} from './EditGridPage.constants';
import useStyles from './styles';
import './animation.css';
import 'animate.css';

const selector = createSelector(
  storyListSelectedEditFrame,
  storyListEditItemSelector,
  storyListSelectedIdsSelector,
  storyListEditTypeSelector,
  storyListSelectedPageSelector,
  storyItemsSelector,
  framesListSelector,
  framesListLoadingSelector,
  storyListShowGridLinesSelector,
  storyListSnapFramesSelector,
  (
    selectedEditFrame,
    editItem,
    selectedIds,
    editType,
    selectedPage,
    story,
    framesList,
    // frameAnimation,
    framesListLoading,
    showGridLines,
    snapFramesEnabled
  ) => ({
    selectedEditFrame,
    editItem,
    selectedIds,
    editType,
    selectedPage,
    story,
    framesList,
    // frameAnimation,
    framesListLoading,
    showGridLines,
    snapFramesEnabled,
  })
);

const EditGridPage = () => {
  const { spacing } = useTheme();
  const [wrapWidth, setWrapWidth] = useState(spacing(GRID_WIDTH_LANDSCAPE));
  const [wrapHeight, setWrapHeight] = useState(spacing(GRID_WIDTH_PORTRAIT));
  const { isPressedShift } = useKeyboardEvents();
  const classes = useStyles({ wrapWidth, wrapHeight });
  const dispatch = useDispatch();
  const {
    selectedEditFrame,
    editItem,
    selectedIds,
    editType,
    selectedPage,
    story,
    framesList,
    // frameAnimation,
    framesListLoading,
    showGridLines,
    snapFramesEnabled,
  } = useSelector(selector);
  const [layout, setLayout] = useState([]);

  const [appliedPlaceholder, setAppliedPlaceholder] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressStarted, setProgressStarted] = useState(false);

  const { isPressedAlt } = useKeyboardEvents();

  useEffect(() => {
    setWrapWidth(
      !story?.isLandscape
        ? spacing(GRID_WIDTH_LANDSCAPE)
        : spacing(GRID_WIDTH_PORTRAIT)
    );
    setWrapHeight(
      story?.isLandscape
        ? spacing(GRID_WIDTH_LANDSCAPE)
        : spacing(GRID_WIDTH_PORTRAIT)
    );
  }, [story?.isLandscape, spacing]);

  const page = useMemo(() => {
    if (editType === PAGE_TYPE) {
      return { ...editItem };
    }

    return { ...selectedPage };
  }, [selectedPage, editType, editItem]);

  const currentFrames = useMemo(() => {
    return framesList[page.id];
    // eslint-disable-next-line
  }, [framesList, framesList[page.id], page.id]);

  const handleLayout = useCallback(() => {
    let newLayout = [];
    if (currentFrames?.length) {
      newLayout = generateLayoutFromFrames([...currentFrames], wrapWidth);
    }
    setLayout(newLayout);
  }, [wrapWidth, currentFrames]);

  useEffect(() => {
    handleLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [framesList[page.id], selectedEditFrame, editItem]);

  const handleDrag = useCallback(
    (l, oldLayoutItem, layoutItem) => {
      if (snapFramesEnabled) {
        const [direction, snapTo] = snapFrames(layoutItem, layout);
        const { x, y } = getSnapCoords(direction, layoutItem, snapTo);
        layoutItem.x = x;
        layoutItem.y = y;
      }
      if (appliedPlaceholder) return;
      const holder = document.querySelector('.react-grid-placeholder');
      if (!holder) return;
      const layoutFrame = currentFrames.find(
        (frame, idx) =>
          (frame.id || frame.fid || frame.i || `${frame.createdAt}-${idx}`) ===
          layoutItem.i
      );
      if (layoutFrame.isCircle) holder.style.borderRadius = '50%';
      holder.style.clipPath = `polygon(${layoutFrame.formCoords.nw.x}% ${layoutFrame.formCoords.nw.y}%,
        ${layoutFrame.formCoords.ne.x}% ${layoutFrame.formCoords.ne.y}%,
        ${layoutFrame.formCoords.se.x}% ${layoutFrame.formCoords.se.y}%,
        ${layoutFrame.formCoords.sw.x}% ${layoutFrame.formCoords.sw.y}%)`;
      setAppliedPlaceholder(true);
    },
    [currentFrames, appliedPlaceholder, layout, snapFramesEnabled]
  );

  const handleLayoutChange = useCallback(
    (changedLayout) => {
      setAppliedPlaceholder(false);
      const newFrames = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < changedLayout.length; i++) {
        if (framesChanged(currentFrames[i], changedLayout[i])) {
          newFrames[i] = {
            ...currentFrames[i],
            ...changedLayout[i],
          };
          let snappedFrame = newFrames[i];
          if (snapFramesEnabled) {
            const [direction, snapTo] = snapFrames(newFrames[i], changedLayout);
            const { x, y } = getSnapCoords(direction, newFrames[i], snapTo);
            snappedFrame = { ...newFrames[i], x, y };
          }

          if (newFrames[i].id) {
            dispatch(updateFrameAction(snappedFrame, UPDATE_TYPE.UPDATE));
            dispatch(updateFrameSuccessAction(snappedFrame));
          } else if (newFrames[i].fid) {
            framesList[page.id][i] = snappedFrame;
            dispatch(updateFrameSuccessAction(snappedFrame));
          }
          dispatch(
            editItemAction({
              item: snappedFrame,
              type: FRAME_TYPE,
            })
          );
        }
      }
    },
    [framesList, currentFrames, dispatch, page, snapFramesEnabled]
  );

  const isActive = useCallback(
    (frame) => {
      if (selectedEditFrame) {
        if (selectedEditFrame?.fid && frame?.fid) {
          return selectedEditFrame?.fid === frame?.fid;
        }

        return selectedEditFrame?.id === frame?.id;
      }

      return selectedIds.frame === frame?.id;
    },
    [selectedEditFrame, selectedIds]
  );

  const onSelectFrame = useCallback(
    (frame) => {
      if (!isActive(frame)) {
        dispatch(
          setSelectedAction({ ...selectedIds, frame: frame?.id || frame?.fid })
        );
        dispatch(editItemAction({ item: frame, type: FRAME_TYPE }));
      }
    },
    [dispatch, selectedIds, isActive]
  );

  const openNewWindow = useCallback((frame) => {
    const { width: sw, height: sh } = window.screen;
    const {
      title,
      attachmentInfo,
      windowWidth = 800,
      windowHeight = 600,
    } = frame;

    window.open(
      attachmentInfo.url,
      title,
      `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${windowWidth},height=${windowHeight},top=
      ${sh / 2 - windowHeight / 2},left=${sw / 2 - windowWidth / 2}`
    );
  }, []);

  /* eslint-disable no-param-reassign */
  const handleResize = useCallback(
    (l, oldLayoutItem, layoutItem, placeholder) => {
      if (isPressedShift) return;
      const heightDiff = layoutItem.h - oldLayoutItem.h;
      const widthDiff = layoutItem.w - oldLayoutItem.w;
      const changeCoef = oldLayoutItem.w / oldLayoutItem.h;
      const maxWidth = GRID_WIDTH_PORTRAIT - layoutItem.x;
      const maxHeight = GRID_HEIGHT;
      let expectedHeight = layoutItem.w / changeCoef;
      let expectedWidth = layoutItem.h * changeCoef;

      if (Math.abs(heightDiff) < Math.abs(widthDiff)) {
        if (expectedHeight > maxHeight) {
          expectedHeight = maxHeight;
          layoutItem.w = expectedHeight * changeCoef;
          placeholder.w = expectedHeight * changeCoef;
        }
        layoutItem.h = expectedHeight;
        placeholder.h = expectedHeight;
      } else {
        if (expectedWidth > maxWidth) {
          expectedWidth = maxWidth;
          layoutItem.h = expectedWidth / changeCoef;
          placeholder.h = expectedWidth / changeCoef;
        }
        layoutItem.w = expectedWidth;
        placeholder.w = expectedWidth;
      }
    },
    [isPressedShift]
  );

  const setZindex = (idx, newZIndex) => {
    const newFrames = framesList[page.id];
    newFrames[idx].zIndex += newZIndex;
    if (newFrames[idx].zIndex >= 0 && newFrames[idx].id)
      dispatch(updateFrameAction(newFrames[idx], UPDATE_TYPE.UPDATE));
  };

  const frameStyle = useCallback(
    (frame) => ({ zIndex: frame.zIndex || 1 }),
    []
  );

  const animationParams = useCallback(
    (frame) => {
      const frameAnimation = frame.animation;
      if (!frameAnimation || !selectedEditFrame) return '';
      if (
        frame.id !== selectedEditFrame.id &&
        frame.fid !== selectedEditFrame.fid
      )
        return '';

      let animation;

      const currentFrame = document.getElementById(`frameImage-${frame.id}`);

      if (frameAnimation.focus) {
        const prop = frameAnimation.focus.replace(/([A-Z])/g, ' $1').split(' ');
        document.documentElement.style.setProperty(
          '--focus-direction',
          `${prop[1]?.toLowerCase()} ${prop[0]}`
        );

        document.documentElement.style.setProperty(
          '--zoom-in',
          1 + (frameAnimation.zoomIn || 15) / 100
        );
        let animationName = 'kenBurns';

        if (frameAnimation.focus === 'zoomIn') animationName = 'focusZoomIn';
        if (frameAnimation.focus === 'zoomOut') animationName = 'focusZoomOut';

        const delay = frame.animation.duration || 0;
        if (currentFrame) {
          setTimeout(() => {
            currentFrame.style.animation = `${animationName} ${
              frameAnimation.focusSpeed || 10
            }s forwards infinite`;
          }, delay * 1000);
        }
      } else {
        if (currentFrame) currentFrame.style.animation = '';
        document.documentElement.style.setProperty('--focus-direction', '');
        document.documentElement.style.setProperty('--zoom-in', '');
        clearTimeout();
      }

      if (frameAnimation.animation)
        animation = `${frameAnimation.duration || 3}s ease-in-out 0s ${
          frameAnimation.animation
        }`;

      return {
        animation,
        animationIterationCount: 1,
      };
    },
    [selectedEditFrame]
  );

  const handleDragStart = (l, layoutItem) => {
    const frameId = currentFrames.find(
      (item, idx) =>
        (item.id || item.fid || item.i || `${item.createdAt}-${idx}`) ===
        layoutItem.i
    ).id;
    const frame = currentFrames.find(
      (item, idx) =>
        (item.id || item.fid || item.i || `${item.createdAt}-${idx}`) ===
        layoutItem.i
    );
    dispatch(
      setSelectedAction({
        ...selectedIds,
        frame: frameId,
      })
    );
    dispatch(editItemAction({ item: frame, type: FRAME_TYPE }));
  };

  const handleImageValidation = useCallback((files) => {
    const validImages = [];

    [...files].forEach((file) => {
      const imageSize = file.size / 1024;
      if (imageSize < MAX_IMAGE_SIZE) return validImages.push(file);
      const fileName = file.name.split('.').slice(0, -1).join('.');
      return errorToast(`The file with name ${fileName} is larger than 5 MB`);
    });

    return validImages;
  }, []);

  const handleDropFiles = (files) => {
    if (files) {
      const validImages = handleImageValidation(files);

      if (validImages.length) {
        setProgressStarted(true);
        uploadMultipleFiles(validImages, {
          onUploadProgress: (progressEvent) =>
            uploadProgress(progressEvent, setProgress),
        })
          .then((res) => {
            const { result: uploadedFiles } = res || {};
            const frames = [];
            loadAllImageSizes(uploadedFiles).then((imageSizes) => {
              imageSizes.forEach((s, index) => {
                const { width, height, name } = s;
                const sizes = getImageSize(width, height, story.isLandscape);
                const { imageHeight: h, imageWidth: w } = sizes;
                const frame = {
                  ...getDefaultFrame(),
                  h,
                  w,
                  x: index * 3,
                  y: index * 3,
                  attachmentInfo: uploadedFiles[index].original,
                  mobileAttachmentInfo: uploadedFiles[index].compressed,
                  originPage: selectedPage.id,
                  story: story.id,
                  title: removeFileExtension(name),
                };
                frames.push(frame);
              });
              dispatch(createMultipleFramesAction(frames));
              setProgressStarted(false);
            });
          })
          .catch(() => setProgressStarted(false));
      }
    }
  };

  return (
    <Box className={classes.innerWrap}>
      <FileDrop
        onDrop={handleDropFiles}
        className={classes.dropWrap}
        targetClassName={classes.dropTarget}
        draggingOverTargetClassName={classes.dropOverTarget}
      >
        <Box className={classes.wrap}>
          {!currentFrames?.length && !framesListLoading && (
            <Box className={classes.dropPlaceholder}>
              <Typography noWrap>
                {progressStarted
                  ? `${progress}%`
                  : 'You can drop one or multiple images to the page'}
              </Typography>
              {progressStarted && (
                <CircularProgress size={55} className={classes.dropLoader} />
              )}
            </Box>
          )}

          {showGridLines && (
            <>
              <Box className={`${classes.gridLines} main`} />
              <Box className={`${classes.gridLines} secondary`} />
            </>
          )}
          {framesListLoading && (
            <Box className={classes.loader}>
              <CircularProgress className='loading' />
            </Box>
          )}
          {!!layout.length && (
            <GridLayout
              className={classes.grid}
              cols={COLS}
              rowHeight={ROW_HEIGHT}
              width={wrapWidth}
              onDrag={handleDrag}
              onDragStart={handleDragStart}
              onDragStop={handleLayoutChange}
              margin={GRID_MARGIN}
              layout={layout}
              onResize={handleResize}
              onResizeStop={handleLayoutChange}
              useCSSTransforms
              preventCollision
              allowOverlap
              isDraggable={!isPressedAlt}
              isResizable={!isPressedAlt}
            >
              {currentFrames?.map((frame, idx) => {
                const active = isActive(frame);
                const style = frameStyle(frame);
                const animateIt = animationParams(frame);

                return (
                  <Box
                    key={`${
                      frame.fid || frame.id || `${frame.createdAt}-${idx}`
                    }`}
                    data-grid={{
                      i: `${frame.fid || frame.id || frame.createdAt}`,
                      x: frame.x,
                      y: frame.y,
                      w: frame.w,
                      h: frame.h,
                      maxH: GRID_HEIGHT,
                      isBounded: true,
                      zIndex: frame.zIndex || 1,
                    }}
                    style={{ ...style, display: 'flex' }}
                  >
                    <div
                      style={{ ...animateIt, flex: 'auto' }}
                      onAnimationEnd={() =>
                        setTimeout(() => {
                          dispatch(
                            setAnimation({ frameId: '', animationType: '' })
                          );
                        }, frame.animation.duration * 1000 || 2000)
                      }
                    >
                      <div
                        style={{
                          position: 'absolute',
                          display: 'flex',
                          height: '20px',
                          marginTop: '2px',
                          zIndex: frame.zIndex + 10,
                          top: `${frame.formCoords?.nw?.y}%`,
                          left: frame.isCircle
                            ? 'calc(50% - 20px)'
                            : `${frame.formCoords?.nw?.x}%`,
                        }}
                      >
                        <IconButton
                          onClick={() => setZindex(idx, 1)}
                          className={classes.zIndexButton}
                        >
                          <ArrowUpIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => setZindex(idx, -1)}
                          className={classes.zIndexButton}
                          disabled={frame.zIndex < 1}
                        >
                          <ArrowDownIcon />
                        </IconButton>
                      </div>
                      <GridItemWrap
                        onGoClick={() => openNewWindow(frame)}
                        formCoords={frame.formCoords}
                        onClick={onSelectFrame}
                        isActive={active}
                        {...frame}
                      />
                    </div>
                  </Box>
                );
              })}
            </GridLayout>
          )}
        </Box>
      </FileDrop>
    </Box>
  );
};

export default EditGridPage;

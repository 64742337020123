import axios from 'axios';

export const getLayersListService = (params = {}) => {
  return axios
    .get('/layers', {
      params: { limit: 100, ...params },
    })
    .then((res) => res.data);
};

export const createLayerService = (body, type) =>
  axios.post(`/layers?type=${type}`, body).then((res) => res.data);

export const deleteLayerService = (id, type) =>
  axios.delete(`/layers/${id}?type=${type}`);

export const updateLayerService = (body, updateType) =>
  axios
    .put(`/layers/${body.id}`, body, { params: { type: updateType } })
    .then((res) => res.data);

export const updateLayerListService = (body, type) =>
  axios.post(`/layers/list?type=${type}`, body).then((res) => res.data);

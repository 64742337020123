import React from 'react';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import classNames from 'classnames';

export const AddPageIcon = ({ fill = 'white', className, ...props }) => {
  return (
    <AddCircleOutlineOutlinedIcon
      viewBox='0 0 22 22'
      style={{
        fill,
        width: 16,
        height: 16,
      }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};

// <svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
// <rect
//   x='.5'
//   y='.5'
//   width='15'
//   height='15'
//   rx='7.5'
//   stroke='#fff'
//   strokeOpacity='.23'
// />
// <path
//   opacity='.87'
//   d='M8.58 7.66h2.78v1.2H8.58V12H7.31V8.86H4.53v-1.2h2.78v-2.9h1.27v2.9z'
//   fill='#fff'
// />
// </svg>

import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

export default makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.colors.sidebarBackground,
    overflow: 'auto',

    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
    },

    [theme.breakpoints.up('sm')]: {
      borderRadius: '4px',
    },

    '& > form': {
      width: '100%',
      paddingBottom: theme.spacing(8.5),

      [theme.breakpoints.down('sm')]: {
        paddingBottom: '50px',
      },
    },

    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(1.75),
    },

    '& .MuiInputBase-input': {
      height: theme.spacing(4.75),
    },

    '& .MuiSelect-root': {
      lineHeight: `${theme.spacing(4.75)}px`,
    },

    '& .MuiFormLabel-root': {
      fontSize: theme.spacing(1.5),
    },
  },

  label: {
    fontSize: '11px !important',
    position: 'absolute',
    top: '30px',
    left: '8px',
    color: '#fff',
  },

  selectLanguage: {
    position: 'relative',
  },

  title: {
    fontWeight: 500,
  },

  viewCount: { color: theme.colors.grey, fontSize: theme.spacing(1.5) },

  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '36px 24px 0px',

    [theme.breakpoints.down('sm')]: {
      padding: '15px 15px 0px',
    },
  },

  titleContainer: {
    width: 'calc(100% - 150px)',
  },

  preview: {
    fontSize: theme.spacing(1.25),
    color: transparentize(0.2, theme.colors.white),
    display: 'flex',
    flexDirection: 'column',
    marginBottom: ({ isDefaultStory }) => (isDefaultStory ? '25px' : '0px'),
  },

  previewButtons: {
    padding: '5px',
  },

  info: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.spacing(1.25),
    padding: '0px 24px 27px',
    color: transparentize(0.2, theme.colors.white),

    [theme.breakpoints.down('sm')]: {
      padding: '0px 15px 15px',
    },
  },

  submitWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.up('sm')]: {
      marginBottom: '40px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '0px',
    },

    [theme.breakpoints.down('md')]: {
      '@media (orientation: landscape)': {
        marginBottom: '40px',
      },
    },
  },

  buttonHolder: {
    '& > .MuiButtonBase-root': {
      '&:first-child': {
        marginRight: theme.spacing(3.125),
      },
    },
  },

  prefixValue: {
    fontSize: '10px',
    position: 'absolute',
  },

  divider: {
    width: '100%',
    height: '8px',
    backgroundColor: '#212538',
  },

  content: {
    padding: '16px 24px 14px',

    [theme.breakpoints.down('sm')]: {
      padding: '15px 15px 0px',
    },
  },

  btnContainer: {
    padding: '0px 24px 24px',

    [theme.breakpoints.down('sm')]: {
      padding: '0px 15px 5px',
    },
  },

  languageTab: {
    marginBottom: '15px',
    width: '100%',
  },

  tab: {
    maxWidth: 'fit-content',
    padding: '0px 15px 6px',
    minWidth: theme.spacing(4.25),
    minHeight: 'auto',
    color: transparentize(0.42, theme.colors.white),
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '11.72px',

    '&.Mui-selected': {
      color: theme.colors.white,
      fontWeight: 500,
    },

    '&..MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
  },
}));

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& >.MuiCollapse-container': {
      paddingLeft: theme.spacing(2.5),
    },
    '& .MuiCollapse-wrapperInner': {
      color: 'red',
      '& > .collapse-divider': { margin: '4px 0' },
      '& > .sortable > .MuiBox-root': {
        paddingLeft: theme.spacing(2.5),
      },
      '& > .MuiBox-root': {
        paddingLeft: theme.spacing(2.5),
      },
    },
  },
}));

import React from 'react';
import classNames from 'classnames';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';

export const ArrowUpIcon = ({ fill = '#fff', className, ...props }) => {
  return (
    <ArrowUpwardOutlinedIcon
      viewBox='0 0 24 24'
      style={{
        fill,
        width: 18,
        height: 18,
        stroke: '#fff',
        strokeWidth: '1.5',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};

// <svg width='12' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'>
// <path
//   d='M.94 6.9l-.6-.6a.64.64 0 010-.9L5.54.19a.64.64 0 01.91 0l5.2 5.2c.26.25.26.66 0 .91l-.59.6a.64.64 0 01-.92-.02L7.07 3.66v7.7c0 .35-.28.64-.64.64h-.86a.64.64 0 01-.64-.64v-7.7L1.86 6.89a.64.64 0 01-.92 0z'
//   fill='#fff'
// />
// </svg>

import { PAGE_TYPE } from 'constants/editTypes';
import { UPDATE_TYPE } from 'constants/updateTypes';
import {
  CREATE_LAYER,
  DELETE_LAYER,
  END_LOADING_LAYERS,
  FETCHED_LAYERS,
  START_LOADING_LAYERS,
  UPDATE_LAYER,
} from 'common/types/layersList';
import {
  storyListEditItemSelector,
  storyListSelectedIdsSelector,
  storyListSelectedPageSelector,
} from 'common/selectors/editStory';
import { storyItemsSelector } from 'common/selectors/story';
import { pagesListSelector } from 'common/selectors/pages';
import {
  createLayerService,
  deleteLayerService,
  getLayersListService,
  updateLayerListService,
  updateLayerService,
} from 'services/layers';
import { errorToast, successToast } from 'services/toast';
import {
  clearEditItemAction,
  editItemAction,
  fetchHistoryListAction,
  setEditAction,
  setSelectedAction,
} from './editStoryActions';

export const startLoadingLayersAction = () => ({
  type: START_LOADING_LAYERS,
});

export const endLoadingLayersAction = () => ({
  type: END_LOADING_LAYERS,
});

export const fetchLayersSuccessAction = (payload) => ({
  type: FETCHED_LAYERS,
  payload,
});

export const updateLayerSuccessAction = (payload) => ({
  type: UPDATE_LAYER,
  payload,
});

export const removeLayerSuccessAction = (payload) => ({
  type: DELETE_LAYER,
  payload,
});

export const createLayerSuccessAction = (payload) => ({
  type: CREATE_LAYER,
  payload,
});

export const fetchLayersListAction = (storyId, language) => {
  return (dispatch, getState) => {
    const state = getState();
    const currentLang = state.storyList.currentLanguage;
    dispatch(startLoadingLayersAction());

    getLayersListService({ story: storyId, language: language || currentLang })
      .then((res) => {
        dispatch(fetchLayersSuccessAction(res.rows || []));
        dispatch(endLoadingLayersAction());
      })
      .catch((error) => {
        dispatch(endLoadingLayersAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const removeLayerAction = (layer, type = UPDATE_TYPE.STANDART) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = storyListSelectedIdsSelector(state);
    const editLayer = storyListEditItemSelector(state);
    const selectedPage = storyListSelectedPageSelector(state);
    const { id } = storyItemsSelector(state);

    dispatch(startLoadingLayersAction());

    deleteLayerService(layer?.id || editLayer.id, type)
      .then(() => {
        dispatch(removeLayerSuccessAction(layer || editLayer));
        dispatch(fetchHistoryListAction(id));
        dispatch(clearEditItemAction());
        dispatch(setSelectedAction({ ...selectedIds, frameItem: '' }));
        dispatch(endLoadingLayersAction());
        dispatch(
          editItemAction({
            item: selectedPage,
            type: PAGE_TYPE,
          })
        );
        dispatch(setEditAction(false));
        successToast('Layer deleted success');
      })
      .catch((error) => {
        dispatch(endLoadingLayersAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

export const addLayerAction = (body, type = UPDATE_TYPE.STANDART) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = storyListSelectedIdsSelector(state);
    const selectedPage = storyListSelectedPageSelector(state);
    const { id } = storyItemsSelector(state);
    dispatch(startLoadingLayersAction());

    createLayerService(
      {
        ...body,
        story: id,
        originPage:
          type !== UPDATE_TYPE.STANDART ? body.originPage : selectedIds.page,
        originFrame:
          type !== UPDATE_TYPE.STANDART ? body.originFrame : selectedIds.frame,
      },
      type
    )
      .then((layer) => {
        dispatch(createLayerSuccessAction(layer));
        dispatch(fetchHistoryListAction(id));
        dispatch(setEditAction(false));
        dispatch(setSelectedAction({ ...selectedIds, frameItem: '' }));
        dispatch(
          editItemAction({
            item: selectedPage,
            type: PAGE_TYPE,
          })
        );
        dispatch(endLoadingLayersAction());
        successToast('Layer create success');
      })
      .catch((error) => {
        dispatch(endLoadingLayersAction());
        if (error.response.status === 413) {
          errorToast('Speechbubble size is too large');
        } else {
          errorToast(error?.message || 'Something wrong. Try again');
        }
      });
  };
};

export const updateLayerAction = (body, updateType) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = storyListSelectedIdsSelector(state);
    const pagesList = pagesListSelector(state);
    const { id } = storyItemsSelector(state);

    dispatch(startLoadingLayersAction());
    updateLayerService(body, updateType)
      .then((layer) => {
        dispatch(updateLayerSuccessAction(layer));
        dispatch(setEditAction(false));
        dispatch(fetchHistoryListAction(id));
        dispatch(
          setSelectedAction({
            ...selectedIds,
            page: layer.layer.originPage,
            frame: '',
            frameItem: '',
          })
        );
        dispatch(
          editItemAction({
            item: pagesList.find((page) => page.id === layer.layer.originPage),
            type: PAGE_TYPE,
          })
        );
        // todo refactor if update hotspot mappedArrayToPageObject selector didint recalculate
        dispatch(fetchLayersListAction(layer.layer.story));
        dispatch(endLoadingLayersAction());
        successToast('Layer is successfully updated');
      })
      .catch((error) => {
        dispatch(endLoadingLayersAction());
        if (error.response.status === 413) {
          errorToast('Speechbubble size is too large');
        } else {
          errorToast(error?.message || 'Something wrong. Try again');
        }
      });
  };
};

export const updateLayersListAction = (
  layerIds,
  type = UPDATE_TYPE.STANDART
) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(startLoadingLayersAction());
    const { id } = storyItemsSelector(state);

    updateLayerListService({ ids: layerIds }, type)
      .then(() => {
        dispatch(endLoadingLayersAction());
        dispatch(fetchLayersListAction(id));
      })
      .catch((error) => {
        dispatch(endLoadingLayersAction());
        errorToast(error?.message || 'Something wrong. Try again');
      });
  };
};

import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Stories from 'pages/admin/Stories';
import { useDebounce } from 'utils/hooks/useDebounce';
import {
  fetchStoryListAction,
  fetchUsersAction,
} from 'common/actions/storyListActions';
import Header from '../Header';
import useStyles from './styles';

const StoriesListLayout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const fetchStory = useCallback(() => {
    dispatch(fetchStoryListAction(debouncedSearchTerm));
  }, [debouncedSearchTerm, dispatch]);

  const fetchUsers = useCallback(() => {
    dispatch(fetchUsersAction());
  }, [dispatch]);

  useEffect(() => {
    fetchStory();
  }, [fetchStory]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <Box className={classes.wrap}>
      <Box className={classes.contentWrap}>
        <Header />
        <Box className={classes.content}>
          <Stories searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </Box>
      </Box>
    </Box>
  );
};

export default StoriesListLayout;

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0.125),
    paddingBottom: theme.spacing(0.125),

    '& > .MuiFormControlLabel-root': {
      marginRight: theme.spacing(1.25),
    },

    '& .MuiCheckbox-root': {
      padding: theme.spacing(0.375),
    },

    '& .MuiListItemText-primary': {
      fontSize: theme.spacing(1.25),
    },
  },
}));

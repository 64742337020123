import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'constants/index';
import EditStoryLayout from 'components/EditStoryLayout';
import StoriesListLayout from 'components/StoriesListLayout';

const ProtectedRoutes = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.HOME} component={StoriesListLayout} />
      <Route exact path={ROUTES.STORIES_EDIT} component={EditStoryLayout} />
      <Redirect to={ROUTES.HOME} />
    </Switch>
  );
};

export default ProtectedRoutes;

import { createSelector } from 'reselect';
import { mappedArrayToPageObject } from 'utils/mappedArrayToPageObject';

export const decisionListSelector = (state) => state.decisionsList.decisions;
export const decisionListLoadingSelector = (state) =>
  state.decisionsList.loading;

export const mappedDecisionObject = createSelector(
  decisionListSelector,
  (list) => mappedArrayToPageObject(list)
);

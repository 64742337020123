import React from 'react';
import classNames from 'classnames';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';

export const ArrowDownIcon = ({ fill = '#fff', className, ...props }) => {
  return (
    <ArrowDownwardOutlinedIcon
      viewBox='0 0 24 24'
      style={{
        fill,
        width: 18,
        height: 18,
        stroke: '#fff',
        strokeWidth: '1.5',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }}
      className={classNames({
        [className]: !!className,
      })}
      {...props}
    />
  );
};

// <svg
// width='12'
// height='12'
// viewBox='0 0 12 12'
// fill='none'
// xmlns='http://www.w3.org/2000/svg'
// >
// <path
//   d='M11.0646 5.10211L11.6592 5.69669C11.9109 5.94844 11.9109 6.35554 11.6592 6.60462L6.45529 11.8112C6.20354 12.0629 5.79644 12.0629 5.54736 11.8112L0.340796 6.60462C0.0890381 6.35286 0.0890381 5.94577 0.340796 5.69669L0.935372 5.10211C1.18981 4.84767 1.60494 4.85303 1.85402 5.11282L4.92868 8.34014V0.642785C4.92868 0.286575 5.21525 0 5.57146 0H6.42851C6.78472 0 7.0713 0.286575 7.0713 0.642785V8.34014L10.146 5.11282C10.395 4.85035 10.8102 4.845 11.0646 5.10211Z'
//   fill='white'
// />
// </svg>

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import soundIcon from 'assets/icons/sound.svg';
import videoIcon from 'assets/icons/video.svg';
import Image from 'components/Image';
import useStyles from './styles';

const GridBackpack = ({
  width,
  height,
  left,
  top,
  imageUrl,
  imageName,
  audioName,
  videoName,
}) => {
  const classes = useStyles({ width, height, left, top });
  return (
    <Box className={classes.wrap}>
      <Image src={imageUrl} alt={imageName} />
      {(audioName || videoName) && (
        <Box className={classes.mediaIcon}>
          {audioName && <Image src={soundIcon} alt={audioName} />}
          {videoName && <Image src={videoIcon} alt={videoName} />}
        </Box>
      )}
    </Box>
  );
};

GridBackpack.defaultProps = {
  top: '',
  left: '',
  width: '',
  height: '',
  imageUrl: '',
  imageName: '',
};

GridBackpack.propTypes = {
  top: PropTypes.string,
  left: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  imageUrl: PropTypes.string,
  imageName: PropTypes.string,
};

export default GridBackpack;

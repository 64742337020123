export const defaultKeyboardActions = {
  page: false,
  frame: false,
  save: false,
  selectFrame: [],
};

export const ARROW_DIRECTIONS = {
  ArrowUp: 'N',
  ArrowDown: 'S',
  ArrowLeft: 'W',
  ArrowRight: 'E',
};
